import React from "react";
import { Table, Badge, Menu, Icon, Dropdown } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { filterUnemployed } from "utils/filterUnemployed";
import { compareDesc, format, parseISO } from "date-fns";

type Props = {
  history: RouteComponentProps["history"];
  userList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};

export default ({
  userList,
  history,
  currentPage,
  pageSize,
  listTotal,
  handlePagination
}: Props) => {
  const columns = [
    {
      title: "User",
      dataIndex: "",
      key: "firstName",
      render: record =>
        (record.firstName && record.lastName
          ? record.firstName + " " + record.lastName
          : ""
        ).toUpperCase()
    },
    {
      title: "ID Number",
      dataIndex: "idNumber",
      key: "userList"
    },

    {
      title: "Company Name",
      dataIndex: "",
      key: "companyName",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x.company?.registeredName || x.company?.tradingName)
          .join(", ")
          .toUpperCase();
      }
    },
    {
      title: "Capacity",
      dataIndex: "",
      key: "capacity",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x?.capacity?.name)
          .join(", ");
      }
    },
    {
      title: "Council Membership Number",
      dataIndex: "",
      key: "company",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x?.councilMembershipNumber)
          .join(", ");
      }
    },
    {
      title: "Valid Until",
      key: "certificates",
      render: ({ certificates }) => {
        if (!certificates || certificates.length === 0) return "n/a";
        return format(
          certificates
            .map(({ toDate }) => parseISO(toDate))
            .sort(compareDesc)[0],
          "yyyy-MM-dd"
        );
      }
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      key: "userStatus",
      render: record => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "",
      key: "id",
      render: record => {
        const items = [
          <Menu.Item
            key="View user profile"
            onClick={() => history.push(`/admin/member/${record.id}`)}
          >
            View user profile
          </Menu.Item>
        ];

        return (
          <Dropdown
            overlay={<Menu>{items.map(i => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <span className="ant-dropdown-link purple-link">
              More <Icon type="down" title="member actions dropdown" />
            </span>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize,
        onChange: page => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>
      }}
      dataSource={userList}
    />
  );
};
