import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip
} from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { openNotificationWithIcon } from "utils/notification";
import {
  CREATE_USER_LANGUAGE_PROFICIENCY,
  CREATE_USER_QUALIFICATION,
  DELETE_USER_LANGUAGE_PROFICIENCY,
  DELETE_USER_QUALIFICATION,
  REQUEST_OTP,
  UPDATE_USER,
  UPDATE_USER_LANGUAGE_PROFICIENCY,
  UPDATE_USER_QUALIFICATION
} from "../../graphql/mutations";
import {
  GET_LANGUAGES_LIST,
  GET_LANGUAGE_ABILITIES_LIST,
  GET_PROFICIENCY_LEVELS,
  GET_QUALIFICATIONS_TYPES,
  GET_USER_LANGUAGE_PROFICIENCY_LIST,
  GET_USER_QUALIFICATIONS
} from "../../graphql/queries";
import "../Register/index.css";
import { If, IfNot } from "components/If";
import { useRegistrationState } from "stores/registration";
import { useUserState } from "stores/user";

const AuditorAccountantInfoForm: React.FC<any> = ({
  form,
  editProfileMode = false,
  userId
}) => {
  const { id: uid } = useUserState();
  const {
    setRegistrationState,
    manualRegistrationType,
    manualRegisterId
  } = useRegistrationState();
  const { getFieldDecorator } = form;
  const [addingLanguage, setAddingLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [selectedQualification, setSelectedQualification] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();
  const [useUserId, setUserId] = useState<any>(
    manualRegistrationType ? manualRegisterId : userId || uid
  );
  const [userQualificationList, setUserQualificationsList] = useState([]);
  const [addingQualification, setAddingQualification] = useState(false);
  const [languageProficiencySaved, setLanguageProficiencySaved] = useState(
    false
  );
  const [gradeInfoSaved, setGradeInfoSaved] = useState(false);
  const [languageProficiencyList, setLanguageProficiencyList] = useState([]);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 49;
  const [deleteQualification] = useMutation(DELETE_USER_QUALIFICATION);
  const [deleteLanguageProficiency] = useMutation(
    DELETE_USER_LANGUAGE_PROFICIENCY
  );

  useEffect(() => {
    if (typeof userId === "number") {
      setUserId(userId);
    } else {
      setUserId(manualRegistrationType ? manualRegisterId : uid);
    }
  }, [userId]);

  const [upsertLanguageProficiency] = useMutation(
    isEmpty(selectedLanguage)
      ? CREATE_USER_LANGUAGE_PROFICIENCY
      : UPDATE_USER_LANGUAGE_PROFICIENCY,
    {
      onError: () => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving language information. Please try again"
        );
      },
      onCompleted: data => {
        setProficiencyMutationResult(data);
      }
    }
  );

  const [updateUserGradeInformation] = useMutation(UPDATE_USER, {
    onError: () => {
      setIsSubmiting(false);
      openNotificationWithIcon(
        "error",
        "Save Error",
        "Error when saving grade information. Please try again"
      );
    },
    onCompleted: () => {
      setIsSubmiting(false);
      setGradeInfoSaved(true);
      if (languageProficiencySaved && !editProfileMode) {
        setRegistrationState("step5Saved", true);
      }
      openNotificationWithIcon(
        "success",
        "Save Success",
        "Grade information saved successfully"
      );
    }
  });

  const [upsertUserQualification] = useMutation(
    isEmpty(selectedQualification)
      ? CREATE_USER_QUALIFICATION
      : UPDATE_USER_QUALIFICATION,
    {
      onCompleted: data => {
        setQualificationMutationResult(data);
      }
    }
  );

  const [requestOTP] = useMutation(REQUEST_OTP, {
    variables: {
      input: {
        userId: useUserId
      }
    }
  });

  useEffect(() => {
    if (
      !isEmpty(languageProficiencyList) &&
      !isEmpty(userInfo?.highestGrade) &&
      !editProfileMode
    ) {
      setRegistrationState("step5Saved", true);
    }
  }, [languageProficiencyList, userQualificationList, userInfo]);

  const {
    loading: loadingLanguages,
    //error: errorLanguages,
    data: dataLanguages
  } = useQuery(GET_LANGUAGES_LIST);
  const {
    loading: loadingLanguageAbilities,
    //error: LanguageAbilites,
    data: dataLanguageAbilites
  } = useQuery(GET_LANGUAGE_ABILITIES_LIST);
  const {
    loading: loadingProficiencyLevels,
    //error: errorAddressTypes,
    data: dataLanguageProficiencyLevels
  } = useQuery(GET_PROFICIENCY_LEVELS);
  const {
    loading: loadingProficiencies
    //error: errorAddressTypes,
  } = useQuery(GET_USER_LANGUAGE_PROFICIENCY_LIST, {
    variables: { userId: +useUserId },
    onCompleted: data =>
      setLanguageProficiencyList(data.languageProficiencyList)
  });
  const {
    loading: loadingQualificationTypes,
    //error: errorQualificationTypes,
    data: dataQualificationTypes
  } = useQuery(GET_QUALIFICATIONS_TYPES);
  const {
    loading: loadingUserQualifications
    //error: errorAddressTypes,
  } = useQuery(GET_USER_QUALIFICATIONS, {
    variables: { userId: +useUserId },
    onCompleted: data => {
      setUserQualificationsList(data.qualificationsList);
      setUserInfo(data.userData);
    },
    fetchPolicy: "network-only"
  });

  if (
    loadingLanguages ||
    loadingProficiencies ||
    loadingLanguageAbilities ||
    loadingProficiencyLevels ||
    loadingQualificationTypes ||
    loadingUserQualifications
  )
    return (
      <>
        <Skeleton active paragraph={{ rows: 4, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 4, width: "100%" }} />
      </>
    );

  const { proficiencyLevels } =
    !loadingProficiencyLevels && dataLanguageProficiencyLevels;
  const { languagesList } = !loadingLanguages && dataLanguages;
  const { languageAbilitiesList } =
    !loadingLanguageAbilities && dataLanguageAbilites;
  const { qualificationsTypes } =
    !loadingQualificationTypes && dataQualificationTypes;

  //selectable language options
  const languageOptions: any = languagesList
    ? languagesList.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable language options
  const proficiencyLevelOptions: any = proficiencyLevels
    ? proficiencyLevels.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];
  //selectable language ability options
  const languageAbilityOptions: any = languageAbilitiesList
    ? languageAbilitiesList.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  //selectable address types options
  const qualificationTypesOptions: any = qualificationsTypes
    ? qualificationsTypes.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  const yearOptions = [];
  const gradeOptions = [];

  for (let i = minYear; i < maxYear; i++) {
    yearOptions.push(
      <Select.Option key={i} value={i}>
        {i}
      </Select.Option>
    );
  }
  for (let i = 7; i <= 12; i++) {
    gradeOptions.push(
      <Select.Option key={i} value={i.toString()}>
        {i}
      </Select.Option>
    );
  }
  const step = {
    title: "Step 4: Education &  Languages",
    content: "Complete the form stating your credentials and work experience"
  };

  const handleSubmitLanguage = () => {
    form.validateFields(
      ["languageProficiency", "language", "languageAbility"],
      (err, values) => {
        if (err) return;

        setIsSubmiting(true);
        upsertLanguageProficiency({
          variables: {
            input: {
              id: isEmpty(selectedLanguage) ? undefined : selectedLanguage.id,
              proficiencyLevelId: values.languageProficiency,
              abilities: values.languageAbility,
              languageId: values.language,
              userId: isEmpty(selectedLanguage) ? useUserId : undefined
            }
          }
        });
        form.resetFields();
        setSelectedLanguage({});
      }
    );
  };

  const handleSubmitGradeInfo = () => {
    form.validateFields(
      ["highestGrade", "yearGradeObtained"],
      (err, values) => {
        if (err) return;

        setIsSubmiting(true);
        updateUserGradeInformation({
          variables: {
            input: {
              highestGrade: values.highestGrade,
              highestGradeYear: values.yearGradeObtained.toString(),
              id: useUserId
            }
          }
        });
      }
    );
  };

  const handleSubmitQualificationInfo = () => {
    form.validateFields(
      [
        "qualificationType",
        "nameOfInstitution",
        "qualification",
        "yearQualificationObtained"
      ],
      (err, values) => {
        if (err) return;

        setIsSubmiting(true);
        upsertUserQualification({
          variables: {
            input: {
              id: isEmpty(selectedQualification)
                ? undefined
                : selectedQualification.id,
              institutionName: values.nameOfInstitution,
              year: values.yearQualificationObtained,
              qualification: values.qualification,
              typeId: values.qualificationType,
              userId: isEmpty(selectedQualification) ? useUserId : undefined
            }
          }
        });
        form.resetFields();
        setSelectedQualification({});
      }
    );
  };

  const setProficiencyMutationResult = data => {
    setAddingLanguage(false);
    let proficiencyList = languageProficiencyList;
    if (data.createLanguageProficiency) {
      proficiencyList.push(data.createLanguageProficiency);
    }
    if (data.updateLanguageProficiency) {
      proficiencyList = proficiencyList.filter(
        x => x.id !== data.updateLanguageProficiency.id
      );
      proficiencyList.push(data.updateLanguageProficiency);
    }
    setLanguageProficiencyList(proficiencyList);
    setLanguageProficiencySaved(true);
    setIsSubmiting(false);
    if (gradeInfoSaved && !editProfileMode) {
      setRegistrationState("step5Saved", true);
    }
    openNotificationWithIcon(
      "success",
      "Save Success",
      "Language information saved successfully"
    );
  };

  const setQualificationMutationResult = data => {
    let qualificationList = userQualificationList;

    if (data.createEducationalQualification) {
      qualificationList.push(data.createEducationalQualification);

      setUserInfo(data.createEducationalQualification?.user);
    }
    if (data.updateEducationalQualification) {
      qualificationList = qualificationList.filter(
        x => x.id !== data.updateEducationalQualification.id
      );
      qualificationList.push(data.updateEducationalQualification);

      setUserInfo(data.updateEducationalQualification?.user);
    }

    setUserQualificationsList(qualificationList);
    setAddingQualification(false);
    setIsSubmiting(false);

    if (!isEmpty(userInfo?.highestGrade) && !isEmpty(languageProficiencyList)) {
      if (!editProfileMode) {
        requestOTP()
          .then(() => {
            setRegistrationState("step5Saved", true);
            openNotificationWithIcon(
              "success",
              "Save Success",
              "Qualification information saved successfully"
            );
          })
          .catch(() => {
            openNotificationWithIcon(
              "error",
              "OTP Service Error",
              "Information saved, but could not send the OTP"
            );
          });
      } else {
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Qualification information saved successfully"
        );
      }
    }
  };

  const handleDeleteQualification = async qualification => {
    setIsDeleting(true);
    if (qualification.id) {
      await deleteQualification({ variables: { id: qualification.id } });

      let qualificationList = userQualificationList;
      qualificationList = qualificationList.filter(
        x => x.id !== qualification.id
      );
      setUserQualificationsList(qualificationList);
    }
    setIsDeleting(false);
    openNotificationWithIcon(
      "success",
      "Deletion Success",
      "Qualification information has been deleted successfully "
    );
  };

  const handleDeleteProficiency = async proficiency => {
    setIsDeleting(true);
    if (proficiency.id) {
      await deleteLanguageProficiency({ variables: { id: proficiency.id } });
      let proficiencyList = languageProficiencyList;
      proficiencyList = languageProficiencyList.filter(
        x => x.id !== proficiency.id
      );
      setLanguageProficiencyList(proficiencyList);
    }
    setIsDeleting(false);
    openNotificationWithIcon(
      "success",
      "Deletion Success",
      "Language information has been deleted successfully "
    );
  };

  return (
    <div className="" style={{ marginBottom: "30px" }}>
      <IfNot condition={!!editProfileMode}>
        <div>
          <span className="step-title">{step.title}</span>
          <p>{step.content}</p>
          <Divider />
        </div>

        <Row type="flex" justify="space-between">
          <h3>Language Proficiency</h3>
          <Button
            type="link"
            className="purple-text"
            onClick={() => setAddingLanguage(true)}
            disabled={addingLanguage}
            icon="plus"
          >
            Add Language
          </Button>
        </Row>
        <Row>
          <p style={{ marginBottom: "20px" }}>
            Please indicate languages in which you are proficient from the
            dropdown headers, as well as level of proficiency below each
            language selected.
          </p>
        </Row>
      </IfNot>

      <If condition={isEmpty(languageAbilitiesList) && !addingLanguage}>
        <Row type="flex" justify="center" style={{ marginBottom: "20px" }}>
          <p>
            You haven't added any languages. Use the button above to add
            languages
          </p>
        </Row>
      </If>

      <If condition={!isEmpty(languageProficiencyList)}>
        <Table
          pagination={false}
          dataSource={languageProficiencyList}
          columns={[
            {
              title: "Language",
              key: "language",
              render: record => {
                return record.language?.name || "";
              }
            },
            {
              title: "Proficiency Level",
              key: "proficiencyLevel",
              render: record => {
                return record?.proficiencyLevel?.name || "";
              }
            },
            {
              title: "Abilities",
              key: "abilities",
              render: record => {
                return record.abilities?.map(a => a.name)?.join(", ") || "";
              }
            },
            {
              title: "Action",
              key: "type",
              render: record => {
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Popover content="Edit/Update language">
                      <Button
                        type="link"
                        icon="edit"
                        onClick={() => {
                          setSelectedLanguage(record);
                          setAddingLanguage(true);
                        }}
                        style={{ color: "orange" }}
                      />
                    </Popover>
                    <Popover content="Delete language">
                      <Button
                        type="link"
                        icon="delete"
                        disabled={isDeleting}
                        onClick={() => {
                          handleDeleteProficiency(record);
                        }}
                        style={{ color: "red" }}
                      />
                    </Popover>
                  </div>
                );
              }
            }
          ]}
        />
      </If>

      <If condition={addingLanguage}>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item label="Language">
              {getFieldDecorator("language", {
                initialValue: selectedLanguage?.language?.id,
                rules: [
                  {
                    required: true,
                    message: "Please select language"
                  }
                ]
              })(
                <Select className="input-select" placeholder="Select...">
                  {languageOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Proficiency">
              {getFieldDecorator("languageProficiency", {
                initialValue: selectedLanguage?.proficiencyLevel?.id,
                rules: [
                  {
                    required: true,
                    message: "Please select language proficiency"
                  }
                ]
              })(
                <Select className="input-select" placeholder="Select...">
                  {proficiencyLevelOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <Tooltip title="You may select multiple">
                  Ability&nbsp;
                  <Icon type="info-circle" />
                </Tooltip>
              }
            >
              {getFieldDecorator("languageAbility", {
                initialValue: selectedLanguage?.abilities?.map(a => a.id),
                rules: [
                  {
                    required: true,
                    message: "Please select language ability"
                  }
                ]
              })(
                <Select
                  className="input-select"
                  mode="multiple"
                  placeholder="Select..."
                >
                  {languageAbilityOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <div
              className="flex-column input-block input-select input-spacer"
              style={{ gap: "5px", padding: "2px" }}
            >
              <Button
                className="btn-registration-capacity-selected"
                onClick={handleSubmitLanguage}
                loading={isSubmitting}
                icon="save"
              >
                Save
              </Button>
              <Button
                icon="close-circle"
                onClick={() => setAddingLanguage(false)}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </If>

      <Row style={{ marginTop: "30px" }}>
        <Row type="flex" justify="space-between">
          <h3>High School Grade</h3>
        </Row>

        <If condition={loadingUserQualifications}>
          <Skeleton active />
        </If>

        <Row type="flex" gutter={10} align="bottom">
          <Col span={8}>
            <Form.Item label="Highest grade obtained">
              {getFieldDecorator("highestGrade", {
                initialValue: userInfo?.highestGrade,
                rules: [
                  {
                    required: true,
                    message: "Please enter highest grade obtained"
                  }
                ]
              })(
                <Select placeholder="Select..." className="input-select">
                  {gradeOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Year obtained">
              {getFieldDecorator("yearGradeObtained", {
                initialValue: userInfo?.highestGradeYear,
                rules: [
                  {
                    required: true,
                    message: "Please select the year the grade was obtained"
                  }
                ]
              })(
                <Select placeholder="Select" className="input-select">
                  {yearOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              className="purple-button full-width"
              onClick={handleSubmitGradeInfo}
              loading={isSubmitting}
              icon="save"
              style={{ marginBottom: "28px" }}
            >
              Save
            </Button>
          </Col>
        </Row>

        <IfNot condition={!!editProfileMode}>
          <Row
            type="flex"
            justify="space-between"
            style={{ marginTop: "30px" }}
          >
            <h3>Qualifications</h3>
            <Button
              type="link"
              className="purple-text"
              onClick={() => setAddingQualification(true)}
              disabled={addingQualification}
              icon="plus"
            >
              Add Qualification
            </Button>
          </Row>
          <Row>
            <p>
              Please complete below, all qualifications (certificate, diplomas,
              degrees) obtained:
            </p>
          </Row>
        </IfNot>

        <If condition={addingQualification}>
          <Row gutter={5}>
            <Col span={3}>
              <Form.Item label="Type">
                {getFieldDecorator("qualificationType", {
                  initialValue: selectedQualification?.type?.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select the type of qualification"
                    }
                  ]
                })(
                  <Select className="input-select" placeholder="Select...">
                    {qualificationTypesOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Institution">
                {getFieldDecorator("nameOfInstitution", {
                  initialValue: selectedQualification?.institutionName,
                  rules: [
                    {
                      required: true,
                      message: "Please enter the name of your institution"
                    }
                  ]
                })(
                  <Input placeholder="Please enter the name of your institution" />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Qualification">
                {getFieldDecorator("qualification", {
                  initialValue: selectedQualification?.qualification,
                  rules: [
                    {
                      required: true,
                      message: "Please enter your qualification"
                    }
                  ]
                })(<Input placeholder="Please enter your qualification" />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Year">
                {getFieldDecorator("yearQualificationObtained", {
                  initialValue: selectedQualification?.year,
                  rules: [
                    {
                      required: true,
                      message: "Please select year"
                    }
                  ]
                })(
                  <Select className="input-select" placeholder="Select...">
                    {yearOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Row
                type="flex"
                align="bottom"
                justify="center"
                style={{ paddingTop: "10px" }}
              >
                <Button
                  onClick={handleSubmitQualificationInfo}
                  loading={isSubmitting}
                  icon="save"
                  className="purple-button"
                  style={{ width: "100%", margin: 0 }}
                >
                  Save
                </Button>
                <Button
                  icon="close-circle"
                  onClick={() => setAddingQualification(false)}
                  className="full-width"
                  style={{ marginTop: "5px" }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </If>

        <If condition={!isEmpty(userQualificationList)}>
          <Table
            pagination={false}
            dataSource={userQualificationList}
            columns={[
              {
                title: "type",
                key: "type",
                render: record => {
                  return record.type?.name;
                }
              },
              {
                title: "Institution",
                key: "institutionName",
                render: record => {
                  return record.institutionName;
                }
              },
              {
                title: "Qualification",
                key: "qualification",
                render: record => {
                  return record.qualification;
                }
              },
              {
                title: "Year",
                key: "year",
                render: record => {
                  return record.year;
                }
              },
              {
                title: "Action",
                key: "type",
                render: record => {
                  return (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Popover content="Edit/Update qualification">
                        <Button
                          type="link"
                          icon="edit"
                          onClick={() => {
                            setSelectedQualification(record);
                            setAddingQualification(true);
                          }}
                          style={{ color: "orange" }}
                        />
                      </Popover>
                      <Popover content="Delete qualification">
                        <Button
                          type="link"
                          icon="delete"
                          onClick={() => {
                            handleDeleteQualification(record);
                          }}
                          style={{ color: "red" }}
                        />
                      </Popover>
                    </div>
                  );
                }
              }
            ]}
          />
        </If>

        <If condition={isEmpty(userQualificationList) && !addingQualification}>
          <Row type="flex" justify="center" style={{ margin: "10px 0 10px 0" }}>
            <p>
              You haven't added any qualifications. Use the button above to add
              qualifications
            </p>
          </Row>
        </If>
      </Row>
    </div>
  );
};

interface IProps extends FormComponentProps {
  editProfileMode?: boolean;
  userId?: number;
  nextStep?: () => void;
}

const AuditorAccountantInfo = Form.create<IProps>({ name: "normal_register" })(
  AuditorAccountantInfoForm
);
export default AuditorAccountantInfo;
