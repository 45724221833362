import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";
import Completed from "../../assets/images/task_complete.svg";
import "./index.css";

export default function Verification() {
  const history = useHistory();

  return (
    <div className="verification-result">
      <Result
        className="flex-column"
        title="Successful Payment"
        subTitle="Your payment was successfully processed."
        extra={[
          <Button type="default" onClick={() => history.replace("/tasks")}>
            Go back to tasks
          </Button>
        ]}
        icon={
          <img
            src={Completed}
            alt="Successful payment"
            className="result-icon"
          />
        }
      />
    </div>
  );
}
