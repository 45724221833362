import React, { Fragment, useState } from "react";
import { Card, Checkbox, Button } from "antd";
import InformationLoader from "./informationLoader";
import { useQuery } from "@apollo/react-hooks";
import { GET_COMPANY_INFO } from "../graphql/queries";
import { isEmpty } from "lodash";
import ResultComment from "./resultComment";
import "./index.css";
import DocumentView from "components/Uploader/DocumentView";

const ReviewStep1: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { company } = transaction;
  const [companyInfo, setCompanyInfo] = useState<any>();
  const [addressInfo, setAddressInfo] = useState<any>();
  const [contactsInfo, setContactsInfo] = useState<any>();
  const [membersInfo, setMembersInfo] = useState<any>();
  const [cipcDocument, setCipcDocument] = useState<any>();

  const { loading } = useQuery(GET_COMPANY_INFO, {
    variables: { companyId: company?.id },
    onCompleted: async data => {
      setCompanyInfo(data.companyBusinessInformation?.company);
      setAddressInfo(data.companyBusinessInformation?.locations);
      setContactsInfo(data.companyBusinessInformation?.contacts);
      setMembersInfo(data.companyBusinessInformation?.members);
      setCipcDocument(data.companyBusinessInformation?.cipcDocument);
    }
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "BusinessInformation",
    reviewInfo,
    registrationTaskId
  };

  if (loading) return <InformationLoader />;

  return (
    <div className="business-info-wrapper">
      <div className="flex-column information-block">
        <br />
        <span>
          Registration Type: <label>New Registration</label>
        </span>
        {companyInfo?.company?.registrationCapacity ? (
          <span>
            Registration Capacity:{" "}
            <label>{companyInfo?.company?.registrationCapacity}</label>
          </span>
        ) : null}

        {companyInfo?.registeredName ? (
          <span>
            Registered Name: <label>{companyInfo?.registeredName}</label>
          </span>
        ) : null}
        <span>
          Registering as: <label>{companyInfo?.natureOfBusiness}</label>
        </span>
        <span>
          Trading name: <label>{companyInfo?.tradingName}</label>
        </span>
        <span>
          Trading as: <label>{companyInfo?.tradingAs}</label>
        </span>
        <span>
          Is this business registered for VAT:{" "}
          <label>{companyInfo?.isRegisteredForVat ? "Yes" : "No"}</label>
        </span>
        {companyInfo?.vatNumber ? (
          <span>
            VAT number: <label>{companyInfo?.vatNumber}</label>
          </span>
        ) : null}
        <span>
          CIPC Document:{" "}
          {!cipcDocument && <label>Document not Submitted</label>}
        </span>
        {cipcDocument && <DocumentView documents={[cipcDocument]} />}
      </div>

      <div>
        <br />
        <label>Location Information:</label>
        {!isEmpty(addressInfo) &&
          addressInfo.map((contact, index) => {
            return (
              <Fragment key={index}>
                <Card className="information-card" key={`Address ${index + 1}`}>
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      {`Address ${index + 1}`}
                    </Button>
                    <br />
                    <br />
                    <span>
                      Address type: <label>{contact.addressType?.name}</label>
                      <br />
                      Complex/Building/Apartment name:{" "}
                      <label>{contact.addressName}</label>
                      <br />
                      Street Address: <label>{contact.streetAddress}</label>
                      <br />
                      Town/Suburb: <label>{contact.suburb}</label>
                      <br />
                      City: <label>{contact.city}</label>
                      <br />
                      Postal Code: <label>{contact.postalCode}</label>
                      <br />
                      {!contact.addressType?.name.includes("Postal") && (
                        <Checkbox
                          checked={contact.domicilliumAddress}
                          value={contact.domicilliumAddress}
                        >
                          Domicillium Address
                        </Checkbox>
                      )}
                    </span>
                  </div>
                </Card>
                <br />
              </Fragment>
            );
          })}
      </div>

      <div>
        <br />
        <label>Related team members:</label>
        <br />
        {!isEmpty(membersInfo) &&
          membersInfo.map((representative, idx) => {
            return (
              <Fragment key={idx}>
                <Card
                  key={representative?.firstName}
                  className="information-card"
                >
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      Related Party: {representative?.firstName}
                    </Button>
                    <br />
                    <br />
                    <span>
                      First Name: <label>{representative?.firstName}</label>
                      <br />
                      Last Name: <label>{representative?.lastName}</label>
                      <br />
                      ID/Passport number:{" "}
                      <label>{representative?.idNumber}</label>
                      <br />
                      Role: <label>{representative?.capacity?.name}</label>
                    </span>
                  </div>
                </Card>
                <br />
              </Fragment>
            );
          })}
      </div>
      <div>
        <br />
        <label>Contacts Information:</label>
        <br />
        {!isEmpty(contactsInfo) &&
          contactsInfo.map((contact, idx) => {
            return (
              <Fragment key={idx}>
                <Card
                  className="information-card"
                  key={contact.businessUnit.name}
                >
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      Company Contact: {contact.businessUnit.name}
                    </Button>
                    <br />
                    <br />
                    <span>
                      Contact Name:{" "}
                      <label>{contact.businessUnitContactName}</label>
                      <br />
                      Cellphone: <label>{contact.cellphone}</label>
                      <br />
                      Cellphone 2: <label>{contact.secondaryCellphone}</label>
                      <br />
                      Email: <label>{contact.email}</label>
                      <br />
                      Work Number: <label>{contact.workPhone}</label>
                      <br />
                      Home Number: <label>{contact.homePhone}</label>
                      <br />
                      Email2: <label>{contact.secondaryEmail}</label>
                    </span>
                  </div>
                </Card>
                <br />
              </Fragment>
            );
          })}
      </div>
      <ResultComment {...ResultComponentsProps} />
    </div>
  );
};

export default ReviewStep1;
