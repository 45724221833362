import React, { useState, useEffect } from "react";
import { Form, Radio, Input, Button, Skeleton, Divider } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import "../Register/index.css";
import { COMPANY_TYPES } from "../../constants";
import { GET_AUDITOR_ACCOUNTANT_INFO } from "../../graphql/queries";
import {
  CREATE_AUDITOR_ACCOUNTANT_INFO,
  UPDATE_AUDITOR_ACCOUNTANT_INFO,
  REQUEST_OTP
} from "../../graphql/mutations";
import { useMutation, useQuery } from "react-apollo";
import { openNotificationWithIcon } from "utils/notification";
import { isInteger } from "lodash";
import { useRegistrationState } from "stores/registration";
import { useUserState } from "stores/user";

function AuditorAccountantInfoForm({
  useCompanyId,
  useRegisteringAs,
  isRegistrationMode = true,
  ...props
}) {
  const { getFieldDecorator } = props.form;
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    companyId: cid,
    ...rest
  } = useRegistrationState();

  const { id: userId } = useUserState();

  //const [companyId, setCompanyId] = useState("");
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [role, setRole] = useState("Auditor");
  const [businessName, setBusinessName] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactOfficeNumber, setContactOfficeNumber] = useState("");
  const [contactCellNumber, setContactCellNumber] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [currentStep, setCurrentStep] = useState("step5Saved");

  const registeredAs = useRegisteringAs || registeringAs;
  const companyId = useCompanyId ? +useCompanyId : cid;
  const isCompany = COMPANY_TYPES.includes(registeredAs);

  const [auditorAccountantId, setAuditorAccountantId] = useState<number>();
  const [upsertAuditorInfo] = useMutation(
    isInteger(auditorAccountantId)
      ? UPDATE_AUDITOR_ACCOUNTANT_INFO
      : CREATE_AUDITOR_ACCOUNTANT_INFO,
    {
      onError: () => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving auditor/accountant information. Please try again"
        );
      },
      onCompleted: () => {
        setIsSubmiting(false);
        if (isRegistrationMode) {
          // trigger OTP
          requestOTP()
            .then(() => {
              setRegistrationState({ [currentStep]: true });
              openNotificationWithIcon(
                "success",
                "Save Success",
                "Company auditor/accountant information saved successfully"
              );
            })
            .catch(() => {
              openNotificationWithIcon(
                "error",
                "OTP Service Error",
                "Information saved, but could not send the OTP"
              );
            });
        }
      }
    }
  );

  useEffect(() => {
    isCompany ? setCurrentStep("step5Saved") : setCurrentStep("step7Saved");
  }, [registeredAs, isCompany]);

  useEffect(() => {
    if (!!rest[currentStep] || !isRegistrationMode) {
      refetchAuditorInfo({ companyId }).then(resp => {
        setAuditorAccountantInfo(resp.data);
      });
    }
  }, []);

  const { loading: loadingAuditorInfo, refetch: refetchAuditorInfo } = useQuery(
    GET_AUDITOR_ACCOUNTANT_INFO,
    {
      variables: {
        companyId
      },
      onError: () =>
        openNotificationWithIcon(
          "error",
          "Error",
          "Error loading auditor/accountant information"
        ),
      onCompleted: dataAccountantInfo => {
        setAuditorAccountantInfo(dataAccountantInfo);
      }
    }
  );
  const [requestOTP] = useMutation(REQUEST_OTP, {
    variables: {
      input: {
        userId
      }
    }
  });

  const step = {
    title: `Step ${isCompany ? 5 : 7}: Auditor/Accountant information`,
    content: "Complete your auditor/accountant information"
  };

  const setAuditorAccountantInfo = dataAccountantInfo => {
    if (!isInteger(dataAccountantInfo.companyAccountant)) {
      setBusinessName(dataAccountantInfo.companyAccountant?.businessName);
      setContactPersonName(
        dataAccountantInfo.companyAccountant?.contactPersonName
      );
      setContactOfficeNumber(
        dataAccountantInfo.companyAccountant?.contactOfficeNumber
      );
      setContactCellNumber(
        dataAccountantInfo.companyAccountant?.contactCellNumber
      );
      setBusinessEmail(dataAccountantInfo.companyAccountant?.email);
      setRole(dataAccountantInfo.companyAccountant?.role);
      setAuditorAccountantId(dataAccountantInfo.companyAccountant?.id);
    }
  };

  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;

      setIsSubmiting(true);

      upsertAuditorInfo({
        variables: {
          input: {
            id: isInteger(auditorAccountantId)
              ? auditorAccountantId
              : undefined,
            businessName: values.auditorBusinessName,
            contactPersonName: values.auditorContactPerson,
            contactOfficeNumber: values.auditorOfficeNumber,
            contactCellNumber: values.auditorCellphone,
            email: values.auditorEmail,
            role: values.role,
            companyId: isInteger(auditorAccountantId) ? undefined : companyId
          }
        }
      });
    });
  };

  if (loadingAuditorInfo) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <div className="flex-column">
      {isRegistrationMode && (
        <>
          <div>
            <span className="step-title">{step.title}</span>
            <p>{step.content}</p>
            <Divider />
          </div>
        </>
      )}
      <div className="input-block-wrapper">
        <div className="flex-column input-block input-select">
          <Form.Item label="Please select role">
            {getFieldDecorator("role", {
              initialValue: role,
              rules: [
                {
                  required: true,
                  message: "Please enter branch code"
                }
              ]
            })(
              <Radio.Group
                onChange={e => {
                  setRole(e.target.value);
                }}
                className="input-select input-spacer"
              >
                <Radio value={"Auditor"}>Auditor</Radio>
                <Radio value={"Accountant"} disabled>
                  Accountant
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </div>
        <div className="flex-column input-block">
          <Form.Item label="Name of business">
            {getFieldDecorator("auditorBusinessName", {
              initialValue: businessName,
              rules: [
                {
                  required: true,
                  message: "Please enter name of auditor"
                }
              ]
            })(
              <Input placeholder="Please enter name of auditor / accountant" />
            )}
          </Form.Item>
        </div>
        <div className="flex-column input-block">
          <Form.Item label="Contact person name">
            {getFieldDecorator("auditorContactPerson", {
              initialValue: contactPersonName,
              rules: [
                {
                  required: true,
                  message: "Please enter branch code"
                }
              ]
            })(<Input placeholder="Please enter name and surname" />)}
          </Form.Item>
        </div>
        <div className="flex-column input-block">
          <Form.Item label="Contact number (office)">
            {getFieldDecorator("auditorOfficeNumber", {
              initialValue: contactOfficeNumber,
              rules: [
                {
                  required: true,
                  message: "Please enter office number"
                }
              ]
            })(<Input placeholder="Please enter office number" />)}
          </Form.Item>
        </div>
        <div className="flex-column input-block">
          <Form.Item label="Contact number (cell)">
            {getFieldDecorator("auditorCellphone", {
              initialValue: contactCellNumber,
              rules: [
                {
                  required: false,
                  message: "Please enter cellphone number"
                }
              ]
            })(
              <Input placeholder="Please enter cellphone number (optional)" />
            )}
          </Form.Item>
        </div>
        <div className="flex-column input-block">
          <Form.Item label="Email address">
            {getFieldDecorator("auditorEmail", {
              initialValue: businessEmail,
              rules: [
                {
                  required: true,
                  message: "Please enter email address"
                },
                {
                  type: "email",
                  message: "Please enter a valid email address"
                }
              ]
            })(<Input placeholder="Please enter email address" />)}
          </Form.Item>
        </div>
        <div className="flex-column input-block input-select input-spacer">
          <Button
            className="btn-registration-capacity-selected"
            onClick={handleSubmit}
            loading={isSubmitting}
          >
            Save information
          </Button>
        </div>
      </div>
    </div>
  );
}

interface IProps extends FormComponentProps {
  useCompanyId?: number;
  isRegistrationMode?: boolean;
  useRegisteringAs?: string;
  nextStep?: () => void;
}

const AuditorAccountantInfo = Form.create<IProps>({ name: "normal_register" })(
  AuditorAccountantInfoForm
);
export default AuditorAccountantInfo;
