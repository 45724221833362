import SignUp from "containers/SignUp";
import ForgotPassword from "../containers/ForgotPassword";
import AdminTaskReview from "../containers/Admin/Review";
import AdminRenewalTaskReview from "../containers/Admin/Review/Renewal";
import AdminPenaltyTaskReview from "../containers/Admin/Review/Penalty";
import ComplaintTaskReview from "../containers/Admin/Complaint";
import Dashboard from "../containers/Dashboard";
import UserProfile from "../containers/Profile";
import BusinessProfile from "../containers/BusinessProfile";
import AdminProfile from "../containers/Admin/Profile";
import TeamProfile from "../containers/Admin/Team/Profile";
import Tasks from "../containers/Admin/Tasks";
import Transactions from "../containers/Admin/Transactions";
import NormalTransactions from "../containers/Transactions";
import TransactionOverview from "../containers/Admin/Transactions/transactionOverview";
import RegistrationTask from "../containers/Tasks/Registration";
import GeneralTask from "../containers/Admin/Tasks/taskOverview";
import PaymentTask from "../containers/Tasks/paymentTask";
import RenewalTask from "../containers/Tasks/renewalTask";
import PenaltyTask from "../containers/Tasks/penaltyTask";
import AuditTask from "../containers/Tasks/Audit/index";
import DirectTaskReview from "../containers/Tasks/directTask";
import Register from "../containers/Register";
import RegistrationSuccess from "../containers/Register/registrationSuccess";
import RegistrationResult from "../containers/Register/registrationResults";
import Verification from "../containers/Register/paymentVerifiction";
import Login from "../containers/Login";
import Members from "../containers/Admin/Members";
import Team from "../containers/Admin/Team";
import MemberOverview from "../containers/Admin/Members/MemberOverview";
import CompanyOverview from "../containers/Admin/Companies/CompanyOverview";
import Roles from "../components/UserManagement/ListRoles";
import Permissions from "../components/UserManagement/ListPermissions";
import Companies from "../containers/Admin/Companies";
import Reports from "../containers/Admin/Reports";
import BusinessUnits from "../containers/BusinessUnits/businessUnitList";
import Complaint from "../containers/Complaint";
import DebtCollectorComplaint from "../containers/Complaint/debtCollectorForm";
import UserTasks from "../containers/Tasks";
import Documents from "../containers/Documents";
import Employees from "../containers/Employees";
import RegisterManual from "../containers/Admin/Team/Register";
import BatchRegistration from "containers/Register/BatchRegistration/BatchRegistration";
import BatchRegistrationTask from "containers/Register/BatchRegistration/BatchRegistrationTask";
import BatchRegistrationComplete from "containers/Register/BatchRegistration/CompleteRegistration";
import BlackList from "components/UserManagement/BlackList";
import WithdrawnEmployment from "containers/Admin/WithdrawnEmployment/WithdrawnEmployment";
import PendingApplications from "containers/Admin/PendingApplications/PendingApplications";

const PRIVATE_ROUTES = [
  {
    exact: true,
    path: "/",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "Dashboard" }],
    component: Dashboard,
    title: "Dashboard"
  },
  {
    exact: true,
    path: "/profile",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "Profile" }],
    component: UserProfile,
    title: "Profile"
  },
  {
    exact: true,
    path: "/business-profile",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "BusinessProfile" }],
    component: BusinessProfile,
    title: "BusinessProfile"
  },
  {
    exact: true,
    path: "/tasks",
    breadcrumbs: [{ path: "/", title: "Tasks" }],
    component: UserTasks,
    title: "Tasks"
  },
  {
    exact: true,
    path: "/documents",
    breadcrumbs: [{ path: "/", title: "Documents" }],
    component: Documents,
    title: "Documents"
  },
  {
    exact: true,
    path: "/admin/documents",
    breadcrumbs: [{ path: "/", title: "Documents" }],
    component: Documents,
    title: "Documents"
  },
  {
    exact: true,
    path: "/employees",
    breadcrumbs: [{ path: "/", title: "Employees" }],
    component: Employees,
    title: "Employees"
  },
  {
    exact: true,
    path: "/register",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "Register" }],
    component: Register,
    title: "Register"
  },
  {
    exact: true,
    path: "/register/employee-invitation/:invitationCode",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "Register" }],
    component: Register,
    title: "Register"
  },
  {
    exact: true,
    path: "/register/results",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "RegistrationSuccess" }],
    component: RegistrationSuccess,
    title: "RegistrationSuccess"
  },
  {
    exact: true,
    path: "/register/results/business",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "RegistrationResults" }],
    component: RegistrationResult,
    title: "RegisterationResultsBusiness"
  },
  {
    exact: true,
    path: "/payment-task-verification",
    positionInNav: 2,
    breadcrumbs: [{ path: "/", title: "Verification" }],
    component: Verification,
    title: "Verification"
  },
  {
    exact: true,
    path: "/admin/team/manual-register",
    component: RegisterManual,
    title: "Manual Register"
  },
  {
    exact: true,
    path: "/admin/team",
    breadcrumbs: [{ path: "/", title: "Team Management" }],
    component: Team,
    title: "Team Management"
  },
  {
    exact: true,
    path: "/admin/team/:teamMemberId",
    breadcrumbs: [{ path: "/", title: "Team Management" }],
    component: TeamProfile,
    title: "Team Member Profile"
  },
  {
    exact: true,
    path: "/admin/members",
    breadcrumbs: [{ path: "/", title: "All Members" }],
    component: Members,
    title: "All Members"
  },
  {
    exact: true,
    path: "/admin/member/:memberId",
    breadcrumbs: [{ path: "/", title: "Member" }],
    component: MemberOverview,
    title: "Member profile"
  },
  {
    exact: true,
    path: "/admin/roles",
    breadcrumbs: [{ path: "/", title: "Roles" }],
    component: Roles,
    title: "Roles"
  },
  {
    exact: true,
    path: "/admin/permissions",
    breadcrumbs: [{ path: "/", title: "Permissions" }],
    component: Permissions,
    title: "Permissions"
  },
  {
    exact: true,
    path: "/admin/blacklist",
    breadcrumbs: [{ path: "/", title: "BlackList" }],
    component: BlackList,
    title: "BlackList"
  },
  {
    exact: true,
    path: "/admin/withdrawn-employment",
    breadcrumbs: [{ path: "/", title: "Withdrawn Employment" }],
    component: WithdrawnEmployment,
    title: "Withdrawn Employment"
  },
  {
    exact: true,
    path: "/admin/pending-applications",
    breadcrumbs: [{ path: "/", title: "Pending Applications" }],
    component: PendingApplications,
    title: "Pending Applications"
  },
  {
    exact: true,
    path: "/admin/companies",
    breadcrumbs: [{ path: "/", title: "Companies" }],
    component: Companies,
    title: "Companies"
  },
  {
    exact: true,
    path: "/admin/reports",
    breadcrumbs: [{ path: "/", title: "Reports" }],
    component: Reports,
    title: "Reports"
  },
  {
    exact: true,
    path: "/admin/company/:companyId",
    breadcrumbs: [{ path: "/", title: "Company" }],
    component: CompanyOverview,
    title: "Company profile"
  },
  {
    exact: true,
    path: "/admin/businessunits",
    breadcrumbs: [{ path: "/", title: "BusinessUnits" }],
    component: BusinessUnits,
    title: "BusinessUnits"
  },
  {
    exact: true,
    path: "/admin/profile",
    breadcrumbs: [{ path: "/", title: "Profile" }],
    component: AdminProfile,
    title: "Profile"
  },
  {
    exact: true,
    path: "/admin/tasks",
    breadcrumbs: [{ path: "/", title: "Tasks" }],
    component: Tasks,
    title: "Tasks"
  },
  {
    exact: true,
    path: "/admin/transactions",
    breadcrumbs: [{ path: "/", title: "Transactions" }],
    component: Transactions,
    title: "Transactions"
  },
  {
    exact: true,
    path: "/admin/transaction/:transactionId",
    breadcrumbs: [{ path: "/", title: "Transaction Overview" }],
    component: TransactionOverview,
    title: "TransactionOverview"
  },
  {
    exact: true,
    path: "/transactions",
    breadcrumbs: [{ path: "/", title: "Transactions" }],
    component: NormalTransactions,
    title: "NormalTransactions"
  },
  {
    exact: true,
    path: "/admin/tasks/review",
    breadcrumbs: [
      { path: "/tasks/new applicants/review", title: "TaskReview" }
    ],
    component: AdminTaskReview,
    title: "TaskReview"
  },
  {
    exact: true,
    path: "/admin/tasks/review/renewal",
    breadcrumbs: [
      { path: "/tasks/new applicants/review", title: "TaskReview" }
    ],
    component: AdminRenewalTaskReview,
    title: "RenewalTaskReview"
  },
  {
    exact: true,
    path: "/admin/tasks/review/audit/:id",
    component: AuditTask,
    title: "AuditTaskReview"
  },
  {
    exact: true,
    path: "/admin/tasks/review/penalty",
    breadcrumbs: [
      { path: "/tasks/new applicants/review", title: "TaskReview" }
    ],
    component: AdminPenaltyTaskReview,
    title: "PenaltyTaskReview"
  },
  {
    exact: true,
    path: "/admin/tasks/:id",
    breadcrumbs: [
      { path: "/tasks/complaint/review", title: "ComplaintTaskReview" }
    ],
    component: ComplaintTaskReview,
    title: "ComplaintTaskReview"
  },
  {
    exact: true,
    path: "/admin/tasks/general/:id",
    breadcrumbs: [
      { path: "/tasks/general/review", title: "GeneralTaskReview" }
    ],
    component: GeneralTask,
    title: "GeneralTaskReview"
  },
  {
    exact: true,
    path: "/tasks/payment/:id",
    component: PaymentTask,
    title: "Payment Task"
  },
  {
    exact: true,
    path: "/tasks/renewal/:id",
    component: RenewalTask,
    title: "Renewal"
  },
  {
    exact: true,
    path: "/tasks/penalty/:id",
    component: PenaltyTask,
    title: "Penalty"
  },
  {
    exact: true,
    path: "/tasks/audit/:id",
    component: AuditTask,
    title: "Audit"
  },
  {
    exact: true,
    path: "/tasks/registration/:id",
    component: RegistrationTask,
    title: "Registration"
  },
  {
    exact: true,
    path: "/tasks/direct/:id",
    component: DirectTaskReview,
    title: "Task"
  },
  {
    exact: true,
    path: "/register/batch-registration/:id",
    component: BatchRegistration,
    title: "Batch Registration"
  },
  {
    exact: true,
    path: "/register/batch-registration/:id/complete",
    component: BatchRegistrationComplete,
    title: "Complete Batch Registration"
  },
  {
    exact: true,
    path: "/register/batch-registration/:id/create-task/:applicationId",
    component: BatchRegistrationTask,
    title: "Batch Registration - Create Task"
  }
];

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: "/login",
    component: Login,
    title: "Login"
  },
  {
    exact: true,
    path: "/login/:emailVerificationToken",
    component: Login,
    title: "Login"
  },
  {
    exact: true,
    path: "/signup",
    component: SignUp,
    title: "Sign up"
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot Password"
  },
  {
    exact: true,
    path: "/create-complaint",
    component: Complaint,
    title: "Create Complaint"
  },
  {
    exact: true,
    path: "/create-complaint/:complaintId",
    component: DebtCollectorComplaint,
    title: "Create Complaint"
  },
  {
    exact: true,
    path: "/employee-invitation/:invitationCode",
    component: SignUp,
    title: "Employee Invitation Acceptance - Register"
  },
  {
    exact: true,
    path: "/login/employee-invitation/:invitationCode",
    component: Login,
    title: "Employee Invitation Acceptance - Login"
  }
];

export { PUBLIC_ROUTES, PRIVATE_ROUTES };
