import React, { useReducer, useContext, createContext, FC } from "react";
import {
  ManualRegistrationType,
  ContextType,
  State,
  Action
} from "./typings/state";
import { DEFAULT_TASK_FILTERS } from "./constants";

const initialState: State = {
  taskFilters: { ...DEFAULT_TASK_FILTERS, excludeIds: [4, 10, 22] },
  manualRegistrationType: !!localStorage.getItem("manualRegisterId")
    ? (localStorage.getItem("manualRegistrationType") as ManualRegistrationType)
    : null,
  isTransferable: !!localStorage.getItem("isTransferable"),
  isExistingUser: !!localStorage.getItem("isExistingUser")
};

export const GlobalStateContext = createContext<ContextType>({
  state: initialState,
  setGlobalState: state => console.warn("no context provider"),
  clearGlobalState: () => console.warn("no context provider")
});

export const GlobalStateProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer((state: State, action: Action) => {
    switch (action.type) {
      case "SET_STATE":
        return { ...state, ...action.payload };
      case "CLEAR_STATE":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const setGlobalState = (data: State) =>
    dispatch({ type: "SET_STATE", payload: data });

  const clearGlobalState = () => dispatch({ type: " CLEAR_STATE" });

  return (
    <GlobalStateContext.Provider
      value={{ state, setGlobalState, clearGlobalState }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
