import { useLazyQuery } from "@apollo/react-hooks";
import { Divider, Skeleton, Table } from "antd";
import { GET_COMPANY_REGISTRATION_FEES } from "graphql/queries";
import React, { useEffect, useState } from "react";
import { openNotificationWithIcon } from "utils/notification";
import { COMPANY_TYPES } from "../../constants";
import "../Register/index.css";
import { isNumber } from "lodash";
import { formatCurrency } from "../../utils/formatCurrency";
import { useGlobalState } from "../../globalStore";
import { useRegistrationState } from "stores/registration";

export default function CompanyRegistrationFeePayment() {
  const {
    registeringAs,
    companyId,
    setRegistrationState
  } = useRegistrationState();
  const { setGlobalState, state } = useGlobalState();
  const [businessFee, setBusinessFee] = useState(0);
  const [relatedPartyFee, setRelatedPartyFee] = useState(0);
  const [employeeFee, setEmployeeFee] = useState(0);
  const [total, setTotal] = useState(0);

  const { registrationFeeInfo } = state;

  const isCompany = COMPANY_TYPES.includes(registeringAs);

  const [
    getRegistrationFeeInfo,
    { loading: loadingFeeInfo, data: regFeeData }
  ] = useLazyQuery(GET_COMPANY_REGISTRATION_FEES, {
    variables: {
      companyId
    },
    onError: () =>
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading financial information"
      ),
    onCompleted: () => {
      setBusinessFee(
        regFeeData.companyRegistrationFees?.businessFee?.amountIncl || 0
      );
      setRelatedPartyFee(
        regFeeData.companyRegistrationFees?.relatedPartyFee?.amountIncl || 0
      );
      setEmployeeFee(
        regFeeData.companyRegistrationFees?.employeeFee?.amountIncl || 0
      );
      setGlobalState({ registrationFeeInfo: regFeeData });
    }
  });

  useEffect(() => {
    if (!registrationFeeInfo) {
      getRegistrationFeeInfo();
    } else {
      setBusinessFee(
        registrationFeeInfo.companyRegistrationFees.businessFee.amountIncl
      );
      setRelatedPartyFee(
        registrationFeeInfo.companyRegistrationFees.relatedPartyFee.amountIncl
      );
      setEmployeeFee(
        registrationFeeInfo.companyRegistrationFees.employeeFee.amountIncl
      );
    }
  }, []);

  useEffect(() => {
    setTotal(businessFee + relatedPartyFee + employeeFee);
    setRegistrationState(
      "regFees",
      businessFee + relatedPartyFee + employeeFee
    );
  }, [businessFee, relatedPartyFee, employeeFee]);

  const step = {
    title: `Step ${isCompany ? 8 : 9}: Registration Fee and Payment`,
    content: "Complete your payment for the registration fee"
  };

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      {loadingFeeInfo ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : (
        <div className="input-block-wrapper">
          <div className="flex-column input-block input-select">
            <label>Registration Fees</label>
          </div>

          <div className="flex-column input-block input-spacer input-select">
            <span>
              Registration and/or Transfer Fees, as outlined in the Debit
              Collectors Act 114 of 1998 and Regulations of 2003, for submission
              of this registration as calculated amounts to:
            </span>
          </div>

          <Table
            className="full-width"
            showHeader={false}
            pagination={false}
            dataSource={[
              {
                name:
                  "New Registration Fee (inclusive of Annual Fees) of business type",
                amount: `R ${
                  isNumber(businessFee) ? formatCurrency(businessFee) : 0
                }`
              },
              {
                name: "Registration Fee calculation per [PARTY] of business",
                amount: `R ${
                  isNumber(relatedPartyFee)
                    ? formatCurrency(relatedPartyFee)
                    : 0
                }`
              },
              {
                name: "Registration Fee calculation per [RELATION] of business",
                amount: `R ${
                  isNumber(employeeFee) ? formatCurrency(employeeFee) : 0
                }`
              },
              {
                name: "TOTAL AMOUNT DUE AND PAYABLE",
                amount: `R ${isNumber(total) ? formatCurrency(total) : 0}`
              }
            ]}
            rowKey={record => record.name}
            columns={[
              {
                title: "",
                dataIndex: "name",
                key: "name"
              },
              {
                title: "",
                render: (_: any, { amount }) => <h2>{amount}</h2>,
                key: "amount"
              }
            ]}
          />

          <div className="flex-column input-block input-spacer input-select">
            <span>
              The above amounts are inclusive of VAT and your employer will
              receive notification and electronic copy via email of the invoice
              due and payable
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
