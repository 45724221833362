import React, { useState, Fragment } from "react";
import { Card, Button, Checkbox, Descriptions, Typography } from "antd";
import { useQuery } from "react-apollo";
import { GET_USER } from "../graphql/queries";
import { GET_ADDRESS_LIST } from "../../../graphql/queries";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import { isEmpty, isInteger } from "lodash";
import "./index.css";

function ReviewPersonalInfo({
  transaction,
  reviewInfo,
  registrationTaskId
}: any) {
  const { employee, type, company } = transaction;
  const [userInfo, setUserInfo] = useState<any>();
  const [addressInfo, setAdressInfo] = useState<any>();
  const email =
    type.name === "Employee" ? employee.email : company.createdBy?.email;
  const companyId = type.name !== "Employee" ? company.id : undefined;
  const userId = type.name === "Employee" ? employee.id : undefined;

  const { loading: loadingEmployee } = useQuery(GET_USER, {
    skip: isEmpty(email),
    variables: {
      input: { email }
    },
    onCompleted: data => {
      setUserInfo(data.userData);
    }
  });
  const { loading: loadingAddress } = useQuery(GET_ADDRESS_LIST, {
    skip: !isInteger(companyId) && !isInteger(userId),
    variables: {
      companyId,
      userId
    },
    onCompleted: data => {
      // set company address info from query result
      setAdressInfo(data.addresses);
    }
  });
  const ResultComponentsProps = {
    ...transaction,
    step: "PersonalInformation",
    reviewInfo,
    registrationTaskId
  };

  if (loadingEmployee || loadingAddress) return <InformationLoader />;

  return (
    <>
      <div className="flex-column information-block">
        <Descriptions>
          <Descriptions.Item label="ID Number:">
            {userInfo?.idNumber}
          </Descriptions.Item>
          <Descriptions.Item label="First Name:">
            {userInfo?.firstName}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name:">
            {userInfo?.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number:">
            {userInfo?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Email:">
            {userInfo?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Capacity:">
            {userInfo?.employment[0]?.capacity?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Secondary Email:">
            {userInfo?.secondaryEmail ?? "<Not Provided>"}
          </Descriptions.Item>
          <Descriptions.Item label="Work Number:">
            {userInfo?.workPhone ?? "<Not Provided>"}
          </Descriptions.Item>
          <Descriptions.Item label="Secondary Phone:">
            {userInfo?.secondaryPhone ?? "<Not Provided>"}
          </Descriptions.Item>
          <Descriptions.Item label="Fax:">
            {userInfo?.fax ?? "<Not Provided>"}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <div className="">
        <br />
        <label>Address Information:</label>
        {!isEmpty(addressInfo) ? (
          addressInfo.map((contact, index) => {
            return (
              <Fragment key={`Address ${index}`}>
                <Card className="information-block-wrapper input-select">
                  <div className="flex-column information-block">
                    <Button
                      shape="round"
                      className="btn-registration-capacity-selected"
                    >
                      {`Address ${index + 1}`}
                    </Button>
                    <br />
                    <br />
                    <span>
                      Address type: <label>{contact.addressType?.name}</label>
                      <br />
                      Complex/Building/Apartment name:{" "}
                      <label>{contact.addressName}</label>
                      <br />
                      Street Address: <label>{contact.streetAddress}</label>
                      <br />
                      Town/Suburb: <label>{contact.suburb}</label>
                      <br />
                      City: <label>{contact.city}</label>
                      <br />
                      Postal Code: <label>{contact.postalCode}</label>
                      <br />
                      <br />
                      {!contact.addressType?.name.includes("Postal") && (
                        <Checkbox
                          checked={contact.domicilliumAddress}
                          value={contact.domicilliumAddress}
                        >
                          Domicillium Address
                        </Checkbox>
                      )}
                    </span>
                  </div>
                </Card>
                <br />
              </Fragment>
            );
          })
        ) : (
          <>
            <br />
            <Typography.Text>{"<Address Info Not Provided>"}</Typography.Text>
          </>
        )}
      </div>
      <ResultComment {...ResultComponentsProps} />
    </>
  );
}

export default ReviewPersonalInfo;
