import React from "react";
import { Result, Button } from "antd";

type Props = {
  error?: string;
};
export default function InternalErrorPage({ error }: Props) {
  return (
    <Result
      status="500"
      title="An error occurred"
      subTitle={error || "Sorry, something went wrong."}
      extra={
        <Button onClick={() => window.location.reload()} type="primary">
          Reload Page
        </Button>
      }
    />
  );
}
