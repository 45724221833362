import React from "react";
import PrequalificationCompany from "../RegisterCompany/prequalification";
import PrequalificationEmployee from "../RegisterEmployee/prequalification";
import { If } from "components/If";
import { useRegistrationState } from "stores/registration";

type Props = {
  nextStep: () => void;
};

export default function Prequalification({ nextStep }: Props) {
  const { registrationCapacity } = useRegistrationState();

  return (
    <>
      <If condition={registrationCapacity === "Business"}>
        <PrequalificationCompany nextStep={nextStep} />
      </If>
      <If condition={registrationCapacity === "Individual"}>
        <PrequalificationEmployee nextStep={nextStep} />
      </If>
    </>
  );
}
