import React, { useState, useEffect, useRef } from "react";
import { Card, Table, Button, Spin, Divider } from "antd";
import moment from "moment";
import { useLazyQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router";
import { GET_FILTERED_TRANSACTION_LIST } from "../../../graphql/queries";
import TransactionFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import EmptyState from "../../../components/Styled/EmptyState";
import "../../Projects/project.css";

const isActiveMultiFilter = multiFilter => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  id: false,
  typeId: false,
  transactionStatusId: false,
  assignedTo: false,
  companyId: false,
  sort: "DESC",
  invoiceNumber: false
};

const MemberTransactionsList: React.FC<any> = ({
  memberId = false,
  companyId = false,
  mode,
  history
}) => {
  const [transactionList, setTransactionList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const prePopulatedFilter =
    mode === "member-overview-transactions" && memberId
      ? {
          assignedTo: memberId.toString()
        }
      : mode === "company-overview-transactions" && companyId
      ? {
          companyId: +companyId
        }
      : {};
  const [multiFilter, setMultiFilter] = useState({
    ...defaultMultiFilter,
    ...prePopulatedFilter
  });
  const [useMode] = useState(mode);
  const pageSize = 10;

  const handlePagination = page => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        prePopulatedFilter
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterTransactionQuery(newMultiFilter, 0, true);
    }
  };

  const [getFilteredTransactionList] = useLazyQuery(
    GET_FILTERED_TRANSACTION_LIST,
    {
      onCompleted: data => {
        setTransactionList(data.transactions.transactionList);
        if (data.transactions.total) {
          setListTotal(data.transactions.total);
          if (showEmptyState) setShowEmptyState(false);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: error => {
        setError(error.message);
      },
      fetchPolicy: "network-only"
    }
  );

  const [getFilteredTransactionListNoCache] = useLazyQuery(
    GET_FILTERED_TRANSACTION_LIST,
    {
      onCompleted: data => {
        setTransactionList(data.transactions.transactionList);
        if (data.transactions.total) {
          setListTotal(data.transactions.total);
          if (showEmptyState) setShowEmptyState(false);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: error => {
        setError(error.message);
      },
      fetchPolicy: "network-only"
    }
  );

  const runMultiFilterTransactionQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }
    if (!skipCache) {
      getFilteredTransactionList({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize
          }
        }
      });
    } else {
      getFilteredTransactionListNoCache({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize
          }
        }
      });
    }
  };

  const handleTransactionFilterChange = (value, filterType, reset) => {
    const newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterTransactionQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runMultiFilterTransactionQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = [
    {
      title: "Trn #",
      dataIndex: "id",
      key: "id"
    },
    ...(window.location.pathname === "/admin/transactions"
      ? [
          {
            title: "Company Name",
            dataIndex: "company.registeredName",
            key: "company.registeredName"
          }
        ]
      : [
          {
            title: "Ref #",
            dataIndex: "directDepositInvoiceNumber",
            key: "directDepositInvoiceNumber"
          }
        ]),
    {
      title: "Applicant Name",
      key: "task",
      dataIndex: "task",
      render: record =>
        record?.employee
          ? record.employee?.firstName + " " + record.employee?.lastName
          : ""
    },

    { title: "Type", dataIndex: "type.name", key: "type" },
    { title: "Linked Task", dataIndex: "task.id", key: "task" },
    {
      title: "Date Created",
      key: "createdDate",
      render: record =>
        record.createdDate
          ? moment(record.createdDate).format("YYYY-MM-DD")
          : ""
    },
    {
      title: "Invoice Amount",
      dataIndex: "task",
      key: "task",
      render: record => {
        if (record?.invoice?.amount && !isNaN(record?.invoice?.amount)) {
          return parseFloat(record.invoice.amount).toFixed(2);
        } else {
          return "";
        }
      }
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: "",
    //   render: record => {
    //     let useStatusText = "Outstanding";
    //     let useStatusColour:
    //       | "success"
    //       | "processing"
    //       | "default"
    //       | "error"
    //       | "warning" = "warning";
    //     if (record.status && record.status.name === "Paid") {
    //       useStatusColour = "success";
    //       useStatusText = "Paid";
    //     } else {
    //       if (moment(record.dueDate).isSameOrBefore(moment())) {
    //         useStatusColour = "processing";
    //         useStatusText = "Due";
    //       } else {
    //         useStatusColour = "error";
    //         useStatusText = "Outstanding";
    //       }
    //     }
    //     return record ? (
    //       <span style={{ display: "flex" }}>
    //         <Badge status={useStatusColour} /> {useStatusText}
    //       </span>
    //     ) : (
    //       ""
    //     );
    //   }
    // },
    {
      title: <span>Action</span>,
      dataIndex: "",
      render: record => {
        return (
          <a
            className="ant-dropdown-link purple-link"
            onClick={() => {
              history.push({
                pathname: `/admin/transaction/${record.id}`,
                state: { transaction: record }
              });
            }}
          >
            View
          </a>
        );
      }
    }
  ];

  if (error) {
    console.error("transactions_error", error);
    return (
      <>
        <InternalErrorPage />
      </>
    );
  }

  return (
    <Spin tip="Loading..." className="loader" spinning={showSpin}>
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="transactions"
          headingText="Nothing to see here"
          bodyText="You currently have no transaction history. You can create a ticket to our support team to help with any problems you may be facing."
        />
      )}
      {!showEmptyState && (
        <>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <div className="card-header">
                {useMode === "member-overview-transactions" ? (
                  <h3>Transactions</h3>
                ) : (
                  <h3>All Transactions</h3>
                )}
                <div>
                  <Button
                    style={{ marginRight: "20px" }}
                    className="purple-button"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                </div>
              </div>
              <div className={loading ? "no-interact" : null}>
                {!initialising && (
                  <TransactionFilters
                    ref={filtersRef}
                    setError={setError}
                    handleTransactionFilterChange={
                      handleTransactionFilterChange
                    }
                    defaultMultiFilter={defaultMultiFilter}
                    mode={useMode}
                  />
                )}
                <Divider />
                <Table
                  rowKey="id"
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    pageSize,
                    onChange: page => handlePagination(page),
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>
                  }}
                  dataSource={transactionList}
                />
              </div>
            </Card>
          </div>
        </>
      )}
    </Spin>
  );
};

export default withRouter(MemberTransactionsList);
