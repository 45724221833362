import { useCallback, useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router";
import { Filters } from "../containers/Admin/Tasks/Batch/BatchFilters";

const PAGE_SIZE = 20;

export function useBatchFilters() {
  const location = useLocation();
  const history = useHistory();
  const [skip, setSkip] = useState<number>(0);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ]);

  const id = searchParams.get("id");
  const completed = searchParams.get("completed");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const page = searchParams.get("page") || "1";
  const take = PAGE_SIZE;

  useEffect(() => {
    setSkip((+page - 1) * PAGE_SIZE);
  }, [page]);

  const clearFilters = useCallback(() => {
    searchParams.delete("id");
    searchParams.delete("completed");
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    searchParams.delete("page");

    history.replace({
      search: searchParams.toString()
    });
  }, [history, searchParams]);

  const setFilterState = useCallback(
    (filters: Partial<Filters>) => {
      if (filters.taskId) {
        searchParams.set("id", filters.taskId);
      }

      if (filters.taskStatus) {
        searchParams.set("completed", filters.taskStatus);
      }

      if (filters.startDate) {
        searchParams.set("startDate", filters.startDate);
      }

      if (filters.endDate) {
        searchParams.set("endDate", filters.endDate);
      }

      if (filters.page) {
        searchParams.set("page", filters.page);
      }

      history.replace({
        search: searchParams.toString()
      });
    },
    [history, searchParams]
  );

  return {
    id,
    completed,
    startDate,
    endDate,
    page: +page,
    take,
    skip,
    setFilterState,
    clearFilters
  };
}
