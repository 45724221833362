import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { GQL_HTTP_ENDPOINT } from "./constants";
import { createBrowserHistory } from "history";
import { ApolloLink } from "apollo-link";

// Connect to GraphQL API
const httpLink = createHttpLink({
  uri: GQL_HTTP_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage
  const token = localStorage.getItem("id_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const refreshLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  const { response } = operation.getContext();

  if (response?.status === 401) {
    const historyWithRefresh = createBrowserHistory({ forceRefresh: true });
    historyWithRefresh.push("/login");
  }
});

// Initialize APolloClient
const client = new ApolloClient({
  link: ApolloLink.from([authLink, refreshLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
