import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { Tabs } from "antd";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../../containers/Admin/graphql/queries";
import MemberTransactions from "../Admin/Transactions/memberTransactions";
import "./index.css";

const AllTransactions = () => {
  const userRole = localStorage.getItem("userRoles");
  const roleStream = localStorage.getItem("roleStream");
  const memberId = Number(localStorage.getItem("userId"));
  const [companyId, setCompanyId] = useState(null);
  const isEmployee = userRole.indexOf("Employee") > -1;

  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: memberId
      },
      onCompleted: data => {
        if (typeof data?.userData?.employment[0]?.company?.id === "number") {
          setCompanyId(data.userData.employment[0].company.id);
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (!isEmployee) fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      <div className="tasks-header-div">
        <h2>Transactions</h2>
      </div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Transactions" key="1" className="tab-content">
          {companyId &&
            ["Sole Proprietor", "Company"].indexOf(userRole) > -1 &&
            roleStream === "Member" && (
              <MemberTransactions
                mode="company-overview-transactions"
                companyId={companyId}
              />
            )}
          {memberId && userRole === "Employee" && roleStream === "Member" && (
            <MemberTransactions
              mode="member-overview-transactions"
              memberId={memberId}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default AllTransactions;
