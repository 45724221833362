import * as React from "react";
import styled from "styled-components";

// Themes
import { theme } from "../../theme";

interface IProps {
  children: React.ReactNode;
  background?: string;
  overlay?: boolean;
  height?: string;
}

interface IWrapper {
  background?: string;
  overlay?: boolean;
  height?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${(props: IWrapper) =>
    props.background ? `url(${props.background}) no-repeat #f6f8fe` : `#fff`};
  align-items: center;
  justify-content: center;
  height: ${(props: IWrapper) => (props.height ? props.height : "100vh")};
  background-size: contain;
  background-position: top right;
  z-index: -1;
  padding: 0;
  margin: 0;
  flex-direction: column;
  &:before {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props: IWrapper) =>
      props.overlay ? theme.colors.transparentBlack : theme.colors.transparent};
    content: "";
    z-index: 0;
  }
`;

export default class PageWrapper extends React.Component<IProps> {
  render() {
    const { children, background, overlay } = this.props;
    return (
      <Wrapper background={background} overlay={overlay}>
        {children}
      </Wrapper>
    );
  }
}
