import React, { useState, useMemo } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Modal,
  Form,
  Spin,
  Select,
  Input,
  Card,
  Menu,
  Icon,
  Button,
  Upload
} from "antd";
import { GET_PRESIGNED_URL } from "../../../graphql/queries";
import { CREATE_DOCUMENT } from "../../../graphql/mutations";
import { CHANGE_COMPANY_STATUS, DELETE_COMPANY } from "../graphql/mutations";
import { uploadToS3 } from "../../../utils/uploadToS3";
import { openNotificationWithIcon } from "../../../utils/notification";
import "../../Employees/index.css";

type Props = {
  typeOfChange: "status" | "delete";
  form: any;
  companyDetails: any;
  visible: boolean;
  onCancel: () => void;
  refreshList: () => void;
  setModalVisible: (arg: boolean) => void;
};

const ChangeCompanyModal = (props: Props) => {
  const {
    onCancel,
    refreshList,
    setModalVisible,
    form,
    companyDetails,
    visible,
    typeOfChange
  } = props;
  const { getFieldDecorator } = form;
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();

  const name = useMemo(() => {
    if (companyDetails)
      return `(
        ${companyDetails.tradingName || companyDetails.registeredName}
      )`.toUpperCase();
    else return "";
  }, [companyDetails]);

  const reasons = useMemo<string[]>(() => {
    switch (status) {
      case "active":
        return [
          "Database Error",
          "Misallocation of Payment",
          "Termination of Suspension"
        ];
      case "deactivated":
        return ["Outcome of Hearing"];
      case "suspended":
        return [
          "Under Investigation",
          "Outstanding Fees",
          "Outcome of Hearing",
          "Outstanding Audit Certificate"
        ];
      default:
        return [""];
    }
  }, [status]);

  const statuses = ["Active", "Suspended", "Deregistered"];

  const handleChange = async () => {
    try {
      setLoading(true);
      if (typeOfChange === "status") {
        await changeStatus({
          variables: {
            input: {
              status,
              reason,
              id: companyDetails?.id
            }
          }
        });
      } else {
        await deleteCompany({ variables: { id: companyDetails.id } });
      }

      openNotificationWithIcon(
        "success",
        "Success",
        typeOfChange === "status"
          ? "successfully updated the company status"
          : "successfully deleted the company from the platform"
      );

      setModalVisible(false);
      refreshList();
    } catch (error) {
      console.error(error);
      openNotificationWithIcon(
        "error",
        "Change Company Error",
        error.message.replace("GraphQL error: ", "")
      );
    } finally {
      setLoading(false);
    }
  };

  const [changeStatus] = useMutation(CHANGE_COMPANY_STATUS);

  const [deleteCompany] = useMutation(DELETE_COMPANY);

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              forCompany: true,
              companyId: companyDetails?.id
            }
          }
        });
      }
    },
    onError: err => {
      console.error({ err });
    }
  });

  const handleUpload = async ({ file, onSuccess }) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `change_status_${Date.now()}`;
    const path = `${companyDetails?.id}/admin-uploads/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          companyName: "Admin"
        }
      }
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  return (
    <Modal
      visible={visible}
      title={
        typeOfChange === "status"
          ? "Change Company Status"
          : `Delete Company ${name}`
      }
      okText={typeOfChange === "status" ? "Change" : "Delete"}
      onCancel={onCancel}
      onOk={handleChange}
      okButtonProps={{
        className: "purple-button",
        disabled: loading || uploading
      }}
      cancelButtonProps={{
        disabled: loading || uploading
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        {typeOfChange === "delete" ? (
          <div style={{ textAlign: "center" }}>
            <h3>
              You are about to delete a company and all its associated data.
              <br />
              Are you sure you want to continue with this irreversible action?
            </h3>
            <br />
          </div>
        ) : (
          <Form layout="vertical">
            <Form.Item label="New Status">
              {getFieldDecorator("status", {
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Please select the new member status"
                  }
                ]
              })(
                <Select
                  className="input-select"
                  onChange={value => setStatus(value.toString())}
                >
                  {statuses.map((item, index) => (
                    <Select.Option
                      key={`item-${index}`}
                      value={item.toLowerCase().replace(/ /g, "_")}
                    >
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={`Reason for change of status`}>
              {getFieldDecorator("reason", {
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Please select one of the reasons"
                  }
                ]
              })(
                <Select
                  className="input-select"
                  onChange={value => setReason(value.toString())}
                >
                  {reasons.map(r => (
                    <Select.Option
                      key={r}
                      value={r.toLowerCase().replace(/ /g, "_")}
                    >
                      {r}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <h4>Add a comment</h4>
            <Card
              className="comment-card"
              cover={
                <Input.TextArea
                  style={{ resize: "none" }}
                  rows={3}
                  placeholder="Start typing..."
                  disabled
                />
              }
            >
              <Card.Meta
                title={
                  <div className="comment-action">
                    <Menu
                      mode="vertical"
                      theme="light"
                      className="link-user-menu"
                      style={{ visibility: "hidden" }}
                    >
                      <Menu.SubMenu
                        title={
                          <span>
                            <Icon
                              type="link"
                              style={{
                                fontSize: "26px",
                                color: "darkslategray"
                              }}
                            />
                            <span>Link</span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key={"y"}
                          id={"1"}
                          onClick={e => {
                            // setLinkedUserId(e.key);
                            // setLinkedUserName(e.item.props.id);
                          }}
                        >
                          Hello
                        </Menu.Item>
                        <Menu>
                          {[].map(u => (
                            <Menu.Item
                              key={u.id}
                              id={`${u.firstName} ${u.lastName}`}
                              onClick={e => {}}
                            >
                              {u.firstName} {u.lastName}(Admin)
                            </Menu.Item>
                          ))}
                        </Menu>
                      </Menu.SubMenu>
                    </Menu>
                    <div>
                      <Button className="btn-add-comment" disabled>
                        Save
                      </Button>
                    </div>
                  </div>
                }
              />
            </Card>
            <Upload
              disabled={loading || uploading}
              customRequest={handleUpload}
            >
              <Button disabled={!!!companyDetails?.id}>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default Form.create({
  name: "change_status_modal_form"
})(ChangeCompanyModal);
