import React from "react";
import { Card, Tabs } from "antd";
import HireIcon from "../../../assets/images/hire.svg";
import ProfileManager from "./profile";
import ProfileSecurity from "./security";

const Profile: React.FC<any> = ({ ...props }) => {
  return (
    <>
      <Card className="header-card">
        <div>
          <img src={HireIcon} alt="" className="header-card-img" />
          <span>
            Please complete the information below to set up your profile and get
            started
          </span>
        </div>
      </Card>
      <Card className="body-card">
        <Tabs tabPosition="left">
          <Tabs.TabPane tab="Basic Settings" key="1" className="tab-content">
            <ProfileManager />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Security Settings" key="2">
            <ProfileSecurity />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default Profile;
