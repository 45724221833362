import { Button, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import Completed from "../../assets/images/task_complete.svg";
import Unverified from "../../assets/images/unverified.svg";
import { ADMIN_STREAMS } from "../../constants";
import { CHECK_APPLICATION_STATUS } from "../../graphql/queries";
import { cleanup } from "utils";
import { useHistory } from "react-router";
import { useRegistrationState } from "stores/registration";

export default function RegistrationResults() {
  const history = useHistory();

  const { clearRegistrationState, applicationId } = useRegistrationState();

  const [retries, setRetries] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const role = localStorage.getItem("roleStream");

  const isAdmin = ADMIN_STREAMS.includes(role);

  const handleDone = () => {
    const path = isAdmin ? "/admin/tasks" : "/tasks";
    history.replace(path);
    clearRegistrationState();
  };

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const [checkApplicationStatus, { refetch }] = useLazyQuery(
    CHECK_APPLICATION_STATUS,
    {
      variables: { applicationId },
      onCompleted: async ({ checkApplicationStatus }) => {
        if (checkApplicationStatus.complete === true) {
          setIsComplete(true);
          setLoading(false);
          cleanup();
        } else {
          if (retries < 5) {
            await wait(10000);
            refetch();
          } else {
            setIsFailed(true);
            setLoading(false);
          }
          setRetries(retries + 1);
        }
      },
      onError: err => {
        console.error(err);
      }
    }
  );

  useEffect(() => {
    setLoading(true);
    checkApplicationStatus();
  }, []);

  return (
    <>
      {isFailed && (
        <div className="verification-result">
          <Result
            className="flex-column"
            title="Registration failed"
            subTitle="We were unable to complete your registration due to an error. Please try again later."
            icon={
              <img
                width="40%"
                src={Unverified}
                alt="Unsuccessful registration"
                className="result-icon"
              />
            }
          />
        </div>
      )}
      {loading && (
        <div className="verification-result">
          <Result
            className="flex-column vertical-center"
            title="Completing Registration"
            subTitle={
              <b>
                Please do not refresh this page. This may take up to 2 minutes.
              </b>
            }
            icon={<Spin />}
          />
        </div>
      )}
      {!loading && isComplete && (
        <div className="verification-result">
          <Result
            className="flex-column"
            title="Registration Complete"
            subTitle="Your registration has been submitted successfully to the Council of Debt Collectors. Please note that we will notify you via email if we require any further documentation from you. Your registration needs to be reviewed and approved by the Council before you will receive your Membership number and certificate. You will receive an email once your registration has been approved or rejected and you will be able to access your portal."
            extra={[<Button onClick={handleDone}>Done</Button>]}
            icon={
              <img
                width="40%"
                src={Completed}
                alt="Successful registration"
                className="result-icon"
              />
            }
          />
        </div>
      )}
    </>
  );
}
