import React, { useEffect, useState } from "react";
import EmployeeInvitationModal from "./employeeInvitationModal";
import "./index.css";
import RegisterForm from "./registerForm";

const Register: React.FC<any> = ({ ...props }) => {
  const [employeeHelperVisible, setEmployeeHelperVisible] = useState(false);

  useEffect(() => {
    if (props?.match?.params?.invitationCode) {
      setEmployeeHelperVisible(true);
    }
  }, []);

  return (
    <>
      <div className="register-form-wrapper">
        <div className="register-sidebar">
          <RegisterForm
            employeeInvitationCode={props?.match?.params?.invitationCode}
          />
        </div>
        <div className="sider-background">
          <h2>
            Council for Debt
            <br />
            Collectors <span>in South Africa</span>
          </h2>
        </div>
      </div>
      <EmployeeInvitationModal
        visible={employeeHelperVisible}
        setEmployeeHelperVisible={setEmployeeHelperVisible}
        employeeInvitationCode={props?.match?.params?.invitationCode}
      />
    </>
  );
};

export default Register;
