import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { GET_AUDITOR_ACCOUNTANT_INFO } from "../../../graphql/queries";
import { isInteger } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { Descriptions } from "antd";

const ReviewAuditorInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { company, type } = transaction;
  const companyId = type.name !== "Employee" ? company.id : undefined;

  const [auditorInfo, setAuditorInfo] = useState<any>();
  const { loading } = useQuery(GET_AUDITOR_ACCOUNTANT_INFO, {
    skip: !isInteger(companyId),
    variables: {
      companyId
    },
    onCompleted: data => setAuditorInfo(data.companyAccountant)
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "Auditor/AccountantInformation",
    reviewInfo,
    registrationTaskId
  };

  if (loading) {
    return <InformationLoader />;
  }

  return (
    <>
      <div className="flex-column information-block">
        <br />
        <Descriptions>
          <Descriptions.Item label="Role">
            {auditorInfo?.role}
          </Descriptions.Item>
          <Descriptions.Item label="Business Name">
            {auditorInfo?.businessName}
          </Descriptions.Item>
          <Descriptions.Item label="Contact Person Name">
            {auditorInfo?.contactPersonName}
          </Descriptions.Item>
          <Descriptions.Item label="Contact Person Number">
            {auditorInfo?.contactCellNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Contact Number Office">
            {auditorInfo?.contactOfficeNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {auditorInfo?.email}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <br />
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewAuditorInformation;
