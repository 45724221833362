import React from "react";
import { Form, Radio } from "antd";
import "../Register/index.css";
import RegistrationCompany from "../RegisterCompany/registration";
import RegistrationSoleProprietor from "../RegisterSoleProprietor/registration";
import { useRegistrationState } from "stores/registration";
import { If } from "components/If";

function BusinessRegistrationForm(props: any) {
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    registrationCapacity
  } = useRegistrationState();

  const isBusiness = registrationCapacity === "Business";

  const { getFieldDecorator } = props.form;

  return (
    <div className="flex-column">
      <If condition={isBusiness}>
        <div className="business-basic-details">
          {getFieldDecorator("natureOfBusiness", {
            initialValue: registeringAs,
            rules: [
              {
                required: true,
                message: "Please input the registered name"
              }
            ]
          })(
            <Radio.Group
              onChange={e => {
                setRegistrationState({ registeringAs: e.target.value });
              }}
              value={registeringAs}
            >
              <Radio value={"Company"}>Company</Radio>
              <Radio value={"Closed Corporation"}>Closed Corporation</Radio>
              <Radio value={"Partnership"}>Partnership</Radio>
              <Radio value={"A Sole Proprietor"}>A Sole Proprietor</Radio>
              <Radio value={"Co-operative"}>Co-operative</Radio>
              <Radio value={"Trust"}>Trust</Radio>
            </Radio.Group>
          )}

          <If condition={!!registeringAs}>
            {registeringAs === "A Sole Proprietor" ? (
              <RegistrationSoleProprietor />
            ) : (
              <RegistrationCompany registeredAs={registeringAs} />
            )}
          </If>
        </div>
      </If>
    </div>
  );
}

const BusinessRegistration = Form.create({ name: "normal_register" })(
  BusinessRegistrationForm
);

export default BusinessRegistration;
