import React, { useState } from "react";
import { Button } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import { openNotificationWithIcon } from "utils/notification";

import fileIcon from "../../assets/images/pdf_icon.png";

import { GET_DOWNLOAD_URL } from "../../graphql/queries";

interface IDocumentViewProps {
  documents: any[];
}

function DocumentView({ documents }: IDocumentViewProps) {
  const [loadingDoc, setLoadingDoc] = useState(false);

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: err => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setLoadingDoc(false);
      window.open(data.preSignedDownloadUrl);
    }
  });

  const handleViewDocument = filePath => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true
        }
      }
    });
  };

  return (
    <>
      {documents?.map(document => (
        <span key={document?.documentPath}>
          <img style={{ height: "25px", width: "25px" }} src={fileIcon} />
          &nbsp; &nbsp;
          {document?.name.toUpperCase()}
          <Button
            type="link"
            loading={loadingDoc}
            onClick={() => handleViewDocument(document?.documentPath)}
          >
            View
          </Button>
        </span>
      ))}
    </>
  );
}

export default DocumentView;
