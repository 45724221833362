import React from "react";
import { Button, Table } from "antd";
import { Address } from "typings/types";

type Props = { addresses: Address[]; onEdit: (address: any) => void };

function AddressTable({ addresses, onEdit }: Props) {
  return (
    <div>
      <Table
        size="small"
        dataSource={addresses.map((address, index) => ({
          ...address,
          key: index + 1
        }))}
        rowKey={record => String(record.id)}
        columns={[
          {
            title: "#",
            render: (text: any, record: any) => {
              return `Address ${record.key}`;
            }
          },
          {
            title: "Type",
            render: (_, record: any) => String(record.addressType?.name || ""),
            key: "type"
          },
          {
            title: "Complex Name",
            render: (_, record: any) => String(record.addressName || ""),
            key: "name"
          },
          {
            title: "Street/Postal",
            render: (_, record: any) =>
              String(record.streetAddress || record.postalAddress || ""),
            key: "street"
          },

          {
            title: "Suburb",
            render: (_, record: any) => String(record.suburb || ""),
            key: "suburb"
          },
          {
            title: "city",
            render: (_, record: any) => String(record.city || ""),
            key: "City"
          },
          {
            title: "Postal Code",
            render: (_, record: any) => String(record.postalCode || ""),
            key: "postalCode"
          },
          {
            title: "Domicillium",
            render: (_, record: any) =>
              String(record.domicilliumAddress || "False").toUpperCase(),
            key: "domicile"
          },
          {
            title: "",
            render: (_, record: any) => (
              <Button type="link" onClick={() => onEdit(record)}>
                Edit
              </Button>
            ),
            key: "action"
          }
        ]}
      />
    </div>
  );
}

export default AddressTable;
