import gql from "graphql-tag";

export const GENERATE_SAGE_DOC = gql`
  query generateSageDocument($input: GenerateDocument) {
    generateSageDocument(input: $input)
  }
`;

export const GET_COMPANY_OWNER = gql`
  query companyOwner($companyId: Int!) {
    companyOwner(companyId: $companyId) {
      id
      email
      firstName
      idNumber
      lastName
      phone
      email
      capacity {
        id
        name
      }
      businessUnit {
        id
        name
      }
      secondaryPhone
    }
  }
`;
export const GET_OFFENCES_LIST = gql`
  query {
    offenceList: offences {
      id
      name
    }
  }
`;
export const GET_CAPACITY_LIST = gql`
  query {
    capacityList: capacities {
      id
      name
    }
  }
`;
export const GET_ETHNICITY_LIST = gql`
  query {
    ethnicityList: ethnicities {
      id
      name
    }
  }
`;

export const GET_MARITAL_STATUS_LIST = gql`
  query {
    maritalStatusList: maritalStatuses {
      id
      name
    }
  }
`;

export const GET_COMPANY_REGISTRATION_FEES = gql`
  query companyRegistrationFees($companyId: Int!) {
    companyRegistrationFees(companyId: $companyId) {
      businessFee {
        amountIncl
        amountExcl
      }
      relatedPartyFee {
        amountIncl
        amountExcl
      }
      employeeFee {
        amountIncl
        amountExcl
      }
    }
  }
`;

export const GET_EMPLOYEE_REGISTRATION_FEES = gql`
  query employeeRegistrationFees($userId: Int!, $companyId: Int!) {
    employeeRegistrationFees(userId: $userId, companyId: $companyId) {
      businessFee {
        amountIncl
        amountExcl
      }
      relatedPartyFee {
        amountIncl
        amountExcl
      }
      employeeFee {
        amountIncl
        amountExcl
      }
    }
  }
`;

export const GET_USER_REGISTRATIONS = gql`
  query getUserRegistrations {
    getUserRegistrations {
      id
      employee {
        id
        firstName
        lastName
      }
      employer {
        id
        tradingName
        registeredName
      }
    }
  }
`;

export const GET_ADDRESS_TYPES = gql`
  query {
    addressTypeList: addressTypes {
      id
      name
    }
  }
`;

export const GET_BUSINESS_UNITS_LIST = gql`
  query {
    businessUnitList: businessUnits {
      id
      name
    }
  }
`;

export const GET_USER_COMPANY = gql`
  query userCompany($userId: Int!) {
    userCompany(userId: $userId) {
      id
      registrationType
      registrationCapacity
      natureOfBusiness
      registeredName
      registeredNumber
      tradingName
      membershipNumber
      isRegisteredForVat
      vatNumber
      application {
        id
        applicationStatus {
          id
          name
        }
      }
      createdBy {
        id
        firstName
        employment {
          id
          capacity {
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPANY_REPRESENTATIVES_FRAGMENT = gql`
  fragment CompanyRepresentativesFragment on CompanyRepresentative {
    id
    firstName
    lastName
    idNumber
    email
    phone
    secondaryEmail
    secondaryPhone
    workPhone
    homePhone
    capacity {
      id
      name
    }
    maritalStatus {
      id
      name
    }
    ethnicity {
      id
      name
    }
    age
    isEmancipated
  }
`;

export const GET_COMPANY_REPRENSENTATIVES = gql`
  query companyRepresentatives($companyId: Int!) {
    companyRepresentatives(companyId: $companyId) {
      ...CompanyRepresentativesFragment
    }
  }
  ${COMPANY_REPRESENTATIVES_FRAGMENT}
`;

export const ADDRESS_LIST_FRAGMENT = gql`
  fragment AddressListFragment on Address {
    id
    addressName
    location
    addressType {
      id
      name
    }
    country
    city
    suburb
    streetAddress
    postalCode
    postalAddress
    domicilliumAddress
  }
`;

export const GET_ADDRESS_LIST = gql`
  query addresses($companyId: Int, $userId: Int) {
    addresses(companyId: $companyId, userId: $userId) {
      ...AddressListFragment
    }
  }
  ${ADDRESS_LIST_FRAGMENT}
`;

export const COMPANY_CONTACTS_FRAGMENT = gql`
  fragment CompanyContactsFragment on CompanyContact {
    id
    cellphone
    secondaryCellphone
    email
    secondaryEmail
    workPhone
    homePhone
    businessUnitContactName
    businessUnit {
      id
      name
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query companyContacts($companyId: Int!) {
    companyContacts(companyId: $companyId) {
      ...CompanyContactsFragment
    }
  }
  ${COMPANY_CONTACTS_FRAGMENT}
`;
export const GET_PRESIGNED_URL = gql`
  query preSignedUrl($input: PresignedUrlInput!) {
    preSignedUrl(input: $input)
  }
`;

export const COMPANY_FINANCIAL_INFORMATION_FRAGMENT = gql`
  fragment CompanyFinancialInformationFragment on FinancialInformation {
    trustAccounts {
      id
      bank
      accountName
      accountNumber
      accountType
      branchCode
      avsReport
    }
    businessAccounts {
      id
      bank
      accountName
      accountNumber
      accountType
      branchCode
      avsReport
    }
    company {
      natureOfBusiness
      financialYearStartDate
      financialYearEndDate
      tradingAs
      vatNumber
    }
  }
`;

export const GET_COMPANY_FINANCIAL_INFO = gql`
  query companyFinancialInformation($companyId: Int!) {
    companyFinancialInformation(companyId: $companyId) {
      ...CompanyFinancialInformationFragment
    }
  }
  ${COMPANY_FINANCIAL_INFORMATION_FRAGMENT}
`;

export const GET_PREQUALIFICATION = gql`
  query prequalification($companyId: Int, $userId: Int) {
    prequalification(companyId: $companyId, userId: $userId) {
      id
      onTrial
      convicted
      appliedForExemption
      curatedForLegalCapacity
      dateOfTrial
      typeOfOffence {
        id
        name
      }
      guiltyOfImproperConduct
      reportUrl
    }
  }
`;

export const GET_COMPANIES_BY_FILTER = gql`
  query companiesByFilter($criteria: String!, $filter: String!) {
    companiesByFilter(criteria: $criteria, filter: $filter) {
      companyList {
        id
        registrationType
        registrationCapacity
        natureOfBusiness
        registeredName
        registeredNumber
        tradingName
        membershipNumber
        financialYearEndDate
        financialYearStartDate
        application {
          id
          applicationStatus {
            id
            name
          }
        }
        addresses {
          streetAddress
        }
      }
      total
    }
  }
`;

export const GET_COMPANY_FROM_EMPLOYEE_INVITATION = gql`
  query companyFromEmployeeInvitation($code: String!) {
    companyFromEmployeeInvitation(input: { code: $code }) {
      id
      registeredName
      registeredNumber
      tradingName
      membershipNumber
    }
  }
`;

export const AUDITOR_ACCOUNTANT_INFO_FRAGMENT = gql`
  fragment AuditorAccountantInfoFragment on CompanyAccountant {
    id
    businessName
    contactPersonName
    contactOfficeNumber
    contactCellNumber
    email
    role
  }
`;

export const GET_AUDITOR_ACCOUNTANT_INFO = gql`
  query companyAccountant($companyId: Int!) {
    companyAccountant(companyId: $companyId) {
      ...AuditorAccountantInfoFragment
    }
  }
  ${AUDITOR_ACCOUNTANT_INFO_FRAGMENT}
`;

export const GET_CREDIT_BUREAU_RESULTS = gql`
  query eagleEyeValidation($userId: Int!) {
    eagleEyeValidation(userId: $userId) {
      idPassesCDV
      nameMatches
      isDeceased
      underDebtReview
      hasDefault
      hasJudgment
      isIndigent
      hasTraceLocator
      hasSAFPS
      hasSAFPSPR
    }
  }
`;

export const GET_LANGUAGES_LIST = gql`
  query languages {
    languagesList: languages {
      id
      name
    }
  }
`;

export const GET_PROFICIENCY_LEVELS = gql`
  query proficiencies {
    proficiencyLevels: proficiencies {
      id
      name
    }
  }
`;

export const GET_BATCH_REGISTRATION_EMPLOYEES = gql`
  query getEmployeesInBatchRegistration(
    $input: BatchRegistrationEmployeesInput!
  ) {
    getEmployeesInBatchRegistration(input: $input) {
      id
      idNumber
      firstName
      lastName
    }
  }
`;

export const GET_USER_LANGUAGE_PROFICIENCY_LIST = gql`
  query languageProficiencies($userId: Int!) {
    languageProficiencyList: languageProficiencies(userId: $userId) {
      id
      proficiencyLevel {
        id
        name
      }
      abilities {
        id
        name
      }
      language {
        id
        name
      }
    }
  }
`;
export const GET_LANGUAGE_ABILITIES_LIST = gql`
  query languageAbilities {
    languageAbilitiesList: languageAbilities {
      id
      name
    }
  }
`;
export const GET_QUALIFICATIONS_LIST = gql`
  query educationalQualification {
    languageProficiencyList: educationalQualification {
      id
      name
    }
  }
`;
export const GET_QUALIFICATIONS_TYPES = gql`
  query educationalQualificationTypes {
    qualificationsTypes: educationalQualificationTypes {
      id
      name
    }
  }
`;

export const GET_USER_QUALIFICATIONS = gql`
  query educationalQualifications($userId: Int!) {
    qualificationsList: educationalQualifications(userId: $userId) {
      id
      institutionName
      year
      qualification
      type {
        id
        name
      }
    }
    userData: user(input: { id: $userId }) {
      id
      highestGrade
      highestGradeYear
    }
  }
`;

export const GET_TRANSACTION_STATUS = gql`
  query checkTransactionStatus(
    $transactionId: String!
    $registrationCapacity: String
    $forcePaymentSuccess: Boolean
  ) {
    checkTransactionStatus(
      transactionId: $transactionId
      registrationCapacity: $registrationCapacity
      forcePaymentSuccess: $forcePaymentSuccess
    )
  }
`;
export const GET_APPLICATION_STATUS = gql`
  query applicationStatus {
    applicationStatus {
      id
      name
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query documentTypes {
    documentTypes {
      id
      name
      umbrella
      extendedName
    }
  }
`;

export const GET_DOCUMENT_STATUSES = gql`
  query documentStatuses {
    documentStatuses {
      id
      name
    }
  }
`;

export const GET_TRANSACTION_TYPES = gql`
  query transactionTypes {
    transactionTypes {
      id
      name
    }
  }
`;

export const GET_TRANSACTION_STATUSES = gql`
  query transactionStatuses {
    transactionStatuses {
      id
      name
    }
  }
`;

export const GET_TASK = gql`
  query task($taskId: Int!) {
    task(id: $taskId) {
      id
      createdDate
      modifiedDate
      taskStatus
      isManual
      comment
      invoice {
        amount
        sageInvoiceId
        sagePayload
      }
      relatedTasks {
        id
        type {
          id
          name
        }
        invoice {
          amount
          sageInvoiceId
        }
      }
      relatedTask {
        id
        type {
          id
          name
        }
        invoice {
          amount
          sageInvoiceId
        }
      }
      applicantType
      type {
        id
        name
      }
      employee {
        id
        firstName
        lastName
        email
        idNumber
        applications {
          id
          applicationStatus {
            id
            name
          }
        }
      }
      company {
        id
        registeredName
        registrationCapacity
        natureOfBusiness
        registeredName
        registeredNumber
        tradingName
        tradingAs
        vatNumber
        createdBy {
          id
          email
          firstName
          lastName
        }
        application {
          id
          applicationStatus {
            id
            name
          }
        }
        sageTaxInvoiceRef
        sageRecurringInvoiceId
        invoices {
          amount
        }
      }
      employeeInvitation {
        id
        acceptedBy {
          firstName
          lastName
          idNumber
        }
        transaction {
          id
        }
        application {
          id
          task {
            id
          }
        }
      }
      application {
        id
      }
      audit {
        id
        amountRecovered
        bbeeeLevel
        valueOfBooks
        geographicalArea
        industry
        documents {
          documentPath
          name
        }
        financialYear
      }
    }
  }
`;

export const BATCH_REGISTRATION_QUERY = gql`
  query getBatchRegistrationByFilters($input: BatchRegistrationFilter!) {
    getBatchRegistrationByFilters(input: $input) {
      batchRegistrations {
        id
        dateCreated
        completed
        tasks
        financialTaskId
      }
      total
    }
  }
`;

export const GET_FILTERED_NEW_TASK_LIST = gql`
  query tasks($input: TaskQueryInput!) {
    tasks(input: $input) {
      taskList {
        id
        createdDate
        modifiedDate
        taskStatus
        priorityStatus
        applicantType
        isManual
        comment
        invoice {
          amount
          sageInvoiceId
        }
        assignedTo {
          id
        }
        relatedTasks {
          id
          invoice {
            amount
            sageInvoiceId
          }
          type {
            name
          }
        }
        application {
          id
        }
        relatedTask {
          id
          invoice {
            id
            amount
          }
          taskStatus
        }
        complaint {
          id
          firstName
          lastName
          email
          contactNumber
          identificationType
          identificationNumber
          complaintAgainst
          debtCollectorName
          debtCollectorCompany
          debtCollectorContactNumber
          debtCollectorEmail
          description
          debtCollector {
            id
            userStatus
          }
        }
        application {
          id
          applicantType
          applicationStatus {
            id
            name
          }
        }
        type {
          id
          name
        }
        employee {
          id
          firstName
          lastName
          email
          idNumber
          applications {
            id
            applicantType
            applicationStatus {
              id
              name
            }
          }
        }
        company {
          id
          registeredName
          registrationCapacity
          natureOfBusiness
          registeredName
          registeredNumber
          tradingName
          tradingAs
          vatNumber
          createdBy {
            id
            email
            firstName
            lastName
          }
          application {
            id
            applicantType
            applicationStatus {
              id
              name
            }
          }
        }
      }
      total
    }
  }
`;

export const GET_MY_FILTERED_TASK_LIST = gql`
  query tasks($input: TaskQueryInput!) {
    tasks(input: $input) {
      taskList {
        id
        taskStatus
        priorityStatus
        createdDate
        modifiedDate
        applicantType
        isManual
        comment
        type {
          id
          name
        }
        relatedTask {
          id
          type {
            name
          }
        }
        relatedTasks {
          id
          type {
            name
          }
        }
        application {
          id
          applicantType
          applicationStatus {
            id
            name
          }
        }
        employee {
          firstName
          lastName
          id
          email
          idNumber
          applications {
            id
            applicantType
            applicationStatus {
              id
              name
            }
          }
        }
        company {
          id
          registeredName
          natureOfBusiness
          registeredName
          registeredNumber
          tradingName
          tradingAs
          vatNumber
          createdBy {
            id
            email
            firstName
            lastName
          }
          application {
            id
            applicantType
            applicationStatus {
              id
              name
            }
          }
        }
        complaint {
          id
          firstName
          lastName
          email
          contactNumber
          identificationType
          identificationNumber
          complaintAgainst
          debtCollectorName
          debtCollectorCompany
          debtCollectorContactNumber
          debtCollectorEmail
          description
          debtCollector {
            id
            userStatus
          }
        }
        employeeInvitation {
          id
        }
      }
      total
    }
  }
`;

export const GET_FILTERED_TRANSACTION_LIST = gql`
  query transactions($input: TransactionQueryInput!) {
    transactions(input: $input) {
      transactionList {
        id
        status {
          id
          name
        }
        type {
          id
          name
        }
        createdDate
        modifiedDate
        dueDate
        directDepositInvoiceNumber
        assignedTo {
          id
          firstName
          lastName
        }
        task {
          employee {
            id
            firstName
            lastName
          }
          id
          invoice {
            amount
            sageInvoiceId
          }
        }
        company {
          id
          sageRecurringInvoiceId
          registeredName
          tradingName
          vatNumber
        }
      }
      total
    }
  }
`;

export const GET_TRANSACTION_BY_FILTER = gql`
  query transactions($input: TransactionQueryInput!) {
    transactions(input: $input) {
      transactionList {
        id
        status {
          id
          name
        }
        type {
          id
          name
        }
        createdDate
        modifiedDate
        dueDate
        paymentMethod
        directDepositInvoiceNumber
        assignedTo {
          id
          firstName
          lastName
        }
        task {
          id
        }
        company {
          id
          sageRecurringInvoiceId
          vatNumber
        }
        sageTaxInvoiceId
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query transaction($id: Int!) {
    transaction(id: $id) {
      id
      status {
        id
        name
      }
      type {
        id
        name
      }
      createdDate
      modifiedDate
      dueDate
      paymentMethod
      peachPaymentsTransactionId
      directDepositInvoiceNumber
      assignedTo {
        id
        firstName
        lastName
      }
      task {
        id
      }
      company {
        id
        sageTaxInvoiceRef
        sageRecurringInvoiceId
        vatNumber
      }
      sageTaxInvoiceId
    }
  }
`;

export const GET_COMPANY_SUMMARY_MEMBER_PROFILE = gql`
  query companySummaryMemberProfile($companyId: Int!) {
    companyFinancialInformation(companyId: $companyId) {
      ...CompanyFinancialInformationFragment
    }
    companyRepresentatives(companyId: $companyId) {
      ...CompanyRepresentativesFragment
    }
    companyContacts(companyId: $companyId) {
      ...CompanyContactsFragment
    }
    companyAccountant(companyId: $companyId) {
      ...AuditorAccountantInfoFragment
    }
    addresses(companyId: $companyId) {
      ...AddressListFragment
    }
    company(input: { id: $companyId }) {
      id
      membershipNumber
      registeredName
      tradingName
      registeredNumber
      natureOfBusiness
      vatNumber
      tradingAs
      status
      certificates {
        toDate
      }
    }
  }
  ${COMPANY_FINANCIAL_INFORMATION_FRAGMENT}
  ${COMPANY_REPRESENTATIVES_FRAGMENT}
  ${COMPANY_CONTACTS_FRAGMENT}
  ${AUDITOR_ACCOUNTANT_INFO_FRAGMENT}
  ${ADDRESS_LIST_FRAGMENT}
`;

export const GET_DOCUMENTS = gql`
  query documents($input: DocumentQueryInput!) {
    documents(input: $input) {
      documentList {
        id
        name
        documentPath
        createDate
        dateRequired
        documentType {
          id
          extendedName
        }
        status {
          id
          name
        }
        company {
          id
          registeredName
          tradingName
        }
        uploadedBy {
          id
          firstName
          lastName
        }
      }
      total
    }
  }
`;

export const GET_USER_ACTIVE_APPLICATIONS = gql`
  query userActiveApplication($userId: Int!) {
    userActiveApplication(userId: $userId) {
      applicationStatus {
        id
        name
      }
      employee {
        id
        firstName
        lastName
        employment {
          id
          employerAddressType
          employerBranchLocation
          businessUnit {
            id
            name
          }
          capacity {
            id
            name
          }
          company {
            id
            registeredName
            registeredNumber
            natureOfBusiness
            financialYearStartDate
            financialYearEndDate
            tradingAs
            tradingName
            membershipNumber
            vatNumber
          }
        }
      }
      task {
        id
      }
      id
      dateCreated
      dateModified
      registrationType
      registrationCapacity
      registeredAs
      applicantType
      applicantionType
    }
  }
`;

export const GET_APPLICATION_REVIEW_INFO = gql`
  query reviewByTask($taskId: Int!) {
    reviewByTask(taskId: $taskId) {
      id
      hasPassed
      steps {
        id
        step
        hasPassed
        comments {
          id
          createdDate
          body
          author {
            id
            firstName
            lastName
            avatarUrl
          }
          documents {
            documentPath
          }
        }
      }
    }
  }
`;
export const GET_FILTERED_REMOVED_EMPLOYMENTS_LIST = gql`
  query getRemovedEmploymentsByFilter($input: FilterRemovedEmployments!) {
    getRemovedEmploymentsByFilter(input: $input) {
      removedEmploymentList {
        userId
        companyId
        dateRemoved
        councilMembershipNumber
        capacity {
          id
          name
        }
        identity
        removalReason
      }
      total
    }
  }
`;

export const GET_FILTERED_EMPLOYEE_INVITATIONS_LIST = gql`
  query employeeInvitations($input: EmployeeInvitationsQueryInput!) {
    employeeInvitations(input: $input) {
      employeeInvitationList {
        id
        email
        idNumber
        status
        lastSent
        retryCount
        expiryDate
        createdDate
        linkedCompany {
          id
        }
        createdBy {
          id
        }
        acceptedBy {
          id
          firstName
          lastName
        }
        application {
          id
          task {
            id
            taskStatus
          }
        }
      }
      total
    }
  }
`;

export const GET_FILTERED_EMPLOYMENT_LIST = gql`
  query getEmploymentsByFilter($input: EmploymentQueryInput!) {
    getEmploymentsByFilter(input: $input) {
      employmentList {
        id
        dateCreated
        capacity {
          id
          name
        }
        businessUnit {
          id
          name
        }
        councilMembershipNumber
        user {
          id
          idNumber
          firstName
          lastName
          email
          userStatus
          phone
          certificates {
            id
            toDate
            debtCollectorEmployment {
              id
            }
            company {
              id
            }
          }
        }
      }
      total
      representative {
        id
        idNumber
      }
    }
  }
`;

export const GET_FILTERED_REPRESENTATIVE_LIST = gql`
  query getRepresentativesByFilter($input: RepresentativeQueryInput!) {
    getRepresentativesByFilter(input: $input) {
      representativeList {
        id
        firstName
        lastName
        idNumber
        email
        phone
        secondaryEmail
        secondaryPhone
        workPhone
        homePhone
        capacity {
          id
          name
        }
        userId
      }
      total
    }
  }
`;

export const SEARCH_REPRESENTATIVE_BY_ID = gql`
  query companyRepresentativeByIdNumber($idNumber: String!) {
    companyRepresentativeByIdNumber(idNumber: $idNumber) {
      idNumber
      firstName
      lastName
      represents {
        id
        registeredName
        registeredNumber
        tradingName
      }
      capacity {
        id
        name
      }
    }
  }
`;

export const GET_APPLICATION_BY_TASK = gql`
  query applicationByTask($taskId: Int!) {
    applicationByTask(taskId: $taskId) {
      id
      dateCreated
      dateModified
      registrationType
      registrationCapacity
      registeredAs
      applicantType
      applicantionType
      applicationStatus {
        id
        name
      }
      company {
        id
      }
      employer {
        id
      }
      employee {
        id
        employment {
          id
          company {
            id
          }
        }
      }
      task {
        id
      }
    }
  }
`;

export const GET_USER_EMPLOYMENT_BY_COMPANY = gql`
  query userEmploymentByCompany($userId: Int!, $companyId: Int!) {
    userEmploymentByCompany(userId: $userId, companyId: $companyId) {
      company {
        id
        tradingName
        registeredName
        registeredNumber
        membershipNumber
        vatNumber
      }
      businessUnit {
        id
        name
      }
      capacity {
        id
        name
      }
      employerAddressType
      employerBranchLocation
      councilMembershipNumber
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  query preSignedDownloadUrl($input: PresignedDownloadUrlInput!) {
    preSignedDownloadUrl(input: $input)
  }
`;

export const GET_CONTROL_LIST = gql`
  query getSubscriptionControlList($renewalTaskId: Int!) {
    getSubscriptionControlList(renewalTaskId: $renewalTaskId) {
      id
      documentPath
      name
    }
  }
`;

export const GET_TASK_ACTIVITIES = gql`
  query activities($input: ActivityQueryInput!) {
    activities(input: $input) {
      activityList {
        id
        description
        dateCreated
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const PROSECUTION_APPEARANCE_FRAGMENT = gql`
  fragment ProsecutionAppearanceFragment on ComplaintProsecutionAppearance {
    id
    dateOfAppearance
    timeOfAppearance
    placeOfAppearance
    noticeEmailSent
    appearanceStatus
    appearancePostponed
    postponementReason
    hearingVerdict
    sanctionOrSentence
    penalty {
      id
      penaltyAmount
      paymentTerm
      penaltyStatus
      invoices {
        sageInvoiceId
      }
    }
  }
`;

export const GET_COMPLAINT = gql`
  query complaint($id: Int!) {
    complaint(id: $id) {
      id
      firstName
      lastName
      email
      contactNumber
      identificationType
      identificationNumber
      complaintAgainst
      debtCollectorName
      debtCollectorCompany
      debtCollectorContactNumber
      debtCollectorEmail
      description
      noticeEmailSent
      clientResponded
      prosecuted
      noticeDocumentGenerated
      debtCollector {
        id
        idNumber
        firstName
        lastName
        userStatus
        employment {
          id
          company {
            id
            tradingName
            registeredName
          }
        }
      }
      company {
        id
        status
        registeredName
        tradingName
        sageCustomerId
        contacts {
          id
          cellphone
          email
          businessUnitContactName
          businessUnit {
            id
            name
          }
        }
      }
      prosecution {
        id
        reason
        noticeEmailSent
        chargeSheetGenerated
        noticeTemplateGenerated
        appearanceAddressGenerated
        numberOfCharges
        prosecutionCharges {
          id
          description
          alternativeCharge
          dateCreated
          dateModified
        }
        prosecutionAppearances {
          ...ProsecutionAppearanceFragment
        }
      }
      recommendation {
        id
        approved
      }
      debtCollectorResponse {
        id
      }
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const GET_PROSECUTION_BY_COMPLAINT = gql`
  query complaintProsecutionByComplaint($complaintId: Int!) {
    complaintProsecutionByComplaint(complaintId: $complaintId) {
      id
      reason
      noticeEmailSent
      chargeSheetGenerated
      noticeTemplateGenerated
      appearanceAddressGenerated
      numberOfCharges
      prosecutionCharges {
        id
        description
        alternativeCharge
        dateCreated
        dateModified
      }
      prosecutionAppearances {
        ...ProsecutionAppearanceFragment
      }
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const GET_PROSECUTION_APPEARANCES = gql`
  query prosecutionAppearances($prosecutionId: Int!) {
    prosecutionAppearances(prosecutionId: $prosecutionId) {
      ...ProsecutionAppearanceFragment
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const GET_AVS_REPORT = gql`
  query accountAVSReport($input: CreateCompanyBankAccountInput!) {
    accountAVSReport(input: $input)
  }
`;

export const GET_COMPANY = gql`
  query company($input: GetCompanyInput!) {
    company(input: $input) {
      id
      registeredName
      tradingName
    }
  }
`;

export const GET_AUDIT = gql`
  query getAudit($input: GetAuditInput!) {
    getAudit(input: $input) {
      id
      bbeeeLevel
      industry
      geographicalArea
      valueOfBooks
      amountRecovered
      documents {
        id
      }
      task {
        id
      }
    }
  }
`;

export const CHECK_APPLICATION_STATUS = gql`
  query checkApplicationStatus($applicationId: Int!) {
    checkApplicationStatus(applicationId: $applicationId) {
      complete
    }
  }
`;

export const GET_LOOKUPS = gql`
  query {
    getLookups {
      renewalMonths
      admins {
        total
        userList {
          id
          firstName
          lastName
          idNumber
        }
      }
      taskTypes {
        id
        name
      }
      userRoles {
        id
        name
      }
      registrationCount
      transactionStatuses {
        id
        name
      }
      capacities {
        id
        name
      }
      companyTypes
      assignedTaskCount
      documentTypes {
        id
        umbrella
        name
        extendedName
      }
    }
  }
`;

export const GET_PROSECUTION_RECOMMENDATION = gql`
  query getProsecutionRecommendation($id: Int!) {
    getProsecutionRecommendation(id: $id) {
      id
      reason
      approved
      comments {
        id
        createdDate
        body
        author {
          id
          firstName
          lastName
          avatarUrl
        }
        documents {
          name
          documentPath
        }
      }
    }
  }
`;

export const GET_DEBT_COLLECTOR_RESPONSE = gql`
  query getDebtCollectorResponse($id: Int!) {
    getDebtCollectorResponse(id: $id) {
      id
      comments {
        id
        createdDate
        body
        author {
          id
          firstName
          lastName
          avatarUrl
        }
        documents {
          name
          documentPath
        }
      }
    }
  }
`;

export const GET_CFDC_USERS_REPORT = gql`
  query getCfdcUsersReport {
    getCfdcUsersReport
  }
`;
