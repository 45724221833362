import React from "react";
import { Modal, Form, Spin } from "antd";
import { openNotificationWithIcon } from "../../../utils/notification";

const ModalFormComponent = ({
  visible,
  setVisible,
  refreshList,
  taskDetails,
  isLoading,
  setIsLoading,
  onCancel,
  deleteTask
}) => {
  const taskId = taskDetails?.id;

  const handleDeleteTask = async () => {
    if (!taskId) return;
    try {
      setIsLoading(true);
      await deleteTask({ variables: { id: taskId } });
      openNotificationWithIcon(
        "success",
        "Success",
        "Successfully deleted the task"
      );
      setVisible(false);
      refreshList();
    } catch (error) {
      console.error("remove_task_error", error);
      openNotificationWithIcon("error", "Error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Delete Task (${taskId})`}
      okText="Delete"
      onCancel={onCancel}
      cancelButtonProps={{
        disabled: isLoading
      }}
      onOk={handleDeleteTask}
      okButtonProps={{
        className: "purple-button",
        disabled: isLoading
      }}
      okType="default"
    >
      <Spin tip="Removing employee..." spinning={isLoading}>
        <div style={{ textAlign: "center" }}>
          <h3>
            You are about to delete a task and all its associated data. <br />
            Are you sure you want to continue with this irreversible action?
          </h3>
          <br />
        </div>
      </Spin>
    </Modal>
  );
};

const RemoveEmployeeModal = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default RemoveEmployeeModal;
