import React from "react";
import { Modal, Form, Input } from "antd";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  initialData
}) => {
  const { getFieldDecorator } = form;
  return (
    <Modal
      visible={visible}
      title="Invite a member"
      okText="Invite"
      onCancel={onCancel}
      onOk={onCreate}
      okButtonProps={{
        className: "purple-button"
      }}
      okType="default"
    >
      <Form layout="vertical">
        <Form.Item label="Email">
          {getFieldDecorator("email", {
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!"
              }
            ]
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const InviteUserForm = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default InviteUserForm;
