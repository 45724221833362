import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Table, Spin, Card, Button, Icon } from "antd";
import "../Projects/project.css";
import InternalErrorPage from "../InternalErrorPage";
import PermissionsGuard from "../../components/Auth/can";
import {
  UPDATE_BUSINESS_UNIT,
  DELETE_BUSINESS_UNIT,
  CREATE_BUSINESS_UNIT
} from "../Admin/graphql/mutations";
import { isEmpty } from "lodash";
import { GET_BUSINESS_UNITS } from "../Admin/graphql/queries";
import AccessDenied from "../../components/Auth/accessDenied";
import { openNotificationWithIcon } from "../../utils/notification";
import EditBusinessUnitForm from "./editBusinessUnit";
import { TableLoader } from "../../components/UserManagement/TableContentPlaceholder";

const BusinessUnit: React.FC<any> = ({ ...pros }) => {
  const [showSpin, setShowSpin] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const permissions = localStorage.getItem("userPermissions");
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState();
  const [updateBusinessUnit] = useMutation(UPDATE_BUSINESS_UNIT);
  const [deleteBusinessUnit] = useMutation(DELETE_BUSINESS_UNIT);
  const [createBusinessUnit] = useMutation(CREATE_BUSINESS_UNIT);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: <span>Action</span>,
      dataIndex: "",
      key: "id",
      render: record => (
        <div>
          <Icon type="edit" title="Edit" onClick={() => showModal(record)} />
          &nbsp;&nbsp;
          <Icon
            type="delete"
            title="Delete"
            onClick={() => _deleteBusinessUnit(record.id)}
          />
        </div>
      )
    }
  ];

  const showModal = e => {
    setSelectedBusinessUnit(e);
    setModalVisible(true);
  };

  const _deleteBusinessUnit = async id => {
    try {
      setShowSpin(true);
      const resp = await deleteBusinessUnit({ variables: { id } });

      if (resp.data) {
        const res = await refetch();
        if (res.data) {
          setBusinessUnitList(res.data.buList);
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Delete operation success ",
            "Business unit deleted successfully"
          );
        }
      }
    } catch (error) {
      setShowSpin(false);
      openNotificationWithIcon(
        "error",
        "Delete operation error ",
        error.message
      );
    }
  };

  const handleCreate = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      setModalVisible(false);
      setShowSpin(true);

      const createUpdateMutation = values.id
        ? updateBusinessUnit
        : createBusinessUnit;
      //submit data
      const resp = await createUpdateMutation({
        variables: {
          id: values.id,
          name: values.name,
          description: values.description
        }
      });

      if (resp.data) {
        const res = await refetch();
        if (res.data) {
          setBusinessUnitList(res.data.buList); // refetch updated project list
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Update success ",
            "Role updated successfully"
          );
        }

        formRef.resetFields();
      }
    });
  };

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const ModalProps = {
    ref: saveFormRef,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleCreate(),
    initialData: selectedBusinessUnit
  };

  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_UNITS, {
    pollInterval: 600000,
    onCompleted: data => {
      setBusinessUnitList(data.buList);
    }
  });

  if (loading) return <TableLoader />;
  if (error)
    return (
      <>
        <InternalErrorPage />
      </>
    );

  if (isEmpty(data.buList))
    return (
      <div className="col-sm-12 col-md-12 placeholder-table-card">
        <Table />
      </div>
    );
  return (
    <PermissionsGuard
      permissions={permissions}
      perform={"bu:list"}
      yes={() => (
        <>
          <Spin
            tip="Loading..."
            className="loader"
            style={{ display: showSpin ? "block" : "none" }}
          />
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card style={{ height: "14vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Card.Meta
                  title={"Platform Business Unit Management"}
                  description={
                    <p style={{ height: "fit-content" }}>
                      Manage SovTech Business Unts
                    </p>
                  }
                />
                <PermissionsGuard
                  permissions={permissions}
                  perform={"bu:create"}
                  yes={() => (
                    <Button
                      type="primary"
                      style={{ alignSelf: "auto" }}
                      onClick={() => showModal(undefined)}
                    >
                      Add a Bussiness Unit
                    </Button>
                  )}
                />
              </div>
            </Card>
          </div>

          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <Table
                columns={columns}
                pagination={{ pageSize: 10 }}
                dataSource={businessUnitList}
                rowKey="id"
              />
            </Card>
          </div>
          <EditBusinessUnitForm {...ModalProps} />
        </>
      )}
      no={() => <AccessDenied permission="bu:list" />}
    />
  );
};

export default BusinessUnit;
