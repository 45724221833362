import React, { useState, useEffect } from "react";
import { Icon, Button, Card, Result } from "antd";
import {
  GET_APPLICATION_REVIEW_INFO,
  GET_APPLICATION_BY_TASK
} from "../../../graphql/queries";
import {
  UPDATE_APPLICATION_REVIEW,
  UPDATE_APPLICATION,
  UPDATE_TASK,
  UPSERT_USER_EMPLOYMENT
} from "../../../graphql/mutations";
import {
  APPLICATION_VERIFIED,
  APPLICATION_FAILED,
  TASK_COMPLETED,
  TASK_FAILED
} from "../../../constants";
import InformationLoader from "./informationLoader";
import {
  notifyError,
  openNotificationWithIcon
} from "../../../utils/notification";
import { useLazyQuery, useMutation } from "react-apollo";
import { useHistory } from "react-router";
import { isInteger } from "lodash";

const CompleteReview = ({ taskId, applicationId, setCurrentStep }) => {
  const history = useHistory();
  const [reviewInfo, setReviewInfo] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stepsPassed, setStepsPassed] = useState(0);
  const [totalSteps, setTotalSteps] = useState(1);
  const [reviewStepKeys, setReviewStepKeys] = useState([]);
  const [userId, setUserId] = useState<number>();
  const [employerId, setEmployerId] = useState<number>();
  const [employmentId, setEmploymentId] = useState<number>();
  const [application_id, setApplicationId] = useState<number>();
  const [updateReview] = useMutation(UPDATE_APPLICATION_REVIEW);
  const [updateTask] = useMutation(UPDATE_TASK);
  const [updateApplication] = useMutation(UPDATE_APPLICATION);
  const [updateUserMembership] = useMutation(UPSERT_USER_EMPLOYMENT);

  useEffect(() => {
    if (applicationId) setApplicationId(applicationId);
    if (isInteger(taskId)) getReviewInfo();
    getApplicationDetails();
  }, []);

  const [getReviewInfo, { loading: loadingReview, refetch }] = useLazyQuery(
    GET_APPLICATION_REVIEW_INFO,
    {
      fetchPolicy: "no-cache",
      variables: { taskId },
      onCompleted: data => {
        resetReviewInfo(data);
      },
      onError: err => {
        if (err.graphQLErrors[0].message === "Not Authorised!") {
          setLoading(true);
          refetch().then(res => {
            resetReviewInfo(res.data);
            setLoading(false);
          });
        }
      }
    }
  );

  const resetReviewInfo = data => {
    if (data.reviewByTask) {
      setReviewInfo(data.reviewByTask);
      let passed = 0;
      let totalSteps = 0;
      const keys = [];
      data.reviewByTask.steps.map(step => {
        if (step.hasPassed === true) passed++;
        totalSteps++;
        keys.push(`${step.step}Saved`);
      });
      setStepsPassed(passed);
      setTotalSteps(totalSteps);
      setReviewStepKeys(keys);
    }
  };
  const [getApplicationDetails] = useLazyQuery(GET_APPLICATION_BY_TASK, {
    fetchPolicy: "no-cache",
    variables: {
      taskId
    },
    onCompleted: ({ applicationByTask }) => {
      if (applicationByTask) {
        const companyId = applicationByTask?.employer?.id;
        const employment = applicationByTask?.employee?.employment.find(
          ({ company }) => company.id === companyId
        );
        setEmploymentId(employment?.id);
        setEmployerId(companyId);
        setApplicationId(applicationByTask?.id);
        setUserId(applicationByTask?.employee?.id);
      }
    }
  });

  const clearReviewLocalData = () => {
    reviewStepKeys.map(x => localStorage.removeItem(x));
    localStorage.removeItem("reviewId");
  };

  const handleSubmitReviewResult = async (result: "fail" | "pass") => {
    try {
      setIsSubmitting(true);

      // update review
      await updateReview({
        variables: {
          input: {
            id: reviewInfo?.id,
            hasPassed: result === "pass" ? true : false,
            creatorId: Number.parseInt(localStorage.getItem("userId"))
          }
        }
      });

      // update task
      await updateTask({
        variables: {
          input: {
            id: taskId,
            taskStatus: result === "pass" ? TASK_COMPLETED : TASK_FAILED
          }
        }
      });

      if (result === "pass" && userId) {
        // update employment membership
        await updateUserMembership({
          variables: {
            input: {
              id: employmentId,
              userId,
              employerId,
              approveMembership: true
            }
          }
        });
      }

      // update application status
      if (application_id)
        await updateApplication({
          variables: {
            input: {
              id: application_id,
              applicationStatus:
                result === "pass" ? APPLICATION_VERIFIED : APPLICATION_FAILED
            }
          }
        });

      //clear steps data from storage
      clearReviewLocalData();

      setIsSubmitting(false);
      openNotificationWithIcon(
        "success",
        "Save Success",
        "Review saved successfully"
      );

      // return to task list
      setTimeout(() => {
        history.push("/admin/tasks");
      }, 2000);
    } catch (err) {
      setIsSubmitting(false);
      // @ts-ignore
      notifyError(err);
    }
  };

  if (loadingReview || loading) return <InformationLoader />;
  return (
    <>
      <Result
        status="success"
        title="Successful Review"
        subTitle="Please see the review outcome below"
      />
      <Card className="review-result-card">
        {reviewInfo &&
          reviewInfo.steps.map((step, index) => {
            return (
              <div key={step + index}>
                <div>
                  <label>{step.step}: </label>
                  <Icon
                    style={{
                      fontSize: "22px",
                      color: step.hasPassed ? "rgb(82, 196, 26)" : "red"
                    }}
                    type={step.hasPassed ? "check-circle" : "close-circle"}
                  />
                </div>
                <br />
              </div>
            );
          })}
        <div className="row" style={{ paddingLeft: "10px" }}>
          <h3>Total Pass: </h3>
          <label>
            {stepsPassed}/{totalSteps}
          </label>
        </div>
      </Card>
      <div style={{ textAlign: "center" }}>
        <br />
        <label>Please accept or reject this user application</label>
        <br />
        <br />
        <div>
          {" "}
          <Button
            className="btn-previous-reject"
            type="default"
            loading={isSubmitting}
            onClick={() => handleSubmitReviewResult("fail")}
          >
            Reject
          </Button>
          {stepsPassed === totalSteps && (
            <Button
              className="btn-add-comment"
              loading={isSubmitting}
              onClick={() => handleSubmitReviewResult("pass")}
            >
              Accept
            </Button>
          )}
          {stepsPassed !== totalSteps && (
            <>
              <Button
                className="btn-add-comment"
                loading={isSubmitting}
                onClick={() => setCurrentStep()}
              >
                Back
              </Button>
              <Button
                className="btn-previous-reject"
                loading={isSubmitting}
                onClick={() => history.push("/admin/tasks")}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CompleteReview;
