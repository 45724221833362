import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { FormComponentProps } from "antd/lib/form/Form";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { authenticateUser } from "../../utils/auth/authenticateUser";
import { confirmUserRegistration } from "../../utils/auth/confirmUserRegistration";
import ConfirmAccountForm from "./confirmAccount";
import { isLoggedIn } from "../../utils/isLoggedIn";
import { useHistory } from "react-router";
import { openNotificationWithIcon } from "../../utils/notification";
import { useLazyQuery } from "react-apollo";
import {
  GET_USER,
  GET_USER_TYPES
} from "../../containers/Admin/graphql/queries";
import {
  UPDATE_EMAIL_RESEND_VERIFICATION,
  CREATE_ACTIVITY,
  UPDATE_USER
} from "../../graphql/mutations";
import {
  MEMBER_STREAM,
  ADMIN_STREAM,
  APPLICATION_TO_BE_VERIFIED,
  APPLICATION_IN_PROGRESS,
  APPLICATION_VERIFIED,
  FINANCE_STREAM,
  SUPER_USER_STREAM,
  LEGAL_STREAM,
  EXECUTIVE_STREAM,
  EMPLOYEE_USER_ROLE
} from "../../constants";
import { useUserState } from "stores/user";

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  }
});

function LoginForm(props: any) {
  const { setUserStateObject } = useUserState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [confirmationEmailPrefill, setConfirmationEmailPrefill] = useState(
    null
  );
  const [accountConfirmMode, setAccountConfirmMode] = useState(null);
  const history = useHistory();
  const [createActivity] = useMutation(CREATE_ACTIVITY);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateUserEmailAndResendVerification] = useMutation(
    UPDATE_EMAIL_RESEND_VERIFICATION
  );

  const [getUserTypes, { loading: loadingUserTypes }] = useLazyQuery(
    GET_USER_TYPES,
    {
      fetchPolicy: "network-only",
      onCompleted: async data => {
        const role = data.userTypesList.filter(
          x => x.name === EMPLOYEE_USER_ROLE
        );

        await updateUser({
          variables: {
            input: {
              id: Number(localStorage.getItem("userId")),
              userType: [role[0]?.id]
            }
          }
        });
      }
    }
  );

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const tokenValid = isLoggedIn();

    if (isAuthenticated && tokenValid) {
      debugger;
      history.push("/");
      window.location.reload();
    }
  }, []);

  const [getUserDetails] = useLazyQuery(GET_USER, {
    variables: { input: { email: "", idNumber: "" } },
    onCompleted: async data => {
      debugger;
      const { id, userType, applications, employment: emp } = data.userData;
      const employment = emp.filter((x: any) => x.company.id !== 13132);

      if (employment.length > 0) {
        localStorage.setItem("companyStatus", employment[0].company.status);
      }

      localStorage.setItem("idNumber", data?.userData?.idNumber);
      localStorage.setItem("userId", id);
      // if userType is null - assign default role
      if (isEmpty(userType)) {
        localStorage.setItem("username", data?.userData?.email);
        localStorage.setItem("userRoles", EMPLOYEE_USER_ROLE);
        localStorage.setItem("roleStream", MEMBER_STREAM);
        getUserTypes();
      }

      if (data.userData.requirePasswordReset) {
        await Auth.forgotPassword(data?.userData?.idNumber);
        props.form.resetFields(["password"]);
        localStorage.removeItem("isAuthenticated");
        setAccountConfirmMode("update-password-confirmation");
        setModalVisible(true);
        setLoading(false);
      } else if (
        data.userData &&
        data.userData.userType &&
        data.userData.emailVerified
      ) {
        await createActivity({
          variables: {
            input: {
              userId: id,
              description: "Logging into CouncilSmart"
            }
          }
        });

        const permissions = userType
          .map(t => t.permissions.map((p: any) => p.name))
          .flat();

        const roles = userType?.map((r: any) => r.name);

        const stream = userType[0]?.stream?.name;
        const applicationStatus = applications?.map(
          x => x.applicationStatus?.name
        );

        localStorage.setItem("username", data?.userData?.email);
        localStorage.setItem("idNumber", data?.userData?.idNumber);
        localStorage.setItem("userRoles", roles);
        localStorage.setItem("userPermissions", permissions);
        localStorage.setItem("userId", id);
        localStorage.setItem("capacityId", data?.userData?.capacity?.id);
        localStorage.setItem("roleStream", stream);
        localStorage.setItem(
          "applicationStatus",
          applicationStatus instanceof Array
            ? applicationStatus[0]
            : applicationStatus
        );
        localStorage.setItem("applicationId", applications[0]?.id);
        setLoading(false);

        setUserStateObject({
          id: id,
          email: data?.userData?.email,
          firstName: data?.userData?.firstName,
          lastName: data?.userData?.lastName,
          idNumber: data?.userData?.idNumber,
          stream: stream,
          phone: data?.userData?.phone,
          permissions: permissions,
          roles: roles,
          applicationId: applications[0]?.id,
          companyId: employment[0]?.company?.id
        });

        if (
          [
            ADMIN_STREAM,
            FINANCE_STREAM,
            SUPER_USER_STREAM,
            LEGAL_STREAM
          ].includes(stream) &&
          applicationStatus.includes(APPLICATION_TO_BE_VERIFIED)
        ) {
          history.push("/admin/profile");
        } else if (
          ![
            ADMIN_STREAM,
            FINANCE_STREAM,
            SUPER_USER_STREAM,
            LEGAL_STREAM,
            EXECUTIVE_STREAM
          ].includes(stream) &&
          !isEmpty(applicationStatus) &&
          applicationStatus !== APPLICATION_IN_PROGRESS &&
          !props.employeeInvitationCode
        ) {
          history.push("/profile");
        } else if (
          stream === MEMBER_STREAM &&
          ![APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
            applicationStatus
          ) &&
          !props.employeeInvitationCode
        ) {
          history.push("/register");
        } else if (
          [
            ADMIN_STREAM,
            FINANCE_STREAM,
            SUPER_USER_STREAM,
            LEGAL_STREAM,
            EXECUTIVE_STREAM
          ].includes(stream)
        ) {
          history.push("/admin/tasks");
        } else
          history.push(
            props.employeeInvitationCode
              ? `/register/employee-invitation/${props.employeeInvitationCode}`
              : "/register"
          );
      } else if (
        data.userData &&
        data.userData.userType &&
        !data.userData.emailVerified
      ) {
        localStorage.removeItem("isAuthenticated");
        setAccountConfirmMode("update-email-confirmation");
        setModalVisible(true);
        setLoading(false);
      }
    },
    fetchPolicy: "network-only"
  });

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      // if neither email nor id is captured, return
      if (isEmpty(values.email) && isEmpty(values.idNumber)) {
        return openNotificationWithIcon(
          "error",
          "Error",
          "Please enter either Email Address or ID/Passport number"
        );
      }

      await authenticationHandler({
        idNumber: values.idNumber?.trim(),
        email: values.email?.trim(),
        password: values.password?.trim()
      });
    });
  };

  const authenticationHandler = async (credentials: any) => {
    try {
      setLoading(true);

      await authenticateUser(
        {
          ...credentials
        },
        async (err, result, useCognitoUser) => {
          debugger;
          if (err) {
            switch (err.code) {
              case "UserNotConfirmedException":
                setAccountConfirmMode("aws-email-confirmation");
                setModalVisible(true);
                break;
              default:
                openNotificationWithIcon(
                  "error",
                  "Authentication Error",
                  err.message
                );
                props.form.resetFields();
                break;
            }
            setLoading(false);
          } else {
            if (useCognitoUser) {
              setConfirmationEmailPrefill(credentials.email);
            }
            if (props.runTokenEmailVerification) {
              await props.runTokenEmailVerification();
            }
            getUserDetails({
              variables: {
                input: {
                  email: credentials.email,
                  idNumber: credentials.idNumber
                }
              }
            });
          }
        }
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handleAccountConfirm = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setModalVisible(false);
      await confirmUserRegistration({
        idNumber: values.idNumber,
        code: values.code
      });
    });
  };

  const handleUpdateEmailConfirm = () => {
    formRef.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      updateUserEmailAndResendVerification({
        variables: {
          newEmail: values.email
        }
      }).then(res => {
        if (res.data.updateEmailResendVerificationToken) {
          localStorage.clear();
          openNotificationWithIcon(
            "success",
            "Verification Link Resent",
            "Please navigate to the link which has been emailed to you in order to verify your email address and log in."
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Verification Link Resend Failed",
            "Please try again or contact support."
          );
        }
        setModalVisible(false);
        props.form.resetFields();
      });
    });
  };

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const ModalProps = {
    ref: saveFormRef,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () =>
      accountConfirmMode === "aws-email-confirmation"
        ? handleAccountConfirm()
        : handleUpdateEmailConfirm(),
    mode: accountConfirmMode,
    emailPrefill:
      accountConfirmMode === "update-email-confirmation"
        ? confirmationEmailPrefill
        : "",
    okText:
      accountConfirmMode === "update-email-confirmation"
        ? "Request verification link"
        : "Save",
    modalHeading:
      accountConfirmMode === "update-password-confirmation"
        ? "Password Reset Required"
        : "Account Confirmation",
    cancelText:
      accountConfirmMode === "update-password-confirmation"
        ? "Close"
        : "Cancel",
    hideOkButton:
      accountConfirmMode === "update-password-confirmation" ? true : false
  };

  const { getFieldDecorator } = props.form;

  return (
    <Spin spinning={loadingUserTypes} className="loader">
      <Form layout="vertical" onSubmit={handleSubmit} className="login-form">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-login" />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2vh",
              marginBottom: "1vh"
            }}
          >
            <h3>Sign In</h3>
          </div>
        </div>
        <Form.Item label="Email" className="form-item">
          {getFieldDecorator("email", {
            normalize: s => s?.toLowerCase(),
            rules: [{ required: false, message: "Please input your email!" }]
          })(
            <Input placeholder="Please enter your email" readOnly={loading} />
          )}
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <h4>OR</h4>
        </div>
        <Form.Item label="ID/Passport Number" className="form-item">
          {getFieldDecorator("idNumber", {
            rules: [
              {
                required: false,
                message: "Please enter either your ID or passport #!"
              }
            ]
          })(
            <Input
              placeholder="Please enter either your ID or passport #"
              readOnly={loading}
            />
          )}
        </Form.Item>

        <Form.Item label="Password" className="form-item">
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input.Password
              placeholder="Please enter your password"
              readOnly={loading}
            />
          )}
        </Form.Item>

        <Form.Item className="form-item">
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox disabled={loading}>Remember me</Checkbox>)}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-signin"
            onClick={handleSubmit}
            loading={loading}
          >
            Sign In
          </Button>
        </Form.Item>

        <Link to="/forgot-password">Forgot password?</Link>

        <span style={{ marginTop: "3vh" }}>Don't have an account yet?</span>

        <Button
          type="default"
          className="btn-signup"
          disabled={loading}
          onClick={() =>
            props.employeeInvitationCode
              ? window.location.replace(
                  `/employee-invitation/${props.employeeInvitationCode}`
                )
              : window.location.replace("/signup")
          }
        >
          Sign Up
        </Button>
      </Form>
      <ConfirmAccountForm {...ModalProps} />
    </Spin>
  );
}

interface IProps extends FormComponentProps {
  runTokenEmailVerification?: any;
  employeeInvitationCode?: string;
}

const WrappedNormalLoginForm = Form.create<IProps>({ name: "normal_login" })(
  LoginForm
);
export default WrappedNormalLoginForm;
