import React, { useState } from "react";
import { Form, Button, Input, Select, Card, Skeleton, Table } from "antd";
import { useMutation, useQuery } from "react-apollo";
import {
  CREATE_COMPANY_CONTACT,
  UPDATE_COMPANY_CONTACT
} from "../../graphql/mutations";
import {
  GET_BUSINESS_UNITS_LIST,
  GET_COMPANY_CONTACTS
} from "../../graphql/queries";
import "../Register/index.css";
import InternalErrorPage from "containers/InternalErrorPage";
import { openNotificationWithIcon } from "utils/notification";
import { FormComponentProps } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import { validateMobilePhoneNumber } from "../../utils/validatePhoneNumber";
import { useRegistrationState } from "stores/registration";
import { If } from "components/If";

const ContactsInfoForm: React.FC<any> = ({
  form,
  onDone,
  useCompanyId = false
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { Option } = Select;
  const { getFieldDecorator } = form;

  const [addingContact, setAddingContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any>();
  const [companyContactsInfo, setCompanyContactsInfo] = useState([]);
  const [createCompanyContact] = useMutation(CREATE_COMPANY_CONTACT);
  const [updateCompanyContact] = useMutation(UPDATE_COMPANY_CONTACT);

  const { companyId: cid } = useRegistrationState();

  const companyId = useCompanyId ? +useCompanyId : cid;

  const {
    loading: loadingBusinessUnits,
    error: errorBusinessUnits,
    data: dataBusinessUnits
  } = useQuery(GET_BUSINESS_UNITS_LIST);

  const {
    loading: loadingContacts,
    error: errorContacts,
    data: dataContacts,
    refetch: refetchCompanyContacts
  } = useQuery(GET_COMPANY_CONTACTS, {
    variables: {
      companyId
    },
    onCompleted: () => {
      if (!isEmpty(dataContacts.companyContacts)) {
        setCompanyContactsInfo(dataContacts.companyContacts);

        // check if Legal, Finance and Administration bubisness unit conatcts have been saved
        const requiredContactsSaved =
          dataContacts.companyContacts.filter(x =>
            ["Finance", "Legal", "Administration"].includes(x.businessUnit.name)
          ).length >= 3;

        // pass contact information to parent
        if (onDone) {
          onDone(false, requiredContactsSaved, dataContacts.companyContacts);
        }
      }
    }
  });

  if (loadingBusinessUnits || loadingContacts) {
    return <Skeleton active paragraph={{ rows: 5 }} />;
  }

  if (errorBusinessUnits || errorContacts) {
    return <InternalErrorPage />;
  }

  const { businessUnitList } = !loadingBusinessUnits && dataBusinessUnits;

  // selectable business units options
  const businessUnitOptions: any = businessUnitList
    ? businessUnitList
        .filter(
          unit => ["Finance", "Legal", "Administration"].indexOf(unit.name) > -1
        )
        .map(type => {
          return (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          );
        })
    : [];

  const handleSubmitContactInformation = e => {
    e.preventDefault();
    form.validateFields(
      [
        "businessUnitId",
        "phoneNumber2",
        "phoneNumber",
        "email",
        "email2",
        "workNumber",
        "homeNumber",
        "businessUnitContact"
      ],
      async (err, values) => {
        if (err) {
          return;
        }
        setIsSubmitting(true);
        //update user details

        const upsertCompanyContact = !isEmpty(selectedContact)
          ? updateCompanyContact
          : createCompanyContact;
        const resp = await upsertCompanyContact({
          variables: {
            input: {
              id: selectedContact?.id,
              secondaryCellphone: values.phoneNumber2,
              email: values.email,
              workPhone: values.workNumber,
              secondaryEmail: values.email2,
              businessUnitId: values.businessUnitId,
              businessUnitContactName: values.businessUnitContact,
              cellphone: values.phoneNumber,
              homePhone: values.homeNumber,
              companyId: isEmpty(selectedContact) ? companyId : undefined
            }
          }
        });

        if (resp.errors) {
          setIsSubmitting(false);
          return openNotificationWithIcon(
            "error",
            "Error",
            resp.errors[0].message
          );
        }

        //setIsUserAdditionalInfoSaved(true);

        const refetch_resp = await refetchCompanyContacts();
        if (refetch_resp.errors) {
          return openNotificationWithIcon(
            "error",
            "Refetch",
            "Error encountered when refetching data"
          );
        }

        setSelectedContact({});
        setIsSubmitting(false);
        setAddingContact(false);
        setCompanyContactsInfo(refetch_resp.data.companyContacts);

        // check if Legal, Finance and Administration business unit contacts have been saved
        const requiredContactsSaved =
          refetch_resp.data.companyContacts.filter(x =>
            ["Finance", "Legal", "Administration"].includes(x.businessUnit.name)
          ).length >= 3;

        if (onDone) {
          onDone(true, requiredContactsSaved, companyContactsInfo);
        }
        return openNotificationWithIcon(
          "success",
          "Save Success",
          "Information updated successfully"
        );
      }
    );
  };

  return (
    <>
      <If condition={!isEmpty(companyContactsInfo)}>
        <Table
          dataSource={companyContactsInfo}
          pagination={false}
          rowKey={record => String(record.id)}
          columns={[
            {
              key: "unit",
              title: "Unit",
              dataIndex: "businessUnit.name"
            },
            {
              key: "email",
              title: "Email",
              dataIndex: "email"
            },
            {
              key: "cellphone",
              title: "Cellphone",
              dataIndex: "cellphone"
            },
            {
              key: "workPhone",
              title: "Work Phone",
              dataIndex: "workPhone"
            },
            {
              key: "homePhone",
              title: "Home Phone",
              dataIndex: "homePhone"
            },
            {
              key: "cellphone2",
              title: "Secondary Cellphone",
              dataIndex: "secondaryCellphone"
            },
            {
              key: "email2",
              title: "Secondary Email",
              dataIndex: "secondaryEmail"
            },
            {
              key: "actions",
              title: "Actions",
              render: (record: any) => (
                <Button
                  icon="edit"
                  type="link"
                  className="purple-text"
                  onClick={() => {
                    setSelectedContact(record);
                    setAddingContact(true);
                  }}
                >
                  Edit
                </Button>
              )
            }
          ]}
        />
      </If>
      <br />
      {addingContact && (
        <Card className="input-select contact-table-card">
          <Form>
            <table
              className="table table-bordered"
              style={{ width: "100%" }}
              key="contacts"
            >
              <thead style={{ border: "1px solid ligthtgrey" }}>
                <tr>
                  <th>Contact Information</th>
                  <th>Contact Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Business Unit Contact Name</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("businessUnitContact", {
                        initialValue: selectedContact?.businessUnitContactName,
                        rules: [
                          {
                            required: true,
                            message: "Please enter name"
                          }
                        ]
                      })(<Input placeholder="Enter name" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Business Unit</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("businessUnitId", {
                        initialValue: selectedContact?.businessUnit?.id,
                        rules: [
                          {
                            required: true,
                            message: "Please select business unit"
                          }
                        ]
                      })(
                        <Select style={{ width: "100%" }} placeholder="Select">
                          {businessUnitOptions}
                        </Select>
                      )}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>CellPhone</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("phoneNumber", {
                        initialValue: selectedContact?.cellphone,
                        rules: [
                          {
                            required: true,
                            message: "Please enter a phone number"
                          },
                          {
                            validator: validateMobilePhoneNumber
                          }
                        ]
                      })(<Input placeholder="Enter cellphone" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>CellPhone2</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("phoneNumber2", {
                        initialValue: selectedContact?.secondaryCellphone,
                        rules: [
                          {
                            required: false
                          },
                          {
                            validator: validateMobilePhoneNumber
                          }
                        ]
                      })(<Input placeholder="Enter secondary phone" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Work Number</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("workNumber", {
                        initialValue: selectedContact?.workPhone,
                        rules: [
                          {
                            required: true,
                            message: "Please enter work number"
                          }
                        ]
                      })(<Input placeholder="Enter work number" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Home Number</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("homeNumber", {
                        initialValue: selectedContact?.homePhone,
                        rules: [
                          {
                            required: false,
                            message: "Please enter home number"
                          }
                        ]
                      })(<Input placeholder="Enter home number" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("email", {
                        initialValue: selectedContact?.email,
                        rules: [
                          {
                            required: true,
                            message: "Please input email address"
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address"
                          }
                        ]
                      })(<Input placeholder="Enter email" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Email 2</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("email2", {
                        initialValue: selectedContact?.secondaryEmail,
                        rules: [
                          {
                            required: false,
                            message: "Please input secondary email"
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address"
                          }
                        ]
                      })(<Input placeholder="Enter secondary email" />)}
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>

          <div className="input-spacer input-block input-select ">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmitContactInformation}
              loading={isSubmitting}
            >
              Save contact information
            </Button>
          </div>
        </Card>
      )}
      <div className="input-block input-select ">
        <Button
          type="default"
          className="purple-button"
          style={{
            width: "100%",
            height: "40px"
          }}
          onClick={() => setAddingContact(true)}
          disabled={addingContact}
          loading={isSubmitting}
        >
          + Add Contact
        </Button>
      </div>
    </>
  );
};
interface IProps extends FormComponentProps {
  useCompanyId?: number;
}

const ContactsInfo = Form.create<IProps>({ name: "normal_register" })(
  ContactsInfoForm
);

export default ContactsInfo;
