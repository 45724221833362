import React, { useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import { useMutation } from "react-apollo";
import { FormComponentProps } from "antd/lib/form/Form";
import "../Register/index.css";
import { UPDATE_COMPANY } from "../../graphql/mutations";
import { notifyError, openNotificationWithIcon } from "utils/notification";

const AuditorAccountantInfoForm: React.FC<any> = ({ companyDetails, form }) => {
  const { getFieldDecorator, validateFields } = form;
  const [isSubmitting, setIsSubmiting] = useState(false);

  const {
    membershipNumber,
    natureOfBusiness,
    registeredName,
    tradingName,
    tradingAs,
    registeredNumber,
    vatNumber,
    id
  } = companyDetails;

  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: () => {
      setIsSubmiting(false);
      openNotificationWithIcon("success", "Success", "Company details updated");
    },
    onError: err => {
      setIsSubmiting(false);
      notifyError(err);
    }
  });

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) return;

      setIsSubmiting(true);
      updateCompany({
        variables: {
          input: { ...values, id }
        }
      });
    });
  };

  return (
    <Spin spinning={isSubmitting}>
      <div className="flex-column">
        <div className="input-block-wrapper">
          <div className="flex-column input-block full-width">
            <label>Membership Number</label>
            <Form.Item>
              {getFieldDecorator("membershipNumber", {
                initialValue: membershipNumber ?? "",
                rules: [
                  {
                    required: true,
                    message:
                      "Please enter the  membership number of the company"
                  }
                ]
              })(
                <Input placeholder="Please enter the membership number of the company" />
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Registered Name</label>
            <Form.Item>
              {getFieldDecorator("registeredName", {
                initialValue: registeredName ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the registered name of the company"
                  }
                ]
              })(
                <Input placeholder="Please enter the registered name of the company" />
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Trading Name</label>
            <Form.Item>
              {getFieldDecorator("tradingName", {
                initialValue: tradingName ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the trading name of the company"
                  }
                ]
              })(
                <Input placeholder="Please enter the trading name of the company" />
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Nature of Bussiness</label>
            <Form.Item>
              {getFieldDecorator("natureOfBusiness", {
                initialValue: natureOfBusiness ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the nature of business"
                  }
                ]
              })(<Input placeholder="Please enter the nature of business" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Trading As</label>
            <Form.Item>
              {getFieldDecorator("tradingAs", {
                initialValue: tradingAs ?? "",
                rules: [
                  {
                    required: false,
                    message: "Please enter the trading as"
                  }
                ]
              })(<Input placeholder="Please enter the trading as" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>VAT Number</label>
            <Form.Item>
              {getFieldDecorator("vatNumber", {
                initialValue: vatNumber ?? ""
              })(<Input placeholder="Please enter the VAT number" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Registration Number</label>
            <Form.Item>
              {getFieldDecorator("registeredNumber", {
                initialValue: registeredNumber ?? "",
                rules: [
                  {
                    required: true,
                    message: "Please enter the company registration number"
                  }
                ]
              })(
                <Input placeholder="Please enter the company registration number" />
              )}
            </Form.Item>
          </div>

          <div className="flex-column input-block input-select input-spacer">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

interface IProps extends FormComponentProps {
  companyDetails?: any;
}

const AuditorAccountantInfo = Form.create<IProps>({ name: "normal_register" })(
  AuditorAccountantInfoForm
);
export default AuditorAccountantInfo;
