import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_DOCUMENT_TYPES,
  GET_DOCUMENT_STATUSES
} from "../../../graphql/queries";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
import { notifyError } from "utils/notification";
const { Option } = Select;

export const DocumentFilters = (
  { handleDocumentFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [documentStatusList, setDocumentStatusList] = useState([]);
  const documentNameFilterRef = useRef();
  const documentTypeFilterRef = useRef();
  const documentStatusFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      let conditionalRefs = [];
      resetFilterCollection([
        {
          ref: documentNameFilterRef,
          defaultValue: defaultMultiFilter["documentName"]
        },
        {
          ref: documentTypeFilterRef,
          defaultValue: defaultMultiFilter["documentTypeId"]
        },
        {
          ref: documentStatusFilterRef,
          defaultValue: defaultMultiFilter["documentStatusId"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        },
        ...conditionalRefs
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  const [getDocumentTypes] = useLazyQuery(GET_DOCUMENT_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setDocumentTypeList(data.documentTypes);
    },
    onError: error => {
      notifyError(error);
      setError(error);
    }
  });

  const [getDocumentStatuses] = useLazyQuery(GET_DOCUMENT_STATUSES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setDocumentStatusList(data.documentStatuses);
    },
    onError: error => {
      setError(error);
    }
  });

  useEffect(() => {
    getDocumentTypes({ variables: {} });
    getDocumentStatuses({ variables: {} });
  }, []);

  const handleDocumentFilterUpdate = data => {
    if (data) {
      handleDocumentFilterChange(data, "documentName");
    }
  };

  const debouncedDocumentNameFilterUpdate = useCallback(
    debounce(handleDocumentFilterUpdate, 250),
    []
  );

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Document:</label>
            <Input
              ref={documentNameFilterRef}
              placeholder="Search document by name"
              style={{ width: "calc(100% - 75px)" }}
              onChange={event =>
                debouncedDocumentNameFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Type:</label>
            <Select
              ref={documentTypeFilterRef}
              placeholder="Select document type"
              onChange={value =>
                handleDocumentFilterChange(value, "documentTypeId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {documentTypeList.map(x => {
                return (
                  <Option key={x.extendedName} value={x.id}>
                    {x.extendedName}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Status:</label>
            <Select
              ref={documentStatusFilterRef}
              placeholder="Select document status"
              onChange={value =>
                handleDocumentFilterChange(value, "documentStatusId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {documentStatusList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort: </label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={value => handleDocumentFilterChange(value, "sort")}
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(DocumentFilters);
