import React from "react";
import "./index.css";
import RegisterForm from "./registerForm";
import Registration from "../../../Register";
import { useRegistrationState } from "stores/registration";

function Register(props: any) {
  const { manualRegistrationType } = useRegistrationState();

  if (manualRegistrationType) {
    return <Registration />;
  }

  return (
    <div className="register-form-wrapper">
      <RegisterForm
        employeeInvitationCode={props?.match?.params?.invitationCode}
      />
    </div>
  );
}

export default Register;
