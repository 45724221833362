import baseStyled, { ThemedStyledInterface } from "styled-components";
import { lighten, darken } from "polished";

const theme = {
  fontSizes: [
    "0.833rem",
    "1rem",
    "1.2rem",
    "1.44rem",
    "1.728rem",
    "2.074rem",
    "2.488rem"
  ], // Golden ratio
  space: [0, 5, 10, 20, 40, 80, 160],
  fontFamily: "Lato, Helvetica Neue, sans-serif",
  colors: {
    accent: "#5AC1F2",
    htmlBackground: "5AC1F2",
    inverseTextColor: "#CACACA",
    neutral: "#C9BDBC",
    primary: "#43075f",
    secondary: "#5AC1F2",
    textColor: "#5AC1F2",
    transparent: "#00000000",
    transparentBlack: "#00000004",
    transparentWhite: "#FFFFFF04",
    darkSlateBlue: "#234361",
    battleshipGrey: "#707177",
    dusk: "#425a70",
    pastelRed: "#ec4c47",
    linkedinBlue: "#0077b5",
    lightDark: "#33353d",
    blue: {
      lightest: lighten(0.2, "#1476F2"),
      light: lighten(0.1, "#1476F2"),
      base: "#1476f2",
      dark: darken(0.1, "#1476F2")
    },
    border: {
      default: "#E4E7EB",
      muted: "#EDF0F2"
    },
    text: {
      muted: "#66788A",
      default: "#425A70",
      dark: "#222429"
    },
    intent: {
      success: "#47B881",
      danger: "#EC4C47",
      warning: "#D9822B",
      info: "#5AC1F2"
    },
    background: {
      tint1: "#F9F9FB",
      tint2: "#F5F6F7"
    },
    solid: {
      black: "#000",
      white: "#FFF"
    },
    opacity: {
      transparent: "transparent",
      transparentBlack: "rgba(0,0,0,0.1)",
      transparentWhite: "rgba(255,255,255,0.2)"
    }
  },
  baseRadius: 4,
  rounding: [0, 4, 8, 16, 32],
  gridGutter: 15,
  textTransform: "none",
  pointer: false,
  baseBoxShadow: "0 4px 6px rgba(50,50,50, .11), 0 1px 3px rgba(0,0,0,.08)",
  baseTransition: "all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
  maxContentWidth: 1200
};

export type Theme = typeof theme;

export interface ThemeInterface {
  theme: Theme;
}

const styled = baseStyled as ThemedStyledInterface<Theme>;

export { theme, styled };
