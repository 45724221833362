import React, { useState, useEffect } from "react";
import { Button, Card, Icon, Upload } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  GET_TASK,
  GET_PRESIGNED_URL,
  GENERATE_SAGE_DOC
} from "graphql/queries";
import { openNotificationWithIcon } from "utils/notification";
import ComplaintLoader from "../Admin/Complaint/SubComponents/complaintLoader";
import { CREATE_DOCUMENT, PROCESS_DD_PAYMENT } from "graphql/mutations";
import { uploadToS3 } from "utils/uploadToS3";

export default ({ history, match }) => {
  const [task, setTask] = useState<any>({});
  const [loadingTask, setLoadingTask] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [documentPath, setDocumentPath] = useState("");
  const [fileType, setFileType] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [popFile, setPopFile] = useState<any>();
  const [invoiceSagePayload, setInvoiceSagePayload] = useState<any>();
  const [paymentRef, setPaymentRef] = useState<number>();

  const userId = +localStorage.getItem("userId");

  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "network-only",
    onError: err => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        history.push("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading task"
      );
    },
    onCompleted: data => {
      if (data?.task) {
        setTask(data.task);
        setLoadingTask(false);

        if (data?.task?.invoice?.sagePayload) {
          const invoice = JSON.parse(data?.task.invoice.sagePayload);
          setInvoiceSagePayload(invoice);
          setPaymentRef(invoice?.Reference?.replace("-", "_"));
        }

        localStorage.setItem("registrationCapacity", "Individual");
        localStorage.setItem("companyId", data?.task?.company?.id.toString());
      }
    }
  });

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [processDDPayment] = useMutation(PROCESS_DD_PAYMENT, {
    onCompleted: data => {
      if (!isEmpty(data)) {
        localStorage.setItem(
          "directDepositTransactionId",
          data.processDDPayment.transaction.id
        );
        localStorage.setItem(
          "paymentTaskId",
          data.processDDPayment.paymentTask.id
        );
      }
      setIsSubmitting(false);
    },
    onError: error => {
      setIsSubmitting(false);
      console.error("process_dd_payment_error", error);
    }
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (!isEmpty(data.preSignedUrl)) {
        let currentList = documentList;
        currentList.push({
          fileName: documentName,
          presignedUrl: data.preSignedUrl,
          type: fileType,
          popFile,
          path: documentPath
        });
        setDocumentList(currentList);
        setIsUploading(false);
      }
    },
    onError: err => {
      setIsUploading(false);
      console.log(err);
    }
  });

  const uploadDocument = async () => {
    const uploadedDocs = [];
    try {
      if (documentList.length === 0) {
        return openNotificationWithIcon(
          "error",
          "Error",
          "Please upload proof of payment/deposit slip to submit for review"
        );
      }

      setIsSubmitting(true);

      for (let {
        presignedUrl,
        type,
        popFile,
        path,
        fileName
      } of documentList) {
        await uploadToS3(presignedUrl, type, popFile);
        const { data } = await createDocument({
          variables: {
            input: {
              documentName: fileName,
              documentPath: path,
              documentTypeId: 20,
              documentStatusId: 1,
              documentFormat: type,
              companyId: task?.company?.id,
              userId: +userId,
              forCompany: true
            }
          }
        });

        uploadedDocs.push(data.createDocument.id);
      }

      await processDDPayment({
        variables: {
          input: {
            userId,
            companyId: task?.company?.id,
            directDepositInvoiceNumber: paymentRef,
            paymentMethod: "Direct",
            paymentTaskId: task?.id,
            documents: uploadedDocs
          }
        }
      });

      history.push("/tasks");
    } catch (error) {
      // @ts-ignore
      if (error.message.includes("504")) {
        return history.push("/login");
      }
      console.error("upload proof of payment error", error);
      openNotificationWithIcon(
        "error",
        "Upload Error",
        "Error occurred submitting proof of payment"
      );
    }
  };

  const generatePresignedUrl = async option => {
    try {
      const { file, onSuccess } = option;

      const fileExt: string = file.name.split(".").pop();
      const docNo = documentList.length;
      const docName = `Direct_Deposit_Payment_${task?.company?.id}_${paymentRef}_${docNo}.${fileExt}`;
      const path = `${task?.company?.id}/Proof_of_Payments/Direct_Deposits/${docName}`;

      setIsUploading(true);
      setPopFile(file);
      setFileType(file.type);
      setDocumentName(docName);
      setDocumentPath(path);

      await getPresignedUrl({
        variables: {
          input: {
            filePath: path,
            contentType: file.type,
            forCompany: true
          }
        }
      });

      setTimeout(() => {
        onSuccess();
      }, 2000);
    } catch (error) {
      console.error("generating url", error);
      openNotificationWithIcon("error", "Error", "Error when generating url");
    }
  };

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "network-only",
    onError: err => {
      setLoading(false);

      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      ) {
        history.push("/login");
      }

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      window.open(data.generateSageDocument);
      setLoading(false);
    }
  });

  const handleViewTemplate = (type: string, companyId: number, id: number) => {
    setLoading(true);
    getSageDocument({
      variables: {
        input: {
          docType: type,
          companyId,
          sageInvoiceId: id
        }
      }
    });
  };

  useEffect(() => {
    getTask({ variables: { taskId: +match?.params?.id } });
  }, [match?.params?.id]);

  return (
    <>
      {loadingTask && <ComplaintLoader />}
      {!loadingTask && (
        <div style={{ margin: "1vw" }}>
          <Card className="information-card" key={`Address ${1}`}>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h2>Payment Task {task?.id}</h2>
                <h3>
                  Status: <a>{task?.taskStatus}</a>
                </h3>
              </div>
              <br />
              <span>
                Task Type: <label>{task?.type?.name}</label>
                <br />
                Date Created:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
                <br />
                Last Modified:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
              </span>
            </div>
          </Card>
          <br />
          {!isNaN(task?.company?.id) &&
            !["complete", "resolved"].includes(
              task.taskStatus.toLowerCase()
            ) && (
              <div className="flex-row">
                <div className="flex-column" style={{ width: "100%" }}>
                  <Card>
                    <>
                      <div className="flex-colum">
                        <h4>
                          Please deposit your penalty amount into the account
                          below. Once you have made the payment, you will need
                          to upload your proof of payment or deposit slip in
                          order to proceed.
                        </h4>
                        <br />
                        <Card
                          bodyStyle={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2rem"
                          }}
                        >
                          <div className="flex-column input-block">
                            <Button
                              shape="round"
                              className="btn-registration-capacity-selected"
                            >
                              CFDC Bank Details
                            </Button>
                            <br />
                            <span>
                              Bank: <label>ABSA</label>
                              <br />
                              Account name: <label>CFDC Account</label>
                              <br />
                              Account number: <label>4055948699</label>
                              <br />
                              Account type: <label>Cheque</label>
                              <br />
                              Branch code: <label>633505</label>
                              <br />
                            </span>
                          </div>

                          <div className="flex-column input-block">
                            <Button
                              shape="round"
                              className="btn-registration-capacity-selected"
                            >
                              Payment Information
                            </Button>
                            <br />
                            <span>
                              Amount to pay:{" "}
                              {
                                <label>
                                  {task?.invoice
                                    ? `R ${task?.invoice.amount}`
                                    : "n/a"}
                                </label>
                              }
                              <br />
                              Your reference number: <label>{paymentRef}</label>
                              <br />
                              {task?.comment && (
                                <>
                                  Comment: <label>{task?.comment}</label>
                                </>
                              )}
                            </span>
                            <span>
                              Tax Invoice{" "}
                              <Button
                                type="link"
                                loading={loading}
                                className="purple-link"
                                onClick={() => {
                                  handleViewTemplate(
                                    "invoice",
                                    task?.company?.id,
                                    invoiceSagePayload?.ID
                                  );
                                }}
                              >
                                View
                              </Button>
                            </span>
                          </div>
                        </Card>
                        <br />
                        <br />
                        <h4>
                          Please upload your proof of payment or deposit slip
                          indicating that you have processed the above payment.
                        </h4>
                      </div>
                      <div className="flex-column input-block">
                        <br />
                        <h4>Proof of payment/Deposit Slip</h4>
                        <Upload
                          customRequest={generatePresignedUrl}
                          style={{ display: "flex" }}
                          disabled={isUploading}
                        >
                          <Button>
                            <Icon type="upload" />
                            <span>Upload</span>
                          </Button>
                        </Upload>
                        <br />
                        <Button
                          disabled={isUploading}
                          loading={isSubmitting}
                          className="purple-button"
                          onClick={() => uploadDocument()}
                        >
                          Submit for Review
                        </Button>
                      </div>
                    </>
                  </Card>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};
