import React from "react";
import { Modal, Form, Input, Select } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_TYPES } from "containers/Admin/graphql/queries";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  initialData
}) => {
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const { data } = useQuery(GET_USER_TYPES);

  let children: any = [];

  // only superAdmin can add another super admin
  if (data && data.userTypesList) {
    data.userTypesList.map(type => {
      children.push(
        <Option key={type.id} value={type.id}>
          {type.name}
        </Option>
      );
    });
  }

  // array of user types/role that user is assigned
  const userRolesId: any = [];
  const userRolesName: any = [];
  if (initialData)
    // eslint-disable-next-line
    initialData?.roles.map(role => {
      userRolesId.push(role.id);
      userRolesName.push(role.name);
    });

  return (
    <Modal
      visible={visible}
      title={
        initialData && initialData.id ? "Edit Permission" : "Add Permission"
      }
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Form.Item label="ID" style={{ display: "none" }}>
          {getFieldDecorator("id", {
            initialValue: initialData ? initialData.id : undefined
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Permission Name">
          {getFieldDecorator("name", {
            initialValue: initialData ? initialData.name : "",
            rules: [
              {
                required: true,
                message: "Please input name of the permission!"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: initialData ? initialData.description : "",
            rules: [
              {
                required: true,
                message: "Please input the permission's description!"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="User Type">
          {getFieldDecorator("userRole", {
            initialValue: userRolesId,
            rules: [
              {
                required: true,
                message: "Please select user type(s)"
              }
            ]
          })(
            <Select mode="multiple" style={{ width: "100%" }}>
              {children}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditPermissionForm = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default EditPermissionForm;
