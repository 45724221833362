import React, { useState, useMemo, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Icon,
  Upload,
  Row,
  Col,
  Select,
  InputNumber,
  Radio
} from "antd";
import { format, subMonths } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { GET_TASK, GET_PRESIGNED_URL, GET_DOWNLOAD_URL } from "graphql/queries";
import { notifyError, notifySuccess } from "utils/notification";
import ComplaintLoader from "../../Admin/Complaint/SubComponents/complaintLoader";
import { CREATE_AUDIT, CREATE_DOCUMENT } from "graphql/mutations";
import { uploadToS3 } from "utils/uploadToS3";
import { useGlobalState } from "../../../globalStore";
import { Link } from "react-router-dom";
import { bbeeeLevels, industries, provinces } from "../../../constants";
import { isAdmin } from "utils";
import ResultComment from "containers/Admin/Review/resultComment";
import UserComment from "./UserComment";
import fileIcon from "../../../assets/images/pdf_icon.png";
import { hasPermission } from "components/Auth/can";

export default ({ history, match }) => {
  const { state } = useGlobalState();
  const style = { width: "300px" };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [task, setTask] = useState<any>();
  const [documentList, setDocumentList] = useState([]);
  const [bbeeeLevel, setBbeeeLevel] = useState<string>();
  const [bbeeeLevelEnabled, setBbeeeLevelEnabled] = useState(true);
  const [industry, setIndustry] = useState<string[]>();
  const [geographicalArea, setGeographicalArea] = useState<string[]>();
  const [bookValue, setBookValue] = useState<number>();
  const [amountRecovered, setAmountRecovered] = useState<number>();

  const username = localStorage.getItem("username");

  const userId = +localStorage.getItem("userId");
  const docId = useMemo(() => {
    return state.lookups?.documentTypes.find(
      t => t.name === "Audit Certificates"
    )?.id;
  }, [state.lookups?.documentTypes]);

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [createAudit] = useMutation(CREATE_AUDIT);

  const { refetch: getPresignedUrl } = useQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    skip: true,
    onError: err => {
      notifyError(err);
    }
  });

  const [getTask, { loading: loadingTask }] = useLazyQuery(GET_TASK, {
    fetchPolicy: "no-cache",
    variables: { taskId: +match?.params?.id },
    onCompleted: ({ task }) => {
      setTask(task);
      if (task?.audit) {
        setBbeeeLevel(task?.audit?.bbeeeLevel);
        setIndustry(task?.audit?.industry?.split(" > "));
        setGeographicalArea(task?.audit?.geographicalArea?.split(" > "));
        setBookValue(task?.audit?.valueOfBooks);
        setAmountRecovered(task?.audit?.amountRecovered);
      }
    },
    onError: err => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        history.push("/login");

      notifyError(err);
    }
  });

  function isReviewPage() {
    const location = window.location.pathname;
    return location.includes("admin/tasks/review/audit");
  }

  const uploadDocument = async option => {
    try {
      setIsUploading(true);
      const { file, onSuccess } = option;

      const docNo = documentList.length;
      const docName = `${task?.company?.id}/Audit/${docNo}_${file.name}`;
      const path = `${task?.company?.id}/Proof_of_Payments/Direct_Deposits/${docName}`;

      const { data: url } = await getPresignedUrl({
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: true
        }
      });

      await uploadToS3(url.preSignedUrl, file.type, file);
      const { data: doc } = await createDocument({
        variables: {
          input: {
            documentName: file.name,
            documentPath: path,
            documentTypeId: docId,
            documentStatusId: 2,
            documentFormat: file.type,
            companyId: task?.company?.id,
            userId: +userId,
            forCompany: true
          }
        }
      });

      setDocumentList([...documentList, doc.createDocument.id]);

      onSuccess();
      notifySuccess("Document successfully uploaded");
    } catch (error) {
      console.error("generating url", error);
      // @ts-ignore
      notifyError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      if (!bbeeeLevel && bbeeeLevelEnabled) {
        setIsSubmitting(false);
        return notifyError("Please select the BBEEE Level");
      }

      if (!industry) {
        setIsSubmitting(false);
        return notifyError("Please the industry");
      }

      if (!geographicalArea) {
        setIsSubmitting(false);
        return notifyError("Please select the geographical area");
      }

      if (!bookValue) {
        setIsSubmitting(false);
        return notifyError("Please enter the book value");
      }

      if (!amountRecovered) {
        setIsSubmitting(false);
        return notifyError("Please enter the amount recovered");
      }

      if (!documentList.length) {
        setIsSubmitting(false);
        return notifyError("Please upload the audit document");
      }

      const { errors } = await createAudit({
        variables: {
          input: {
            ...(bbeeeLevel && { bbeeeLevel }),
            documentIds: documentList,
            geographicalArea: geographicalArea.join(" > "),
            industry: industry.join(" > "),
            amountRecovered,
            bookValue,
            taskId: task?.id
          }
        }
      });

      if (errors && errors.length) {
        notifyError(errors[0].message);
      }

      history.replace("/tasks");
    } catch (err) {
      // @ts-ignore
      notifyError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isadmin = isAdmin();
  const disabled =
    isReviewPage() ||
    (isadmin &&
      username !== "doreen@cfdc.org.za" &&
      !hasPermission("audit_certificate:complete"));

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: err => {
      setIsUploading(false);
      notifyError(err);
    },
    onCompleted: data => {
      setIsUploading(false);
      window.open(data.preSignedDownloadUrl);
    }
  });

  const handleViewDocument = (filePath: string) => {
    setIsUploading(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true
        }
      }
    });
  };

  useEffect(() => {
    getTask();
  }, []);

  return (
    <>
      {loadingTask && <ComplaintLoader />}
      {!loadingTask && (
        <div style={{ margin: "1vw" }}>
          <Card className="detail-card" key={`Address ${1}`}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/tasks">Audit Report Task</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {task?.company?.tradingName || task?.company?.registeredName}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h2>
                  {task?.company?.tradingName || task?.company?.registeredName}
                </h2>
                <h3>
                  Status: <a>{task?.taskStatus}</a>
                </h3>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h3>
                  Task ID: <strong>{task?.id}</strong>
                </h3>
                <h3>
                  Type: <strong>{task?.type?.name}</strong>
                </h3>
                <h3>
                  Financial Year Ended:{" "}
                  <strong>
                    {task &&
                      format(
                        task.audit?.financialYear
                          ? new Date(task.audit.financialYear)
                          : subMonths(new Date(task.createdDate), 1),
                        "MMMM yyyy"
                      )}
                  </strong>
                </h3>
                <h3>
                  Created:{" "}
                  <strong>
                    {format(
                      task?.createdDate
                        ? new Date(task?.createdDate)
                        : new Date(),
                      "dd-MM-yyyy"
                    )}
                  </strong>
                </h3>
              </div>
            </div>
          </Card>
          <br />
          {!isNaN(task?.company?.id) &&
            !["complete", "resolved"].includes(
              task.taskStatus.toLowerCase()
            ) && (
              <div className="flex-row">
                <div className="flex-column" style={{ width: "100%" }}>
                  <Card>
                    <div className="flex-colum">
                      <h3>Submission of Audit Report Progress</h3>
                      <h4>
                        This process consists of two sections and will be
                        reviewed upon submission. Each section is{" "}
                        <strong>Mandatory</strong> to complete and any part be
                        incomplete, this task will not be available to Submit
                        for Review.
                      </h4>
                    </div>

                    <br />
                    <h3>1. GENERAL SECTION</h3>
                    <small>Please complete the below:</small>
                    <Row
                      gutter={24}
                      justify="space-around"
                      style={{ marginBottom: "10px" }}
                    >
                      <Col span={6}>BBEEE Level:</Col>
                      <Col span={6}>
                        <Radio.Group
                          onChange={e => setBbeeeLevelEnabled(e.target.value)}
                          value={bbeeeLevelEnabled}
                          disabled={disabled}
                        >
                          <Radio value={false}>No</Radio>
                          <Radio value={true}>Yes</Radio>
                        </Radio.Group>
                      </Col>
                      <Col span={12}>
                        <Select
                          disabled={!bbeeeLevelEnabled || disabled}
                          style={style}
                          placeholder="Select the level"
                          onChange={v => setBbeeeLevel(v)}
                          value={bbeeeLevel}
                        >
                          {bbeeeLevels.map(i => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={24} style={{ marginBottom: "10px" }}>
                      <Col span={12}>Industry:</Col>
                      <Col span={12}>
                        <Select
                          style={style}
                          placeholder="Select the industry"
                          onChange={v => setIndustry(v)}
                          value={industry}
                          disabled={disabled}
                          mode="multiple"
                        >
                          {industries.map(i => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={24} style={{ marginBottom: "10px" }}>
                      <Col span={12}>
                        Geographic Area where Collections are Done:
                      </Col>
                      <Col span={12}>
                        <Select
                          style={style}
                          placeholder="Select the geographical area"
                          onChange={v => setGeographicalArea(v)}
                          value={geographicalArea}
                          disabled={disabled}
                          mode="multiple"
                        >
                          {provinces.map(i => (
                            <Select.Option key={i} value={i}>
                              {i}
                            </Select.Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={24} style={{ marginBottom: "10px" }}>
                      <Col span={12}>
                        Value of Books Worked On for the Year:
                      </Col>
                      <Col span={12}>
                        <InputNumber
                          style={style}
                          step={1000}
                          placeholder="Enter the book value"
                          onChange={v => setBookValue(v)}
                          readOnly={disabled}
                          value={bookValue}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={24} style={{ marginBottom: "10px" }}>
                      <Col span={12}>
                        Total Amount Recovered During the Financial Year:
                      </Col>
                      <Col span={12}>
                        <InputNumber
                          style={style}
                          step={1000}
                          placeholder="Enter the value recovered"
                          onChange={v => setAmountRecovered(v)}
                          readOnly={disabled}
                          value={amountRecovered}
                        />
                      </Col>
                    </Row>

                    <br />
                    <h3>2. DOCUMENTATION UPLOAD</h3>
                    <small>
                      Click upload button to upload your completed audit report
                      (Form 5)
                    </small>
                    <br />
                    {disabled ? (
                      <>
                        <h4>Supporting Documents:</h4>

                        {task?.audit?.documents?.map(
                          ({ documentPath, name }) => (
                            <span key={documentPath}>
                              <img
                                style={{ height: "17px", width: "17px" }}
                                src={fileIcon}
                              />
                              &nbsp; &nbsp; {name}
                              <Button
                                type="link"
                                loading={isSubmitting}
                                onClick={() => handleViewDocument(documentPath)}
                              >
                                View
                              </Button>
                            </span>
                          )
                        )}
                        {!task?.audit?.documents &&
                          !task?.audit?.documents?.length && (
                            <p>No supporting documents uploaded</p>
                          )}
                      </>
                    ) : (
                      <Upload
                        customRequest={uploadDocument}
                        disabled={isUploading}
                      >
                        <Button>
                          <Icon type="upload" />
                          <span>Upload</span>
                        </Button>
                      </Upload>
                    )}
                    <br />

                    {isadmin && isReviewPage() ? (
                      <ResultComment {...{ ...task, step: "AdminApproval" }} />
                    ) : (
                      <>
                        <UserComment {...{ ...task, step: "AdminApproval" }} />
                        <br />
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <Button
                            disabled={isUploading || isUploading}
                            className="purple-button"
                            style={{
                              backgroundColor: "#fff",
                              color: "#333",
                              width: "100px"
                            }}
                            onClick={() =>
                              history.push(isadmin ? "/admin/tasks" : "/tasks")
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={isUploading}
                            loading={isSubmitting}
                            className="purple-button"
                            style={{
                              width: "100px"
                            }}
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};
