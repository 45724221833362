import React, { useState } from "react";
import { Button, Form, Input, Select, Divider } from "antd";
import { validateMobilePhoneNumber } from "../../utils/validatePhoneNumber";
import { notifyError, openNotificationWithIcon } from "utils/notification";
import {
  BASE_ENDPOINT,
  COMPLAINT_SUPPORTING_DOCUMENT_TYPE_ID,
  UNREGISTERED_COMPANY_ID
} from "../../constants";
import { uploadToS3 } from "../../utils/uploadToS3";
import DocumentUploader from "./uploadDocuments";
import { Success } from "./success";
import OTPForm from "./complantsOTP";
import Header from "./header";

const DebtCollectorForm = props => {
  const complaintId = props.match.params.complaintId;
  const [submitting, setSubmitting] = useState(false);
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [values, setValues] = useState<any>();
  const [loadingCompany, setLoadingCompany] = useState<any>();

  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const { getFieldDecorator } = props.form;

  const handleCompaniesSearch = async (value: any) => {
    if (value.length > 3) {
      setLoadingCompany(true);
      const resp = await fetch(`${BASE_ENDPOINT}/companies`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          input: { criteria: "searchName", filter: value }
        })
      });
      const data = await resp.json();
      setCompaniesList(data.result);
      setLoadingCompany(false);
    }
  };

  const setCompanyDetails = (e: string) => {
    setIsOtherCompany(e === "Other");
    setSelectedCompany(companiesList.find(x => x.id === +e));
  };

  const authenticateUser = async () => {
    props.form.validateFields(async (err, values) => {
      if (err) return;

      setSubmitting(true);

      if (
        values.debtCollectorContactNumber?.substring(0, 3) === "027" ||
        values.debtCollectorContactNumber?.substring(0, 3) === "+27"
      ) {
        values.debtCollectorContactNumber =
          "+27" + values.debtCollectorContactNumber?.slice(3);
      } else if (values.debtCollectorContactNumber?.substring(0, 1) === "0") {
        values.debtCollectorContactNumber =
          "+27" + values.debtCollectorContactNumber?.slice(1);
      } else if (values.debtCollectorContactNumber?.substring(0, 3) !== "+27") {
        values.debtCollectorContactNumber =
          "+27" + values.debtCollectorContactNumber;
      }

      setValues(values);

      // trigger otp
      const res = await fetch(`${BASE_ENDPOINT}/complaint/otp/${complaintId}`, {
        method: "GET"
      });

      const data = await res.json();

      if (!data) {
        setSubmitting(false);
        return openNotificationWithIcon(
          "error",
          "Error",
          "Error submitting information. Please try again"
        );
      }
      if (data?.result === "success") setShowOTP(true);
    });
  };

  const updateComplaint = async () => {
    const resp = await fetch(`${BASE_ENDPOINT}/complaint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        input: {
          id: +complaintId,
          companyId: isOtherCompany
            ? UNREGISTERED_COMPANY_ID
            : selectedCompany?.id,
          complaintAgainst: "Debt Collector",
          debtCollectorCompany: isOtherCompany
            ? values.otherCompanyName
            : selectedCompany?.tradingName || selectedCompany?.registeredName,
          debtCollectorName: values.debtCollectorName,
          debtCollectorEmail: values.debtCollectorEmail,
          debtCollectorContactNumber: values.debtCollectorContactNumber,
          description: values.description
        }
      })
    });

    const data = await resp.json();

    // create documents
    if (documentsToUpload.length) await uploadDocuments();

    setSubmitting(false);
    setSubmitted(true);
    if (data?.result?.id) {
      setSuccessful(true);
    }
  };

  const uploadDocuments = async () => {
    try {
      let documentRecords = [];
      await Promise.all(
        documentsToUpload.map(async doc => {
          await uploadToS3(doc.preSignedUrl, doc.contentType, doc.file);

          documentRecords.push({
            documentName: doc.documentName,
            documentPath: `${
              isOtherCompany ? UNREGISTERED_COMPANY_ID : selectedCompany?.id
            }/Complaints/Complaint_${complaintId}/Supporting_Documents/${
              doc.documentName
            }`,
            documentTypeId: COMPLAINT_SUPPORTING_DOCUMENT_TYPE_ID,
            documentStatusId: 1,
            standardDescription: doc.documentName,
            altDescription: "",
            documentFormat: doc.contentType,
            complaintId: +complaintId,
            companyId: isOtherCompany
              ? UNREGISTERED_COMPANY_ID
              : selectedCompany?.id,
            forCompany: true
          });
        })
      );
      const res = await fetch(`${BASE_ENDPOINT}/createDocument`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          input: documentRecords
        })
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
    } catch {
      notifyError("Error occurred during upload");
    }
  };

  return (
    <>
      <Header />
      {!successful && (
        <div>
          <div className="debt-collector-form-container">
            <h3>Create a Complaint</h3>
            <Divider />

            <span>Please fill out the details on debt collector below: </span>
            <br />
            <div className="">
              <div className="form-item-block">
                <Form.Item label="Company name" className="input-block">
                  {getFieldDecorator("debtCollectorCompany", {
                    initialValue: selectedCompany?.tradingName,
                    rules: [
                      {
                        required: true,
                        message: "Please enter company name"
                      }
                    ]
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Enter company's name and select"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      notFoundContent={null}
                      onSearch={handleCompaniesSearch}
                      onChange={setCompanyDetails}
                      loading={loadingCompany}
                    >
                      {companiesList?.map(type => {
                        return (
                          <Select.Option key={type.id} value={type.id}>
                            {type.tradingName || type.registeredName}
                          </Select.Option>
                        );
                      })}
                      <Select.Option key="Other" value="Other">
                        Other
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
                {isOtherCompany && (
                  <Form.Item label={"Company Name"} className="input-block">
                    {getFieldDecorator("otherCompanyName", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter the company name"
                        }
                      ]
                    })(<Input placeholder="Please enter the company name" />)}
                  </Form.Item>
                )}
                <Form.Item
                  label={"Debt Collector's Name"}
                  className="input-block"
                >
                  {getFieldDecorator("debtCollectorName", {
                    rules: [
                      {
                        required: false,
                        message: `Please enter debt collector's name`
                      }
                    ]
                  })(<Input placeholder="Please input Name" />)}
                </Form.Item>
              </div>

              <div className="form-item-block">
                <Form.Item
                  label={`Debt Collector's Email`}
                  className="input-block"
                >
                  {getFieldDecorator("debtCollectorEmail", {
                    rules: [
                      {
                        required: false,
                        type: "email",
                        message: `Please enter valid email address`
                      }
                    ]
                  })(<Input placeholder="Please input email address" />)}
                </Form.Item>
                <Form.Item
                  label="Debt Collector Contact Number"
                  className="input-block"
                >
                  {getFieldDecorator("debtCollectorContactNumber", {
                    rules: [
                      {
                        required: false,
                        message: "Please enter a valid number"
                      },
                      {
                        validator: validateMobilePhoneNumber
                      }
                    ]
                  })(
                    <Input
                      addonBefore={
                        <div>
                          <span style={{ color: "black" }}>🇿🇦</span> +27
                        </div>
                      }
                      type="number"
                      placeholder="Please input contact number"
                    />
                  )}
                </Form.Item>
              </div>

              <h3>Please upload all supporting documentation/communication</h3>
              <br />

              <DocumentUploader
                updateDocumentList={list => setDocumentsToUpload(list)}
                complaintId={complaintId}
                companyId={
                  isOtherCompany ? UNREGISTERED_COMPANY_ID : selectedCompany?.id
                }
                companyName={
                  isOtherCompany
                    ? values?.otherCompanyName
                    : selectedCompany?.tradingName ||
                      selectedCompany?.registeredName
                }
              />
              <br />
              <Form.Item label="Describe your complaint: (Max: 1000 characters)">
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a description for the complaint"
                    }
                  ]
                })(<Input.TextArea rows={5} maxLength={1000} />)}
              </Form.Item>

              {!submitted && (
                <Button
                  onClick={authenticateUser}
                  className="purple-button"
                  loading={submitting}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {submitted && successful && <Success />}

      {showOTP && (
        <OTPForm
          show={showOTP}
          complaintId={complaintId}
          updateComplaint={updateComplaint}
          cancelSumbit={(val: boolean) => setSubmitting(val)}
        />
      )}
    </>
  );
};

export default Form.create({ name: "normal_register" })(DebtCollectorForm);
