import React, { useState, useEffect, useMemo } from "react";
import { Button, Card, Breadcrumb, Badge, Table } from "antd";
import { useLocation, withRouter } from "react-router";
import { parseISO, format } from "date-fns";
import { useLazyQuery, useMutation } from "react-apollo";
import ResultComment from "../resultComment";
import {
  GET_DOCUMENTS,
  GET_TRANSACTION_BY_FILTER,
  GET_DOWNLOAD_URL,
  GENERATE_SAGE_DOC,
  GET_CONTROL_LIST
} from "../../../../graphql/queries";
import { isEmpty } from "lodash";
import "../index.css";
import InformationLoader from "./../informationLoader";
import { notifyError, openNotificationWithIcon } from "utils/notification";
import PermissionsGuard from "../../../../components/Auth/can";
import AccessDenied from "../../../../components/Auth/accessDenied";
import fileIcon from "../../../../assets/images/pdf_icon.png";
import { GET_REPORT_AMOUNT } from "containers/Admin/graphql/queries";
import {
  REFRESH_SUBLIST,
  CREATE_MANUAL_INVOICE
} from "containers/Admin/graphql/mutations";
import SubscriptionControlList from "./SubscriptionControlList";
import { InvalidTask } from "./InvalidTask";

function RenewalReview() {
  const location = useLocation() as any;
  const [transaction, setTransaction] = useState<any>();
  const [returnInvoiceSageID, setReturnInvoiceSageID] = useState<number>();
  const [invoiceExists, setInoviceExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [loadingGenereateInvoice, setLoadingGenereateInvoice] = useState(false);
  const [popDocs, setPoPDocs] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState();
  const [renewalAmount, setRenewalAmount] = useState(0);

  const task = location.state;
  const { id: taskId, createdDate, taskStatus, company, invoice } = task;
  const companyName = company.registeredName || company.tradingName;

  const invoiceId = useMemo<string>(() => {
    if (invoice?.sageInvoiceId) {
      return invoice.sageInvoiceId;
    } else if (transaction) {
      return transaction.sageTaxInvoiceId;
    } else {
      return returnInvoiceSageID ? String(returnInvoiceSageID) : undefined;
    }
  }, [invoice, transaction, returnInvoiceSageID]);

  const [
    getControlList,
    { loading: loadingControlList, data: controlListData }
  ] = useLazyQuery(GET_CONTROL_LIST, {
    variables: {
      renewalTaskId: taskId
    },
    onError: notifyError
  });

  const [getTransaction] = useLazyQuery(GET_TRANSACTION_BY_FILTER, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        taskId,
        includeTotal: false
      }
    },
    onError: () => {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading transactions for review"
      );
    },
    onCompleted: ({ transactions }) => {
      if (transactions.transactionList && transactions.transactionList[0]) {
        setTransaction(transactions.transactionList[0]);
        setInoviceExists(
          transactions.transactionList[0].directDepositInvoiceNumber && true
        );
      }
      setLoading(false);
    }
  });

  const [getDocuments] = useLazyQuery(GET_DOCUMENTS, {
    variables: {
      input: { taskId }
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ documents }) => {
      setLoadingDoc(false);
      if (documents?.documentList) {
        const popList = documents?.documentList.filter(
          d => d.documentType.extendedName === "Transactions - Banking"
        );
        setPoPDocs(popList);
      }
    },
    onError: err => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    }
  });

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: err => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    }
  });

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "no-cache",
    onError: err => {
      console.error("generate document error", err);
      setLoadingDoc(false);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setLoadingDoc(false);
      setDownloadUrl(data.generateSageDocument);
    }
  });

  const [getReports] = useLazyQuery(GET_REPORT_AMOUNT, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        taskId
      }
    },
    onCompleted: data => {
      setRenewalAmount(data.getReportAmount?.amount || 0);
    },
    onError: err => {
      setLoadingDoc(false);
      notifyError(err);
    }
  });

  const [create_invoice] = useMutation(CREATE_MANUAL_INVOICE, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        companyId: company.id,
        taskId,
        date: new Date(createdDate).toISOString()
      }
    },
    onCompleted: ({ createManualInvoice }) => {
      if (createManualInvoice.result) {
        setReturnInvoiceSageID(createManualInvoice.sageInvoiceId);
        setInoviceExists(true);
      }

      setLoadingGenereateInvoice(false);
    },
    onError: err => {
      setLoadingDoc(false);
      setLoadingGenereateInvoice(false);
      notifyError(err);
    }
  });

  const handleViewInvoice = (inv?: string) => {
    setLoadingDoc(true);
    getSageDocument({
      variables: {
        input: {
          docType: "invoice",
          companyId: company.id,
          sageInvoiceId: inv ?? invoiceId
        }
      }
    });
  };

  const handleViewDocument = filePath => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true
        }
      }
    });
  };

  useEffect(() => {
    getTransaction();
    getDocuments();
    getReports();
  }, []);

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  useEffect(() => {
    if (returnInvoiceSageID) handleViewInvoice(String(returnInvoiceSageID));
  }, [returnInvoiceSageID]);

  useEffect(() => {
    getControlList();
  }, [taskId]);

  if (isEmpty(task)) {
    return <InvalidTask />;
  }

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Amount",
      dataIndex: "fee",
      key: "fee"
    }
  ];

  const dataSource = [
    {
      key: "1",
      fee: renewalAmount ? `R ${renewalAmount}` : "n/a",
      description: "Renewal Fee calculation per business:"
    },
    {
      key: "2",
      fee: renewalAmount ? `R ${renewalAmount}` : "n/a",
      description: "Total"
    }
  ];

  const isAdmin = localStorage
    .getItem("userPermissions")
    .split(",")
    .includes("review:edit");

  if (loading) return <InformationLoader />;

  return (
    <PermissionsGuard
      perform={"review:edit"}
      yes={() => (
        <>
          <Card className="detail-card" key="detail-card">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/admin/tasks">Tasks: New Tasks</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Renewal</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <div className="flex-row">
              <h2>{companyName}</h2>
              <h3>
                Status:{" "}
                <span>
                  <Badge color={"orange"} status={taskStatus} />
                  {taskStatus}
                </span>
              </h3>
            </div>
            <div className="flex-row">
              <label>
                Task ID: <strong>{taskId}</strong>
              </label>
              <label>
                Type: <strong>Renewal</strong>
              </label>
              <label>
                Date Created:{" "}
                <strong>{format(parseISO(createdDate), "dd-MM-yyyy")}</strong>
              </label>
            </div>
          </Card>
          <Card
            key="review-card"
            className="card"
            title={
              <>
                <h3>Review Process</h3>
                <span>
                  Please review the task related to renewal using{" "}
                  <b>Direct Deposit </b> as a payment option.
                </span>
              </>
            }
          >
            <div className="review-content-section">
              <div className="review-step-content">
                {!transaction && !loading && (
                  <div
                    style={{
                      background: "red",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff",
                      textAlign: "center"
                    }}
                  >
                    The transaction linked to this task cannot be found.
                  </div>
                )}
                <br />
                <div className="flex-column information-block">
                  <span>
                    Status: <label>{transaction?.status.name || ""}</label>
                  </span>
                  <span>
                    Type:{" "}
                    <strong>
                      Renewals - {format(parseISO(createdDate), "MMM yyyy")}
                    </strong>
                  </span>
                  <span>
                    Payee Responsible: <label>{companyName}</label>
                  </span>
                  <span>
                    Payment Method: <label>Direct Deposit</label>
                  </span>
                  <span>
                    Direct Deposit Reference:{" "}
                    <label>
                      {false
                        ? transaction?.directDepositInvoiceNumber
                        : company.tradingName}
                    </label>
                  </span>
                  {task?.comment && (
                    <span>
                      Comment: <label>{task.comment}</label>
                    </span>
                  )}
                  <br />
                  {/* <span>Payee Breakdown: </span> */}
                  <span>Please find Pro forma Invoice below:</span>
                </div>
                <br />
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
                <br />
                <SubscriptionControlList
                  taskId={taskId}
                  loadingDoc={loadingDoc}
                  handleViewDocument={handleViewDocument}
                  companyId={company?.id}
                  createdDate={createdDate}
                  onRefreshComplete={() => {
                    getDocuments();
                    getReports();
                  }}
                />

                <br />
                <div className="flex-column information-block">
                  <span>Invoice:</span>
                  <div style={{ paddingLeft: "15px" }}>
                    <div>
                      {invoiceId || returnInvoiceSageID ? (
                        <span>
                          <img
                            style={{ height: "25px", width: "25px" }}
                            src={fileIcon}
                          />
                          &nbsp; &nbsp;
                          {"TAX INVOICE.pdf"}
                          <Button
                            type="link"
                            loading={loadingDoc}
                            onClick={() => handleViewInvoice()}
                          >
                            View
                          </Button>
                        </span>
                      ) : (
                        <span>
                          <Button
                            loading={loadingGenereateInvoice}
                            onClick={() => {
                              setLoadingGenereateInvoice(
                                !loadingGenereateInvoice
                              );
                              create_invoice();
                            }}
                          >
                            Generate Invoice
                          </Button>
                        </span>
                      )}
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex-column information-block">
                  <span>Proof of Payments:</span>
                  <div style={{ paddingLeft: "15px" }}>
                    {!loading && !popDocs.length && (
                      <p>Proof of payments not found</p>
                    )}
                    {!loading &&
                      popDocs.map(doc => {
                        return (
                          <div key={doc.id}>
                            <span>
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={fileIcon}
                              />
                              &nbsp; &nbsp;
                              {doc.name.toUpperCase()}
                              <Button
                                type="link"
                                loading={loadingDoc}
                                onClick={() =>
                                  handleViewDocument(doc.documentPath)
                                }
                              >
                                View
                              </Button>
                            </span>
                            <br />
                          </div>
                        );
                      })}
                  </div>
                  <br />
                  <div className={isAdmin && transaction ? "" : "no-interact"}>
                    <ResultComment
                      {...{
                        ...task,
                        invoiceExists,
                        step: "RegistrationFee",
                        isForRenewal: true
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
      no={() => <AccessDenied permission="review:edit" />}
    />
  );
}

export default RenewalReview;
