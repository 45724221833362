import React from "react";
import MemberList from "./TeamList";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";

const TeamMemberManagement: React.FC<any> = ({ ...props }) => {
  return (
    <PermissionsGuard
      perform={"admin_team:view"}
      yes={() => <MemberList />}
      no={() => <AccessDenied permission="admin_team:view" />}
    />
  );
};

export default TeamMemberManagement;
