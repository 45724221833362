import React, { useState, useMemo } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Form, Spin, Select, Input, InputNumber, Row, Col } from "antd";
import { notifyError, notifySuccess } from "../../../utils/notification";
import "../../Employees/index.css";
import { CREATE_FINANCIAL_TASK } from "../graphql/mutations";

type Props = {
  form: any;
  companyDetails: any;
  visible: boolean;
  onCancel: () => void;
  setModalVisible: (arg: boolean) => void;
};

function calculateTaxExcl(amountIncl: number) {
  return Number((amountIncl / 1.15).toFixed(2));
}

function CreateFinancialTaskModal(props: Props) {
  const { onCancel, setModalVisible, form, companyDetails, visible } = props;
  const { getFieldDecorator, validateFields } = form;
  const [loading, setLoading] = useState(false);
  const [createTask] = useMutation<{ id: number }>(CREATE_FINANCIAL_TASK);

  const name = useMemo(() => {
    if (companyDetails)
      return (
        companyDetails.tradingName || companyDetails.registeredName
      ).toUpperCase();
    else return "";
  }, [companyDetails]);

  const handleCreateTask = async () => {
    try {
      setLoading(true);
      const values = await new Promise<any>(resolve => {
        validateFields((err, val) => {
          if (err) {
            console.error("validateFieldsErr", err);
            return resolve(null);
          }

          return resolve(val);
        });
      });

      if (!values) return;

      const { errors } = await createTask({
        variables: {
          input: { ...values, amountExcl: calculateTaxExcl(values.amountIncl) }
        }
      });

      if (errors?.length) {
        return notifyError(errors[0].message);
      }

      notifySuccess(`Successfully created a financial ticket for ${name}`);

      setModalVisible(false);
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width="60%"
      visible={visible}
      title={"Create Financial Task"}
      okText={"Create Task"}
      onCancel={onCancel}
      onOk={handleCreateTask}
      okButtonProps={{
        className: "purple-button",
        disabled: loading
      }}
      cancelButtonProps={{
        disabled: loading
      }}
      okType="default"
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form layout="vertical">
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item label="Type of Financial Ticket">
                {getFieldDecorator("taskType", {
                  initialValue: "Financial Ticket",
                  rules: [
                    {
                      required: true,
                      message: "Please select the type of financial ticket"
                    }
                  ]
                })(
                  <Select className="input-select">
                    <Select.Option value="Financial Ticket">
                      Financial Ticket (Registration)
                    </Select.Option>
                    <Select.Option value="Renewal">Renewal</Select.Option>
                    <Select.Option value="Penalty">Penalty</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Company">
                {getFieldDecorator("companyId", {
                  initialValue: companyDetails.id,
                  rules: [
                    {
                      required: true,
                      message: "Please select the company"
                    }
                  ]
                })(
                  <Select className="input-select">
                    <Select.Option
                      key={`item-${companyDetails.id}`}
                      value={companyDetails.id}
                    >
                      {name}
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item label={`Invoice Reference`}>
                {getFieldDecorator("reference", {
                  initialValue: name,
                  rules: [
                    {
                      required: true,
                      message: "Please enter the payment reference"
                    }
                  ]
                })(
                  <Input
                    className="input-select"
                    placeholder="Please enter the payment reference"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Invoice Amount (Tax Incl)">
                {getFieldDecorator("amountIncl", {
                  initialValue: 0,
                  rules: [
                    {
                      required: true,
                      message: "Please enter the invoice amount"
                    }
                  ]
                })(
                  <InputNumber
                    step={1000}
                    min={0}
                    className="input-select"
                    placeholder="Please enter the invoice amount"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item label={`Invoice Description`}>
                {getFieldDecorator("description", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: "Please enter the payment reference"
                    }
                  ]
                })(
                  <Input
                    className="input-select"
                    placeholder="Please enter the payment description"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={`Add a comment`}>
                {getFieldDecorator("comment", {
                  initialValue: "",
                  rules: []
                })(
                  <Input.TextArea
                    style={{ resize: "none" }}
                    rows={5}
                    placeholder="Start typing..."
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default Form.create({
  name: "create_financial_task_modal_form"
})(CreateFinancialTaskModal);
