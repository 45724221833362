import React, { useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  List,
  Icon,
  Avatar
} from "antd";
import { validateMobilePhoneNumber } from "../../utils/validatePhoneNumber";

const CreateComplaint = ({ handleSubmit, form, loading }) => {
  const [passed, setPassed] = useState<string>("RSA ID");
  const [complaintAgainst, setComplaintAgainst] = useState("Debt Collector");

  const { getFieldDecorator } = form;

  const complaintTypes = useMemo<{ id: number; name: string }[]>(
    () => [
      {
        id: 2,
        name: "Credit Provider"
      },
      {
        id: 3,
        name: "Debt Counsellor"
      },
      {
        id: 4,
        name: "Debt Collector"
      }
    ],
    []
  );

  const toggleResult = e => {
    setPassed(e.target.value);
  };

  const BackToHome = (
    <Button
      type="default"
      onClick={() => window.location.replace("/")}
      className="purple-button"
    >
      Back to website
    </Button>
  );

  return (
    <>
      <div className="ant-row">
        <h3>Select who the complaint is against:</h3>
        <Select
          placeholder="Select who complaint is against"
          onSelect={value =>
            localStorage.setItem("complaintAgainst", `${value}`)
          }
          onChange={setComplaintAgainst}
          defaultValue="Debt Collector"
          style={{ width: "200px" }}
        >
          {complaintTypes.map(type => {
            return (
              <Select.Option key={"user-type-" + type.id} value={type.name}>
                {type.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <br />
      <br />
      {complaintAgainst === "Debt Collector" && (
        <>
          <h3>Please fill out your details below:</h3>
          <Form onSubmit={handleSubmit}>
            <Spin
              style={{ display: loading ? "block" : "none" }}
              className="loader"
            />
            <div className="form-row">
              <Form.Item label="First Name" className="new-form-item">
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your first name!"
                    }
                  ]
                })(<Input placeholder="Please input first name!" />)}
              </Form.Item>

              <Form.Item label="Last Name" className="new-form-item">
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your last name"
                    }
                  ]
                })(<Input placeholder="Please input last name" />)}
              </Form.Item>
            </div>

            <div className="form-row">
              <Form.Item label="Email" className="new-form-item">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address"
                    }
                  ]
                })(<Input placeholder="Please input email!" />)}
              </Form.Item>

              <Form.Item label="Contact Number" className="new-form-item">
                {getFieldDecorator("contactNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter contact number"
                    },
                    {
                      min: 9,
                      message: "The number must be minimum 9 digits long."
                    },
                    {
                      max: 10,
                      message:
                        "The number must not be more than 10 digits long."
                    },
                    {
                      validator: validateMobilePhoneNumber
                    }
                  ]
                })(
                  <Input
                    addonBefore={
                      <div>
                        <span style={{ color: "black" }}>🇿🇦</span> +27
                      </div>
                    }
                    type="number"
                    placeholder="Please input number"
                    maxLength={10}
                    minLength={9}
                  />
                )}
              </Form.Item>
            </div>

            <div className="form-row">
              <Form.Item label="Identification Type" className="new-form-item">
                {getFieldDecorator("identificationType", {
                  rules: [
                    {
                      required: true,
                      message: "Please choose your Identification Type"
                    }
                  ]
                })(
                  <Radio.Group
                    onChange={toggleResult}
                    value={passed}
                    buttonStyle="solid"
                  >
                    <Radio.Button className="btn-add-comment" value={"RSA ID"}>
                      RSA ID
                    </Radio.Button>
                    <Radio.Button value={"Passport"}>Passport</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>

              <Form.Item label="ID Number" className="new-form-item">
                {getFieldDecorator("identificationNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter id number"
                    }
                  ]
                })(<Input placeholder="Please input ID!" />)}
              </Form.Item>
            </div>
          </Form>
          <br />
          <Button
            type="default"
            onClick={handleSubmit}
            className="btn-add-comment"
          >
            Next Step
          </Button>
        </>
      )}

      {complaintAgainst === "Credit Provider" && (
        <>
          <h3>Credit Providers:</h3>
          <p>
            The council for debt collectors only deals with complaints
            pertaining to debt collectors. For complaints regarding CREDIT
            PROVIDERS please refer such complaints to the NCR using the link
            provided:
          </p>
          <br />

          <List
            split={false}
            itemLayout="horizontal"
            dataSource={[
              {
                icon: <Icon type="link" />,
                description: <a href="https://ncr.org.za">https://ncr.org.za</a>
              },
              {
                icon: <Icon type="home" />,
                description: "complaints@ncr.org.za"
              },
              {
                icon: <Icon type="home" />,
                description: "Fax: (087) 234 7822"
              }
            ]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={item.icon} />}
                  description={item.description}
                />
              </List.Item>
            )}
          />
          <br />
          {BackToHome}
        </>
      )}

      {complaintAgainst === "Debt Counsellor" && (
        <>
          <h3>Debt Counsellor:</h3>
          <p>
            The council for debt collectors only deals with complaints
            pertaining to debt collectors. For complaints regarding DEBT
            COUNSELLORS please refer such complaints to the NCR using the link
            provided:
          </p>
          <br />
          <List
            split={false}
            itemLayout="horizontal"
            dataSource={[
              {
                icon: <Icon type="link" />,
                description: <a href="https://ncr.org.za">https://ncr.org.za</a>
              },
              {
                icon: <Icon type="home" />,
                description: "dccomplaints@ncr.org.za"
              },
              {
                icon: <Icon type="home" />,
                description: "Fax: (087) 234 7789"
              }
            ]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={item.icon} />}
                  description={item.description}
                />
              </List.Item>
            )}
          />
          <br />
          {BackToHome}
        </>
      )}
    </>
  );
};

export default CreateComplaint;
