import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Radio,
  DatePicker,
  TimePicker,
  Form
} from "antd";
import { useMutation } from "react-apollo";
import {
  CREATE_PROSECUTION_APPEARANCE,
  UPDATE_PROSECUTION_APPEARANCE
} from "graphql/mutations";
import { isEmpty } from "lodash";
import moment from "moment";
import { openNotificationWithIcon } from "utils/notification";
import SubmitToClient from "./submitToClient";

const ProsecutionAppearance: React.FC<any> = ({
  show = false,
  appearanceId,
  prosecutionId,
  taskId,
  updateVisible,
  companyContacts,
  complaint,
  form
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(show);
  const [mode, setMode] = useState("");
  const [submitToClient, setSubmitToClient] = useState(false);
  const { getFieldDecorator } = form;

  const [createProsecutionAppearance] = useMutation(
    CREATE_PROSECUTION_APPEARANCE,
    {
      onCompleted: data =>
        openNotificationWithIcon(
          "success",
          "Success",
          "Appearance information updated successfully"
        ),
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Error",
          "The was an error creatin prosecution record"
        );
      }
    }
  );
  const [updateProsecutionAppearance] = useMutation(
    UPDATE_PROSECUTION_APPEARANCE,
    {
      onCompleted: data =>
        openNotificationWithIcon(
          "success",
          "Success",
          "Appearance information updated successfully"
        ),
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Error",
          "The was an error updating prosecution"
        );
      }
    }
  );

  const handleOk = async () => {
    form.validateFields(
      ["No Show"].includes(mode)
        ? ["debtCollectorAppeared", "date", "time", "place"]
        : [
            "debtCollectorAppeared",
            "sufficientEvidence",
            "postponementReason",
            "date",
            "time",
            "place"
          ],
      async (err, values) => {
        if (err) return;

        setLoading(true);

        if (["No Show", "Postponed"].includes(mode)) {
          // update old appearance
          await updateProsecutionAppearance({
            variables: {
              input: {
                id: appearanceId,
                taskId: taskId,
                appearanceStatus: ["No Show"].includes(mode)
                  ? "No Show"
                  : "Postponed",
                appearancePostponed: ["No Show"].includes(mode)
                  ? undefined
                  : values.sufficientEvidence === "Yes"
                  ? true
                  : false,
                postponementReason: ["No Show"].includes(mode)
                  ? undefined
                  : values.postponementReason
              }
            }
          });

          //create new appearance
          await createProsecutionAppearance({
            variables: {
              input: {
                dateOfAppearance: values.date,
                timeOfAppearance: values.time,
                placeOfAppearance: values.place,
                prosecutionId
              }
            }
          });
          setLoading(false);
          setSubmitToClient(true);
        } else {
          // update old appearance
          await updateProsecutionAppearance({
            variables: {
              input: {
                id: appearanceId,
                taskId: taskId,
                appearanceStatus: "Complete"
              }
            }
          });

          // TO DO: update complaint status

          setLoading(false);
          updateVisible(false, true);
        }
      }
    );
  };

  const handleCancel = () => {
    setVisible(false);
    updateVisible(false);
  };

  const validatePostponementReason = (rules, value, callback) => {
    if (form.getFieldValue("sufficientEvidence") === "No" && isEmpty(value)) {
      callback("Please select reason for postponement");
    } else {
      callback();
    }
  };

  const SubmitToClientProps = {
    companyContacts: companyContacts,
    taskId,
    complaint: complaint,
    setNoticeSent: (val: boolean) => updateVisible(false, true),
    updateActivity: () => {}
  };

  const renderUpdateAppearance = () => {
    return (
      <>
        {!submitToClient && (
          <>
            <Form>
              <Form.Item
                label={"Did the Debt Collector/representative appear?"}
              >
                {getFieldDecorator("debtCollectorAppeared", {
                  rules: [
                    {
                      required: true,
                      message: `Please indicate if the Debt Collector or representative appeared`
                    }
                  ]
                })(
                  <Radio.Group
                    onChange={e => {
                      if (e.target.value === "No") setMode("No Show");
                      else setMode("");
                    }}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {!["No Show"].includes(mode) && (
                <>
                  <Form.Item label="Is evidence deemed sufficient?">
                    {getFieldDecorator("sufficientEvidence", {
                      rules: [
                        {
                          required: true,
                          message:
                            "Please indicate if evidence is sufficient to proceed"
                        }
                      ]
                    })(
                      <Radio.Group
                        onChange={e => {
                          if (e.target.value === "No") setMode("Postponed");
                          else setMode("");
                        }}
                      >
                        <Radio value="No">No, postpone</Radio>
                        <Radio value="Yes">Yes, proceed</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  {mode === "Postponed" && (
                    <Form.Item label={"Reason for postponement"}>
                      {getFieldDecorator("postponementReason", {
                        rules: [
                          {
                            validator: validatePostponementReason,
                            message: "Please select reason for postponement"
                          }
                        ]
                      })(
                        <Radio.Group>
                          <Radio value="Insufficient/lack of evidence">
                            Insufficient/lack of evidence
                          </Radio>
                          <Radio value="Absence of witness">
                            Absence of witness
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  )}
                </>
              )}
            </Form>
            {["No Show", "Postponed"].includes(mode) &&
              renderCreateAppearance()}
          </>
        )}

        {submitToClient && (
          <SubmitToClient {...SubmitToClientProps} mode={"notice-to-appear"} />
        )}
      </>
    );
  };

  const renderCreateAppearance = () => {
    return (
      <>
        <Form>
          <Form.Item label={"Date of appearance"}>
            {getFieldDecorator("date", {
              initialValue: moment(),
              rules: [
                {
                  required: true,
                  message: `Please select date of appearance`
                }
              ]
            })(
              <DatePicker
                style={{ width: "100%" }}
                className="input-height input-select"
              />
            )}
          </Form.Item>
          <Form.Item label="Time of hearing">
            {getFieldDecorator("time", {
              initialValue: moment("00:00:00", "HH:mm"),
              rules: [
                {
                  required: true,
                  message: "Please enter time of appearance"
                }
              ]
            })(
              <TimePicker
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="Enter time of appearance"
              />
            )}
          </Form.Item>
          <Form.Item label={"Place of appearance"}>
            {getFieldDecorator("place", {
              rules: [
                {
                  required: true,
                  message: `Please enter address for appearance`
                }
              ]
            })(<Input placeholder="Please enter address" />)}
          </Form.Item>
        </Form>
      </>
    );
  };

  return (
    <>
      <Modal
        visible={visible}
        title="Appearance Details"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          !submitToClient && [
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="purple-button"
              loading={loading}
              onClick={handleOk}
            >
              Next Step
            </Button>
          ]
        }
      >
        {renderUpdateAppearance()}
      </Modal>
    </>
  );
};

export default ProsecutionAppearance;
