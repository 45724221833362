import { Result, Button, Spin } from "antd";
import BatchRegistrationEmployees from "components/BatchRegistrationEmployees";
import { CREATE_EMPLOYEE_REGISTRATION_TASK } from "graphql/mutations";
import React, { useEffect, useState, useMemo } from "react";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router";
import { useRegistrationState } from "stores/registration";

function CompleteRegistration() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [employeeTask, setEmployeeTask] = useState<any>(null);

  const {
    batchRegistrationId,
    manualRegistrationApplicationId: applicationId,
    completeBatchApplicationId,
    previousCompanyId,
    manualRegistrationType,
    clearRegistrationState: cleanup,
    setRegistrationState
  } = useRegistrationState();

  const input = { applicationId, batchRegistrationId };

  const isTransfer = useMemo(() => {
    return manualRegistrationType?.toLowerCase() === "transfer";
  }, [manualRegistrationType]);

  if (isTransfer) {
    input["previousCompanyId"] = previousCompanyId;
  }

  const employeeName = useMemo(() => {
    return `${employeeTask?.employee?.firstName?.toUpperCase() ||
      ""} ${employeeTask?.employee?.lastName?.toUpperCase() ||
      ""} (${employeeTask?.employee?.idNumber || ""} )`;
  }, [employeeTask?.employee]);

  const [createRegistrationTask] = useMutation(
    CREATE_EMPLOYEE_REGISTRATION_TASK,
    {
      variables: { input },
      onCompleted: (data: any) => {
        setEmployeeTask(data.createBatchEmployeeTask);
        setIsLoading(false);
        setRegistrationState("completeBatchApplicationId", applicationId);
      },
      onError: (err: any) => {
        setError(err);
        setIsLoading(false);
        console.error("complete_reg_error", err.message);
      }
    }
  );

  useEffect(() => {
    if (completeBatchApplicationId === applicationId) {
      setIsLoading(false);
      return;
    }
    createRegistrationTask();
  }, [
    applicationId,
    batchRegistrationId,
    previousCompanyId,
    completeBatchApplicationId
  ]);

  function addEmployee() {
    cleanup();
    history.replace(
      `/admin/team/manual-register?batch_id=${batchRegistrationId}`
    );
  }

  function completeRegistration() {
    history.push(
      `/register/batch-registration/${batchRegistrationId}/complete`
    );
  }

  if (isLoading) {
    return (
      <Result
        icon={<Spin spinning size="large" />}
        status="info"
        title="Creating a registration task"
        subTitle={
          "Please wait while we create a registration task. This may take a few minutes."
        }
      />
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to create a registration task"
        subTitle={error.message}
      />
    );
  }

  return (
    <>
      <Result
        status="success"
        title="Employee Added"
        subTitle={`You have ${
          employeeTask ? "successfully" : "already"
        } added ${
          employeeTask ? employeeName : "the employee"
        } to the batch registration #${batchRegistrationId}. You can continue adding more employees or complete the batch registration.`}
        extra={[
          <Button type="primary" key="add" onClick={addEmployee}>
            Add Another Employee
          </Button>,
          <Button key="complete" onClick={completeRegistration}>
            Complete Batch Registration
          </Button>
        ]}
      />
      <BatchRegistrationEmployees
        batchRegistrationId={batchRegistrationId}
        loading={isLoading}
      />
    </>
  );
}

export default CompleteRegistration;
