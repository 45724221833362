import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Radio,
  Select,
  DatePicker,
  Divider,
  Skeleton
} from "antd";
import moment from "moment";
import { useQuery, useMutation } from "react-apollo";
import { GET_OFFENCES_LIST, GET_PREQUALIFICATION } from "../../graphql/queries";
import {
  CREATE_PREQUALIFICATION,
  UPDATE_PREQUALIFICATION
} from "../../graphql/mutations";
import { COMPANY_TYPES } from "../../constants";
import "../Register/index.css";
import InternalErrorPage from "containers/InternalErrorPage";
import { notifyError, openNotificationWithIcon } from "utils/notification";
import { isBoolean, isEmpty } from "lodash";
import { useRegistrationState } from "stores/registration";
import { FormComponentProps } from "antd/lib/form/Form";

function PrequalificationForm(props: any) {
  const { getFieldDecorator } = props.form;

  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs: registeredAs,
    companyId,
    ...rest
  } = useRegistrationState();

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [hasOffence, setHasOffence] = useState<boolean>();
  const [convicted, setConvicted] = useState<boolean>();
  const [curatedForLegalCapacity, setCuratedForLegalCapacity] = useState<
    boolean
  >();
  const [improperConduct, setImproperConduct] = useState<boolean>();
  const [appliedForExemption, setAppliedForExemption] = useState<boolean>();

  const [prequalificationId, setPrequalificationId] = useState<number>();
  const [prequalification, setPrequalification] = useState<any>();

  const [currentStep, setCurrentStep] = useState("step3Saved");
  const [createPrequalification] = useMutation(CREATE_PREQUALIFICATION);
  const [updatePrequalification] = useMutation(UPDATE_PREQUALIFICATION);

  const isSP = registeredAs === "A Sole Proprietor";
  const isCompany = COMPANY_TYPES.includes(registeredAs);
  const step =
    registeredAs === "A Sole Proprietor" ? "step4Saved" : "step3Saved";

  useEffect(() => {
    setCurrentStep(step);

    if (!!rest[step]) {
      refecthPrequalification().then(resp => {
        const _prequalification = resp.data.prequalification;

        setPrequalificationId(_prequalification?.id);
        setPrequalification(_prequalification);
      });
    }
  }, []);

  const stepDetails = {
    title: `Step ${isSP ? 4 : 3}: Prequalification`,
    content: "Complete the short form that enables you for prequalification"
  };

  const {
    loading: loadingPrequalification,
    error: errorPrequalification,
    data: dataPrequalification,
    refetch: refecthPrequalification
  } = useQuery(GET_PREQUALIFICATION, {
    variables: {
      companyId
    },
    onCompleted: () => {
      if (!isEmpty(dataPrequalification.prequalification)) {
        const _prequalification = dataPrequalification.prequalification;

        setPrequalificationId(_prequalification?.id);
        setPrequalification(_prequalification);
        setHasOffence(_prequalification?.onTrial);

        setRegistrationState({
          appliedForExemption: _prequalification.appliedForExemption,
          [currentStep]: true
        });

        // currated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          window.location.replace("/register/fail/prequalification");
        }
      }
    }
  });

  const {
    loading: loadingOffences,
    error: errorOffences,
    data: dataOffences
  } = useQuery(GET_OFFENCES_LIST);

  if (errorOffences || errorPrequalification) {
    return <InternalErrorPage />;
  }

  const { offenceList } = !loadingOffences && dataOffences;

  //selectable options of offences
  const offenceOptions: any = offenceList
    ? offenceList.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err && isBoolean(values.hasOffence) && values.hasOffence) {
        return;
      }

      // when company is on trial for offence - Yes to Q1
      if (!values.hasOffence) {
        const input = {
          id: prequalificationId ? prequalificationId : undefined,
          onTrial: values.hasOffence,
          convicted: false,
          appliedForExemption: false,
          curatedForLegalCapacity: false,
          dateOfTrial: null,
          typeOfOffence: null,
          guiltyOfImproperConduct: false,
          isProofOfExemptionApplicationUploaded: false,
          companyId
        };
        return savePrequalification(input);
      }

      const input = {
        id: prequalificationId ? prequalificationId : undefined,
        onTrial: isCompany ? false : values.hasOffence,
        convicted: isCompany ? false : values.convicted,
        appliedForExemption: isCompany ? false : values.appliedForExemption,
        curatedForLegalCapacity: values.curatedForLegalCapacity,
        dateOfTrial: values.dateOfTrialOffence,
        typeOfOffence: isCompany ? null : values.typeOfOffence,
        guiltyOfImproperConduct: values.improperConduct,
        companyId
      };

      savePrequalification(input);
    });
  };

  const savePrequalification = (values: any) => {
    setIsSubmiting(true);

    const upsertPrequalification = prequalificationId
      ? updatePrequalification
      : createPrequalification;

    upsertPrequalification({
      variables: {
        input: { ...values }
      }
    })
      .then(async res => {
        const _prequalification = prequalificationId
          ? res.data.updatePrequalification
          : res.data.createPrequalification;

        setPrequalificationId(_prequalification.id);
        setPrequalification(_prequalification);

        setIsSubmiting(false);

        setRegistrationState({
          appliedForExemption: values.appliedForExemption,
          [currentStep]: true
        });

        openNotificationWithIcon(
          "success",
          "Save Success",
          "Prequalification data successfuly saved"
        );

        // currated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          window.location.replace("/register/fail/prequalification");
        }
      })
      .catch(err => {
        setIsSubmiting(false);
        notifyError(err);
      });
  };

  if (loadingOffences || loadingPrequalification) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{stepDetails.title}</span>
        <p>{stepDetails.content}</p>
        <Divider />
      </div>

      <Form>
        <div className="flex-column input-select input-spacer">
          {!isCompany && (
            <>
              <label>
                1. Are you/any party related to the Business, on trial for an
                offence of which violence, dishonesty, extortion or intimidation
                is an element or have in the preceding 10 years been convicted
                of such offence?{" "}
              </label>
              <div className="input-spacer">
                <Form.Item>
                  {getFieldDecorator("hasOffence", {
                    initialValue: !isEmpty(prequalification)
                      ? prequalification.onTrial
                      : hasOffence,
                    rules: [
                      {
                        required: true,
                        message: "Please indicate"
                      }
                    ]
                  })(
                    <Radio.Group>
                      <Radio.Button
                        value={true}
                        className={
                          hasOffence
                            ? "btn-registration-capacity-selected btn-registration-capacity"
                            : "btn-registration-capacity"
                        }
                        onClick={() => setHasOffence(true)}
                      >
                        Yes
                      </Radio.Button>
                      <Radio.Button
                        value={false}
                        className={
                          !hasOffence
                            ? "btn-registration-capacity-selected btn-registration-capacity"
                            : "btn-registration-capacity"
                        }
                        onClick={() => setHasOffence(false)}
                      >
                        No
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </div>
            </>
          )}

          <div className="flex-column input-block-wrapper">
            {!isCompany && (
              <div
                className="flex-column input-block-wrapper input-select"
                style={{
                  display:
                    prequalification?.onTrial || hasOffence ? "flex" : "none"
                }}
              >
                <div className="flex-column input-block">
                  <label>Date of trial/offence</label>
                  <Form.Item style={{ paddingTop: "5px" }}>
                    {getFieldDecorator("dateOfTrialOffence", {
                      initialValue: !isEmpty(prequalification)
                        ? moment(prequalification.dateOfTrial)
                        : moment(),
                      rules: [
                        {
                          required: true,
                          message: "Please select date of trial or offence"
                        }
                      ]
                    })(<DatePicker className="input-height input-select" />)}
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>Type of offence</label>
                  <Form.Item>
                    {getFieldDecorator("typeOfOffence", {
                      initialValue: !isEmpty(prequalification?.typeOfOffence)
                        ? prequalification.typeOfOffence?.id
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please select date type of offecne"
                        }
                      ]
                    })(
                      <Select className="input-select input-height">
                        {offenceOptions}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className="flex-column input-block">
                  <label>Convicted?</label>
                  <Form.Item>
                    {getFieldDecorator("convicted", {
                      initialValue: !isEmpty(prequalification)
                        ? prequalification.convicted
                        : convicted,
                      rules: [
                        {
                          required: true,
                          message: "Please indicate"
                        }
                      ]
                    })(
                      <Radio.Group
                        onChange={e => {
                          setConvicted(e.target.value);
                        }}
                        value={convicted}
                        className="input-select input-spacer"
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              </div>
            )}
            <div className="flex-column">
              {(isBoolean(convicted) && convicted) ||
                (prequalification?.convicted && (
                  <div className="flex-column input-select">
                    <label>
                      1.1 Has an application for exemption in terms of section
                      10(2) been lodged with the Ministry of Justice and
                      Constitutional Development?{" "}
                    </label>
                    <div className="input-spacer">
                      <Form.Item>
                        {getFieldDecorator("appliedForExemption", {
                          initialValue: !isEmpty(prequalification)
                            ? prequalification.appliedForExemption
                            : appliedForExemption,
                          rules: [
                            {
                              required: true,
                              message: "Please indicate"
                            }
                          ]
                        })(
                          <Radio.Group className="input-select input-spacer">
                            <Radio.Button
                              value={true}
                              className={
                                appliedForExemption
                                  ? "btn-registration-capacity-selected btn-registration-capacity"
                                  : "btn-registration-capacity"
                              }
                              onClick={() => setAppliedForExemption(true)}
                            >
                              Yes
                            </Radio.Button>
                            <Radio.Button
                              value={false}
                              className={
                                !appliedForExemption
                                  ? "btn-registration-capacity-selected btn-registration-capacity"
                                  : "btn-registration-capacity"
                              }
                              onClick={() => setAppliedForExemption(false)}
                            >
                              No
                            </Radio.Button>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                ))}
              <div className="flex-column">
                <label>
                  {isCompany ? 1 : 2}. Has the Business ever, in terms of
                  section 15 of Act, been found guilty of improper conduct?{" "}
                </label>
                <div className="input-spacer">
                  <Form.Item>
                    {getFieldDecorator("improperConduct", {
                      initialValue: !isEmpty(prequalification)
                        ? prequalification.guiltyOfImproperConduct
                        : improperConduct,
                      rules: [
                        {
                          required: true,
                          message: "Please indicate"
                        }
                      ]
                    })(
                      <Radio.Group className="input-select input-spacer">
                        <Radio.Button
                          value={true}
                          className={
                            improperConduct
                              ? "btn-registration-capacity-selected btn-registration-capacity"
                              : "btn-registration-capacity"
                          }
                          onClick={() => setImproperConduct(true)}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value={false}
                          className={
                            !improperConduct
                              ? "btn-registration-capacity-selected btn-registration-capacity"
                              : "btn-registration-capacity"
                          }
                          onClick={() => setImproperConduct(false)}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="flex-column input-spacer">
                <label>
                  {isCompany ? 2 : 3}. Have you or any person in this
                  registration been placed under curatorship for legal capacity?{" "}
                </label>
                <div className="input-spacer">
                  <Form.Item>
                    {getFieldDecorator("curatedForLegalCapacity", {
                      initialValue: !isEmpty(prequalification)
                        ? prequalification.curatedForLegalCapacity
                        : curatedForLegalCapacity,
                      rules: [
                        {
                          required: true,
                          message: "Please indicate"
                        }
                      ]
                    })(
                      <Radio.Group className="input-select input-spacer">
                        <Radio.Button
                          value={true}
                          className={
                            curatedForLegalCapacity
                              ? "btn-registration-capacity-selected btn-registration-capacity"
                              : "btn-registration-capacity"
                          }
                          onClick={() => setCuratedForLegalCapacity(true)}
                        >
                          Yes
                        </Radio.Button>
                        <Radio.Button
                          value={false}
                          className={
                            !curatedForLegalCapacity
                              ? "btn-registration-capacity-selected btn-registration-capacity"
                              : "btn-registration-capacity"
                          }
                          onClick={() => setCuratedForLegalCapacity(false)}
                        >
                          No
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column input-block input-select input-spacer">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

interface IProps extends FormComponentProps {
  nextStep?: () => void;
}

const Prequalification = Form.create<IProps>({ name: "normal_register" })(
  PrequalificationForm
);

export default Prequalification;
