import React, { useState, useEffect } from "react";
import { Form, Input, Icon, Button, Tooltip } from "antd";
import "./index.css";
import { openNotificationWithIcon } from "utils/notification";
import { Auth } from "aws-amplify";
import * as yup from "yup";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../../constants";

const ProfileSecurityForm: React.FC<any> = ({ ...props }) => {
  Auth.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
    }
  });

  const { getFieldDecorator } = props.form;
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    setEmail(localStorage.getItem("username"));
  }, []);

  const sendConfirmationCode = async () => {
    await Auth.forgotPassword(email);
    setCodeSent(true);
  };
  const handleChangePassword = () => {
    props.form.validateFields(
      ["newPassword", "confirmPassword", "confirmationCode"],
      async (err, values) => {
        if (err) return;

        const schema = yup.object().shape({
          code: yup.string(),
          password: yup
            .string()
            .min(8, "Password has to be longer than 8 characters")
            .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
            .required("A password is required"),
          confirmPassword: yup
            .string()
            .min(8, "Password has to be longer than 8 characters")
            .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
            .required("A password is required")
        });

        const isValid = await schema.isValid({
          code: values.confirmationCode,
          password: values.newPassword,
          confirmPassword: values.confirmPassword
        });

        if (!isValid) {
          return openNotificationWithIcon(
            "error",
            "Validation Error",
            "Please make sure that the passwords match and the code is correct."
          );
        }

        try {
          setSubmitting(true);
          await Auth.forgotPasswordSubmit(
            email,
            values.confirmationCode,
            values.newPassword
          );

          props.form.resetFields();
          setCodeSent(false);
          setSubmitting(false);

          openNotificationWithIcon(
            "success",
            "Success",
            "Your password has been reset successfully"
          );
        } catch (e) {
          openNotificationWithIcon("error", "Validation Error", e.message);
        }
      }
    );
  };
  return (
    <>
      <div className="tab-content">
        <div>
          <h3>Security Settings</h3>
          <br />
          <br />
          <h4>Account Password</h4>
          <span>Current password strength</span>
        </div>
        <Button type="link" onClick={sendConfirmationCode} className="btn-link">
          Modify
        </Button>
      </div>
      <hr />
      <br />
      <br />

      {codeSent && (
        <div>
          <Form layout="vertical">
            <Form.Item label="New Password">
              {getFieldDecorator("newPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please enter new password"
                  }
                ]
              })(<Input.Password placeholder="Enter new password" />)}
            </Form.Item>
            <Form.Item label="Confirm Password">
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Confirm password"
                  }
                ]
              })(<Input.Password placeholder="Confirm password" />)}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Confirm Code:&nbsp;
                  <Tooltip title="The code has been sent to your email">
                    <Icon type="info-circle" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("confirmationCode", {
                rules: [
                  {
                    required: true,
                    message: "Enter confirmation code"
                  }
                ]
              })(<Input placeholder="Enter confirmation code" />)}
            </Form.Item>
          </Form>
          <br />
          <Button
            className="btn-upload"
            loading={submitting}
            onClick={handleChangePassword}
            disabled={!codeSent}
          >
            Change Password
          </Button>
        </div>
      )}
    </>
  );
};
const ProfileSecurity = Form.create({ name: "normal_register" })(
  ProfileSecurityForm
);
export default ProfileSecurity;
