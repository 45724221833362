import React, { useState, useEffect } from "react";
import { Button, Divider } from "antd";
import "./index.css";
import { isBoolean } from "lodash";
import RegistrationBusiness from "../RegisterBusiness/registration";
import RegistrationEmployee from "../RegisterEmployee/registration";
import { useRegistrationState } from "stores/registration";

export default function Registration(props: any) {
  const [isNewRegistration, setIsNewRegistration] = useState<boolean>();
  const [isBusiness, setIsBusiness] = useState<boolean>();

  const {
    manualRegisterId,
    isTransferable,
    registrationType,
    isExistingUser,
    manualRegistrationType,
    registrationCapacity,
    setRegistrationState
  } = useRegistrationState();

  const isManualReg = !!manualRegisterId;

  useEffect(() => {
    setIsNewRegistration(true);
    setIsBusiness(false);

    if (!registrationType) {
      setRegistrationState("registrationType", "New");
    }

    if (!registrationCapacity) {
      setRegistrationState("registrationCapacity", "Individual");
    }

    if (registrationCapacity) {
      setIsBusiness(registrationCapacity === "Business");
    }

    if (isManualReg && isExistingUser) {
      setRegistrationState("isMultipleEmployment", true);

      if (!manualRegistrationType) {
        setRegistrationState("manualRegistrationType", "multiple-employments");
      }
    } else if (isManualReg && !isExistingUser) {
      if (!manualRegistrationType) {
        setRegistrationState("manualRegistrationType", "new");
      }
    }

    if (!!registrationType) {
      setIsNewRegistration(registrationType === "New");
    }
  }, []);

  const step = {
    title: "Step 1: Registration",
    content: "Select the type of registration and thereafter type of applicant"
  };

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>
      <span>Please note:</span>
      <br />
      <span>
        1. In the case of a person who has in the preceding 10 years been
        convicted of an offence of which violence, dishonesty, extortion or
        intimidation is an element, this application will only be considered
        upon receipt of proof of exemption by the Minister of Justice and
        Constitutional Development in terms of section 10(2) of Debt Collectors
        Act, 1982 (Act No. 114 of 1998){" "}
      </span>
      <div className="registration-capacity">
        <h3>Registration Type</h3>
        {isExistingUser && isNewRegistration && (
          <h4 style={{ color: "red" }}>
            Note: The user is already employed at another company. Continuing
            with this option will register another employment for the user.
          </h4>
        )}
        <div>
          <Button
            type="default"
            className={
              isNewRegistration
                ? "btn-registration-type btn-registration-capacity-selected"
                : "btn-registration-type"
            }
            onClick={() => {
              setIsNewRegistration(true);
              if (isManualReg && isExistingUser) {
                setRegistrationState("isMultipleEmployment", true);
                setRegistrationState(
                  "manualRegistrationType",
                  "multiple-employments"
                );
              } else if (isManualReg && !isExistingUser) {
                setRegistrationState("manualRegistrationType", "new");
              }
              setRegistrationState("registrationType", "New");
            }}
          >
            New Registration
          </Button>
          {!!manualRegistrationType && (
            <Button
              disabled={!(isExistingUser && isTransferable)}
              type="default"
              className={
                isBoolean(isNewRegistration) && !isNewRegistration
                  ? "btn-registration-type btn-registration-capacity-selected"
                  : "btn-registration-type"
              }
              onClick={() => {
                setIsNewRegistration(false);
                setRegistrationState("registrationType", "Transfer");

                if (!!manualRegisterId) {
                  setRegistrationState("manualRegistrationType", "transfer");
                  setRegistrationState("isMultipleEmployment", "false");
                }
              }}
              style={{ height: "40px" }}
            >
              Transfer from Existing Member
            </Button>
          )}
        </div>
      </div>

      <div className="registration-capacity">
        <h3>Registration Capacity</h3>
        <div>
          <Button
            type="default"
            className={
              isBoolean(isBusiness) && !isBusiness
                ? "btn-registration-capacity-selected btn-registration-capacity"
                : "btn-registration-capacity"
            }
            onClick={() => {
              setRegistrationState("registrationCapacity", "Individual");
              setIsBusiness(false);
            }}
          >
            Individual
          </Button>
          {!!!manualRegistrationType && (
            <Button
              type="default"
              className={
                isBusiness
                  ? "btn-registration-capacity-selected btn-registration-capacity"
                  : "btn-registration-capacity"
              }
              onClick={() => {
                setRegistrationState("registrationCapacity", "Business");
                setIsBusiness(true);
              }}
            >
              Business
            </Button>
          )}
        </div>
      </div>
      {isBusiness ? <RegistrationBusiness /> : <RegistrationEmployee />}
    </div>
  );
}
