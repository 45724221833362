import React from "react";
import { Auth } from "aws-amplify";
import { Modal, Form, Input, Checkbox } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  }
});

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  okText = "Save",
  modalHeading = "Email Address Confirmation",
  cancelText = "Cancel",
  hideOkButton = false
}) => {
  const { getFieldDecorator } = form;

  return (
    <Modal
      visible={visible}
      title={modalHeading}
      okText={okText}
      onCancel={onCancel}
      onOk={onCreate}
      cancelText={cancelText}
      okButtonProps={hideOkButton ? { style: { display: "none" } } : {}}
    >
      <Form layout="vertical">
        <>
          <span>
            Please enter the confirmation code that has been sent to your email.
          </span>
          <br />
          <br />
          <Form.Item label="Confirmation code">
            {getFieldDecorator("code", {
              rules: [
                {
                  required: true,
                  message: "Please input the confirmation code!"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("confirm", {
              valuePropName: "checked",
              rules: [
                {
                  required: true,
                  message: "Please confirm that you understand this."
                }
              ]
            })(
              <Checkbox>
                I understand that updating this email will change the email
                address I use to log in
              </Checkbox>
            )}
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
};

interface IProps extends FormComponentProps {}

const ConfirmAccountForm = Form.create<IProps>({
  name: "modal_form"
})(ModalFormComponent);

export default ConfirmAccountForm;
