import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import AWaitingConfirmation from "../Profile/awaitingCofirmation";
import CompanyProfile from "../Admin/Companies/CompanyOverview/companyProfile";
import { APPLICATION_VERIFIED } from "../../constants";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../Admin/graphql/queries";
import "../Profile/index.css";

const Profile = ({}) => {
  const memberId = Number(localStorage.getItem("userId"));
  const aplicationVerified =
    localStorage.getItem("applicationStatus") === APPLICATION_VERIFIED;
  const [useCompanyId, setCompanyId] = useState(null);
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: memberId
      },
      onCompleted: data => {
        if (typeof data?.userData?.employment[0]?.company?.id === "number") {
          setCompanyId(data.userData.employment[0].company.id);
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      {!aplicationVerified && <AWaitingConfirmation />}
      {aplicationVerified && <CompanyProfile companyId={useCompanyId} />}
    </>
  );
};

export default Profile;
