import React from "react";
import LoginForm from "./loginForm";
import "./index.css";
import { openNotificationWithIcon } from "../../utils/notification";
import { VERIFY_USER_EMAIL_BY_TOKEN } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";

const Login: React.FC<any> = ({ ...props }) => {
  const [verifyUserEmailByToken] = useMutation(VERIFY_USER_EMAIL_BY_TOKEN);

  const runTokenEmailVerification = async () => {
    if (props?.match?.params?.emailVerificationToken) {
      const verificationResponse: any = await verifyUserEmailByToken({
        variables: {
          token: props.match.params.emailVerificationToken
        }
      });
      if (verificationResponse.data.verifyUserEmailByToken) {
        openNotificationWithIcon(
          "success",
          "Verification Success",
          "Email successfully verified."
        );
        return true;
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          "Incorrect verification token provided. Please use the latest verification link emailed to you or attempt to login."
        );
      }
    }
    return false;
  };

  return (
    <div className="login-form-wrapper">
      <div className="login-sidebar">
        <LoginForm
          employeeInvitationCode={props?.match?.params?.invitationCode}
          runTokenEmailVerification={runTokenEmailVerification}
        />
      </div>
      <div className="sider-background">
        <h2>
          Council for Debt
          <br />
          Collectors <span>in South Africa</span>
        </h2>
      </div>
    </div>
  );
};

export default Login;
