import React from "react";
import { Table } from "antd";

type Props = {
  yearRange: [number, number];
  auditSubmissionList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};

export default ({
  yearRange,
  auditSubmissionList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination
}: Props) => {
  const columns = [
    {
      title: "Company ID",
      key: "company",
      dataIndex: "company.id"
    },
    {
      title: "Company",
      key: "company",
      dataIndex: "company",
      render: record =>
        (record.tradingName || record.registeredName || "").toUpperCase()
    }
  ];

  for (let i = yearRange[0]; i <= yearRange[1]; i++) {
    columns.push({
      title: String(i),
      key: String(i),
      dataIndex: String(i),
      render: record => (record ? "YES" : "NO")
    });
  }

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize,
        onChange: page => handlePagination(page),
        total: listTotal
        // showTotal: () => <h3>Total: {listTotal}</h3>
      }}
      dataSource={auditSubmissionList}
    />
  );
};
