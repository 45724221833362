import React, { forwardRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Button, Icon, Upload } from "antd";
import { isEmpty } from "lodash";
import { openNotificationWithIcon } from "utils/notification";
import { GET_PRESIGNED_URL } from "../../graphql/queries";
import { CREATE_DOCUMENT } from "graphql/mutations";
import { uploadToS3 } from "utils/uploadToS3";

export interface IDocumentList {
  fileName: string;
  presignedUrl: string;
  type: string;
  popFile: any;
  path: string;
}

interface IDocumentInput {
  companyId?: number;
  forCompany?: boolean;
  userId: number;
  documentTypeId: number;
}

interface IUploaderProps {
  defaults: { filename: string; directory: string };
  companyId: number;
  onPresigned: (documentList: IDocumentList[]) => void;
  mutiple?: boolean;
}

const Uploader = forwardRef(
  ({ defaults, companyId, onPresigned, mutiple }: IUploaderProps, ref) => {
    const [documentList, setDocumentList] = React.useState<IDocumentList[]>([]);
    const [uploadState, setUploadState] = React.useState({
      documentName: "",
      popFile: undefined,
      fileType: "",
      documentPath: "",
      isUploading: false
    });

    const generatePresignedUrl = async option => {
      try {
        const { file, onSuccess } = option;

        const fileExt: string = file.name.split(".").pop();
        const docNo = documentList.length;
        const documentName = `${defaults.filename + docNo}.${fileExt}`;
        const documentPath = `${companyId}/${defaults.directory}${documentName}`;

        setUploadState({
          documentName,
          popFile: file,
          fileType: file.type,
          documentPath,
          isUploading: true
        });

        await getPresignedUrl({
          variables: {
            input: {
              filePath: documentPath,
              contentType: file.type,
              forCompany: true
            }
          }
        });

        setTimeout(() => {
          onSuccess();
        }, 2500);
      } catch (error) {
        console.error("generating url", error);
        openNotificationWithIcon("error", "Error", "Error when generating url");
      }
    };

    const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
      fetchPolicy: "network-only",
      onCompleted: data => {
        if (!isEmpty(data.preSignedUrl)) {
          const currentList = [
            ...documentList,
            {
              fileName: uploadState.documentName,
              presignedUrl: data.preSignedUrl,
              type: uploadState.fileType,
              popFile: uploadState.popFile,
              path: uploadState.documentPath
            }
          ];
          onPresigned(currentList);
          setDocumentList(currentList);
          setUploadState(prevState => ({ ...prevState, isUploading: false }));
        }
      },
      onError: err => {
        setUploadState(prevState => ({ ...prevState, isUploading: false }));
        console.log(err);
      }
    });

    const [createDocument] = useMutation(CREATE_DOCUMENT);

    const uploadDocument = async (
      documentList: IDocumentList[],
      documentInput: IDocumentInput
    ) => {
      const documents: number[] = [];

      for (let {
        presignedUrl,
        type,
        popFile,
        path,
        fileName
      } of documentList) {
        uploadToS3(presignedUrl, type, popFile);
        const { data } = await createDocument({
          variables: {
            input: {
              ...documentInput,
              documentStatusId: 1,
              documentName: fileName,
              documentPath: path,
              documentFormat: type
            }
          }
        });

        documents.push(data?.createDocument?.id);
      }

      return documents;
    };

    React.useImperativeHandle(ref, () => ({ uploadDocument }));

    return (
      <Upload
        multiple={mutiple}
        customRequest={generatePresignedUrl}
        onRemove={() =>
          !mutiple &&
          setUploadState({
            documentName: "",
            popFile: undefined,
            fileType: "",
            documentPath: "",
            isUploading: false
          })
        }
      >
        <Button
          loading={uploadState.isUploading}
          disabled={!mutiple && !!uploadState.documentPath}
        >
          <Icon type="upload" /> Upload
        </Button>
      </Upload>
    );
  }
);

export default Uploader;
