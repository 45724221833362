import React, { useState } from "react";
import { Button, Card, Descriptions, Icon } from "antd";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_COMPANY_FINANCIAL_INFO,
  GET_AVS_REPORT
} from "../../../graphql/queries";
import { isInteger } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { openNotificationWithIcon } from "utils/notification";

const ReviewFinancialInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { company, type } = transaction;
  const companyId = type.name !== "Employee" ? company.id : undefined;

  const [submitting, setSubmitting] = useState(false);
  const [financialInfo, setFinancialnInfo] = useState<any>();
  const { loading: loadingFinancialInformation } = useQuery(
    GET_COMPANY_FINANCIAL_INFO,
    {
      skip: !isInteger(companyId),
      variables: {
        companyId
      },
      onCompleted: data => setFinancialnInfo(data.companyFinancialInformation)
    }
  );

  const [getAVSRepor] = useLazyQuery(GET_AVS_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setSubmitting(false);
      window.open(data.accountAVSReport);
    },
    onError: () => {
      setSubmitting(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "An occured while generating AVS report"
      );
    }
  });
  const ResultComponentsProps = {
    ...transaction,
    step: "FinancialInformation",
    reviewInfo,
    registrationTaskId
  };

  const handleViewAVSReport = account => {
    setSubmitting(true);
    getAVSRepor({
      variables: {
        input: {
          bank: account.bank,
          accountName: account.accountName,
          accountNumber: account.accountNumber,
          accountType: account.accountType,
          branchCode: account.branchCode,
          account: account.accountName,
          companyId
        }
      }
    });
  };
  if (loadingFinancialInformation) return <InformationLoader />;
  return (
    <>
      <div className="flex-column information-block">
        <br />
        <Descriptions>
          <Descriptions.Item label="Business trades as">
            {financialInfo?.company?.natureOfBusiness}
          </Descriptions.Item>
          <Descriptions.Item label="Financial Year Start">
            {financialInfo?.company?.financialYearStartDate}
          </Descriptions.Item>
          <Descriptions.Item label="Financial Year End">
            {financialInfo?.company?.financialYearEndDate}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <br />

      <div>
        <br />
        <label>Business Accounts:</label>
        {financialInfo?.businessAccounts?.map(account => {
          return (
            <>
              <Card
                key={account.accountNumber}
                className="information-block-wrapper"
              >
                <div className="flex-column information-block">
                  <Button
                    shape="round"
                    className="btn-registration-capacity-selected"
                  >
                    Business Account
                  </Button>
                  <br />
                  <br />
                  <span>
                    Bank: <label>{account.bank}</label>
                    <br />
                    Account name: <label>{account.accountName}</label>
                    <br />
                    Account number: <label>{account.accountNumber}</label>
                    <br />
                    Account type: <label>{account.accountType}</label>
                    <br />
                    Branch code: <label>{account.branchCode}</label>
                    <br />
                  </span>
                </div>
              </Card>
              <br />

              <>
                <div style={{ textAlign: "right" }}>
                  <Button
                    type="default"
                    className="purple-button"
                    loading={submitting}
                    onClick={() => handleViewAVSReport(account)}
                  >
                    <Icon type="file" />
                    View AVS Report PDF
                  </Button>
                </div>
                <br />
              </>
            </>
          );
        })}
      </div>

      <div>
        <br />
        <label>Trust Accounts:</label>
        {financialInfo?.trustAccounts?.map(account => {
          return (
            <>
              <Card
                key={account.accountNumber}
                className="information-block-wrapper"
              >
                <div className="flex-column information-block">
                  <Button
                    shape="round"
                    className="btn-registration-capacity-selected"
                  >
                    Trust Account
                  </Button>
                  <br />
                  <br />
                  <span>
                    Bank: <label>{account.bank}</label>
                    <br />
                    Account name: <label>{account.accountName}</label>
                    <br />
                    Account number: <label>{account.accountNumber}</label>
                    <br />
                    Account type: <label>{account.accountType}</label>
                    <br />
                    Branch code: <label>{account.branchCode}</label>
                    <br />
                  </span>
                </div>
              </Card>
              <br />
              {account.avsReport ? (
                <>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      type="default"
                      onClick={() => window.open(account.avsReport)}
                    >
                      <Icon type="file" />
                      View AVS Report PDF
                    </Button>
                  </div>
                  <br />
                </>
              ) : null}
            </>
          );
        })}
      </div>
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewFinancialInformation;
