import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";

const Navigation = ({ roles, permissions, roleStream }) => {
  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["/admin/profile"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
    >
      <Menu.Item key="/admin/profile">
        <Link to="/admin/profile">
          <Icon type="user" />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/tasks">
        <Link to="/admin/tasks">
          <Icon type="file-text" />
          <span>Tasks</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/members">
        <Link to="/admin/members">
          <Icon type="credit-card" />
          <span>Members</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/companies">
        <Link to="/admin/companies">
          <Icon type="folder-open" />
          <span>Companies</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/transactions">
        <Link to="/admin/transactions">
          <Icon type="credit-card" />
          <span>Transactions</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/admin/team">
        <Link to="/admin/team">
          <Icon type="team" />
          <span>Team</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default Navigation;
