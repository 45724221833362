import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useLazyQuery } from "react-apollo";
import { GET_TASK, GET_DOWNLOAD_URL } from "graphql/queries";
import { openNotificationWithIcon } from "utils/notification";
import ComplaintLoader from "../Admin/Complaint/SubComponents/complaintLoader";
import { useHistory } from "react-router";
import { withRouter } from "react-router";
import RegistrationFeePayment from "../Register/feePayments";

const RenewalTask = props => {
  const [taskId, setTaskId] = useState(
    props?.match?.params?.id ? props.match.params.id * 1 : false
  );
  const history = useHistory();
  //@ts-ignore
  const [proofOfPaymentDocs, setProofOfPaymentDocs] = useState([]);
  const [task, setTask] = useState<any>({});
  const [loadingTask, setLoadingTask] = useState(true);

  useEffect(() => {
    setTaskId(props.match.params.id * 1);
    localStorage.setItem("employerFinanceTaskId", props.match.params.id);
  }, [props.match.params.id]);

  const [getTask] = useLazyQuery(GET_TASK, {
    fetchPolicy: "network-only",
    onError: err => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        history.push("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading activities"
      );
    },
    onCompleted: data => {
      if (!isEmpty(data?.task)) {
        setTask(data.task);
        setLoadingTask(false);

        localStorage.setItem("registrationCapacity", "Individual");
        localStorage.setItem("companyId", data?.task?.company?.id.toString());
      }
    }
  });

  //@ts-ignore
  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      window.open(data.preSignedDownloadUrl);
    }
  });

  useEffect(() => {
    getTask({ variables: { taskId } });
  }, []);

  return (
    <>
      {loadingTask && <ComplaintLoader />}
      {!loadingTask && (
        <div style={{ margin: "1vw" }}>
          <Card className="information-card" key={`Address ${1}`}>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h2>Payment Task {task?.id}</h2>
                <h3>
                  Status: <a>{task?.taskStatus}</a>
                </h3>
              </div>
              <br />
              <span>
                Task Type: <label>{task?.type?.name}</label>
                <br />
                Date Created:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
                <br />
                Last Modified:{" "}
                <label>{moment(task?.createdDate).format("DD-MM-YYYY")}</label>
              </span>
            </div>
          </Card>
          <br />
          {!isNaN(task?.company?.id) &&
            ["complete", "resolved"].indexOf(task.taskStatus.toLowerCase()) ===
              -1 && (
              <div className="flex-row">
                <div className="flex-column" style={{ width: "100%" }}>
                  <Card>
                    <RegistrationFeePayment
                      paymentMethodRequired="Direct"
                      registrationCapacity="Individual"
                      registeringAs="Employee of a Business"
                      companyId={task?.company?.id}
                      taskId={task.id}
                      task={task}
                      isRenewal
                    />
                  </Card>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default withRouter(RenewalTask);
