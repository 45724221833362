import React, { useState, useCallback, useEffect, useRef } from "react";
import { Card, Table, Icon, Button, Spin, Divider, Badge } from "antd";
import { format, parseISO } from "date-fns";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_ADMINS } from "../graphql/queries";
import {
  notifyError,
  openNotificationWithIcon
} from "../../../utils/notification";
import CreateTeamMember from "./CreateTeamMember";
import TeamFilter from "./TeamFilter";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import { withRouter } from "react-router";
import "../../../containers/Projects/project.css";
import { signUp } from "utils/auth/createUser";
import { SIGNUP_MUTATION } from "graphql/mutations";
import EmptyState from "../../../components/Styled/EmptyState";

const isActiveMultiFilter = multiFilter => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  user: false,
  type: false,
  userStatus: false,
  sort: "new"
};

const UserList: React.FC<any> = ({ ...props }) => {
  const [userList, setUserList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const [inviteUserFormRef, setInviteUserFormRef] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(localStorage.getItem("userRoles"));
  const [currentPage, setCurrentPage] = useState(1);
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter)
  );
  const pageSize = 20;
  const filtersRef = useRef();

  const [createUser] = useMutation(SIGNUP_MUTATION, {
    refetchQueries: [
      {
        query: GET_ADMINS,
        variables: {
          skip: skip,
          take: pageSize
        }
      }
    ]
  });

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      setMultiFilter(defaultMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runGetAdminsQuery(defaultMultiFilter, 0);
    }
  };

  useEffect(() => {
    setRole(localStorage.getItem("userRoles"));
  }, []);

  const handlePagination = page => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const [getAdmins] = useLazyQuery(GET_ADMINS, {
    onCompleted: data => {
      const { total, userList } = data.admins;
      setUserList(userList);
      setListTotal(total);
      if (showEmptyState) setShowEmptyState(false);
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error?.message);
      openNotificationWithIcon("error", "Get Admin Team", error?.message);
    }
  });

  const runGetAdminsQuery = (useMultiFilter, skip) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }

    getAdmins({ variables: {} });
  };

  const handleMemberFilterChange = (value, filterType) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      // runGetAdminsQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runGetAdminsQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "firstName",
      render: record =>
        record.firstName && record.lastName
          ? record.firstName + " " + record.lastName
          : ""
    },
    {
      title: "Email",
      dataIndex: "",
      key: "email",
      render: record => record.email || ""
    },
    {
      title: "Role",
      dataIndex: "",
      key: "userType",
      render: record =>
        record.userType && record.userType[0] && record.userType[0].name
          ? record.userType[0].name
          : ""
    },
    {
      title: "Date Joined",
      dataIndex: "",
      key: "createdDate",
      render: ({ createdDate }) =>
        createdDate ? format(parseISO(createdDate), "dd-MM-yyyy") : ""
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      key: "userStatus",
      render: record => {
        let useStatus:
          | "success"
          | "processing"
          | "default"
          | "error"
          | "warning" = "success";
        if (record === "Deregistered") {
          useStatus = "warning";
        } else if (record === "Deactivated") {
          useStatus = "error";
        }
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={useStatus} /> {record}
          </span>
        ) : (
          ""
        );
      }
    },
    {
      title: "Action",
      dataIndex: "",
      key: "id",
      render: record => {
        return (
          <a
            className="ant-dropdown-link purple-link"
            onClick={() => props.history.push(`/admin/team/${record.id}`)}
          >
            View
          </a>
        );
      }
    }
  ];

  const handleInviteUser = () => {
    inviteUserFormRef?.validateFields((err, values) => {
      if (err) {
        return;
      }

      setShowSpin(true);
      try {
        if (
          values.phone.substring(0, 3) == "027" ||
          values.phone.substring(0, 3) == "+27"
        ) {
          values.phone = "+27" + values.phone.slice(3);
        } else if (values.phone.substring(0, 1) == "0") {
          values.phone = "+27" + values.phone.slice(1);
        } else if (values.phone.substring(0, 3) != "+27") {
          values.phone = "+27" + values.phone;
        }

        return signUp(values, async (err, result) => {
          if (err) {
            switch (err.code) {
              default:
                notifyError(err);
                break;
            }
            setShowSpin(false);
          } else {
            const { data }: any = await createUser({
              variables: { input: values }
            });

            if (data.createUser && data.createUser.id) {
              setShowSpin(false);
              //@ts-ignore: Object is possibly 'null'
              inviteUserFormRef.resetFields();
              setInviteUserModalVisible(false);
              return openNotificationWithIcon(
                "success",
                "Invite Success",
                "You have successfully invited a team member. They should check their email for details"
              );
            }
          }
        });
      } catch (error) {
        setShowSpin(false);
        return openNotificationWithIcon(
          "error",
          "Invite Error ",
          error.message
        );
      }
    });
  };

  const saveInviteUserFormRef = useCallback(node => {
    if (node !== null) {
      setInviteUserFormRef(node);
    }
  }, []);

  const InviteUserModalProps = {
    ref: saveInviteUserFormRef,
    visible: inviteUserModalVisible,
    showSpin,
    onCancel: () => setInviteUserModalVisible(false),
    onCreate: () => handleInviteUser()
  };

  if (error) {
    return <InternalErrorPage />;
  }

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      {!initialising && showEmptyState && (
        <EmptyState
          location="team"
          headingText="Nothing to see here"
          bodyText="You currently have no team members. You can create a ticket to our support team to help with any problems you may be facing."
        >
          {role === "Super User" && (
            <p>
              <Button
                onClick={() => setInviteUserModalVisible(true)}
                className="purple-button"
                style={{ alignSelf: "auto" }}
              >
                <Icon type="plus" title="Invite new member" />
                Invite team member
              </Button>
            </p>
          )}
        </EmptyState>
      )}
      {initialising && <TableLoader />}
      {!showEmptyState && (
        <div className="col-sm-12 col-md-12 placeholder-table-card">
          <Card>
            <div className="card-header">
              <h3>Team Management</h3>
              <div style={{ alignSelf: "auto" }}>
                <Button
                  onClick={() => clearFilters()}
                  className="purple-button"
                  style={{ marginRight: "20px" }}
                >
                  Clear filters
                </Button>
                {role === "Super User" && (
                  <Button
                    onClick={() => setInviteUserModalVisible(true)}
                    className="purple-button"
                    style={{ alignSelf: "auto" }}
                  >
                    <Icon type="plus" title="Invite new member" />
                    Invite team member
                  </Button>
                )}
              </div>
            </div>
            <div className={loading ? "no-interact" : null}>
              {!initialising && (
                <TeamFilter
                  ref={filtersRef}
                  handleMemberFilterChange={handleMemberFilterChange}
                  setError={setError}
                  setSkip={setSkip}
                  setCurrentPage={setCurrentPage}
                  defaultMultiFilter={defaultMultiFilter}
                />
              )}
              <Divider />
              <Table
                columns={columns}
                pagination={{
                  current: currentPage,
                  pageSize,
                  onChange: page => handlePagination(page),
                  total: listTotal,
                  showTotal: () => <h3>Total: {listTotal} </h3>
                }}
                dataSource={userList}
                rowKey="id"
              />
            </div>
          </Card>
        </div>
      )}
      <CreateTeamMember {...InviteUserModalProps} />
    </>
  );
};

export default withRouter(UserList);
