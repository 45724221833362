import React from "react";
import { useIsOnline } from "react-use-is-online";
import { ApolloProvider } from "react-apollo";
import * as ReactDOM from "react-dom";
import client from "./apollo";
import Navigation from "./navigation";
import { unregister } from "./serviceWorker";
import OfflinePage from "../src/containers/OfflinePage";
import { GlobalStateProvider } from "./globalStore";
import "./styles/index.css";
import { AdminFilterContext } from "contexts/AdminFilterContext";

function App() {
  const { isOnline } = useIsOnline();
  return (
    <>
      {isOnline ? (
        <ApolloProvider client={client}>
          <GlobalStateProvider>
            <AdminFilterContext>
              <Navigation />
            </AdminFilterContext>
          </GlobalStateProvider>
        </ApolloProvider>
      ) : (
        <OfflinePage />
      )}
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

// unregister service worker
unregister();
