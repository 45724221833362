import React from "react";
import { Result, Button } from "antd";
import { createBrowserHistory } from "history";
import Unverified from "../../assets/images/unverified.svg";
import "../../containers/Register/index.css";

type Props = {
  permission?: string;
};

const ForbiddenPage: React.FC<Props> = ({ permission }) => {
  const history = createBrowserHistory({ forceRefresh: true });

  return (
    <Result
      className="flex-column"
      title="403 Access Denied"
      subTitle={`Access forbidden. You do not have sufficient permissions. {${permission ??
        ""}}`}
      extra={[
        <Button type="primary" onClick={() => history.goBack()}>
          Go back
        </Button>
      ]}
      icon={
        <img
          src={Unverified}
          alt="Unsuccessful registration"
          className="result-icon"
        />
      }
    />
  );
};

export default ForbiddenPage;
