import React, { useState } from "react";
import { Form, Divider } from "antd";
import Header from "./header";
import { notifyError } from "utils/notification";
import CreateComplaint from "./createComplaintForm";
import InformationLoader from "./informationLoader";
import { BASE_ENDPOINT } from "../../constants";

const ComplaintForm: React.FC<any> = ({ form, history }) => {
  const [loading, setLoading] = useState(false);
  const [showComplaintForm, setShowComplaintForm] = useState(true);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true);

      if (
        values.contactNumber.substring(0, 3) == "027" ||
        values.contactNumber.substring(0, 3) == "+27"
      ) {
        values.contactNumber = "+27" + values.contactNumber.slice(3);
      } else if (values.contactNumber.substring(0, 1) == "0") {
        values.contactNumber = "+27" + values.contactNumber.slice(1);
      } else if (values.contactNumber.substring(0, 3) != "+27") {
        values.contactNumber = "+27" + values.contactNumber;
      }

      try {
        const response = await fetch(`${BASE_ENDPOINT}/complaint`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            input: { ...values, complaintAgainst: "Debt Collector" }
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const { result } = data;

        if (result?.id) {
          setLoading(false);
          form.resetFields();
          localStorage.clear();
          history.push(`/create-complaint/${result.id}`);
          setShowComplaintForm(false);
        }
      } catch (error) {
        setLoading(false);
        return notifyError(error);
      }
    });
  };

  return (
    <>
      <Header />
      <div className="base-form">
        <h3>Create a Complaint</h3>
        <Divider />
        <>
          {loading && <InformationLoader />}
          {showComplaintForm && !loading && (
            <CreateComplaint
              handleSubmit={handleSubmit}
              form={form}
              loading={loading}
            />
          )}
        </>
      </div>
    </>
  );
};

export default Form.create({ name: "normal_register" })(ComplaintForm);
