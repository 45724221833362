import React, { useEffect } from "react";
import { Result, Button, Icon, Typography } from "antd";
import { useHistory } from "react-router";
import { useRegistrationState } from "stores/registration";
import { isAdmin } from "utils";
import { useMutation } from "react-apollo";
import { COMPLETE_BATCH_REGISTRATION } from "graphql/mutations";
import { notifyError, notifySuccess } from "utils/notification";
import BatchRegistrationEmployees from "components/BatchRegistrationEmployees";

const { Paragraph, Text } = Typography;

export default function BatchRegistration() {
  const history = useHistory();
  const {
    batchRegistrationId,
    manualRegisterId: manualRegistrationId,
    clearRegistrationState: cleanup
  } = useRegistrationState();

  function addEmployee() {
    history.replace(
      `/admin/team/manual-register?batch_id=${batchRegistrationId}`
    );
  }

  function close() {
    history.replace(isAdmin() ? `/admin/tasks` : "/tasks");
    cleanup(true);
  }

  const [complete, { loading }] = useMutation(COMPLETE_BATCH_REGISTRATION, {
    variables: { input: { id: batchRegistrationId } },
    onCompleted: (data: any) => {
      if (data.completeBatchRegistration?.completed) {
        notifySuccess("Batch registration completed successfully");
        close();
      } else {
        notifyError("Failed to complete batch registration");
      }
    },
    onError: (err: any) => {
      notifyError(err);
    }
  });

  async function completeBatchRegistration() {
    complete();
  }

  useEffect(() => {
    if (batchRegistrationId && manualRegistrationId) {
      addEmployee();
    }
  }, [batchRegistrationId, manualRegistrationId]);

  return (
    <>
      <Result
        status="info"
        title="Welcome to Batch Registration"
        subTitle="Please note the following information before continuing."
        extra={[
          <Button
            icon="plus"
            type="primary"
            key="add"
            onClick={addEmployee}
            disabled={loading}
          >
            Add Employee
          </Button>,
          <Button
            icon="check"
            key="complete"
            onClick={completeBatchRegistration}
            loading={loading}
            disabled={loading}
          >
            Complete Batch Registration
          </Button>
        ]}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16
              }}
            >
              Note:
            </Text>
          </Paragraph>
          <Paragraph>
            <Icon type="check" /> Only do a batch registration if you are
            registering more than one employee. Else do a normal registration.
          </Paragraph>
          <Paragraph>
            <Icon type="check" /> You will not be able to do any other
            registration until you complete this batch registration.
          </Paragraph>
          <Paragraph>
            <Icon type="check" /> Employee task will be created for all
            employees added to this batch registration, but a financial task
            will only be created when you complete the batch registration.
          </Paragraph>
          <Paragraph>
            <Icon type="check" /> All employee tasks will be linked to one
            financial task.
          </Paragraph>
        </div>
      </Result>
      <BatchRegistrationEmployees batchRegistrationId={batchRegistrationId} />
    </>
  );
}
