import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { BASE_ENDPOINT } from "../../constants";
import { notifyError, notifySuccess } from "utils/notification";

const ComplaintsOTP: React.FC<any> = ({
  show = false,
  complaintId,
  updateComplaint,
  cancelSumbit
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(show);
  const [code, setCode] = useState<number>();

  const handleOk = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_ENDPOINT}/complaint/otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ input: { complaintId, otp: code } })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setLoading(false);
      if (data?.result === "success") {
        setVisible(false);
        updateComplaint();
      } else {
        notifyError("Wrong otp code provided. Please try again");
      }
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    cancelSumbit(false);
  };

  const resendOTP = async () => {
    try {
      const response = await fetch(
        `${BASE_ENDPOINT}/complaint/otp/${complaintId}`,
        {
          method: "GET"
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data?.result === "success") {
        notifySuccess("OTP re-sent successfully to the number provided");
      } else {
        notifyError("Error, could not send the OTP this time");
      }
    } catch (error) {
      notifyError(error);
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title="OTP Authorisation"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="purple-button"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>
        ]}
      >
        <ol>
          <li>
            An OTP(One-time PIN) has been sent via SMS to the cellphone number
            provided. Please note the following: by submitting the OTP you agree
            to, and declare that:{" "}
          </li>
          <li>
            All information provided by yourself on behalf of business, as part
            of this complaint is true, complete and acurate to the best of your
            knowledge
          </li>
          <li>
            In terms of Regulations promulgated in terms of the Debt Collectors'
            Act 114 of 1998, it is an offenceto furnish false or misleading
            particulars or information or false or misleading statemnt
          </li>
          <li>
            The complaint registration is subject to review by the Council of
            Debt Collectors for verification; and upon request, additional
            information or supporting documents may be required{" "}
          </li>
        </ol>
        <br />
        <h3>One-Time PIN (OTP): </h3>
        <Input
          type="number"
          placeholder="Confirm Pin..."
          onChange={e => setCode(Number(e.target.value))}
        />
        <br />
        <br />
        <Button
          type="link"
          style={{ paddingLeft: "initial" }}
          onClick={resendOTP}
        >
          <h3 className="purple-link">Resend OTP</h3>
        </Button>
      </Modal>
    </>
  );
};

export default ComplaintsOTP;
