import React from "react";
import { Tabs } from "antd";
import MemberTransactions from "./memberTransactions";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDeined from "../../../components/Auth/accessDenied";
import "./index.css";

const AllTransactions = () => {
  return (
    <PermissionsGuard
      perform={"transactions:view"}
      yes={() => (
        <>
          <div className="tasks-header-div">
            <h2>Transactions</h2>
          </div>
          <Tabs tabPosition="top">
            <Tabs.TabPane tab="Transactions" key="1" className="tab-content">
              <MemberTransactions mode="all-transactions" />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
      no={() => <AccessDeined permission="transactions:view" />}
    />
  );
};

export default AllTransactions;
