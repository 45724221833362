import { Button, Form, Input } from "antd";
import { SIGNUP_MUTATION } from "graphql/mutations";
import { FormComponentProps } from "antd/lib/form/Form";
import React, { useState, useCallback } from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { signUp } from "utils/auth/createUser";
import { openNotificationWithIcon } from "utils/notification";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE, TERMS } from "../../constants";
import { authenticateUser } from "utils/auth/authenticateUser";
import SignupSuccessModal from "./signupSuccessModal";

const RegisterForm: React.FC<any> = props => {
  const [loading, setLoading] = useState(false);
  const [createUser] = useMutation(SIGNUP_MUTATION);
  const [showModal, setShowModal] = useState(false);
  // @ts-ignore the actual variable is not being used
  const [signupSuccessRef, setSignupSuccessRef] = useState(null);

  const RemoveEmployeeRef = useCallback(node => {
    if (node !== null) {
      setSignupSuccessRef(node);
    }
  }, []);

  const RemoveUserModalProps = {
    ref: RemoveEmployeeRef,
    visible: showModal,
    modalTitle: "Successfully Signed Up!",
    actionText: "Ok",
    onCancel: () => handleModal(),
    onAction: () => handleModal()
  };

  const handleModal = () => {
    setShowModal(false);
    window.location.href = "https://www.cfdc.org.za/";
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value) {
      form.validateFields(["confirm_password"], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Your passwords must match!");
    } else {
      callback();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true);

      try {
        delete values.confirm_password;
        if (
          values.phone.substring(0, 3) == "027" ||
          values.phone.substring(0, 3) == "+27"
        ) {
          values.phone = "+27" + values.phone.slice(3);
        } else if (values.phone.substring(0, 1) == "0") {
          values.phone = "+27" + values.phone.slice(1);
        } else if (values.phone.substring(0, 3) != "+27") {
          values.phone = "+27" + values.phone;
        }
        return signUp(values, async (err, result) => {
          if (err) {
            switch (err.code) {
              default:
                openNotificationWithIcon(
                  "error",
                  "Authentication Error",
                  `${err.message} Hint: prefix with country dialing code e.g +27`
                );
                break;
            }
            setLoading(false);
          } else {
            //authenticate user to create a session
            await authenticateUser(
              {
                idNumber: values.idNumber,
                email: values.email,
                password: values.password
              },
              async (err, result) => {
                if (err) {
                  return openNotificationWithIcon(
                    "error",
                    "Sign up Error",
                    err.message
                  );
                }
                // login success
                // create user in db
                const { data }: any = await createUser({
                  variables: {
                    input: {
                      ...values,
                      sendEmailVerificationToken: true
                    }
                  }
                });

                if (data.createUser && data.createUser.id) {
                  setLoading(false);
                  props.form.resetFields();
                  openNotificationWithIcon(
                    "success",
                    "Registration Success",
                    "You have successfully created an account, please check your email for details"
                  );
                  localStorage.clear();
                  setShowModal(true);
                }
              }
            );
          }
        });
      } catch (error) {
        setLoading(false);
        return openNotificationWithIcon(
          "error",
          "Authentication Error",
          err.message.replace("GraphQL error:", "").trim()
        );
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <>
      <Form layout="vertical" onSubmit={handleSubmit} className="register-form">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-register" />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2vh",
              marginBottom: "1vh"
            }}
          >
            <h3>Sign Up</h3>
          </div>
        </div>
        <Form.Item label="ID/Passport Number" className="form-item" hasFeedback>
          {getFieldDecorator("idNumber", {
            rules: [
              {
                required: true,
                message: "Please enter either your ID or passport Number!"
              }
            ]
          })(
            <Input
              placeholder="Please enter either your ID or passport #"
              readOnly={loading}
            />
          )}
        </Form.Item>

        <Form.Item label="First Name" className="form-item" hasFeedback>
          {getFieldDecorator("firstName", {
            normalize: s => s?.toUpperCase(),
            rules: [{ required: true, message: "Please input your name!" }]
          })(<Input placeholder="Please enter your name" readOnly={loading} />)}
        </Form.Item>

        <Form.Item label="Last Name" className="form-item" hasFeedback>
          {getFieldDecorator("lastName", {
            normalize: s => s?.toUpperCase(),
            rules: [{ required: true, message: "Please input your name!" }]
          })(<Input placeholder="Please enter your name" readOnly={loading} />)}
        </Form.Item>

        <Form.Item label="Phone Number" className="form-item" hasFeedback>
          {getFieldDecorator("phone", {
            rules: [{ required: true, message: "Please input your phone!" }]
          })(
            <Input
              addonBefore={
                <div>
                  <span style={{ color: "black" }}>🇿🇦</span> +27
                </div>
              }
              placeholder="Please enter your phone"
              readOnly={loading}
            />
          )}
        </Form.Item>

        <Form.Item label="Email" className="form-item" hasFeedback>
          {getFieldDecorator("email", {
            normalize: s => s?.toLowerCase(),
            rules: [
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "The input is not valid E-mail!"
              }
            ]
          })(
            <Input placeholder="Please enter your email" readOnly={loading} />
          )}
        </Form.Item>

        <Form.Item label="Password" className="form-item" hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Please input your Password!" },
              {
                validator: validateToNextPassword
              },
              {
                pattern: PASSWORD_REGEX,
                message: PASSWORD_REGEX_MESSAGE
              },
              {
                min: 8,
                message: "Password has to be longer than 8 characters!"
              }
            ]
          })(
            <Input.Password
              placeholder="Please enter password here"
              readOnly={loading}
            />
          )}
        </Form.Item>

        <Form.Item label="Confirm Password" className="form-item" hasFeedback>
          {getFieldDecorator("confirm_password", {
            rules: [
              { required: true, message: "Please re-enter your password" },
              { validator: compareToFirstPassword }
            ]
          })(
            <Input.Password
              placeholder="Please re-enter your password"
              readOnly={loading}
            />
          )}
        </Form.Item>
        <div style={{ marginBottom: "30px", padding: "0 40px" }}>
          <h3 style={{ textAlign: "center" }}>
            By signing up for registration I agree to the following:
          </h3>
          <ol style={{ margin: "1vh", fontSize: "13px" }}>
            {TERMS.map((term, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>
                {term}
              </li>
            ))}
          </ol>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-register"
            onClick={handleSubmit}
            loading={loading}
          >
            Sign Up
          </Button>
        </Form.Item>

        <span>Already have an account?</span>

        <Link
          to={
            props.employeeInvitationCode
              ? `/login/employee-invitation/${props.employeeInvitationCode}`
              : "/"
          }
        >
          <h4>Sign In</h4>
        </Link>
      </Form>
      <SignupSuccessModal {...RemoveUserModalProps} />
    </>
  );
};

interface IProps extends FormComponentProps {
  employeeInvitationCode?: string;
}

const WrappedNormalRegisterForm = Form.create<IProps>({
  name: "normal_register"
})(RegisterForm);

export default WrappedNormalRegisterForm;
