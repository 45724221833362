import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_APPLICATION_BY_TASK,
  GET_USER_EMPLOYMENT_BY_COMPANY
} from "../../../graphql/queries";
import { isEmpty } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";

const ReviewEmploymentInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { employee } = transaction;
  const [userId, setUserId] = useState<number>();
  const [companyId, setCompanyId] = useState<number>();
  const [userEmploymentInfo, setUserEmploymentInfo] = useState<any>();

  useEffect(() => {
    if (!userId && !userId && !userEmploymentInfo)
      refetch().then(res => laodEmploymentInfo(res.data));
  }, []);

  const { loading: loadingUserApplicationInfo, refetch } = useQuery(
    GET_APPLICATION_BY_TASK,
    {
      skip: isEmpty(employee),
      variables: {
        taskId: transaction.id
      },
      onCompleted: data => {
        laodEmploymentInfo(data);
      }
    }
  );

  const laodEmploymentInfo = data => {
    const result = data.applicationByTask;
    setUserId(employee.id);
    if (!isEmpty(result)) {
      setCompanyId(result.employer?.id);
      localStorage.setItem("employerId", result.employer?.id);
      getEmploymentInfo({
        variables: { userId: employee.id, companyId: result.employer?.id }
      });
    }
  };

  const [
    getEmploymentInfo,
    { loading: loadingUserEmploymentInfo }
  ] = useLazyQuery(GET_USER_EMPLOYMENT_BY_COMPANY, {
    fetchPolicy: "network-only",
    variables: {
      userId,
      companyId
    },
    onCompleted: data => {
      setUserEmploymentInfo(data.userEmploymentByCompany);
    }
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "EmploymentInformation",
    reviewInfo,
    registrationTaskId
  };
  if (loadingUserApplicationInfo || loadingUserEmploymentInfo)
    return <InformationLoader />;
  return (
    <>
      {userEmploymentInfo?.company && (
        <div className="flex-column information-block">
          <br />
          <span>
            Employer's Name:{" "}
            <label>{userEmploymentInfo?.company?.tradingName}</label>
          </span>
          <span>
            Employment Capacity:{" "}
            <label>{userEmploymentInfo?.capacity?.name}</label>
          </span>
          <span>
            Employer Registration Number:{" "}
            <label>{userEmploymentInfo?.company?.registeredNumber}</label>
          </span>
          <span>
            Employer Council Registeration Number:{" "}
            <label>{userEmploymentInfo?.company?.membershipNumber}</label>
          </span>
          <span>
            Employer Address Type:{" "}
            <label>{userEmploymentInfo?.employerAddressType}</label>
          </span>
          <span>
            Employer Branch:{" "}
            <label>{userEmploymentInfo?.employerBranchLocation}</label>
          </span>
        </div>
      )}
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewEmploymentInformation;
