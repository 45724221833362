import React from "react";

type Props = {
  condition: boolean;
  children: React.ReactNode;
};

type IfElseProps = {
  condition: boolean;
  trueChildren: React.ReactNode;
  falseChildren: React.ReactNode;
};

export function If({ condition, children }: Props) {
  return condition ? <>{children}</> : null;
}

export function IfNot({ condition, children }: Props) {
  return condition ? null : <>{children}</>;
}

export function IfElse({
  condition,
  trueChildren,
  falseChildren
}: IfElseProps) {
  return condition ? <>{trueChildren}</> : <>{falseChildren}</>;
}
