import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Card,
  Table,
  Icon,
  Button,
  Spin,
  Divider,
  Menu,
  Dropdown,
  Badge
} from "antd";
import { isEmpty } from "lodash";
import { format, compareDesc, parseISO } from "date-fns";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  GET_MEMBER_OVERVIEW_INFORMATION,
  GET_USERS_BY_FILTER
} from "../../containers/Admin/graphql/queries";
import {
  INVITE_USER_SIGN_UP,
  REMOVE_USER_EMPLOYMENT,
  UPDATE_USER,
  CHANGE_USER_CAPACITY,
  CHANGE_USER_STATUS,
  CHANGE_MEMBERSHIP_NUMBER,
  DELETE_USER
} from "../../containers/Admin/graphql/mutations";
import {
  notifyError,
  openNotificationWithIcon
} from "../../utils/notification";
import InviteUserForm from "./InviteUser";
import ListUserFilters from "./ListUserFilters";
import { TableLoader } from "./TableContentPlaceholder";
import InternalErrorPage from "../../containers/InternalErrorPage";
import { useHistory } from "react-router";
import "../../containers/Projects/project.css";
import { ADMIN_STREAMS } from "../../constants";
import RemoveEmployeeModal from "containers/Employees/RemoveEmployeeModal";
import ChangeMemberStatusModal from "containers/Employees/ChangeMemberModal";
import ChangeCertificateDateModal from "./ChangeCertificateDates";
import { filterUnemployed } from "../../utils/filterUnemployed";
import BlacklistEntityModal from "containers/Admin/Companies/BlacklistEntityModal";
import { User } from "typings/types";

const isActiveMultiFilter = multiFilter => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  user: false,
  type: false,
  status: false,
  company: false,
  capacity: false,
  sort: "new"
};

type TypeOfChange = "status" | "capacity" | "delete" | "membership";

type ChangeUserResponse = {
  success: boolean;
};

export default function UserList() {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);
  const [changeDateModalVisible, setChangeDateModalVisible] = useState(false);
  const [blacklistModalVisible, setBlacklistModalVisible] = useState(false);
  const [inviteUserFormRef, setInviteUserFormRef] = useState(null);
  const [inviteUserSignUp] = useMutation(INVITE_USER_SIGN_UP);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [typeOfChange, setTypeOfChange] = useState<TypeOfChange>("status");
  const [role, setRole] = useState(localStorage.getItem("userRoles"));
  const [stream, setStream] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter)
  );
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const [changeMemberModalVisible, setChangeMemberModalVisible] = useState(
    false
  );
  const [employeeRemovalFormRef, setEmployeeRemovalFormRef] = useState(null);
  const [isRemovingEmployment, setIsRemovingEmployment] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState<any>();
  const [employment, setEmployment] = useState<{ id: number } | undefined>(
    undefined
  );
  const [isLoadingEmployeeDetails, setIsLoadingEmployeeDetails] = useState(
    true
  );

  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: selectedEmployee?.id
      },
      onCompleted: data => {
        setEmployment(selectedEmployee?.employment[0]);
        setEmployeeDetails(data.userData);
        setIsLoadingEmployeeDetails(false);
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    setIsLoadingEmployeeDetails(true);
    if (selectedEmployee) fetchMemberOverviewInformation();
  }, [selectedEmployee]);

  const [removeUserEmployment] = useMutation(REMOVE_USER_EMPLOYMENT, {
    onCompleted: async () => {
      try {
        await updateUser({
          variables: {
            input: {
              id: +selectedEmployee.id,
              userStatus: "Deregistered"
            }
          }
        });
        openNotificationWithIcon(
          "success",
          "Deregister operation successful",
          "User deregistered"
        );
        setIsRemovingEmployment(false);
        setModalVisible(false);
        setSkip(0);
        setCurrentPage(1);
        return runMultiFilterMemberQuery(multiFilter, 0, true);
      } catch (err) {
        openNotificationWithIcon(
          "error",
          "Delete operation error",
          (err as Error).message
        );
        setModalVisible(false);
        setIsRemovingEmployment(false);
      }
    },
    onError: err => {
      notifyError(err);
      setModalVisible(false);
      setIsRemovingEmployment(false);
    }
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      return "Success";
    },
    onError: err => {
      throw new Error("An error occurred when de-registering the user");
    }
  });

  const pageSize = 10;

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign({}, defaultMultiFilter);
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterMemberQuery(newMultiFilter, 0, true);
    }
  };

  const changeUserOpts = {
    onCompleted: () => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Successfully updated the user details"
      );
      setChangeMemberModalVisible(false);
      setIsLoadingEmployeeDetails(false);
      clearFilters();
    },
    onError: (err: any) => {
      setIsLoadingEmployeeDetails(false);
      notifyError(err);
    }
  };

  const [changeStatus] = useMutation<ChangeUserResponse>(
    CHANGE_USER_STATUS,
    changeUserOpts
  );

  const [changeCapacity] = useMutation<ChangeUserResponse>(
    CHANGE_USER_CAPACITY,
    changeUserOpts
  );

  const [changeMembership] = useMutation<ChangeUserResponse>(
    CHANGE_MEMBERSHIP_NUMBER,
    changeUserOpts
  );

  const [deleteUser] = useMutation<ChangeUserResponse>(DELETE_USER, {
    onCompleted: () => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Successfully deleted the user from the platform"
      );
      setChangeMemberModalVisible(false);
      setIsLoadingEmployeeDetails(false);
      clearFilters();
    },
    onError: err => {
      setIsLoadingEmployeeDetails(false);
      notifyError(err);
    }
  });

  useEffect(() => {
    setRole(localStorage.getItem("userRoles"));
    const localStream = localStorage.getItem("roleStream");
    setStream(localStream ? localStream : "");
    //   setPermissions(localStorage.getItem("userPermissions"));
  }, []);

  const RemoveEmployeeRef = useCallback(node => {
    if (node !== null) {
      setEmployeeRemovalFormRef(node);
    }
  }, []);

  const handleCancelModal = () => {
    setModalVisible(false);
  };

  const handleRemoveEmployee = () => {
    setIsRemovingEmployment(true);
    employeeRemovalFormRef.validateFields(async (err, value) => {
      if (err) {
        return;
      }
      await removeUserEmployment({
        variables: {
          input: {
            id: employment.id,
            reason: value.removalReason
          }
        }
      });
    });
  };

  const removeUserModalProps = {
    ref: RemoveEmployeeRef,
    visible: modalVisible,
    companyId: selectedEmployee?.employment?.[0]?.company?.id,
    modalTitle: "Remove Employee",
    actionText: "Remove",
    employment: selectedEmployee?.employment[0],
    isLoadingEmployeeDetails,
    isRemovingEmployment,
    employeeDetails,
    onCancel: () => handleCancelModal(),
    onAction: () => handleRemoveEmployee()
  };

  const changeMemberProps = {
    ref: undefined,
    visible: changeMemberModalVisible,
    typeOfChange,
    employeeDetails,
    setLoading: setIsLoadingEmployeeDetails,
    loading: isLoadingEmployeeDetails,
    onCancel: () => setChangeMemberModalVisible(false),
    changeStatus,
    changeCapacity,
    deleteUser,
    changeMembership
  };

  const handleOpenModal = record => {
    setSelectedEmployee(record);
    setModalVisible(true);
  };

  const handleUserBlacklist = (record: any) => {
    setSelectedEmployee(record);
    setBlacklistModalVisible(true);
  };

  const handleOpenChangeMemberModal = (record: User, type: TypeOfChange) => {
    setTypeOfChange(type);
    setSelectedEmployee(record);
    setChangeMemberModalVisible(true);
  };

  const handlePagination = page => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const [loadUsersByFilter] = useLazyQuery(GET_USERS_BY_FILTER, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setUserList(data.usersByFilter.userList);
      if (data.usersByFilter.total) {
        setListTotal(data.usersByFilter.total);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error.message);
    }
  });

  const [loadUsersByFilterUncached] = useLazyQuery(GET_USERS_BY_FILTER, {
    onCompleted: data => {
      setUserList(data.usersByFilter.userList);
      if (data.usersByFilter.total) {
        setListTotal(data.usersByFilter.total);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error.message);
    },
    fetchPolicy: "network-only"
  });

  const runMultiFilterMemberQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    if (!skipCache) {
      loadUsersByFilter({
        variables: {
          role,
          filter: JSON.stringify(useMultiFilter),
          includeTotal: useSkip === 0 ? true : false,
          criteria: "multiFilter",
          skip: useSkip,
          take: pageSize
        }
      });
    } else {
      loadUsersByFilterUncached({
        variables: {
          role,
          filter: JSON.stringify(useMultiFilter),
          includeTotal: useSkip === 0 ? true : false,
          criteria: "multiFilter",
          skip: useSkip,
          take: pageSize
        }
      });
    }
  };

  const handleMemberFilterChange = (value, filterType) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      runMultiFilterMemberQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runMultiFilterMemberQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = [
    {
      title: "User",
      dataIndex: "",
      key: "firstName",
      render: record =>
        (record.firstName && record.lastName
          ? record.firstName + " " + record.lastName
          : ""
        ).toUpperCase()
    },
    {
      title: "ID Number",
      dataIndex: "idNumber",
      key: "userList"
    },

    {
      title: "Company Name",
      dataIndex: "",
      key: "companyName",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x.company?.registeredName || x.company?.tradingName)
          .join(", ")
          .toUpperCase();
      }
    },
    {
      title: "Capacity",
      dataIndex: "",
      key: "capacity",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x?.capacity?.name)
          .join(", ");
      }
    },
    {
      title: "Council Membership Number",
      dataIndex: "",
      key: "company",
      render: ({ employment }) => {
        if (!employment || !employment.length) return "";
        return filterUnemployed(employment)
          .map(x => x?.company?.membershipNumber)
          .join(", ");
      }
    },
    {
      title: "Valid Until",
      key: "certificates",
      render: ({ certificates }) => {
        if (!certificates || certificates.length === 0) return "n/a";
        return format(
          certificates
            .map(({ toDate }) => parseISO(toDate))
            .sort(compareDesc)[0],
          "yyyy-MM-dd"
        );
      }
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      key: "userStatus",
      render: record => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "",
      key: "id",
      render: (record: any) => {
        const items = [
          <Menu.Item
            key="View user profile"
            onClick={() => history.push(`/admin/member/${record.id}`)}
          >
            View user profile
          </Menu.Item>
        ];

        if (role === "Super User") {
          items.push(
            <Menu.Item
              key="Delete User"
              onClick={() => handleOpenChangeMemberModal(record, "delete")}
            >
              Delete User
            </Menu.Item>,
            <Menu.Item
              key="Blacklist User"
              onClick={() => handleUserBlacklist(record)}
            >
              Blacklist User
            </Menu.Item>,
            <Menu.Item
              key="Change Status"
              onClick={() => handleOpenChangeMemberModal(record, "status")}
            >
              Change Status
            </Menu.Item>,
            <Menu.Item
              key="Change Capacity"
              onClick={() => handleOpenChangeMemberModal(record, "capacity")}
            >
              Change Capacity
            </Menu.Item>,
            <Menu.Item
              key="Change Membership"
              onClick={() => handleOpenChangeMemberModal(record, "membership")}
            >
              Change Membership Number
            </Menu.Item>
          );
        }

        if (ADMIN_STREAMS.includes(stream)) {
          items.push(
            <Menu.Item
              key="Deregister User"
              onClick={() => handleOpenModal(record)}
            >
              Deregister User
            </Menu.Item>,
            <Menu.Item
              key="Change Certificate Dates"
              disabled={!record?.certificates?.length}
              onClick={() => {
                setSelectedEmployee(record);
                setChangeDateModalVisible(true);
              }}
            >
              Change Certificate Dates
            </Menu.Item>
          );
        } else {
          items.push(
            <Menu.Item key="Change account">Change Account</Menu.Item>,
            <Menu.Item key="Transfer user">Transfer User</Menu.Item>,
            <Menu.Item key="Transfer user">Transfer User</Menu.Item>
          );
        }

        return (
          <Dropdown
            overlay={<Menu>{items.map(i => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
            disabled={role !== "Super User"}
          >
            <span className="ant-dropdown-link purple-link">
              More
              <Icon type="down" title="member actions dropdown" />
            </span>
          </Dropdown>
        );
        //   }}
        // />
      }
    }
  ];

  const handleInviteUser = () => {
    //@ts-ignore: Object is possibly 'null'
    inviteUserFormRef.validateFields((err, values) => {
      if (err) {
        return;
      }

      setShowSpin(true);
      //submit data
      inviteUserSignUp({
        variables: {
          email: values.email
        }
      })
        .then(data => {
          // refetch().then(res => setUserList(res.data.users.userList)); // refetch updated user list
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Invite success",
            "Member successfully invited"
          );
        })
        .catch(error => {
          setShowSpin(false);
          openNotificationWithIcon("error", "Invite Error ", error.message);
        });

      //@ts-ignore: Object is possibly 'null'
      inviteUserFormRef.resetFields();
      setInviteUserModalVisible(false);
    });
  };

  const saveInviteUserFormRef = useCallback(node => {
    if (node !== null) {
      setInviteUserFormRef(node);
    }
  }, []);

  const InviteUserModalProps = {
    ref: saveInviteUserFormRef,
    visible: inviteUserModalVisible,
    onCancel: () => setInviteUserModalVisible(false),
    onCreate: () => handleInviteUser()
  };

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <div className="col-sm-12 col-md-12 placeholder-table-card">
        <Card>
          <div className="card-header">
            <h3>All Members</h3>
            <div style={{ alignSelf: "auto" }}>
              <Button
                onClick={() => clearFilters()}
                className="purple-button"
                style={{ marginRight: "20px" }}
              >
                Clear filters
              </Button>
              <Button
                onClick={() => setInviteUserModalVisible(true)}
                className="purple-button"
                style={{ alignSelf: "auto" }}
              >
                <Icon type="plus" title="Invite new member" />
                Invite new member
              </Button>
            </div>
          </div>
          {initialising && <TableLoader />}
          <div className={loading ? "no-interact" : null}>
            {!initialising && (
              <ListUserFilters
                ref={filtersRef}
                handleMemberFilterChange={handleMemberFilterChange}
                setError={setError}
                setSkip={setSkip}
                setCurrentPage={setCurrentPage}
                defaultMultiFilter={defaultMultiFilter}
              />
            )}
            {userList && !isEmpty(userList) && (
              <>
                <Divider />
                <Table
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    pageSize,
                    onChange: page => handlePagination(page),
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal} </h3>
                  }}
                  dataSource={userList}
                  rowKey="id"
                />
              </>
            )}
          </div>
          {!initialising && userList && isEmpty(userList) && (
            <>
              <Divider />
              <Table />
            </>
          )}
        </Card>
      </div>
      <InviteUserForm {...InviteUserModalProps} />
      <RemoveEmployeeModal {...removeUserModalProps} />
      <ChangeMemberStatusModal {...changeMemberProps} />
      <ChangeCertificateDateModal
        visible={changeDateModalVisible}
        onCancel={() => setChangeDateModalVisible(!changeDateModalVisible)}
        data={selectedEmployee}
        isCompany={false}
        refreshList={clearFilters}
      />
      {blacklistModalVisible && (
        <BlacklistEntityModal
          refreshList={clearFilters}
          type="user"
          entity={selectedEmployee}
          onCancel={() => {
            setBlacklistModalVisible(false);
          }}
        />
      )}
    </>
  );
}
