import React, { useCallback } from "react";
import { Input, Row, Col, Select, DatePicker } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { useBatchFilters } from "hooks/UseBatchFilters";

const { Option } = Select;

export type FilterKey =
  | "taskId"
  | "taskStatus"
  | "startDate"
  | "endDate"
  | "page";

export type Filters = Record<FilterKey, string>;

type BatchFiltersProps = {
  loading?: boolean;
};

export function BatchFilters({ loading }: BatchFiltersProps) {
  const {
    id = undefined,
    completed = undefined,
    startDate,
    endDate,
    setFilterState
  } = useBatchFilters();

  const debouncedTaskIdFilterUpdate = useCallback(
    debounce((data: string) => {
      setFilterState({ taskId: data });
    }, 250),
    []
  );

  function handleStatus(value: string) {
    setFilterState({ taskStatus: value });
  }

  return (
    <Row gutter={24} className={loading ? "no-interact" : ""}>
      <Col span={7}>
        <div className="flex-center-space-between">
          <label>ID:</label>
          <Input
            type="number"
            value={id}
            placeholder="Batch ID"
            style={{ width: "calc(100% - 75px)" }}
            onChange={event => {
              debouncedTaskIdFilterUpdate(event.target.value);
            }}
          />
        </div>
      </Col>

      <Col span={7}>
        <div className="flex-center-space-between">
          <label>Status:</label>
          <Select
            placeholder="Batch status"
            onChange={handleStatus}
            style={{ width: "calc(100% - 75px)" }}
            value={completed}
          >
            <Option value={"true"}>Completed</Option>
            <Option value={"false"}>In Progress</Option>
          </Select>
        </div>
      </Col>
      <Col span={10}>
        <div className="flex-center-space-between">
          <label>Dates: </label>
          <DatePicker.RangePicker
            style={{ width: "calc(100% - 75px)" }}
            separator="=>"
            value={[
              startDate ? moment(startDate) : undefined,
              endDate ? moment(endDate) : undefined
            ]}
            onChange={dates => {
              if (dates.length === 2) {
                const startDate = dates[0]
                  .startOf("day")
                  .locale("en_za")
                  .format("YYYY-MM-DD");
                const endDate = dates[1]
                  .startOf("day")
                  .locale("en_za")
                  .format("YYYY-MM-DD");

                setFilterState({ startDate, endDate });
              }
            }}
          />
        </div>
      </Col>
    </Row>
  );
}

export default BatchFilters;
