import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, Table, Button, Spin, Divider, Icon, Menu, Dropdown } from "antd";
import { withRouter } from "react-router";
import { format, parseISO, compareDesc } from "date-fns";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_FILTERED_EMPLOYMENT_LIST } from "../../../graphql/queries";
import RepresentativeFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import EmptyState from "../../../components/Styled/EmptyState";
import { MANUAL_REGISTRATION_ROLES } from "../../../constants";
import { useGlobalState } from "../../../globalStore";
import CompanyRepModal from "./companyRepModal";

const isActiveMultiFilter = multiFilter => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  username: false,
  status: false,
  businessUnitId: false,
  capacityId: false,
  companyId: false,
  sort: "DESC"
};

type TUpdateRepInput = {
  firstName: string;
  lastName: string;
  idNumber: string;
  userId: number;
  id: number; // representative id
  email: string;
  phone: string;
  capacityId: number;
};

const CompanyRepresentativesList: React.FC<any> = ({
  companyId = false,
  mode,
  history
}) => {
  const { state } = useGlobalState();
  const [relatedPartyList, setRelatedPartyList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [representative, setRepresentative] = useState<{
    id: number;
    idNumber: string;
  }>();
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [newRepInformation, setNewRepInformation] = useState<TUpdateRepInput>();
  const [currentPage, setCurrentPage] = useState(1);
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter, {
      companyId: companyId
    })
  );
  const [useMode] = useState(mode);
  const pageSize = 10;

  const handlePagination = page => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign(
        {},
        defaultMultiFilter,
        typeof companyId === "number" ? { companyId: companyId } : {}
      );
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterRepresentativeQuery(newMultiFilter, 0);
    }
  };

  const [getFilteredEmployeeList] = useLazyQuery(GET_FILTERED_EMPLOYMENT_LIST, {
    fetchPolicy: "network-only",
    onCompleted: ({ getEmploymentsByFilter }) => {
      setRelatedPartyList(getEmploymentsByFilter.employmentList);

      if (getEmploymentsByFilter.total) {
        setListTotal(getEmploymentsByFilter.total);
        if (showEmptyState) setShowEmptyState(false);
      }

      setRepresentative(getEmploymentsByFilter.representative);

      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error.message);
    }
  });

  const runMultiFilterRepresentativeQuery = (useMultiFilter, useSkip) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }

    getFilteredEmployeeList({
      variables: {
        input: {
          ...postFilters,
          includeTotal: useSkip === 0 ? true : false,
          take: pageSize,
          skip: useSkip,
          onlyRelatedParties: true
        }
      }
    });
  };

  const handleRepresentativeFilterChange = (value, filterType, reset) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterRepresentativeQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runMultiFilterRepresentativeQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const columns = useMemo(
    () => [
      {
        title: "Representative",
        key: "user",
        render: record =>
          record?.firstName
            ? `${record.firstName} ${record.lastName}`
            : `${record.user?.firstName} ${record.user?.lastName}`
      },
      {
        title: "ID Number",
        key: "idNumber",
        render: record => record?.idNumber || record.user?.idNumber
      },
      {
        title: "",
        render: record =>
          record?.user?.idNumber === representative?.idNumber && (
            <div style={{ color: "#48BB78" }}>
              <Icon type="check-circle" />
            </div>
          )
      },
      { title: "Capacity", dataIndex: "capacity.name", key: "capacity" },
      {
        title: "Valid Until",
        key: "dateCreated",
        render: ({ user }) => {
          return user.certificates.length
            ? format(
                user.certificates
                  .map(c => parseISO(c.toDate))
                  .sort(compareDesc)[0],
                "dd-MM-yyyy"
              )
            : "N/A";
        }
      },
      {
        title: <span>Action</span>,
        render: record => {
          const user = record?.user;

          const menu = (
            <Menu>
              <Menu.Item
                className={`ant-dropdown-link purple-link ${!user &&
                  "no-interact"}`}
                onClick={() => history.push(`/admin/member/${user?.id}`)}
              >
                View
              </Menu.Item>

              {user?.idNumber !== representative?.idNumber && (
                <Menu.Item
                  onClick={() =>
                    setNewRepInformation({
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      idNumber: user?.idNumber,
                      id: representative?.id,
                      userId: user?.id,
                      capacityId: record?.capacity?.id || user?.capacity?.id,
                      email: user?.email,
                      phone: user?.phone
                    })
                  }
                >
                  Set as Default User
                </Menu.Item>
              )}
            </Menu>
          );
          return (
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link purple-link"
                onClick={e => e.preventDefault()}
              >
                More
                <Icon type="down" title="member actions dropdown" />
              </a>
            </Dropdown>
          );
        }
      }
    ],
    [representative]
  );

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      {initialising && <TableLoader />}
      {!initialising && showEmptyState && (
        <EmptyState
          location="team"
          headingText="Nothing to see here"
          bodyText="You currently have no related parties. You can create a ticket to our support team to help with any problems you may be facing."
        ></EmptyState>
      )}
      {!showEmptyState && (
        <div className="col-sm-12 col-md-12 placeholder-table-card">
          <Card>
            <div className="card-header">
              <h3>Representative Management</h3>
              <div>
                {MANUAL_REGISTRATION_ROLES.includes(
                  localStorage.getItem("userRoles")
                ) && (
                  <Button
                    onClick={() => {
                      history.push("/admin/team/manual-register");
                    }}
                    className="red-button"
                    style={{ marginRight: "20px" }}
                  >
                    {state.manualRegistrationType
                      ? "Continue Registration"
                      : "Manual Registration"}
                  </Button>
                )}
                <Button
                  style={{ marginRight: "20px" }}
                  className="purple-button"
                  onClick={clearFilters}
                >
                  Clear filters
                </Button>
              </div>
            </div>
            <div className={loading ? "no-interact" : null}>
              {!initialising && (
                <RepresentativeFilters
                  ref={filtersRef}
                  setError={setError}
                  handleRepresentativeFilterChange={
                    handleRepresentativeFilterChange
                  }
                  defaultMultiFilter={defaultMultiFilter}
                  mode={useMode}
                />
              )}
              <Divider />
              <Table
                rowKey="id"
                columns={columns}
                pagination={{
                  current: currentPage,
                  pageSize,
                  onChange: page => handlePagination(page),
                  total: listTotal,
                  showTotal: () => <h3>Total: {listTotal}</h3>
                }}
                dataSource={relatedPartyList}
              />
            </div>
          </Card>
          <CompanyRepModal
            visible={!!newRepInformation}
            newRepInformation={newRepInformation}
            setNewRepInformation={setNewRepInformation}
            onCancel={() => setNewRepInformation(undefined)}
            refresh={clearFilters}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(CompanyRepresentativesList);
