import React, { FC } from "react";
import { Tabs } from "antd";
import EmployeesComponent from "../Admin/Employees/companyEmployees";
import RemovedEmployees from "./RemovedEmployees";
import { useUserState } from "stores/user";

const Employees: FC = () => {
  const companyId = useUserState(s => s.companyId);

  const companyStatus = localStorage.getItem("companyStatus");

  // const showTab = !companyStatus || companyStatus !== "Deregistered";
  const showTab = false;

  return (
    <>
      <div className="employees-header-div">
        <h2>Employee Management</h2>
      </div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Employees" key="1" className="tab-content">
          <EmployeesComponent companyId={companyId} />
        </Tabs.TabPane>
        {showTab && (
          <Tabs.TabPane tab="Removed Employees" key="3" className="tab-content">
            <RemovedEmployees companyId={companyId} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};
export default Employees;
