import React from "react";
import { Table, Badge, Menu, Icon, Dropdown } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { format, max } from "date-fns";

type Props = {
  history: RouteComponentProps["history"];
  companyList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};
export default ({
  companyList,
  history,
  currentPage,
  pageSize,
  listTotal,
  handlePagination
}: Props) => {
  const columns = [
    {
      title: "Company",
      key: "company",
      render: record =>
        (record.registeredName
          ? record.registeredName
          : record.tradingName
        ).toUpperCase()
    },
    {
      title: "# of Users",
      key: "userCount",
      dataIndex: "employmentCount"
    },
    {
      title: "Membership #",
      dataIndex: "membershipNumber",
      key: "membershipNumber"
    },
    {
      title: "Valid Until",
      render: ({ certificates }) => {
        if (!certificates || certificates.length === 0) return "N/A";
        return format(
          max(certificates.map(({ toDate }) => new Date(toDate))),
          "yyyy-MM-dd"
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: record => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        ) : (
          ""
        );
      }
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: record => {
        const items = [
          <Menu.Item
            key="View user profile"
            onClick={() => history.push(`/admin/company/${record.id}`)}
          >
            View company
          </Menu.Item>
        ];

        return (
          <Dropdown
            overlay={<Menu>{items.map(i => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <span className="ant-dropdown-link purple-link">
              More
              <Icon type="down" title="member actions dropdown" />
            </span>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize,
        onChange: page => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>
      }}
      dataSource={companyList}
    />
  );
};
