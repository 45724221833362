import { useCallback, useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router";
import { Filters } from "containers/Admin/WithdrawnEmployment/WithdrawnEmploymentFilters";

const PAGE_SIZE = 20;

export function useWithdrawnEmploymentFilters() {
  const location = useLocation();
  const history = useHistory();
  const [skip, setSkip] = useState<number>(0);

  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ]);

  const companyId = searchParams.get("companyId");
  const userId = searchParams.get("userId");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const pageNumber = searchParams.get("pageNumber") || "1";
  const take = PAGE_SIZE;

  useEffect(() => {
    setSkip((+pageNumber - 1) * PAGE_SIZE);
  }, [pageNumber]);

  const clearFilters = useCallback(() => {
    searchParams.delete("companyId");
    searchParams.delete("userId");
    searchParams.delete("startDate");
    searchParams.delete("endDate");
    searchParams.delete("pageNumber");

    history.replace({
      search: searchParams.toString()
    });
  }, [history, searchParams]);

  const setFilterState = useCallback(
    (filters: Partial<Filters>) => {
      if (filters.companyId) {
        searchParams.set("companyId", filters.companyId);
      }

      if (filters.userId) {
        searchParams.set("userId", filters.userId);
      }

      if (filters.startDate) {
        searchParams.set("startDate", filters.startDate);
      }

      if (filters.endDate) {
        searchParams.set("endDate", filters.endDate);
      }

      if (filters.pageNumber) {
        searchParams.set("pageNumber", filters.pageNumber);
      }

      history.replace({
        search: searchParams.toString()
      });
    },
    [history, searchParams]
  );

  return {
    userId,
    companyId,
    startDate,
    endDate,
    pageNumber: +pageNumber,
    take,
    skip,
    setFilterState,
    clearFilters
  };
}
