import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_USER_FILTERS } from "../../../containers/Admin/graphql/queries";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const TeamFilter = (
  {
    handleMemberFilterChange,
    setSkip,
    setCurrentPage,
    defaultMultiFilter,
    setError
  },
  ref
) => {
  const [userTypes, setUserTypes] = useState([]);
  const userFilterRef = useRef();
  const typeFilterRef = useRef();
  const statusFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: userFilterRef,
          defaultValue: defaultMultiFilter["user"]
        },
        {
          ref: typeFilterRef,
          defaultValue: defaultMultiFilter["type"]
        },
        {
          ref: statusFilterRef,
          defaultValue: defaultMultiFilter["userStatus"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        }
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({
            value: useDefaultValue ? [useDefaultValue] : [],
            inputValue: useDefaultValue !== null ? useDefaultValue : ""
          });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else if (currentRef.select && currentRef.select.rcSelect) {
          let setAutocompleteInputValue =
            useDefaultValue !== null ? useDefaultValue : "";
          currentRef.select.rcSelect.setState({
            value: useDefaultValue ? [useDefaultValue] : [],
            inputValue: setAutocompleteInputValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  useEffect(() => {
    getUserFilters();
  }, []);

  const handleUserFilterUpdate = searchString => {
    handleMemberFilterChange(searchString, "user");
  };

  const debouncedUserFilterUpdate = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  const [getUserFilters] = useLazyQuery(GET_USER_FILTERS, {
    onCompleted: data => {
      let userTypesCollection = data.userFilters.userTypes
        .map(item => item.name)
        .filter(
          x =>
            !["Employee", "Company", "Sole Proprietor", "Super User"].includes(
              x
            )
        );

      setUserTypes(userTypesCollection);
    },
    onError: error => {
      setError(error);
    },
    fetchPolicy: "network-only"
  });

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            User:
            <Input
              ref={userFilterRef}
              id="user-search"
              placeholder="Enter search text"
              onChange={event => debouncedUserFilterUpdate(event.target.value)}
              style={{ width: "calc(100% - 75px)" }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Type:
            <Select
              ref={typeFilterRef}
              placeholder="Select type"
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "type")}
            >
              {userTypes.map(item => (
                <Option key={"user-filter-" + item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Status:
            <Select
              ref={statusFilterRef}
              placeholder="Select team member status"
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "userStatus")}
            >
              <Option value="active">Active</Option>
              <Option value="deregistered">Deregistered</Option>
              <Option value="deactivated">Deactivated</Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            Sort:
            <Select
              ref={sortFilterRef}
              defaultValue={defaultMultiFilter["sort"]}
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "sort")}
            >
              <Option value="new">By newest</Option>
              <Option value="old">By oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(TeamFilter);
