import React from "react";
import { Table, Badge } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { format } from "date-fns";

type Props = {
  history: RouteComponentProps["history"];
  auditList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};
export default ({
  auditList,
  history,
  currentPage,
  pageSize,
  listTotal,
  handlePagination
}: Props) => {
  const columns = [
    {
      title: "Created",
      key: "dateCreated",
      dataIndex: "dateCreated",
      render: record => format(new Date(record), "dd/MM/yyyy")
    },
    {
      title: "Company",
      key: "task.company",
      dataIndex: "task.company",
      render: record =>
        (record.tradingName || record.registeredName).toUpperCase()
    },
    {
      title: "BBEEE Level",
      key: "bbeeeLevel",
      dataIndex: "bbeeeLevel"
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry"
    },
    {
      title: "Geographical Area",
      dataIndex: "geographicalArea",
      key: "geographicalArea"
    },
    {
      title: "Value of Books",
      dataIndex: "valueOfBooks",
      key: "valueOfBooks"
    },
    {
      title: "Amount Recovered",
      dataIndex: "amountRecovered",
      key: "amountRecovered"
    },
    {
      title: "Task ID",
      dataIndex: "task.id",
      key: "task.id"
    },
    {
      title: "Status",
      dataIndex: "task.taskStatus",
      key: "status",
      render: record => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        ) : (
          ""
        );
      }
    }
    // {
    //   title: <span>Action</span>,
    //   key: "action",
    //   render: record => {
    //     const items = [
    //       <Menu.Item
    //         key="View user profile"
    //         onClick={() => history.push(`/admin/company/${record.id}`)}
    //       >
    //         View company
    //       </Menu.Item>
    //     ];

    //     return (
    //       <Dropdown
    //         overlay={<Menu>{items.map(i => i)}</Menu>}
    //         placement="bottomRight"
    //         trigger={["click"]}
    //       >
    //         <span className="ant-dropdown-link purple-link">
    //           More
    //           <Icon type="down" title="member actions dropdown" />
    //         </span>
    //       </Dropdown>
    //     );
    //   }
    // }
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize,
        onChange: page => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>
      }}
      dataSource={auditList}
    />
  );
};
