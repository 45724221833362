import React from "react";
import { Modal, Button } from "antd";
import { withRouter, RouteComponentProps } from "react-router";

export interface EmployeeInvitationModalProps extends RouteComponentProps<any> {
  visible: boolean;
  employeeInvitationCode: string;
  setEmployeeHelperVisible: (boolean) => void;
}

const EmployeeInvitationModal = ({
  visible,
  employeeInvitationCode,
  history,
  setEmployeeHelperVisible
}: EmployeeInvitationModalProps) => {
  return (
    <>
      <Modal visible={visible} title={"Employee Invitation"} footer={null}>
        <span>
          Please either sign up or log in to accept an employment invitation.
        </span>
        <div style={{ textAlign: "center" }}>
          <br />
          <Button
            type="primary"
            className="btn-register"
            onClick={() => setEmployeeHelperVisible(false)}
          >
            Sign Up
          </Button>
          <br />
          <br />
          <Button
            type="primary"
            className="btn-register"
            onClick={() =>
              history.push(
                `/login/employee-invitation/${employeeInvitationCode}`
              )
            }
          >
            Log In
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(EmployeeInvitationModal);
