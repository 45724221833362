import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Icon,
  Spin,
  Collapse,
  Table,
  Upload,
  Badge,
  message
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import UserComplaint from "./SubComponents/UserComplaint";
import "./index.css";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import {
  UPDATE_TASK,
  UPDATE_COMPLAINT,
  CREATE_PROSECUTION_RECOMMENDATION,
  GENERATE_NOTICE_FILE,
  CREATE_ACTIVITY,
  CREATE_DOCUMENT
} from "graphql/mutations";
import {
  GET_TASK_ACTIVITIES,
  GET_COMPLAINT,
  GET_PROSECUTION_APPEARANCES,
  GET_DOWNLOAD_URL,
  GET_PRESIGNED_URL
} from "graphql/queries";
import fileIcon from "../../../assets/images/pdf_icon.png";
import { REGULATION_7_4_NOTICE, TASK_STATUSES } from "../../../constants";
import { openNotificationWithIcon } from "utils/notification";
import PermissionsGuard from "../../../components/Auth/can";
import ComplaintLoader from "./SubComponents/complaintLoader";
import SupportingDocuments from "./SubComponents/supportingDocuments";
import ResolveComplaint from "./SubComponents/resolveComplaint";
import Prosecution from "./SubComponents/prosecution";
import SubmitToClient from "./SubComponents/submitToClient";
import ProsecutionAppearance from "./SubComponents/upsertAppearance";
import HearingOutcome from "./SubComponents/hearingOutcome";
import { uploadToS3 } from "../../../utils/uploadToS3";
import InitiateProsecution from "./SubComponents/InitiateProsecutionModal";
import Recommendation from "./SubComponents/Recommendation";
import { useGlobalState } from "../../../globalStore";
import DebtCollectorResponse from "./SubComponents/DebtCollectorResponse";
import { isAdmin } from "utils";

const ComplaintReviewForm = ({ location, form, history }) => {
  const { state } = location;
  const { state: globalState } = useGlobalState();
  const isComplaintAdmin = localStorage.getItem("roleStream") === "Legal";
  const [visible, setVisible] = useState(false);
  const [resolveComplaint, setResolveComplaint] = useState(false);
  const [complaint, setComplaint] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [companyContacts, setCompanyContacts] = useState([]);
  const [legalContact, setLegalContact] = useState<any>();
  const [activityList, setActivityList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [templateGenerated, setTemplateGenerated] = useState(false);
  const [noticeSent, setNoticeSent] = useState(false);
  const [prosecutionNoticeSent, setProsecutionNoticeSent] = useState(false);
  const [templateUrl, setTemplateUrl] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taskStatus, setTaskStatus] = useState<string | undefined>(undefined);
  const [appearances, setAppearances] = useState([]);
  const [updateAppearance, setUpdateAppearance] = useState(false);
  const [selectedAppearance, setSelectedAppearance] = useState<any>();
  const [employment, setEmployment] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [initiateProsecutionVisible, setInitiateProsecutionVisible] = useState(
    false
  );

  const userRole = localStorage.getItem("roleStream");
  const userId = +localStorage.getItem("userId");

  const isSuperUser = userRole === "Super User";

  const [createActivity] = useMutation(CREATE_ACTIVITY);

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [initiateProsecution] = useMutation(CREATE_PROSECUTION_RECOMMENDATION);

  const { refetch: getPresignedUrl } = useQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "no-cache",
    skip: true
  });

  const [generateTemplate] = useMutation(GENERATE_NOTICE_FILE, {
    onError: () => {
      setSubmitting(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "The was an error generating document"
      );
    },
    onCompleted: async data => {
      try {
        setSubmitting(false);
        setTemplateGenerated(true);
        setTemplateUrl(data.generateNoticeDocument);
        await updateTask({
          variables: {
            input: {
              id: state?.id,
              taskStatus: TASK_STATUSES.underInvestigation,
              assignedToId: userId
            }
          }
        });
        await updateComplaint({
          variables: {
            input: {
              id: complaint?.id,
              noticeDocumentGenerated: true
            }
          }
        });
        getActivityList({
          variables: { input: { take: 50, taskId: state?.id } }
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  });

  const [getAppearances] = useLazyQuery(GET_PROSECUTION_APPEARANCES, {
    fetchPolicy: "no-cache",
    onCompleted: data => setAppearances(data.prosecutionAppearances),
    onError: () => {
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error loading appearances"
      );
    }
  });

  const [getActivityList, { loading: loadingActivity }] = useLazyQuery(
    GET_TASK_ACTIVITIES,
    {
      fetchPolicy: "no-cache",
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Load Error",
          "There was an error loading activities"
        );
      },
      onCompleted: data => {
        if (!isEmpty(data.activities?.activityList)) {
          setActivityList(data.activities?.activityList);
        }
      }
    }
  );

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onCompleted: ({ preSignedDownloadUrl }) => {
      setSubmitting(false);
      window.open(preSignedDownloadUrl);
      if (preSignedDownloadUrl?.includes(REGULATION_7_4_NOTICE)) {
        setTemplateUrl(preSignedDownloadUrl);
      }
    }
  });

  const [getComplaint, { loading: loadingComplaint }] = useLazyQuery(
    GET_COMPLAINT,
    {
      fetchPolicy: "no-cache",
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Error",
          "Error when getting complaint details"
        );
      },
      onCompleted: data => {
        if (data) {
          if (data.complaint.debtCollector?.employment) {
            let matchedEmployment = data.complaint.debtCollector.employment.filter(
              employment => employment.company.id === data.complaint.company.id
            );
            setEmployment(matchedEmployment);
          }
          setComplaint(data.complaint);
          setNoticeSent(data.complaint?.noticeEmailSent);

          if (data.complaint?.noticeDocumentGenerated) {
            setTemplateGenerated(true);
          }

          const company = data.complaint.company;
          setCompany(company);

          if (company) {
            const legal = company.contacts
              .filter(x => x.businessUnit.name === "Legal")
              .map(x => ({
                id: x.id,
                contactName: x.businessUnitContactName,
                contactEmail: x?.email,
                contactCellphone: x?.cellphone,
                businessUnit: x.businessUnit.name
              }));

            setLegalContact(legal[0]);
            setCompanyContacts(legal);

            let name: string = company.tradingName || company.registeredName;
            if (name.includes("UNREGISTERED")) {
              name += ` (${data?.complaint?.debtCollectorCompany})`;
            }
            setCompanyName(name);

            if (data.complaint?.prosecution) {
              setProsecutionNoticeSent(
                data.complaint.prosecution.noticeEmailSent
              );
              setAppearances(
                data.complaint?.prosecution?.prosecutionAppearances
              );
            }
          }
        }
      }
    }
  );

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: data => {
      if (!isEmpty(data.updateTask)) {
        setTaskStatus(data.updateTask.taskStatus);
      }
    }
  });

  const [updateComplaint] = useMutation(UPDATE_COMPLAINT, {
    onCompleted: () => {
      refreshComplaint();
    }
  });

  const handleUpdateContact = (field: string, value: string, id: number) => {
    const contacts = [...companyContacts];
    contacts[contacts.findIndex(c => c.id === id)][field] = value;
    setCompanyContacts(contacts);
  };

  const handleGenerateTemplate = async () => {
    setSubmitting(true);
    await generateTemplate({
      variables: {
        complaintId: state?.complaint?.id,
        companyId: company?.id,
        template: REGULATION_7_4_NOTICE
      }
    });
  };

  const handleViewTemplate = () => {
    if (!isEmpty(templateUrl)) window.open(templateUrl);
    else {
      setSubmitting(true);
      getDownloadUrl({
        variables: {
          input: {
            companyName: companyName,
            filePath: `${company?.id}/Complaints/Complaint_${complaint.id}/${REGULATION_7_4_NOTICE}.pdf`,
            forCompany: true,
            userId: complaint.debtCollector?.id
          }
        }
      });
    }
  };

  const uploadDocument = async (
    file: File,
    filePath: string,
    docTypeId: number,
    companyName: string,
    companyId: number,
    taskId?: number
  ) => {
    try {
      setUploading(true);
      const fileName = filePath
        .split("/")
        .pop()
        ?.toUpperCase();

      const { data, errors } = await getPresignedUrl({
        input: {
          filePath,
          contentType: file.type,
          companyName,
          forCompany: true,
          userId
        }
      });

      if (errors && errors.length) throw new Error(errors[0].message);

      await uploadToS3(data.preSignedUrl, file.type, file);

      const { data: document } = await createDocument({
        variables: {
          input: {
            documentName: fileName,
            documentPath: filePath,
            documentTypeId: docTypeId,
            documentStatusId: 2,
            standardDescription: fileName,
            altDescription: "",
            documentFormat: file.type,
            userId,
            ...(taskId && { taskId }),
            companyId,
            forCompany: true
          }
        }
      });

      message.success(`${file.name} uploaded successfully`);

      await updateComplaint({
        variables: {
          input: {
            id: complaint.id,
            documentIds: [document.createDocument.id]
          }
        }
      });

      await createActivity({
        variables: {
          input: {
            taskId: state.id,
            userId,
            description: `${fileName} uploaded`
          }
        }
      });

      refreshActivities();
    } catch (err) {
      console.error("upload document error", err);
      openNotificationWithIcon(
        "error",
        "Upload Error",
        "Error occurred during upload"
      );
    } finally {
      setUploading(false);
    }
  };

  const uploadNoticeDocument = async ({ file, onSuccess }) => {
    await uploadDocument(
      file,
      `${company.id}/Complaints/Complaint_${
        complaint.id
      }/${REGULATION_7_4_NOTICE}.${file.type.split("/").pop()}`,
      globalState.lookups?.documentTypes?.find(
        t => t.name === "Form 7(4) Notice"
      )?.id,
      companyName,
      company?.id,
      state?.id
    );
    await updateComplaint({
      variables: {
        input: {
          id: complaint?.id,
          noticeDocumentGenerated: true
        }
      }
    });
    setTemplateGenerated(true);
    await updateTask({
      variables: {
        input: {
          id: state.id,
          taskStatus: TASK_STATUSES.underInvestigation,
          assignedToId: userId
        }
      }
    });
    onSuccess();
  };

  const uploadAdditionalDocument = async ({ file, onSuccess }) => {
    await uploadDocument(
      file,
      `${company.id}/Complaints/Complaint_${complaint.id}/Supporting_Documents/${file.name}`,
      globalState.lookups?.documentTypes?.find(
        t => t.name === "Supporting Documents" && t.umbrella === "Complaints"
      )?.id,
      companyName,
      company?.id,
      state?.id
    );
    onSuccess();
  };

  const refreshActivities = () => {
    getActivityList({
      variables: {
        input: {
          take: 50,
          taskId: state?.id
        }
      }
    });
  };

  const refreshComplaint = () => {
    getComplaint({ variables: { id: state?.complaint?.id } });
  };

  useEffect(() => {
    if (isEmpty(state)) {
      history.push(isAdmin() ? "/admin/tasks" : "/tasks");
    }
    getActivityList({ variables: { input: { take: 50, taskId: state?.id } } });
    getComplaint({ variables: { id: state?.complaint?.id } });
  }, []);

  return (
    <>
      {resolveComplaint && (
        <ResolveComplaint
          show={resolveComplaint}
          taskId={state?.id}
          complaint={complaint}
          updateVisible={(val: boolean) => setResolveComplaint(val)}
          appearanceId={appearances?.[appearances.length - 1]?.id}
          verdict={appearances?.[appearances.length - 1]?.hearingVerdict}
        />
      )}
      {loadingComplaint && !complaint && <ComplaintLoader />}
      {complaint && (
        <div style={{ margin: "1vw" }}>
          <Card className="information-card" key={`Address ${1}`}>
            <div className="flex-column information-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h2>Task {state?.id}</h2>
                <h3>
                  Status: <a>{taskStatus || state?.taskStatus}</a>
                </h3>
              </div>

              <br />
              <span>
                Task Type: <label>{state?.type?.name}</label>
                <br />
                Date Created:{" "}
                <label>{moment(state?.createdDate).format("DD-MM-YYYY")}</label>
                <br />
                Created By:{" "}
                <label>
                  <a
                    className={!isComplaintAdmin ? "no-interact" : null}
                    onClick={() => setVisible(true)}
                  >
                    {`${state?.complaint?.firstName} ${state?.complaint?.lastName}`}
                  </a>
                </label>
                <br />
                <div className="flex-row">
                  <div>
                    Last Modified:{" "}
                    <label>
                      {state?.modifiedDate
                        ? moment(state?.modifiedDate).format("DD-MM-YYYY")
                        : ""}
                    </label>
                  </div>
                  <PermissionsGuard
                    perform={"task:resolve"}
                    yes={() => (
                      <div>
                        {["Complete", "Resolved"].includes(
                          state?.taskStatus
                        ) ? (
                          <Button
                            className="purple-button"
                            disabled={!isSuperUser}
                            onClick={() => {
                              updateTask({
                                variables: {
                                  input: {
                                    id: state.id,
                                    taskStatus: complaint.prosecuted
                                      ? TASK_STATUSES.prosecutionResolved
                                      : TASK_STATUSES.resolved
                                  }
                                }
                              });
                            }}
                          >
                            Reopen Task
                          </Button>
                        ) : (
                          <Button
                            className="purple-button"
                            onClick={() => setResolveComplaint(true)}
                          >
                            Resolve Task
                          </Button>
                        )}
                      </div>
                    )}
                  />
                </div>
              </span>
            </div>
          </Card>
          <br />
          <div className="flex-row">
            <div className="flex-column" style={{ width: "63%" }}>
              <Card className="information-card">
                <Collapse defaultActiveKey={[1, 2, 3, 4, 5]}>
                  <Collapse.Panel
                    header="Complaint"
                    key="1"
                    className="flex-column information-block"
                  >
                    <span>
                      <h3>Company Details:</h3>
                      Company name: <strong>{companyName}</strong>
                      <br />
                      Company status:{" "}
                      <strong>{complaint?.company?.status || "Unknown"}</strong>
                      <br />
                      Complaint Description:{" "}
                      <strong>{complaint?.description}</strong>
                      <br />
                      <br />
                      {isComplaintAdmin && (
                        <SupportingDocuments
                          taskId={state?.id}
                          debtCollectorId={complaint?.debtCollector?.id}
                          complaintId={complaint?.id}
                          documentTypeId={
                            globalState.lookups?.documentTypes?.find(
                              t =>
                                t.name === "Supporting Documents" &&
                                t.umbrella === "Complaints"
                            )?.id
                          }
                          companyNam={companyName}
                          mode={"consumer"}
                          getDownloadUrl={async input =>
                            getDownloadUrl({ variables: { input } })
                          }
                        />
                      )}
                      <br />
                    </span>

                    {complaint?.debtCollector?.employment &&
                      !submitting &&
                      isEmpty(employment) && (
                        <>
                          <div
                            style={{
                              background: "#d79b2d",
                              width: "100%",
                              padding: "20px",
                              borderRadius: "10px",
                              color: "#fff"
                            }}
                          >
                            The user that this complaint was filed against does
                            not currently exist under the company{" "}
                            <b>{companyName}</b>. However the user does exist
                            under the following companies listed below:
                            <ul>
                              {complaint?.debtCollector?.employment.map(
                                employment => (
                                  <li>
                                    {employment.company.registeredName ||
                                      employment.company.tradingName}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <br />
                        </>
                      )}

                    {isComplaintAdmin && (
                      <Upload
                        disabled={uploading}
                        showUploadList={false}
                        customRequest={uploadAdditionalDocument}
                      >
                        <Button loading={uploading} className="purple-button">
                          <Icon type="upload" />
                          Upload Additional Document
                        </Button>
                      </Upload>
                    )}
                    <br />
                    <br />
                    <h3>Regulation 7(4) Document</h3>
                    {templateGenerated ? (
                      <div>
                        <span>
                          <img
                            style={{ height: "17px", width: "17px" }}
                            src={fileIcon}
                          />
                          &nbsp; &nbsp;
                          {REGULATION_7_4_NOTICE}
                          <Button
                            type="link"
                            loading={submitting}
                            onClick={handleViewTemplate}
                          >
                            View
                          </Button>
                        </span>
                        <br />
                      </div>
                    ) : (
                      <>
                        {isComplaintAdmin && (
                          <div style={{ display: "flex" }}>
                            <Button
                              className="purple-button"
                              loading={submitting}
                              disabled={isEmpty(company) ? true : false}
                              onClick={handleGenerateTemplate}
                            >
                              Generate PDF
                            </Button>
                            <Upload
                              accept=".pdf,.PDF"
                              showUploadList={false}
                              customRequest={uploadNoticeDocument}
                              disabled={uploading}
                            >
                              <Button
                                loading={uploading}
                                className="purple-button"
                              >
                                <Icon type="upload" /> Upload PDF
                              </Button>
                            </Upload>
                          </div>
                        )}
                      </>
                    )}

                    {!noticeSent && (
                      <span>
                        <p style={{ fontSize: "14px" }}>
                          Please confirm the company details below for
                          submission of the complaint to them.
                        </p>
                      </span>
                    )}
                    <br />

                    {noticeSent && !isComplaintAdmin && (
                      <div>
                        <br />
                        <h4>Details sent to: </h4>
                        <span>
                          Business Unit:{" "}
                          <strong>{legalContact?.businessUnit}</strong>
                        </span>
                        <br />
                        <span>
                          Business contact name:{" "}
                          <strong>{legalContact?.contactName}</strong>
                        </span>
                        <br />
                        <span>
                          Contact type: <strong>{"Email"}</strong>
                        </span>
                        <br />
                        <span>
                          Details: <strong>{legalContact?.contactEmail}</strong>
                        </span>
                        <br />
                      </div>
                    )}
                  </Collapse.Panel>
                  {noticeSent && (
                    <Collapse.Panel
                      header="Debt Collector Response"
                      key="2"
                      className="flex-column information-block"
                    >
                      <DebtCollectorResponse
                        complaint={complaint}
                        createActivity={createActivity}
                        loading={false}
                        userId={userId}
                        setInitiateProsecution={setInitiateProsecutionVisible}
                        setResolveComplaint={setResolveComplaint}
                        isComplaintAdmin={isComplaintAdmin}
                        refreshComplaint={refreshComplaint}
                      />
                      {/* <div>
                        {!supportingDocsUploaded && isComplaintAdmin && (
                          <>
                            <span>
                              Notice has been sent.
                              <br /> Awaiting reply from Debt Collector.
                            </span>
                          </>
                        )}

                        <div>
                          <br />
                          <span>Supporting Documentation/communication:</span>
                          {!supportingDocsUploaded && !isComplaintAdmin && (
                            <DocumentUploader
                              taskId={state?.id}
                              debtCollectorId={complaint?.debtCollector?.id}
                              documentTypeId={
                                globalState.lookups?.documentTypes?.find(
                                  t => t.name === "Supporting Documents"
                                )?.id
                              }
                              companyName={companyName}
                              companyId={company?.id}
                              complaintId={complaint?.id}
                              updateComplaint={values =>
                                updateComplaint({
                                  variables: { input: values }
                                })
                              }
                              updateActivity={refreshActivities}
                            />
                          )}
                          <br />
                          {supportingDocsUploaded && (
                            <SupportingDocuments
                              taskId={state?.id}
                              debtCollectorId={complaint?.debtCollector?.id}
                              complaintId={complaint?.id}
                              documentTypeId={
                                globalState.lookups?.documentTypes?.find(
                                  t => t.name === "Supporting Documents"
                                )?.id
                              }
                              companyName={companyName}
                              getDownloadUrl={async input =>
                                getDownloadUrl({ variables: { input } })
                              }
                            />
                          )}
                        </div>

                        {supportingDocsUploaded && isComplaintAdmin && (
                          <>
                            <Divider />
                            <PermissionsGuard
                              perform={"complaint:prosecute"}
                              yes={() => (
                                <div>
                                  <span>
                                    If action is to{" "}
                                    <strong>initiatiate prosecution</strong>{" "}
                                    then proceed to the next step. If action is
                                    to not prosecute, then resolve this task{" "}
                                  </span>

                                  <div>
                                    <br />
                                    <Button
                                      onClick={() => setResolveComplaint(true)}
                                    >
                                      Resolve & Close Task
                                    </Button>
                                    <Button
                                      className="purple-button"
                                      loading={submitting}
                                      onClick={() =>
                                        setInitiateProsecutionVisible(true)
                                      }
                                      disabled={complaint?.recommendation}
                                    >
                                      {complaint?.recommendation === null
                                        ? "Awaiting Approval"
                                        : "Initiate Prosecution"}
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </>
                        )}
                        {!complaint?.recommendation?.approved &&
                          isComplaintAdmin && (
                            <h4>
                              The complaint has been sent to the Super Admin for
                              Approval.
                            </h4>
                          )}
                      </div> */}
                    </Collapse.Panel>
                  )}
                  {(isComplaintAdmin || isSuperUser) &&
                    complaint?.recommendation && (
                      <Collapse.Panel
                        header="Recommendation"
                        key="3"
                        className="flex-column information-block"
                      >
                        <Recommendation
                          complaintId={complaint?.id}
                          userId={userId}
                          recommendationId={complaint?.recommendation?.id}
                          refreshActivities={refreshActivities}
                          refreshComplaint={refreshComplaint}
                          taskId={state?.id}
                          companyId={company?.id}
                        />
                      </Collapse.Panel>
                    )}
                  {complaint?.recommendation?.approved && (
                    <Collapse.Panel
                      header="Prosecution"
                      key="3"
                      className="flex-column information-block"
                    >
                      <Prosecution
                        taskId={state?.id}
                        complaint={complaint}
                        documentTypes={globalState.lookups?.documentTypes?.filter(
                          t => t.umbrella === "Complaints"
                        )}
                        companyContacts={companyContacts}
                        companyId={company?.id}
                        form={form}
                        getDownloadUrl={async input =>
                          getDownloadUrl({ variables: { input } })
                        }
                        updateActivity={refreshActivities}
                        handleUpdateContact={handleUpdateContact}
                        uploadDocument={uploadDocument}
                        companyName={companyName}
                        refreshComplaint={refreshComplaint}
                      />
                    </Collapse.Panel>
                  )}
                  {prosecutionNoticeSent && (
                    <Collapse.Panel
                      header="Appearance"
                      key="4"
                      className="flex-column information-block"
                    >
                      <Table
                        columns={[
                          {
                            title: "Date of Appearance",
                            dataIndex: "dateOfAppearance",
                            key: "dateOfAppearance"
                          },
                          {
                            title: "Time of Appearance",
                            dataIndex: "timeOfAppearance",
                            key: "timeOfAppearance"
                          },
                          {
                            title: "Place of Appearance",
                            dataIndex: "placeOfAppearance",
                            key: "placeOfAppearance"
                          },
                          {
                            title: "Status",
                            dataIndex: "appearanceStatus",
                            key: "appearanceStatus",
                            render: record => {
                              let useStatus:
                                | "success"
                                | "processing"
                                | "default"
                                | "error"
                                | "warning" = "success";
                              if (record === "Upcoming") {
                                useStatus = "warning";
                              } else if (record === "No Show") {
                                useStatus = "error";
                              }
                              return record ? (
                                <span style={{ display: "flex" }}>
                                  <Badge status={useStatus} /> {record}
                                </span>
                              ) : (
                                ""
                              );
                            }
                          },
                          {
                            title: <span>Action</span>,
                            dataIndex: "",
                            render: record => {
                              return (
                                <>
                                  {isComplaintAdmin && (
                                    <Button
                                      type="link"
                                      className={`purple-link ${record?.appearanceStatus !==
                                        "Upcoming" && "no-interact"}`}
                                      onClick={() => {
                                        setUpdateAppearance(true);
                                        setSelectedAppearance(record);
                                      }}
                                    >
                                      Complete
                                    </Button>
                                  )}
                                </>
                              );
                            }
                          }
                        ]}
                        rowKey="appearanceRecord"
                        pagination={false}
                        dataSource={appearances}
                      />
                      {updateAppearance && (
                        <ProsecutionAppearance
                          show={updateAppearance}
                          appearanceId={selectedAppearance?.id}
                          prosecutionId={complaint?.prosecution?.id}
                          companyContacts={companyContacts}
                          taskId={state?.id}
                          complaint={complaint}
                          form={form}
                          updateVisible={(val: boolean, refresh: boolean) => {
                            setUpdateAppearance(val);
                            if (refresh)
                              getAppearances({
                                variables: {
                                  prosecutionId: complaint?.prosecution?.id
                                }
                              });
                          }}
                        />
                      )}
                    </Collapse.Panel>
                  )}
                  {appearances &&
                    appearances[appearances.length - 1]?.appearanceStatus ===
                      "Complete" && (
                      <Collapse.Panel
                        header="Hearing Outcome"
                        key="5"
                        className="flex-column information-block"
                      >
                        <HearingOutcome
                          form={form}
                          debtCollectorId={complaint?.debtCollector?.id}
                          companyId={company?.id}
                          complaint={complaint}
                          complaintId={complaint?.id}
                          taskId={state?.id}
                          taskStatus={state?.taskStatus}
                          appearance={appearances[appearances.length - 1]}
                          isComplaintAdmin={isComplaintAdmin}
                          setResolveComplaint={setResolveComplaint}
                          companyName={companyName}
                          refreshAppearance={() => {
                            getAppearances({
                              variables: {
                                prosecutionId: complaint?.prosecution?.id
                              }
                            });
                          }}
                        />
                      </Collapse.Panel>
                    )}
                </Collapse>
              </Card>
              <br />
              {!noticeSent && templateGenerated && (
                <SubmitToClient
                  companyContacts={companyContacts}
                  taskId={state?.id}
                  complaint={complaint}
                  setNoticeSent={(val: boolean) => setNoticeSent(val)}
                  handleUpdateContact={handleUpdateContact}
                  updateActivity={refreshActivities}
                  refreshComplaint={refreshComplaint}
                />
              )}
            </div>
            <div style={{ width: "35%" }}>
              <Card
                title={
                  <div className="flex-row">
                    <h4>Latest Activity</h4>
                    <span>
                      Message{" "}
                      <span className="icon-dot">
                        <Icon type="message" />
                      </span>
                    </span>
                  </div>
                }
              >
                {loadingActivity && <Spin />}
                {activityList.map(act => {
                  return (
                    <>
                      <div
                        className="inline"
                        style={{ height: "auto", overflowY: "auto" }}
                      >
                        <div>
                          <span className="activity-icon-dot">
                            <Icon
                              type={
                                act.description.includes("Email")
                                  ? "mail"
                                  : act.description.includes("template")
                                  ? "file"
                                  : "hourglass"
                              }
                            />
                          </span>
                        </div>
                        <div>
                          <span>{act.description}</span>&nbsp;
                          <span>
                            {"by"} {act?.user?.firstName} {act?.user?.lastName}
                          </span>
                          <br />
                          <div style={{ color: "#CCC" }}>
                            <span>
                              {moment(act?.dateCreated).format("YYYY-MM-DD")}
                            </span>{" "}
                            <span className="dot"></span>{" "}
                            <span>
                              {moment(act?.dateCreated).format("hh:mm a")}
                            </span>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </>
                  );
                })}
              </Card>
            </div>
          </div>
        </div>
      )}
      <UserComplaint
        initialData={state?.complaint}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <InitiateProsecution
        initiateProsecution={initiateProsecution}
        complaintId={complaint?.id}
        visible={initiateProsecutionVisible}
        onCancel={() => setInitiateProsecutionVisible(false)}
        refreshComplaint={refreshComplaint}
        updateTask={() => {
          return updateTask({
            variables: {
              input: {
                id: state?.id,
                priorityStatus: "High",
                assignedToId: 1
              }
            }
          });
        }}
      />
    </>
  );
};

const ComplaintReview = Form.create({ name: "normal_register" })(
  ComplaintReviewForm
);
export default ComplaintReview;
