import React, { useState } from "react";
import { Modal, Spin, Select, DatePicker } from "antd";
import { useMutation } from "@apollo/react-hooks";
import {
  notifyError,
  openNotificationWithIcon
} from "../../utils/notification";
import { UPDATE_CERTIFICATE } from "../../containers/Admin/graphql/mutations";

type Props = {
  data?: any;
  companyData?: any;
  visible: boolean;
  onCancel: () => void;
  isCompany: boolean;
  refreshList: () => void;
};

const ChangeCertificateDates = ({
  visible,
  onCancel,
  data,
  isCompany,
  refreshList
}: Props) => {
  const [toDate, setToDate] = useState<string>();
  const [fromDate, setFromDate] = useState<string>();
  const [certificateId, setCertificateId] = useState<number>();

  const [updateCertificate, { loading }] = useMutation(UPDATE_CERTIFICATE);

  const handleChange = async () => {
    if (!certificateId) {
      return openNotificationWithIcon(
        "error",
        "Error",
        "Please select the certificate to update"
      );
    }

    if (!fromDate) {
      return openNotificationWithIcon(
        "error",
        "Error",
        "Please select the From-Date"
      );
    }

    if (!toDate) {
      return openNotificationWithIcon(
        "error",
        "Error",
        "Please select the To-Date"
      );
    }

    try {
      await updateCertificate({
        variables: {
          input: { id: certificateId, fromDate, toDate }
        }
      });
      onCancel();
      refreshList();
      openNotificationWithIcon(
        "success",
        "Success",
        "Successfully updated certificate dates."
      );
    } catch (err) {
      notifyError(err);
    }
  };

  const options = isCompany
    ? data?.certificates?.map(c => (
        <Select.Option key={c.id} value={c.id}>
          {`${data?.registeredName ??
            data?.tradingName} (${c.fromDate?.substring(
            0,
            10
          )} - ${c.toDate?.substring(0, 10)}) ${c.active ? "Active" : ""}`}
        </Select.Option>
      ))
    : data?.certificates?.map(c => (
        <Select.Option key={c.id} value={c.id}>
          {`${
            data?.employment?.find(e => e.id === c?.debtCollectorEmployment?.id)
              ?.company?.registeredName
          } (${c.fromDate?.substring(0, 10)} - ${c.toDate?.substring(0, 10)}) ${
            c.active ? "Active" : ""
          }`}
        </Select.Option>
      ));

  return (
    <Modal
      visible={visible}
      title="Change Certificate Dates"
      okText="Change"
      onCancel={onCancel}
      onOk={handleChange}
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        className: "purple-button",
        disabled: loading
      }}
      okType="default"
    >
      <Spin spinning={loading}>
        <h4 className="change-dates-headings">Certificate</h4>
        <Select
          className="input-select"
          placeholder="Select a Certificate"
          onChange={(val: number) => setCertificateId(val)}
        >
          {options}
        </Select>

        <div className="change-dates-date-container">
          <div className="change-dates-date-wrapper">
            <h4 className="change-dates-headings">From Date</h4>
            <DatePicker
              className=""
              onChange={v => setFromDate(v.format("YYYY-MM-DD"))}
            />
          </div>
          <div className="change-dates-date-wrapper">
            <h4 className="change-dates-headings">To Date</h4>
            <DatePicker
              className=""
              onChange={v => setToDate(v.format("YYYY-MM-DD"))}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ChangeCertificateDates;
