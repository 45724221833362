import React, { useEffect } from "react";
import { Tabs, Icon } from "antd";
import NewTasks from "./newTasks";
import MemberTasks from "./memberTasks";
import ComplaintTasks from "./complaintTasks";
import RenewalTasks from "./RenewalTasks";
import AuditTasks from "./AuditTasks";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";
import { useGlobalState } from "../../../globalStore";
import "./index.css";
import { useFilterState } from "contexts/AdminFilterContext";
import BatchRegistrationList from "./Batch/BatchRegistrations";
import { useBatchFilters } from "hooks/UseBatchFilters";

function Tasks() {
  const { clearFilters } = useBatchFilters();
  const { state } = useGlobalState();
  const {
    filters: { tabKey },
    saveTabKey
  } = useFilterState();

  useEffect(() => {
    if (tabKey !== "6") {
      clearFilters();
    }
  }, [tabKey]);

  return (
    <PermissionsGuard
      perform={"tasks:view"}
      yes={() => (
        <>
          <div className="tasks-header-div">
            <h2>Tasks</h2>
          </div>
          <Tabs
            activeKey={tabKey}
            onChange={activeKey => {
              saveTabKey(activeKey);
            }}
            tabPosition="top"
          >
            <Tabs.TabPane
              tab={<span>New Tasks</span>}
              key="1"
              className="tab-content"
            >
              <NewTasks />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  My Tasks{" "}
                  {(state.lookups?.assignedTaskCount || 0) > 0 && (
                    <Icon
                      type="exclamation-circle"
                      theme="filled"
                      style={{ color: "red", fontSize: "15px" }}
                    />
                  )}
                </span>
              }
              key="2"
              className="tab-content"
            >
              <MemberTasks
                memberId={Number.parseInt(localStorage.getItem("userId"))}
                mode="my-tasks"
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Complaint Tasks" key="3" className="tab-content">
              <ComplaintTasks
                memberId={Number.parseInt(localStorage.getItem("userId"))}
                mode="my-tasks"
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Renewal Tasks" key="4" className="tab-content">
              <RenewalTasks />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Audit Tasks" key="5" className="tab-content">
              <AuditTasks />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Batch Registrations"
              key="6"
              className="tab-content"
            >
              <BatchRegistrationList />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
      no={() => <AccessDenied permission="tasks:view" />}
    />
  );
}

export default Tasks;
