import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import AWaitingConfirmation from "./awaitingCofirmation";
import MemberProfile from "../Admin/Members/MemberOverview/profile";
import { APPLICATION_VERIFIED, UNEMPLOYED_COMPANY_ID } from "../../constants";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../Admin/graphql/queries";
import "./index.css";

const Profile = ({}) => {
  const memberId = Number(localStorage.getItem("userId"));
  const applicationStatuses = localStorage
    .getItem("applicationStatus")
    .split(",");
  const isApplicationVerified = applicationStatuses.every(
    value => value === APPLICATION_VERIFIED
  );
  const [useMemberOverviewData, setMemberOverviewData] = useState<any>({});
  const [useCompanyId, setCompanyId] = useState(null);
  const [useCompanyInformation, setCompanyInformation] = useState(null);
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: memberId
      },
      onCompleted: data => {
        if (!isApplicationVerified) localStorage.setItem("companyId", "-1");

        setMemberOverviewData(data);
        const employments = data?.userData?.employment.filter(
          e => e.company?.id != UNEMPLOYED_COMPANY_ID
        );

        if (
          employments.length > 0 &&
          typeof employments[0]?.company?.id === "number"
        ) {
          setCompanyId(employments[0].company.id);
          setCompanyInformation(employments[0]);
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      {!isApplicationVerified && <AWaitingConfirmation />}
      {isApplicationVerified && (
        <MemberProfile
          profileData={useMemberOverviewData}
          companyId={useCompanyId}
          companyInformation={useCompanyInformation}
          refetchProfile={fetchMemberOverviewInformation}
          employments={[useCompanyInformation]}
        />
      )}
    </>
  );
};

export default Profile;
