import React from "react";
import { Button, Result } from "antd";

export function InvalidTask() {
  return (
    <Result
      status="warning"
      title="Invalid task review"
      subTitle="Please go back to tasks page and select a task to review"
      extra={
        <Button type="default" className="btn-add-comment">
          <a href="/admin/tasks">Go To Tasks</a>
        </Button>
      }
    />
  );
}
