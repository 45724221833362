import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Select, DatePicker } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { useWithdrawnEmploymentFilters } from "hooks/UseWithdrawnEmploymentFilters";
import { useLazyQuery } from "react-apollo";
import { SEARCH_COMPANIES, SEARCH_USERS } from "../graphql/queries";
import { notifyError } from "utils/notification";

export type FilterKey =
  | "companyId"
  | "userId"
  | "startDate"
  | "endDate"
  | "pageNumber"
  | "pageSize";

export type Filters = Record<FilterKey, string>;

type BatchFiltersProps = {
  loading?: boolean;
};

const empty = [{ id: "empty", name: "No results found" }];

export function WithdrawnEmploymentFilters({ loading }: BatchFiltersProps) {
  const [companySearchText, setCompanySearchText] = useState("");
  const [userSearchText, setUserSearchText] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [userList, setUserList] = useState([]);

  const {
    companyId = undefined,
    userId = undefined,
    startDate,
    endDate,
    setFilterState
  } = useWithdrawnEmploymentFilters();

  const handleCompanySearch = (txt: string) => {
    setCompanySearchText(txt);
  };

  const handleUserFilterUpdate = (txt: string) => {
    setUserSearchText(txt);
  };

  const debouncedSearchCompanies = useCallback(
    debounce(handleCompanySearch, 250),
    []
  );

  const debouncedSearchUser = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  const [getCompanies] = useLazyQuery(SEARCH_COMPANIES, {
    onCompleted: data => {
      if (
        data &&
        data.companiesByFilter &&
        data.companiesByFilter.companyList
      ) {
        if (data.companiesByFilter.companyList.length > 0) {
          setCompanyList(
            data.companiesByFilter.companyList.map(
              ({ id, registeredName }) => ({ name: registeredName, id })
            )
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: error => {
      notifyError(error);
    }
  });

  const [getUsers] = useLazyQuery(SEARCH_USERS, {
    onCompleted: ({ searchUsers }) => {
      if (searchUsers) {
        if (searchUsers.length > 0) {
          setUserList(
            searchUsers.map(({ id, firstName, lastName }) => ({
              name: `${lastName} ${firstName}`,
              id
            }))
          );
        } else {
          setCompanyList(empty);
        }
      }
    },
    onError: error => {
      notifyError(error);
    }
  });

  useEffect(() => {
    if (companySearchText?.length > 2) {
      setCompanyList([{ id: "loading", name: "Loading..." }]);
      getCompanies({
        variables: { filter: companySearchText, criteria: "searchName" }
      });
    } else {
      setCompanyList([
        { id: "3char", name: "Please enter at least 3 characters" }
      ]);
    }
  }, [companySearchText]);

  useEffect(() => {
    if (userSearchText?.length > 2) {
      setUserList([{ id: "loading", name: "Loading..." }]);
      getUsers({
        variables: { name: userSearchText }
      });
    } else {
      setUserList([
        { id: "3char", name: "Please enter at least 3 characters" }
      ]);
    }
  }, [userSearchText]);

  return (
    <Row gutter={24} className={loading ? "no-interact" : ""}>
      <Col span={7}>
        <div className="flex-center-space-between">
          <label>Company:</label>
          <Select
            showSearch
            showArrow
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            loading={false}
            style={{ width: "calc(100% - 75px)" }}
            onSearch={debouncedSearchCompanies}
            onSelect={company => setFilterState({ companyId: String(company) })}
            placeholder="Search company"
            defaultValue={companyId}
          >
            {companyList.map(company => (
              <Select.Option key={company.id}>{company.name}</Select.Option>
            ))}
          </Select>
        </div>
      </Col>

      <Col span={7}>
        <div className="flex-center-space-between">
          <label>User:</label>
          <Select
            showSearch
            showArrow
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            loading={false}
            style={{ width: "calc(100% - 75px)" }}
            onSearch={debouncedSearchUser}
            onSelect={user => setFilterState({ userId: String(user) })}
            placeholder="Search user"
            defaultValue={userId}
          >
            {userList.map(user => (
              <Select.Option key={user.id}>{user.name}</Select.Option>
            ))}
          </Select>
        </div>
      </Col>

      <Col span={10}>
        <div className="flex-center-space-between">
          <label>Dates: </label>
          <DatePicker.RangePicker
            style={{ width: "calc(100% - 75px)" }}
            separator="=>"
            value={[
              startDate ? moment(startDate) : undefined,
              endDate ? moment(endDate) : undefined
            ]}
            onChange={dates => {
              if (dates.length === 2) {
                const startDate = dates[0]
                  .startOf("day")
                  .locale("en_za")
                  .format("YYYY-MM-DD");
                const endDate = dates[1]
                  .startOf("day")
                  .locale("en_za")
                  .format("YYYY-MM-DD");

                setFilterState({ startDate, endDate });
              }
            }}
          />
        </div>
      </Col>
    </Row>
  );
}
