import React, { useState, useEffect } from "react";
import { Tabs, Breadcrumb } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import BusinessProfile from "../../Companies/CompanyOverview/companyProfile";
import CompanyTasks from "../../Tasks/memberTasks";
import MemberTransactions from "../../Transactions/memberTransactions";
import MemberEmployees from "../../Employees/companyEmployees";
import Representatives from "../../Representatives/companyRepresentatives";
import CompanyDocuments from "../../Documents/memberDocuments";
import { GET_COMPANY_OVERVIEW_INFORMATION } from "../../graphql/queries";
import "../../Members/MemberOverview/index.css";

const MemberOverview = props => {
  // const permissions = localStorage.getItem("userPermissions");
  const [isLoading, setIsLoading] = useState(true);
  const [useCompanyId, setCompanyId] = useState(null);
  const [useCompanyName, setCompanyName] = useState(null);
  const [fetchCompanyOverviewInformation] = useLazyQuery(
    GET_COMPANY_OVERVIEW_INFORMATION,
    {
      variables: {
        companyId: props.match.params.companyId * 1
      },
      onCompleted: data => {
        setIsLoading(false);
        if (typeof data?.company?.id === "number") {
          setCompanyId(data?.company.id);
          setCompanyName(
            data?.company?.registeredName
              ? data?.company?.registeredName
              : data?.company?.tradingName
          );
        }
      },
      fetchPolicy: "network-only"
    }
  );
  useEffect(() => {
    fetchCompanyOverviewInformation();
  }, []);
  return (
    // <Can
    //   permissions={permissions}
    //   perform={"users:list"}
    //   yes={() => (
    //     <>
    <>
      <div className="member-profile-header-div">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/admin/Companies">Companies</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/admin/company/${props.match.params.companyId}`}>
              {useCompanyName ? useCompanyName : props.match.params.companyId}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        {useCompanyName && <h2>{useCompanyName}</h2>}
      </div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Business" key="1" className="tab-content">
          <BusinessProfile companyId={useCompanyId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Related Parties" key="2" className="tab-content">
          <Representatives companyId={Number.parseInt(useCompanyId)} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Employees" key="3" className="tab-content">
          <MemberEmployees companyId={Number.parseInt(useCompanyId)} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Tasks"
          disabled={isLoading}
          key="4"
          className="tab-content"
        >
          <CompanyTasks
            companyId={useCompanyId}
            mode="company-overview-tasks"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Transactions"
          disabled={isLoading}
          key="5"
          className="tab-content"
        >
          <MemberTransactions
            companyId={useCompanyId}
            mode="company-overview-transactions"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Documents"
          disabled={isLoading}
          key="6"
          className="tab-content"
        >
          <CompanyDocuments
            companyId={useCompanyId}
            companyName={useCompanyName}
            mode="company-overview-documents"
          />
        </Tabs.TabPane>
      </Tabs>
    </>
    // </>
    //   )}
    //   no={() => <AccessDeined />}
    // />
  );
};

export default MemberOverview;
