import * as AWSCognito from "amazon-cognito-identity-js";
import { notifyError, openNotificationWithIcon } from "../notification";

export const confirmUserRegistration = async (data: any): Promise<any> => {
  try {
    const { idNumber, code } = data;
    const poolData: AWSCognito.ICognitoUserPoolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
    };
    const userPool = new AWSCognito.CognitoUserPool(poolData);
    const userData = {
      Username: idNumber,
      Pool: userPool
    };
    const cognitoUser = new AWSCognito.CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, function(err, result) {
      if (err) {
        notifyError(err);
        Promise.resolve();
      }
      if (result) {
        openNotificationWithIcon(
          "success",
          "Confirmation Success",
          "Account successfully confirmed. You may now log in"
        );
        Promise.resolve(result);
      }
    });
  } catch (error) {
    Promise.reject(error);
  }
};
