import { Result, Button, Spin } from "antd";
import { COMPLETE_BATCH_REGISTRATION } from "graphql/mutations";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router";
import { useRegistrationState } from "stores/registration";
import { isAdmin } from "utils";

export default function CompleteBatchRegistration() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [batchRegistration, setBatchRegistration] = useState<any>(null);

  const {
    batchRegistrationId,
    clearRegistrationState: cleanup
  } = useRegistrationState();

  const input = { id: batchRegistrationId };

  const [complete] = useMutation(COMPLETE_BATCH_REGISTRATION, {
    variables: { input },
    onCompleted: (data: any) => {
      setBatchRegistration(data.completeBatchRegistration);
      setIsLoading(false);
    },
    onError: (err: any) => {
      setError(err);
      setIsLoading(false);
      console.error("complete_reg_error", err.message);
    }
  });

  useEffect(() => {
    complete();
  }, [batchRegistrationId]);

  function close() {
    history.replace(isAdmin() ? `/admin/tasks` : "/tasks");
    cleanup(true);
  }

  function goBack() {
    history.goBack();
  }

  if (isLoading) {
    return (
      <Result
        icon={<Spin spinning size="large" />}
        status="info"
        title="Completing Batch Registration"
        subTitle={
          "Please wait while we complete your batch registration. This may take a few minutes."
        }
      />
    );
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to Complete Batch Registration"
        subTitle={error.message}
        extra={[
          <Button key="close" onClick={goBack}>
            Go Back
          </Button>
        ]}
      />
    );
  }

  return (
    <Result
      status="success"
      title="Batch Registration Complete"
      subTitle={`You have successfully registered ${batchRegistration?.tasks
        ?.length || ""} employees. Your financial task #${
        batchRegistration?.financialTaskId
      } is ready for you to upload proof of payment.`}
      extra={[
        <Button key="close" onClick={close}>
          Close
        </Button>
      ]}
    />
  );
}
