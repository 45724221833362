import { notification } from "antd";
import { ReactNode } from "react";
import "./index.css";

export const openNotificationWithIcon = (
  type: string,
  message: string,
  description: string | ReactNode
) => {
  notification[type]({
    message,
    description,
    duration: 10,
    className: "notification"
  });
};

export const notifyError = (error: any) => {
  const message = error.message || error;
  console.error(message);
  openNotificationWithIcon(
    "error",
    "Error",
    message.replace("GraphQL error: ", "")
  );
};

export const notifyInfo = (message: string) => {
  openNotificationWithIcon("info", "Info", message);
};

export const notifySuccess = (message: string) => {
  openNotificationWithIcon("success", "Success", message);
};
