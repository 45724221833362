import React, { useState, useEffect, useRef } from "react";
import { Card, Table, Button, Spin, Divider, Icon, Menu, Dropdown } from "antd";
import { useHistory } from "react-router";
import { format, parseISO } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { GET_FILTERED_NEW_TASK_LIST } from "../../../graphql/queries";
import { DELETE_TASK } from "../graphql/mutations";
import NewTaskFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import PermissionsGuard, { hasPermission } from "../../../components/Auth/can";
import EmptyState from "../../../components/Styled/EmptyState";
import {
  ADMIN_STREAMS,
  DEFAULT_TASK_FILTERS,
  TASK_PAGE_SIZE
} from "../../../constants";
import "../../Projects/project.css";
import RemoveTaskModal from "./RemoveTaskModal";
import { prioritize, formatName, writeCSV, formatFilters } from "utils";
import { notifyError } from "utils/notification";
import { useFilterState } from "contexts/AdminFilterContext";

const AuditTaskList: React.FC<any> = props => {
  const history = useHistory();
  const [taskList, setTaskList] = useState([]);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [listTotal, setListTotal] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  // @ts-ignore
  const [downloading, setDownloading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isRemovingTask, setIsRemovingTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>();

  const {
    filters: { filter, savedSkip, savedCurrentPage },
    savePageNumber,
    filterReset
  } = useFilterState();

  const filterData = {
    ...DEFAULT_TASK_FILTERS,
    ...filter,
    taskTypeId: 22
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      filterReset();
      runMultiFilterTaskQuery();
    }
  };

  const { refetch: downloadTasks } = useQuery(GET_FILTERED_NEW_TASK_LIST, {
    fetchPolicy: "no-cache",
    skip: true
  });

  const [getFilteredTaskList] = useLazyQuery(GET_FILTERED_NEW_TASK_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setTaskList(data.tasks.taskList);
      if (Number.isInteger(data.tasks.total)) {
        setListTotal(data.tasks.total);
        if (showEmptyState) setShowEmptyState(false);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error.message);
    }
  });

  const runMultiFilterTaskQuery = () => {
    setShowSpin(true);
    setLoading(true);

    getFilteredTaskList({
      variables: {
        input: {
          ...formatFilters(filterData),
          includeTotal: true,
          skip: savedSkip,
          take: TASK_PAGE_SIZE
        }
      }
    });
  };

  // @ts-ignore
  const handleDownload = async () => {
    try {
      setDownloading(true);
      const data = [];
      let t = 100;
      let s = 0;

      while (data.length < listTotal) {
        const { data: d, errors } = await downloadTasks({
          input: {
            taskTypeId: 22,
            includeTotal: false,
            skip: s,
            take: t
          }
        });

        if (errors && errors.length) {
          setDownloading(false);
          return notifyError(errors[0].message);
        }

        data.push(
          ...d.tasks.taskList.map(t => {
            return {
              "Task ID": t.id,
              "Linked ID": t?.relatedTask ? t.relatedTask.id : "",
              Applicant: t?.employee
                ? `${t.company?.createdBy?.lastName} ${t.company?.createdBy?.firstName}`.toUpperCase()
                : "",
              Company: t?.company
                ? (
                    t.company.tradingName || t.company.registeredName
                  ).toUpperCase()
                : "",
              Type: t?.type?.name,
              "Date Created": format(parseISO(t.createdDate), "yyyy-MM-dd"),
              Status: t.taskStatus
            };
          })
        );
        s += t;
      }

      writeCSV("Audit Tasks", `Audit Tasks ${Date.now()}`, data);
    } catch (err) {
      // @ts-ignore
      notifyError(err);
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, []);

  useEffect(() => {
    runMultiFilterTaskQuery();
  }, [savedCurrentPage, savedSkip, JSON.stringify(filterData)]);

  const columns = [
    {
      title: "",
      key: "priority",
      render: ({ type, priorityStatus, assignedTo }) =>
        prioritize(type, priorityStatus, assignedTo) ? (
          <Icon
            type="exclamation-circle"
            theme="filled"
            style={{ color: "red", fontSize: "15px" }}
          />
        ) : (
          ""
        )
    },
    {
      title: "Task ID",
      key: "id",
      render: ({ id, type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {id}
        </span>
      )
    },
    {
      title: "Linked ID",
      key: "relatedTasks",
      render: ({
        type,
        relatedTask,
        relatedTasks,
        priorityStatus,
        assignedTo
      }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {relatedTask?.id || relatedTasks[0]?.id || "n/a"}
        </span>
      )
    },
    {
      title: "Applicant",
      key: "firstName",
      render: record => (
        <span
          className={
            prioritize(record.type, record.priorityStatus, record.assignedTo)
              ? "bold"
              : ""
          }
        >
          {formatName(record).toUpperCase()}
        </span>
      )
    },
    {
      title: "Company",
      key: "company",
      render: ({ company, type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {company?.registeredName?.toUpperCase() || "n/a"}
        </span>
      )
    },
    {
      title: "Type",
      key: "type",
      render: ({ type, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {type?.name}
        </span>
      )
    },
    {
      title: "Date Created",
      key: "createdDate",
      render: ({ type, createdDate, priorityStatus, assignedTo }) => (
        <span
          className={prioritize(type, priorityStatus, assignedTo) ? "bold" : ""}
        >
          {createdDate ? format(parseISO(createdDate), "yyyy-MM-dd") : ""}
        </span>
      )
    },
    {
      title: "Status",
      key: "status",
      render: ({ taskStatus, type, priorityStatus, assignedTo }) => {
        return taskStatus ? (
          <span
            className={
              prioritize(type, priorityStatus, assignedTo) ? "bold" : ""
            }
          >
            {taskStatus}
          </span>
        ) : (
          ""
        );
      }
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: record => {
        const items = [
          <Menu.Item key="1">
            <PermissionsGuard
              perform={"application:review"}
              yes={() => (
                <a
                  className="ant-dropdown-link purple-link"
                  onClick={() => {
                    localStorage.removeItem("reviewId");
                    history.push({
                      pathname: `/admin/tasks/review/audit/${record.id}`,
                      state: { ...record }
                    });
                  }}
                >
                  Review
                </a>
              )}
            />
          </Menu.Item>
        ];

        if (hasPermission("audit_certificate:complete")) {
          items.push(
            <Menu.Item key="2">
              <a
                className="ant-dropdown-link purple-link"
                onClick={() => {
                  history.push({
                    pathname: `/tasks/audit/${record.id}`,
                    state: { task: record }
                  });
                }}
              >
                Complete Questionnaire
              </a>
            </Menu.Item>
          );
        }

        if (ADMIN_STREAMS.includes(localStorage.getItem("userRoles"))) {
          items.push(
            <Menu.Item
              onClick={() => {
                setSelectedTask(record);
                setModalVisible(true);
              }}
              key="3"
            >
              Remove task
            </Menu.Item>
          );
        }

        return (
          <Dropdown
            overlay={<Menu>{items.map(i => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link purple-link"
              onClick={e => e.preventDefault()}
            >
              More
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingRight: "10px"
                }}
              >
                <Icon type="down" title="member actions dropdown" />
              </div>
            </a>
          </Dropdown>
        );
      }
    }
  ];

  const [deleteTask, {}] = useMutation(DELETE_TASK);

  const removeTaskModalProps = {
    ref: undefined,
    visible: modalVisible,
    refreshList: clearFilters,
    setVisible: setModalVisible,
    taskDetails: selectedTask,
    isLoading: isRemovingTask,
    setIsLoading: setIsRemovingTask,
    onCancel: () => setModalVisible(false),
    deleteTask
  };

  if (error) {
    return <InternalErrorPage />;
  }

  return (
    <>
      <Spin tip="Loading..." className="loader" spinning={showSpin}>
        {initialising && <TableLoader />}
        {!initialising && showEmptyState && (
          <EmptyState
            location="tasks"
            headingText="Nothing to see here"
            bodyText="You currently have no incoming tickets. You can create a ticket to our support team to help with any problems you may be facing."
          ></EmptyState>
        )}
        {!showEmptyState && (
          <>
            <div className="col-sm-12 col-md-12 placeholder-table-card">
              <Card>
                <div className="card-header">
                  <h3>Audit Tasks</h3>
                  <div>
                    <Button
                      loading={downloading}
                      className="purple-button"
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                    <Button className="purple-button" onClick={clearFilters}>
                      Clear filters
                    </Button>
                  </div>
                </div>
                <div className={loading ? "no-interact" : null}>
                  {!initialising && (
                    <NewTaskFilters
                      ref={filtersRef}
                      defaultMultiFilter={filterData}
                      mode="new-tasks"
                    />
                  )}
                  <Divider />
                  <Table
                    rowKey={record => record.id}
                    columns={columns}
                    size="small"
                    pagination={{
                      current: savedCurrentPage,
                      pageSize: TASK_PAGE_SIZE,
                      onChange: savePageNumber,
                      total: listTotal,
                      showTotal: () => <h3>Total: {listTotal}</h3>
                    }}
                    dataSource={taskList}
                  />
                </div>
              </Card>
            </div>
          </>
        )}
      </Spin>
      <RemoveTaskModal {...removeTaskModalProps} />
    </>
  );
};

export default AuditTaskList;
