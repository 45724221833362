import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { GET_USER } from "../graphql/queries";
import { isEmpty } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { Icon } from "antd";

const ReviewAuthenticationInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { company, type, employee } = transaction;

  const email =
    type.name === "Employee" ? employee.email : company.createdBy?.email;
  const [userInfo, setUserInfo] = useState<any>();

  const { loading } = useQuery(GET_USER, {
    skip: isEmpty(email),
    variables: {
      input: { email }
    },
    onCompleted: data => {
      setUserInfo(data.userData);
    }
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "Authentication",
    reviewInfo,
    registrationTaskId
  };

  if (loading) return <InformationLoader />;
  return (
    <>
      <div className="flex-column information-block">
        <br />
        <span>
          {userInfo?.otpConfirmed ? (
            <>
              <span>
                OTP Confirmed{" "}
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#52c41a"
                />
              </span>
            </>
          ) : (
            <>
              <span>
                OTP Not Confirmed{" "}
                <Icon
                  type="close-circle"
                  theme="twoTone"
                  twoToneColor="#f5222d"
                />
              </span>
            </>
          )}{" "}
          <label>(Resent {userInfo?.otpRetries || 0} times)</label>
        </span>
      </div>
      <br />
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewAuthenticationInformation;
