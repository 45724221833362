import React from "react";
import { Row, Col, Divider } from "antd";
import "../../Members/MemberOverview/index.css";
import ContactForm from "../../../RegisterCompany/contactDetailsForm";
import AddressInfo from "../../../RegisterCompany/addressForm";
import RelatedPartyForm from "../../../RegisterCompany/relatedPartyForm";
import FinancialInformationCompany from "../../../RegisterCompany/financialInformation";
import AuditorAccountantCompany from "../../../RegisterCompany/auditorAccountantInfo";
import CompanyDetailsForm from "../../../RegisterCompany/companyDetailsForm";
import BasicInfoCompany from "../../../RegisterSoleProprietor/registration";

const CompanyProfileEditable = ({ companyId, companyDetails }) => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BasicInfoCompany />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CompanyDetailsForm companyDetails={companyDetails} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="card-header">
            <h3>Business location info</h3>
          </div>
          <AddressInfo useCompanyId={companyId} disableHeading={true} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="card-header">
            <h3>Financial info</h3>
          </div>
          <FinancialInformationCompany
            isRegistrationMode={false}
            useRegisteringAs="A Sole Proprietor"
            useCompanyId={companyId}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="card-header">
            <h3>Related party/team members</h3>
          </div>
          <RelatedPartyForm
            useRegisteringAs="A Sole Proprietor"
            useCompanyId={companyId}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="card-header">
            <h3>Contact info</h3>
          </div>
          <ContactForm useCompanyId={companyId} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="card-header">
            <h3>Auditor info</h3>
          </div>
          <AuditorAccountantCompany
            useRegisteringAs="A Sole Proprietor"
            isRegistrationMode={false}
            useCompanyId={companyId}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default CompanyProfileEditable;
