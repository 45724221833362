import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_WITHDRAWN_EMPLOYMENT_BY_FILTER } from "../graphql/queries";
import { notifyError } from "utils/notification";
import { Button, Card, Divider, Skeleton, Table } from "antd";
import { format } from "date-fns";
import { If } from "components/If";
import { WithdrawnEmploymentFilters } from "./WithdrawnEmploymentFilters";
import { useWithdrawnEmploymentFilters } from "hooks/UseWithdrawnEmploymentFilters";

type Props = {};

function WithdrawnEmployment(props: Props) {
  const [total, setTotal] = useState(0);
  const [withdrawnEmployment, setWithdrawnEmployment] = useState([]);
  const {
    companyId,
    userId,
    startDate,
    endDate,
    pageNumber,
    take,
    skip,
    setFilterState,
    clearFilters
  } = useWithdrawnEmploymentFilters();

  const [getWithdrawnEmployment, { loading }] = useLazyQuery(
    GET_WITHDRAWN_EMPLOYMENT_BY_FILTER,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ getWithdrawnEmploymentsByFilter }) => {
        setWithdrawnEmployment(getWithdrawnEmploymentsByFilter.result);
        setTotal(getWithdrawnEmploymentsByFilter.count);
      },
      onError: error => {
        notifyError(error);
      }
    }
  );

  const handleSearch = () => {
    getWithdrawnEmployment({
      variables: {
        input: {
          take,
          skip,
          companyId: companyId ? +companyId : undefined,
          userId: userId ? +userId : undefined,
          dateRange: [startDate, endDate]
        }
      }
    });
  };

  useEffect(() => {
    handleSearch();
  }, [pageNumber, take, skip, startDate, endDate, companyId, userId]);

  return (
    <div className="col-sm-12 col-md-12 placeholder-table-card">
      <Card>
        <div className="card-header">
          <h3>Withdrawn Employment</h3>
          <div>
            {/* <Button
              icon="download"
              className="purple-button"
              onClick={handleDownload}
              loading={downloading}
            >
              Download
            </Button> */}
            <Button className="purple-button" onClick={clearFilters}>
              Clear filters
            </Button>
          </div>
        </div>
        <If condition={loading}>
          <Skeleton active title paragraph={{ rows: 5 }} />
        </If>
        <If condition={!loading}>
          <WithdrawnEmploymentFilters />
          <Divider />
          <Table
            rowKey="id"
            columns={[
              {
                title: "Employee",
                key: "employee",
                render: record => {
                  if (!record?.employment?.user) {
                    return "";
                  }

                  return `${record?.employment?.user?.lastName || ""} ${record
                    ?.employment?.user?.firstName || ""}`.toUpperCase();
                }
              },
              {
                title: "Company",
                key: "company",
                render: record => {
                  if (!record?.employment?.company) {
                    return "";
                  }

                  const {
                    registeredName,
                    tradingName
                  } = record.employment.company;

                  return (registeredName || tradingName).toUpperCase() || "";
                }
              },
              {
                title: "Membership #",
                dataIndex: "employment.councilMembershipNumber",
                key: "membershipNumber"
              },
              {
                title: "Date Withdrawn",
                render: ({ createdAt }) =>
                  format(new Date(createdAt), "dd/MM/yyyy"),
                key: "dateWithdrawn"
              },
              {
                title: "Withdrawn By",
                dataIndex: "withdrawnBy",
                key: "withdrawnBy"
              },
              {
                title: "Reason",
                dataIndex: "reason",
                key: "reason"
              }
            ]}
            size="small"
            pagination={{
              current: pageNumber,
              pageSize: take,
              onChange: page => {
                setFilterState({ pageNumber: page.toString() });
              },
              total: total,
              showTotal: () => <h3>Total: {total}</h3>
            }}
            dataSource={withdrawnEmployment}
          />
        </If>
      </Card>
    </div>
  );
}

export default WithdrawnEmployment;
