import { styled } from "../../theme";
import { color } from "styled-system";

export const ContainerWrapperOuter = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
  min-height: calc(100vh - 30px);
`;

export const ContainerWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  align-self: center;
  justify-content: center;
  max-width: ${(props) => props.theme.maxContentWidth};
`;

export const VersionNumber = styled.div`
  ${color};
`;
