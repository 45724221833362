import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Avatar,
  Upload,
  Icon,
  Button,
  message,
  Spin,
  Select
} from "antd";
import { GET_USER } from "../../graphql/queries";
import {
  UPDATE_USER,
  CREATE_ADDRESS,
  UPDATE_ADDRESS
} from "../../../../graphql/mutations";
import { isEmpty } from "lodash";
import { useMutation, useQuery, useLazyQuery } from "react-apollo";
import {
  GET_PRESIGNED_URL,
  GET_ADDRESS_LIST,
  GET_ADDRESS_TYPES
} from "../../../../graphql/queries";
import "./index.css";
import { uploadToS3 } from "utils/uploadToS3";
import { openNotificationWithIcon } from "utils/notification";
import { HOME_PHYSICAL_ADDRESS, isSuperAdmin } from "../../../../constants";
import history from "utils/history";
import { useGlobalState } from "../../../../globalStore";

const ProfileForm = props => {
  const { getFieldDecorator } = props.form;
  const { state } = useGlobalState();
  const [file, setFile] = useState<any>();
  const [contentType, setContentType] = useState("");
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [userId, setUserId] = useState<number>();
  const [userInfo, setUserInfo] = useState<any>();
  const [userAddress, setUserAddress] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addressTypeId, setAddressTypeId] = useState<number>();

  const id = +props.match.params.teamMemberId;
  const userRoleList = state?.lookups?.userRoles || [];

  const isOwner = Boolean(
    id === Number.parseInt(localStorage.getItem("userId"))
  );

  const isAllowed = Boolean(
    localStorage.getItem("userRoles") === "Executive" ||
      localStorage.getItem("userRoles") === "Administration Manager"
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: data => setUserInfo(data.updateUser)
  });

  const [updateUserAddress] = useMutation(
    userAddress ? UPDATE_ADDRESS : CREATE_ADDRESS,
    {
      onCompleted: data => {
        const addressList = userAddress ? userAddress : [];
        addressList.push(userAddress ? data.updateAddress : data.createAddress);
        setUserAddress(addressList);
      }
    }
  );

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: data => setUserInfo(data.userData)
  });

  const { data: addressTypes, loading } = useQuery(GET_ADDRESS_TYPES, {
    onCompleted: () => {
      //get home physical address type
      const { addressTypeList } = addressTypes;
      const physical = addressTypeList.filter(
        (x: any) => x.name === HOME_PHYSICAL_ADDRESS
      );
      if (physical) setAddressTypeId(physical[0].id);
    }
  });

  const [getUserAddress] = useLazyQuery(GET_ADDRESS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (!isEmpty(data.addresses)) {
        setUserAddress(data.addresses);
      }
    }
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async data => {
      if (!isEmpty(data.preSignedUrl)) {
        setAvatarUrl("Avatar");
        await uploadToS3(data.preSignedUrl, contentType, file);
      }
    }
  });

  useEffect(() => {
    setUserId(id);
    getUser({
      variables: {
        input: { id }
      }
    });
    getUserAddress({
      variables: { userId: id }
    });
  }, []);

  const customRequest = async option => {
    const { file, onSuccess } = option;

    try {
      setUploading(true);
      setFile(file);
      setContentType(file.type);

      await getPresignedUrl({
        variables: {
          input: {
            filePath: `${localStorage.getItem("idNumber")}/avatar`,
            contentType: file.type,
            forCompany: false,
            userId,
            companyName: "Admin"
          }
        }
      });

      onSuccess();
      setUploading(false);
      message.success(`${file.name} file uploaded successfully`);
    } catch {
      setUploading(false);
      message.error(`${file.name} failed to uploaded`);
    }
  };

  const handleSubmit = () => {
    props.form.validateFields(async (err, values) => {
      if (err) return;
      try {
        setSubmitting(true);
        //update user details
        await updateUser({
          variables: {
            input: {
              id: userId,
              firstName: values.firstName,
              lastName: values.lastName,
              idNumber: values.id,
              phone: values.phoneNumber,
              avatarUrl,
              ...(values.userRole !== userInfo?.userType[0]?.name && {
                userType: [+values.userRole]
              })
            }
          }
        });

        // update user address details
        await updateUserAddress({
          variables: {
            input: {
              id: userAddress ? userAddress[0].id : undefined,
              userId: userAddress ? undefined : userId,
              country: values.country,
              city: values.city,
              streetAddress: values.streetAddress,
              addressTypeId: addressTypeId
            }
          }
        });
        setSubmitting(false);
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Profile updated successfully"
        );
      } catch {
        setSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "There was an error updating your profile"
        );
      }
    });
  };

  if (loading) return <Spin />;

  return (
    <>
      <div className="card-form">
        <h3>Basic Profile</h3>
        <br />
        <span>Please complete your profile before proceeding</span>
        <br />
        <br />
        <Form layout="vertical">
          {(isOwner || isAllowed || isSuperAdmin) && (
            <Form.Item label="ID">
              {getFieldDecorator("id", {
                initialValue: userInfo?.idNumber,
                rules: [
                  {
                    required: isOwner,
                    message: "Please enter your ID number"
                  }
                ]
              })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
            </Form.Item>
          )}
          <Form.Item label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: userInfo?.firstName,
              rules: [
                {
                  required: isOwner,
                  message: "Please enter your first name"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: userInfo?.lastName,
              rules: [
                {
                  required: isOwner,
                  message: "Please enter your last name"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              initialValue: userInfo?.email ? userInfo?.email : "",
              rules: [
                {
                  required: isOwner,
                  message: "Please enter your email address"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="Country">
            {getFieldDecorator("country", {
              initialValue: userAddress ? userAddress[0].country : "",
              rules: [
                {
                  required: isOwner,
                  message: "Please enter your country"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="City">
            {getFieldDecorator("city", {
              initialValue: userAddress ? userAddress[0]?.city : "",
              rules: [
                {
                  required: isOwner,
                  message: "Please enter your city"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="Street Address">
            {getFieldDecorator("streetAddress", {
              initialValue: userAddress ? userAddress[0]?.streetAddress : "",
              rules: [
                {
                  required: isOwner,
                  message: "Please enter street address"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="Phone Number">
            {getFieldDecorator("phoneNumber", {
              initialValue: userInfo?.phone,
              rules: [
                {
                  required: isOwner,
                  message: "Please enter phone number"
                }
              ]
            })(<Input readOnly={!(isOwner || isSuperAdmin)} />)}
          </Form.Item>
          <Form.Item label="User Role">
            {getFieldDecorator("userRole", {
              initialValue: userInfo?.userType[0]?.name
            })(
              <Select
                disabled={!isSuperAdmin}
                defaultValue={userInfo?.userType[0]?.name}
              >
                {userRoleList.map(({ id, name }) => (
                  <Select.Option key={id.toString()} value={id.toString()}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
        <br />
        <div className="stepper-btn-container">
          {(isOwner || isSuperAdmin) && (
            <Button
              className="btn-upload"
              loading={submitting}
              onClick={handleSubmit}
            >
              Update
            </Button>
          )}

          <Button
            type="default"
            onClick={() => history.goBack()}
            className="btn-default"
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="avatar-column">
        <Avatar src={userInfo?.avatarUrl} />
        <Upload
          customRequest={customRequest}
          style={{ display: "flex" }}
          disabled={uploading}
        >
          {(isOwner || isSuperAdmin) && (
            <Button>
              <Icon type="upload" />
              <span>Change Avatar</span>
            </Button>
          )}
        </Upload>
      </div>
    </>
  );
};
const ProfileManager = Form.create({ name: "normal_register" })(ProfileForm);
export default ProfileManager;
