import React from "react";
import EducationExperienceEmployee from "../RegisterEmployee/educationExperience";

type Props = { nextStep: () => void };

function EducationInformation({ nextStep }: Props) {
  return <EducationExperienceEmployee nextStep={nextStep} />;
}

export default EducationInformation;
