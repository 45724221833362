import React from "react";
import { Modal, Form, Input } from "antd";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  initialData,
  updatingEmail
}) => {
  const { getFieldDecorator } = form;

  return (
    <Modal
      visible={visible}
      title="Update Email"
      okText="Save"
      okButtonProps={{ loading: updatingEmail }}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: updatingEmail }}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Form.Item label="New Email">
          {getFieldDecorator("email", {
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!"
              }
            ]
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const UpdateEmailForm = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default UpdateEmailForm;
