import { Button, Form, Input } from "antd";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import cfdcLogo from "../../assets/images/cfdc-logo.png";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../constants";
import { openNotificationWithIcon } from "../../utils/notification";
import "./index.css";

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  }
});

const ForgotPassword: React.FC<any> = ({ ...props }) => {
  const [code, setCode] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [password, setPassword] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  useEffect(() => {
    if (confirmed) {
      openNotificationWithIcon(
        "success",
        "Password Saved!!",
        "Your new password has been saved successfuly. You can sign in"
      );
      setTimeout(() => window.location.replace("/"), 2000);
    }
  }, [confirmed]);

  const validateCodeForm = () => {
    return idNumber.length > 2;
  };

  const validateResetForm = () => {
    return (
      code.length > 0 && password.length > 0 && password === confirmPassword
    );
  };

  const handleSendCodeClick = async event => {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(idNumber);
      setCodeSent(true);
    } catch (e) {
      if (e.message === "Username/client id combination not found.") {
        setIsSendingCode(false);
        setIdNumber("");
        return openNotificationWithIcon(
          "error",
          "Username Error",
          "The given ID does not exist in our user pool. Please make sure you type the correct ID number to reset your password."
        );
      }
      setIsSendingCode(false);
    }
  };

  const handleConfirmClick = async event => {
    event.preventDefault();

    const schema = yup.object().shape({
      code: yup.string(),
      password: yup
        .string()
        .min(8, "Password has to be longer than 8 characters")
        .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
        .required("A password is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password has to be longer than 8 characters")
        .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
        .required("A password is required")
    });

    const isValid = await schema.isValid({ code, password, confirmPassword });

    if (!isValid) {
      return openNotificationWithIcon(
        "error",
        "Validation Error",
        "Please make sure that the passwords match and the code is correct."
      );
    }
    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(idNumber, code, password);

      setConfirmed(true);
    } catch (e) {
      setConfirmed(false);
      setIsConfirming(false);
      openNotificationWithIcon("error", "Validation Error", e.message);
    }
  };

  const renderRequestCodeForm = () => {
    return (
      <Form
        layout="vertical"
        onSubmit={handleSendCodeClick}
        className="password-reset-form"
      >
        <div className="flex-column">
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-password-reset" />
          </div>
          <div className="form-title">
            <h2>Forgot Password</h2>
            <div className="instruction-text">
              {
                "Please enter your ID number or Email and we'll send you a confirmation code to reset your password"
              }
            </div>
          </div>
        </div>
        <Form.Item
          label="ID Number or Email"
          htmlFor="idNumber"
          className="form-item"
        >
          <Input
            autoFocus
            value={idNumber}
            onChange={e => setIdNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-password-reset"
            onClick={handleSendCodeClick}
            loading={isSendingCode}
            disabled={!validateCodeForm()}
          >
            Send Confirmation
          </Button>
        </Form.Item>
        <span>
          <Link to="/">Cancel</Link>
        </span>
      </Form>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <Form
        layout="vertical"
        onSubmit={handleConfirmClick}
        className="password-reset-form"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <img src={cfdcLogo} alt="cfdc" className="logo-password-reset" />
          </div>
          <div className="form-title">
            <h2>Forgot Password</h2>
            <div className="instruction-text">
              {"Please enter your new password and confirm it"}
            </div>
          </div>
        </div>
        <Form.Item
          label="Confirmation Code"
          htmlFor="code"
          className="form-item"
          style={{ paddingTop: "2em" }}
        >
          <Input
            autoFocus
            type="tel"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
          <span>Please check your email for the confirmation code.</span>
        </Form.Item>
        <hr />
        <Form.Item
          label="New Password"
          htmlFor="password"
          className="form-item"
        >
          <Input.Password
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          id="confirmPassword"
          className="form-item"
        >
          <Input.Password
            type="password"
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            className="btn-password-reset"
            onClick={handleConfirmClick}
            loading={isConfirming}
            disabled={!validateResetForm()}
          >
            Save new password
          </Button>
        </Form.Item>
        <span>
          <Link to="/">Cancel</Link>
        </span>
      </Form>
    );
  };

  return (
    <div className="password-reset-form-wrapper">
      <div className="password-reset-sidebar">
        {!codeSent ? renderRequestCodeForm() : renderConfirmationForm()}
      </div>
      <div className="password-reset-sider-background" />
    </div>
  );
};

export default ForgotPassword;
