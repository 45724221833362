import React, { useState } from "react";
import { Button, Card, Input } from "antd";
import {
  SEND_COMPLAINT_NOTICE,
  SEND_PROSECUTION_NOTICE
} from "graphql/mutations";
import { useMutation } from "react-apollo";
import { isEmpty } from "lodash";
import { openNotificationWithIcon } from "utils/notification";

const SubmitToClient: React.FC<any> = ({
  mode = "notice-of-complaint",
  companyContacts,
  taskId,
  complaint,
  setNoticeSent,
  updateActivity,
  handleUpdateContact,
  refreshComplaint
}) => {
  const [submitting, setSubmitting] = useState(false);

  const [sendNotice] = useMutation(
    mode === "notice-of-complaint"
      ? SEND_COMPLAINT_NOTICE
      : SEND_PROSECUTION_NOTICE,
    {
      onCompleted: data => {
        updateActivity();
        setSubmitting(false);
        setNoticeSent(true);
        openNotificationWithIcon(
          "success",
          "Success",
          "Complaint notice successfully sent to client"
        );
      },
      onError: err => {
        setSubmitting(false);

        openNotificationWithIcon(
          "error",
          "Error",
          "The was an error submitting notice to client"
        );
      }
    }
  );

  const handleSubmitToClient = async () => {
    if (!isEmpty(companyContacts)) {
      setSubmitting(true);
      await sendNotice({
        variables: {
          input: {
            taskId,
            debtCollectorIDNumber: complaint.debtCollector?.idNumber,
            complaintId: complaint.id,
            companyId: complaint?.company?.id,
            contacts: companyContacts
          }
        }
      });
      refreshComplaint();
    }
  };

  return (
    <Card title="Submit to Client" className="information-card">
      <table>
        <thead>
          <tr>
            <th className="table-header">Business Unit</th>
            <th className="table-header">Contact Name</th>
            <th className="table-header">Email</th>
            <th className="table-header">Cellphone</th>
          </tr>
        </thead>
        <tbody>
          {companyContacts.map(c => (
            <tr key={`c-${c.id}`}>
              <td>
                <Input readOnly value={c.businessUnit} />
              </td>
              <td>
                <Input
                  onChange={e =>
                    handleUpdateContact("contactName", e.target.value, c.id)
                  }
                  value={c?.contactName || ""}
                />
              </td>
              <td>
                <Input
                  onChange={e =>
                    handleUpdateContact("contactEmail", e.target.value, c.id)
                  }
                  value={c?.contactEmail || ""}
                />
              </td>
              <td>
                <Input
                  onChange={e =>
                    handleUpdateContact(
                      "contactCellphone",
                      e.target.value,
                      c.id
                    )
                  }
                  value={c?.contactCellphone || ""}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <DynamicContactsFieldSet /> */}
      <br />
      {!isEmpty(companyContacts) && (
        <Button
          loading={submitting}
          className="purple-button"
          onClick={handleSubmitToClient}
        >
          Submit to Client
        </Button>
      )}
    </Card>
  );
};

export default SubmitToClient;
