import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Divider,
  Radio,
  Icon,
  Comment,
  Avatar,
  Upload,
  Spin
} from "antd";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import { CREATE_DOCUMENT } from "../../../../graphql/mutations";
import {
  GET_PRESIGNED_URL,
  GET_DOWNLOAD_URL
} from "../../../../graphql/queries";
import { parseISO, format } from "date-fns";
import { openNotificationWithIcon } from "utils/notification";
import { uploadToS3 } from "utils/uploadToS3";
import {
  CreateDebtCollectorResponseInput,
  UpdateRecommendationInput
} from "typings";
import { useGlobalState } from "../../../../globalStore";

type Props = {
  mode: string;
  complaintId: number;
  companyId?: number;
  comments?: any;
  userId: number;
  loading: boolean;
  handleSubmitRecommendation?: (
    data: UpdateRecommendationInput
  ) => Promise<any>;
  handleSubmitDebtCollectorResponse?: (
    data: CreateDebtCollectorResponseInput
  ) => Promise<any>;
};

export default (props: Props) => {
  const { state } = useGlobalState();
  const [approved, setApproved] = useState<boolean>();
  const [comment, setComment] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: err => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    }
  });

  const { refetch: getPresignedUrl } = useQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "no-cache",
    skip: true
  });

  const handleUpload = async ({ file, onSuccess }) => {
    setUploading(true);
    const path = `complaints-documents/${file.name}`;

    const { data } = await getPresignedUrl({
      input: {
        filePath: path,
        contentType: file.type,
        forCompany: false,
        companyName: "Admin"
      }
    });

    await uploadToS3(data.preSignedUrl, file.type, file);

    const { data: document } = await createDocument({
      variables: {
        input: {
          documentName: file.name,
          documentPath: path,
          documentTypeId: 33,
          documentStatusId: 2,
          documentFormat: file.type,
          forCompany: true,
          companyId: props.companyId
        }
      }
    });

    setUploadedDocs([...uploadedDocs, document.createDocument.id]);

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 500);
  };

  const handleViewDocument = filePath => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true
        }
      }
    });
  };

  const handleSubmit = async () => {
    if ((props.mode === "recommendation" && !props.comments) || !comment) {
      return;
    }
    if (props.mode === "recommendation") {
      await props.handleSubmitRecommendation({
        id: props.comments?.id,
        approved,
        comment: {
          message: comment,
          ...(uploadedDocs.length && { documentIds: uploadedDocs })
        }
      });
    } else {
      await props.handleSubmitDebtCollectorResponse({
        id: props.comments?.id,
        complaintId: props.complaintId,
        comment: {
          message: comment,
          ...(uploadedDocs.length && { documentIds: uploadedDocs })
        }
      });
    }
    setComment("");
  };

  const isAdmin = (userId: string): boolean => {
    return state.lookups?.admins?.userList?.some(a => a.id === userId) ?? false;
  };

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  return (
    <div>
      {props.mode === "recommendation" && (
        <>
          <Divider />
          <h3>Review</h3>
          <br />
          <Radio.Group onChange={e => setApproved(e.target.value)}>
            <Radio>Pending</Radio>
            <Radio value={false}>Reject</Radio>
            <Radio value={true}>Approve</Radio>
            <br />
            <small style={{ color: "#ff0000" }}>
              Leave this on pending if you just need to send a message
            </small>
          </Radio.Group>
          <Divider />
        </>
      )}

      <div>
        <h3>
          {props.mode === "debt-collector-response" ? "Response" : "Comments"}
        </h3>
        <Spin spinning={loadingDoc}>
          {props.comments?.comments?.map(comment => {
            return (
              <Comment
                key={comment.id?.toString()}
                author={`${comment?.author?.firstName} ${
                  comment?.author?.lastName
                } ${comment?.author?.id === props.userId ? "(You)" : ""}`}
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: isAdmin(comment?.author?.id)
                        ? "rgb(82, 196, 26)"
                        : "#408ef3"
                    }}
                    icon="user"
                  />
                }
                content={
                  <div>
                    <span>{comment.body}</span>
                    <br />
                    {comment?.documents?.map(({ documentPath }) => (
                      <span
                        onClick={() => handleViewDocument(documentPath)}
                        className="purple-link pointer"
                        key={documentPath}
                      >
                        {documentPath.split("/")[1]}
                      </span>
                    ))}
                  </div>
                }
                datetime={format(
                  parseISO(comment.createdDate),
                  "yyyy-MM-dd hh:mm:ss"
                )}
              />
            );
          })}
        </Spin>
      </div>
      <h4> Add a comment</h4>
      <Card
        className="comment-card"
        cover={
          <Input.TextArea
            style={{ resize: "none" }}
            rows={4}
            placeholder="Start typing..."
            value={comment}
            disabled={props.loading}
            onChange={e => setComment(e.target.value)}
          />
        }
      >
        <Card.Meta
          title={
            <div className="comment-action">
              <Upload
                disabled={uploading || props.loading}
                customRequest={handleUpload}
              >
                <Button>
                  <Icon type="upload" /> Upload
                </Button>
              </Upload>
              <div>
                <Button
                  className="purple-button"
                  loading={props.loading}
                  onClick={handleSubmit}
                >
                  <Icon type="caret-right" />
                  Send
                </Button>
              </div>
            </div>
          }
        />
      </Card>
    </div>
  );
};
