import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  SEARCH_COMPANIES,
  GET_USER_FILTERS
} from "../../containers/Admin/graphql/queries";
import { Input, Row, Col, AutoComplete, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const ListUserFilters = (
  {
    handleMemberFilterChange,
    setCurrentPage,
    setSkip,
    defaultMultiFilter,
    setError
  },
  ref
) => {
  const [companyList, setCompanyList] = useState([]);
  const [capacities, setCapacities] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [companySearchText, setCompanySearchText] = useState("");
  const userFilterRef = useRef();
  const typeFilterRef = useRef();
  const statusFilterRef = useRef();
  const companyFilterRef = useRef();
  const capacityFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: userFilterRef,
          defaultValue: defaultMultiFilter["user"]
        },
        {
          ref: typeFilterRef,
          defaultValue: defaultMultiFilter["type"]
        },
        {
          ref: statusFilterRef,
          defaultValue: defaultMultiFilter["userStatus"]
        },
        {
          ref: companyFilterRef,
          defaultValue: defaultMultiFilter["company"]
        },
        {
          ref: capacityFilterRef,
          defaultValue: defaultMultiFilter["capacity"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        }
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({
            value: useDefaultValue ? [useDefaultValue] : [],
            inputValue: useDefaultValue !== null ? useDefaultValue : ""
          });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else if (currentRef.select && currentRef.select.rcSelect) {
          let setAutocompleteInputValue =
            useDefaultValue !== null ? useDefaultValue : "";
          currentRef.select.rcSelect.setState({
            value: useDefaultValue ? [useDefaultValue] : [],
            inputValue: setAutocompleteInputValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  useEffect(() => {
    getUserFilters();
  }, []);

  const handleCompanySearch = searchString => {
    setCompanySearchText(searchString);
  };

  const handleUserFilterUpdate = searchString => {
    handleMemberFilterChange(searchString, "user");
  };

  const handleCompanyFilterUpdate = data => {
    if (!data || data.length === 0) {
      handleMemberFilterChange("", "company");
    }
  };

  const debouncedSearchCompanies = useCallback(
    debounce(handleCompanySearch, 250),
    []
  );
  const debouncedUserFilterUpdate = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  const [getUserFilters] = useLazyQuery(GET_USER_FILTERS, {
    onCompleted: data => {
      let capacityCollection = data.userFilters.capacities.map(
        item => item.name
      );
      let userTypesCollection = data.userFilters.userTypes.map(
        item => item.name
      );
      setCapacities(capacityCollection);
      setUserTypes(userTypesCollection);
    },
    onError: error => {
      setError(error);
    },
    fetchPolicy: "network-only"
  });

  const [getCompanies] = useLazyQuery(SEARCH_COMPANIES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (
        data &&
        data.companiesByFilter &&
        data.companiesByFilter.companyList
      ) {
        if (data.companiesByFilter.companyList.length > 0) {
          const companyNameArray = data.companiesByFilter.companyList.map(
            item => item.registeredName
          );
          setCompanyList(companyNameArray);
        } else {
          setCompanyList(["No results found"]);
        }
      }
    }
  });

  useEffect(() => {
    if (companySearchText && companySearchText.length > 2) {
      setCompanyList(["Loading..."]);
      getCompanies({
        variables: { filter: companySearchText, criteria: "searchName" }
      });
    } else {
      setSkip(0);
      setCurrentPage(1);
      setCompanyList(["Please enter at least 3 characters"]);
    }
  }, [companySearchText]);

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            User:
            <Input
              ref={userFilterRef}
              id="user-search"
              placeholder="Enter search text"
              onChange={event => debouncedUserFilterUpdate(event.target.value)}
              style={{ width: "calc(100% - 75px)" }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Type:
            <Select
              ref={typeFilterRef}
              placeholder="Select type"
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "type")}
            >
              {userTypes.map(item => (
                <Option key={"user-filter-" + item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Status:
            <Select
              ref={statusFilterRef}
              placeholder="Select applicant status"
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "userStatus")}
            >
              <Option value="active">Active</Option>
              <Option value="deregistered">Deregistered</Option>
              <Option value="due">Due</Option>
              <Option value="pending">Pending</Option>
              <Option value="suspended">Suspended</Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            Company:
            <AutoComplete
              ref={companyFilterRef}
              dataSource={companyList}
              style={{ width: "calc(100% - 75px)" }}
              onSearch={search => debouncedSearchCompanies(search)}
              onSelect={company => handleMemberFilterChange(company, "company")}
              onChange={data => handleCompanyFilterUpdate(data)}
              placeholder="Search company name"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Capacity:
            <Select
              ref={capacityFilterRef}
              placeholder="Select capacity"
              style={{ width: "calc(100% - 75px)" }}
              mode="multiple"
              onChange={value => handleMemberFilterChange(value, "capacity")}
            >
              {capacities.map(item => (
                <Option key={"user-filter-" + item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            Sort:
            <Select
              ref={sortFilterRef}
              defaultValue={defaultMultiFilter["sort"]}
              style={{ width: "calc(100% - 75px)" }}
              onChange={value => handleMemberFilterChange(value, "sort")}
            >
              <Option value="new">By newest</Option>
              <Option value="old">By oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(ListUserFilters);
