import React from "react";
import { RouteComponentProps } from "react-router";
import { PageWrap, Image } from "../../components";
import { images } from "../../theme/images";
import Helmet from "react-helmet";

const Dashboard: React.FC<RouteComponentProps> = () => (
  <PageWrap>
    <Image src={images.successImage} />
    <Helmet title="Dashboard" />
  </PageWrap>
);

export default Dashboard;
