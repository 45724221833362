import React, { useState } from "react";
import { Modal, Form, Spin, Select } from "antd";

const ModalFormComponent = ({
  visible,
  onCancel,
  actionText,
  modalTitle,
  onAction,
  isLoadingEmployeeDetails,
  isRemovingEmployment,
  form,
  employeeDetails,
  employment
}) => {
  const { getFieldDecorator } = form;
  const [selectedReason, setSelectedReason] = useState("");
  return (
    <Modal
      visible={visible}
      title={modalTitle}
      okText={actionText}
      onCancel={onCancel}
      onOk={onAction}
      okButtonProps={{
        className: "purple-button",
        disabled:
          employeeDetails &&
          selectedReason &&
          employment &&
          !isRemovingEmployment
            ? false
            : true
      }}
      okType="default"
    >
      {" "}
      <Spin
        tip="Removing employee..."
        style={{ display: isRemovingEmployment ? "block" : "none" }}
      />
      <div className={isRemovingEmployment ? "no-interact" : ""}>
        <h4>Are you sure you want to remove this user as an employee?</h4>
        <br />
        <Spin
          tip="Loading employee details..."
          style={{ display: isLoadingEmployeeDetails ? "block" : "none" }}
        />
        {employeeDetails && !isLoadingEmployeeDetails && (
          <>
            <div>
              <span>
                Member Name:{" "}
                <b>
                  {employeeDetails?.firstName + " " + employeeDetails?.lastName}
                </b>
              </span>
              <br />
              <span>
                ID Number: <b>{employeeDetails?.idNumber}</b>
              </span>
              <br />
              <span>
                Email: <b>{employeeDetails?.email}</b>
              </span>
              <br />
              <span>
                Capacity:{" "}
                <b>
                  {employment?.[0]?.capacity?.name
                    ? employment?.[0]?.capacity?.name
                    : "Unknown"}
                </b>
              </span>
              <br />
              <span>
                Business Unit:{" "}
                <b>
                  {employment?.[0]?.businessUnit
                    ? employment?.[0]?.businessUnit
                    : "Unknown"}
                </b>
              </span>
            </div>
          </>
        )}
        <br />
        <Form layout="vertical">
          <Form.Item label="Reason for removal">
            {getFieldDecorator("removalReason", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "Please specify the reason for removing this user"
                }
              ]
            })(
              <Select
                className="input-select"
                onChange={value => setSelectedReason(value.toString())}
              >
                <Select.Option value={"deceased"}>
                  Member is deceased
                </Select.Option>
                <Select.Option value={"left-employ"}>
                  Member has left the employment of the company
                </Select.Option>
                <Select.Option value={"hearing-outcome"}>
                  Outcome of a hearing
                </Select.Option>
              </Select>
            )}
          </Form.Item>
        </Form>
        <p>
          NB: Please note that once an employee has been removed from the
          company you will not be able to revert this action. To add the an
          employee back, please use the invite feature.
        </p>
        <br />
      </div>
    </Modal>
  );
};

const RemoveEmployeeModal = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default RemoveEmployeeModal;
