import { TCustomFilter } from "typings";

export const CERTIFICATE_COLUMNS: TCustomFilter[] = [
  {
    name: "certificate",
    display: "Certificate",
    fields: [
      {
        name: "id",
        display: "ID"
      },
      {
        name: "dateCreated",
        display: "Date Created"
      },
      {
        name: "fromDate",
        display: "From Date"
      },
      {
        name: "toDate",
        display: "To Date"
      },
      {
        name: "debtCollectorId",
        display: "Debt Collector ID"
      },
      {
        name: "active",
        display: "Active"
      }
    ]
  }
];

export const EMPLOYMENT_COLUMNS: TCustomFilter[] = [
  {
    name: "employment",
    display: "Employment",
    fields: [
      {
        name: "id",
        display: "ID"
      },
      {
        name: "capacity",
        display: "Capacity"
      },
      {
        name: "councilMembershipNumber",
        display: "Membership Number"
      },
      {
        name: "dateCreated",
        display: "Date Created"
      },
      {
        name: "approvedDate",
        display: "Date of Approval"
      },
      {
        name: "userId",
        display: "User ID"
      },
      {
        name: "employerAddressType",
        display: "Address Type"
      },
      {
        name: "active",
        display: "Active"
      }
    ]
  }
];

const USER_PROPS = [
  {
    name: "id",
    display: "ID"
  },
  {
    name: "email",
    display: "Email"
  },
  {
    name: "firstName",
    display: "First Name"
  },
  {
    name: "lastName",
    display: "Last Name"
  },
  {
    name: "idNumber",
    display: "ID Number"
  },
  {
    name: "phone",
    display: "Phone"
  },
  {
    name: "userStatus",
    display: "Status"
  },
  {
    name: "highestGrade",
    display: "Highest Grade"
  },
  {
    name: "highestGradeYear",
    display: "Highest Grade Year"
  },
  {
    name: "secondaryEmail",
    display: "Secondary Email"
  },
  {
    name: "maritalStatus",
    display: "Marital Status"
  },
  {
    name: "ethnicity",
    display: "Ethnicity"
  },
  {
    name: "dateOfBirth",
    display: "Date of Birth"
  },
  {
    name: "createdDate",
    display: "Date Created"
  }
];

const COMPANY_PROPS = [
  {
    name: "id",
    display: "ID"
  },
  {
    name: "status",
    display: "Status"
  },
  {
    name: "natureOfBusiness",
    display: "Nature of Business"
  },
  {
    name: "registeredName",
    display: "Registered Name"
  },
  {
    name: "registeredNumber",
    display: "Registered Number"
  },
  {
    name: "dateOfRegistration",
    display: "Date of Registration"
  },
  {
    name: "tradingName",
    display: "Trading Name"
  },
  {
    name: "isRegisteredForVat",
    display: "Is Registered For VAT"
  },
  {
    name: "vatNumber",
    display: "VAT Number"
  },
  {
    name: "tradingAs",
    display: "Trading As"
  },
  {
    name: "financialYearStartDate",
    display: "Financial Year Start Date"
  },
  {
    name: "financialYearEndDate",
    display: "Financial Year End Date"
  },
  {
    name: "membershipNumber",
    display: "Membership Number"
  }
];

export const AUDIT_COLUMNS = [
  {
    name: "id",
    display: "ID"
  },
  {
    name: "bbeeeLevel",
    display: "BBEEE Level"
  },
  {
    name: "industry",
    display: "Industry"
  },
  {
    name: "geographicalArea",
    display: "Geographical Area"
  },
  {
    name: "valueOfBooks",
    display: "Value of Books"
  },
  {
    name: "amountRecovered",
    display: "Amount Recovered"
  },
  {
    name: "financialYear",
    display: "Financial Year"
  },
  {
    name: "status",
    display: "Status"
  },
  {
    name: "company",
    display: "Company",
    fields: COMPANY_PROPS
  }
];

export const USER_COLUMNS: TCustomFilter[] = [
  ...USER_PROPS,
  {
    name: "company",
    display: "Company (Employer)",
    fields: COMPANY_PROPS
  },
  ...EMPLOYMENT_COLUMNS,
  ...CERTIFICATE_COLUMNS
];

export const COMPANY_COLUMNS: TCustomFilter[] = [
  ...COMPANY_PROPS,
  {
    name: "user",
    display: "User (Member)",
    fields: USER_PROPS
  },
  ...EMPLOYMENT_COLUMNS,
  ...CERTIFICATE_COLUMNS
];
