import React, { useState } from "react";
import { Button, Icon } from "antd";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_PREQUALIFICATION,
  GET_DOWNLOAD_URL
} from "../../../graphql/queries";
import { EAGLE_REPORT } from "../../../constants";
import { isInteger } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import moment from "moment";
import "./index.css";

const ReviewPrequalification: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { type, employee, company } = transaction;
  const userId = type.name === "Employee" ? employee.id : company.createdBy.id;
  const isCompanyReg = type.name === "Company";

  const [prequalificationInfo, setPrequalificationInfo] = useState<any>();
  const { loading: loadingPrequalification } = useQuery(GET_PREQUALIFICATION, {
    skip: !isInteger(userId),
    variables: {
      ...(employee && { userId }),
      ...(company && { companyId: company.id })
    },
    onCompleted: data => setPrequalificationInfo(data.prequalification)
  });

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        fileName: EAGLE_REPORT,
        companyName: isCompanyReg ? company.registeredName : "",
        forCompany: isCompanyReg ? true : false,
        userId
      }
    },
    onCompleted: data => {
      window.open(data.preSignedDownloadUrl);
    }
  });

  const ResultComponentsProps = {
    ...transaction,
    step: "Prequalification",
    reviewInfo,
    registrationTaskId
  };
  if (loadingPrequalification) return <InformationLoader />;
  return (
    <>
      <br />
      {!isCompanyReg && (
        <>
          <div className="flex-column information-block">
            <span>
              1. Are you/any party related to the Business, on trial for an
              offence of which violence, dishonesty, extortion or intimidation
              is an element or have in the preceding 10 years been convicted of
              such offence?{" "}
            </span>
            <br />
            <div className="information-block flex-row">
              <span>
                Answer:
                <label>{prequalificationInfo?.onTrial ? "Yes" : "No"}</label>
              </span>{" "}
              <span>
                Date of Conviction:{" "}
                <label>
                  {prequalificationInfo?.dateOfTrial
                    ? moment(prequalificationInfo?.dateOfTrial).format(
                        "YYYY-MM-DD"
                      )
                    : "N/A"}
                </label>
              </span>
              <span>
                Type of Offence:{" "}
                <label>{prequalificationInfo?.typeOfOffence?.name}</label>
              </span>
              <span>
                Convicted:{" "}
                <label>{prequalificationInfo?.convicted ? "Yes" : "No"}</label>
              </span>
            </div>
          </div>
          <br />
          <br />
          <div className="flex-column information-block">
            <span>
              1.1 Has an application for exemption in terms of section 10(2)
              been lodged with the Ministry of Justice and Constitutional
              Development?{" "}
            </span>
            <br />
            <div className="information-block">
              <span>Answer:</span>{" "}
              <span>
                <label>
                  {prequalificationInfo?.appliedForExemption ? "Yes" : "No"}
                </label>
              </span>
            </div>
          </div>
        </>
      )}
      <br />
      <br />
      <div className="flex-column information-block">
        <span>
          {isCompanyReg ? 1 : 2}. Has the Business ever, in terms of section 15
          of Act, been found guilty of improper conduct?{" "}
        </span>
        <br />
        <div className="information-block">
          <span>Answer:</span>{" "}
          <span>
            <label>
              {prequalificationInfo?.guiltyOfImproperConduct ? "Yes" : "No"}
            </label>
          </span>
        </div>
      </div>
      <br />
      <br />
      <div className="flex-column information-block">
        <span>
          {isCompanyReg ? 2 : 3}. Have you or any person in this registration
          been placed under curatorship for legal capacity?{" "}
        </span>
        <br />
        <div className="information-block">
          <span>Answer:</span>{" "}
          <span>
            <label>
              {prequalificationInfo?.curatedForLegalCapacity ? "Yes" : "No"}
            </label>
          </span>
        </div>
      </div>
      <br />
      <br />
      {!isCompanyReg && (
        <div className="flex-column information-block">
          <span>
            4. Based on Credit Bureau results, the following indicators are
            reflected, please provide proof of the contrary where applicable:{" "}
            <ul>
              <li>Sequestration per [IDNumber]</li>
              <li>Debt Review per [IDNumber]</li>
              <li>Administration Order per [IDNumber]</li>
              <li>Judgements listed per [IDNumber]</li>
            </ul>
          </span>
          <br />
        </div>
      )}
      {!isCompanyReg && prequalificationInfo && prequalificationInfo.reportUrl && (
        <div style={{ textAlign: "right" }}>
          <Button type="default" onClick={() => getDownloadUrl()}>
            <Icon type="file" />
            View Eagle Eye PDF
          </Button>
        </div>
      )}

      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewPrequalification;
