import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Card,
  Divider,
  Radio,
  Icon,
  Comment,
  Avatar,
  Menu,
  Upload,
  Spin,
  InputNumber,
  Col,
  Row
} from "antd";
import { useMutation, useLazyQuery } from "react-apollo";
import { withRouter } from "react-router";
import {
  CREATE_APPLICATION_REVIEW,
  UPDATE_APPLICATION_REVIEW,
  UPDATE_TASK,
  CREATE_RENEWAL_REVIEW,
  CREATE_FINANCIAL_REVIEW,
  CREATE_DOCUMENT,
  CREATE_REVIEW_COMMENT
} from "../../../graphql/mutations";
import {
  GET_APPLICATION_REVIEW_INFO,
  GET_PRESIGNED_URL,
  GET_DOWNLOAD_URL
} from "../../../graphql/queries";
import { TASK_COMPLETED, ADMIN_STREAMS } from "../../../constants";
import { isBoolean, isEmpty } from "lodash";
import { parseISO, format } from "date-fns";
import "./index.css";
import {
  notifyError,
  openNotificationWithIcon
} from "../../../utils/notification";
import { uploadToS3 } from "../../../utils/uploadToS3";

import PermissionsGuard from "../../../components/Auth/can";
import { useGlobalState } from "../../../globalStore";

const ReviewComment: React.FC<any> = props => {
  const { state } = useGlobalState();
  const [passed, setPassed] = useState<boolean>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [failedReason, setFailedReason] = useState("");
  const [reviewInfo, setReviewInfo] = useState<any>();
  const [reviewStepComments, setReviewStepComments] = useState([]);
  const [applicationOwner, setApplicationOwner] = useState([]);
  const [relatedTaskReview, setRelatedTaskReview] = useState<any>();
  const [linkedUserId, setLinkedUserId] = useState("");
  const [linkedUserName, setLinkedUserName] = useState("");
  const reviewId = localStorage.getItem("reviewId");
  const [filePath, setFilePath] = useState<{ path: string; name: string }>();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<any>();
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [createFinancialTicket, setCreateFinancialTicket] = useState(false);
  const [financialTicketAmount, setFinancialTicketAmount] = useState<number>();
  const [downloadUrl, setDownloadUrl] = useState("");

  const {
    id,
    step,
    company,
    employee,
    registrationTaskId,
    isForRenewal,
    history,
    invoiceExists
  } = props;

  const role = localStorage.getItem("userRoles");
  const userId = +localStorage.getItem("userId");
  const isAdmin = ADMIN_STREAMS.includes(role);

  const taskType = props.type.name;
  const linkUsers =
    state.lookups?.admins?.userList
      .filter(({ email }) => email !== localStorage.getItem("username"))
      .map(({ id, firstName, lastName }) => ({ id, firstName, lastName })) ||
    [];

  useEffect(() => {
    if (downloadUrl) window.open(downloadUrl);
  }, [downloadUrl]);

  useEffect(() => {
    if (!isEmpty(props.reviewInfo)) {
      setReviewInfo(props.reviewInfo);
      localStorage.setItem("reviewId", props.reviewInfo.id);
      const stepInfo = props.reviewInfo.steps?.filter(x => x.step === step);

      const currentStep = props.reviewInfo.steps.find(
        ({ step }) => step === props.step
      );

      if (currentStep) {
        setPassed(currentStep?.hasPassed);
      }

      if (!isEmpty(stepInfo)) {
        setReviewStepComments(stepInfo[0].comments);
      }
    }

    //set application owner
    const owners: any = {
      Company: {
        name: `${company?.createdBy?.firstName} ${company?.createdBy?.lastName}`,
        id: company?.createdBy?.id
      }
    };

    if (employee) {
      owners.Employee = {
        name: `${employee?.firstName} ${employee?.lastName}`,
        id: employee.id
      };
    }

    setApplicationOwner(owners);
  }, []);

  const [updateTask] = useMutation(UPDATE_TASK);

  const [getReviewInfo, { loading: loadingReview, refetch }] = useLazyQuery(
    GET_APPLICATION_REVIEW_INFO,
    {
      fetchPolicy: "network-only",
      onCompleted: async data => {
        setRelatedTaskReview(data.reviewByTask);
        await upsertFinanceReview({
          variables: {
            input: {
              step,
              hasPassed: passed,
              creatorId: +localStorage.getItem("userId"),
              companyId: data.reviewByTask ? undefined : company?.id,
              employeeId: data.reviewByTask ? undefined : employee?.id,
              taskId: id,
              comment: isEmpty(comment) ? undefined : comment,
              linkedUserId: +linkedUserId,
              isForRenewal,
              ...(props.relatedTaskId && {
                linkReviewToTaskId: props.relatedTaskId
              }),
              ...(+data?.reviewByTask?.id &&
                !reviewId && { id: +data?.reviewByTask?.id })
            }
          }
        });

        if (!linkedUserId) {
          await updateTask({
            variables: {
              input: {
                id,
                taskStatus: TASK_COMPLETED
              }
            }
          });
        }

        history.push("/admin/tasks");
      },
      onError: err => {
        if (err.graphQLErrors[0]?.message === "Not Authorised!") {
          refetch().then(res => {
            console.log(res.data);
          });
        }
      }
    }
  );

  const [upsertReview] = useMutation(
    reviewId ? UPDATE_APPLICATION_REVIEW : CREATE_APPLICATION_REVIEW,
    {
      onCompleted: data => {
        const reviewData = reviewId ? data.updateReview : data.createReview;
        setReviewInfo(reviewData);
        localStorage.setItem("reviewId", reviewData?.id);

        const stepInfo = reviewData.steps?.filter(x => x.step === step);
        if (stepInfo && stepInfo[0].comments)
          setReviewStepComments(stepInfo[0].comments);

        if (taskType !== "Financial Ticket") setComment("");

        localStorage.setItem(`${step}Saved`, "true");
        setIsSubmitting(false);
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Step review saved successfully"
        );
      },
      onError: err => {
        setIsSubmitting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error saving review, please try again "
        );
      }
    }
  );

  const [createRenewalReview] = useMutation(CREATE_RENEWAL_REVIEW, {
    onCompleted: () => {
      setIsSubmitting(false);
      history.push("/admin/tasks");
    },
    onError: err => {
      notifyError(err);
      setIsSubmitting(false);
    }
  });

  const [createFinancialReview] = useMutation(CREATE_FINANCIAL_REVIEW, {
    onCompleted: () => {
      setIsSubmitting(false);
      history.push("/admin/tasks");
    },
    onError: err => {
      notifyError(err);
      setIsSubmitting(false);
    }
  });

  const [createReviewComment] = useMutation(CREATE_REVIEW_COMMENT, {
    onCompleted: () => {
      setIsSubmitting(false);
      history.push("/admin/tasks");
    },
    onError: err => {
      notifyError(err);
      setIsSubmitting(false);
    }
  });

  const toggleResult = e => {
    setPassed(e.target.value);
  };

  const changeFailReason = e => {
    setFailedReason(e.target.value);
    switch (e.target.value) {
      case "fraud":
        setComment(
          "Application deemed fraudulent by CFDC Finance Administrator. If you believe this to be incorrect, please contact CFDC Finance Admin"
        );
        break;
      case "document-error":
        setComment(
          "There was an issue with the supporting documents provided. If you believe this to be incorrect, please contact CFDC Finance Admin"
        );
        break;
      case "short-payment":
        setComment(
          "Payment amount was insufficient. Full payment must be made before application can be accepted"
        );
        break;
      default:
        break;
    }
  };

  const [upsertFinanceReview] = useMutation(
    relatedTaskReview ? UPDATE_APPLICATION_REVIEW : CREATE_APPLICATION_REVIEW,
    {
      onCompleted: () => {
        setComment("");
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Step review saved successfully to application"
        );
      }
    }
  );

  const [createDocument] = useMutation(CREATE_DOCUMENT);

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "no-cache",
    onError: err => {
      setLoadingDoc(false);
      console.error("generate document error", err);
      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setLoadingDoc(false);
      setDownloadUrl(data.preSignedDownloadUrl);
    }
  });

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: async ({ preSignedUrl }) => {
      if (preSignedUrl) {
        await uploadToS3(preSignedUrl, file.type, file);
        const { data } = await createDocument({
          variables: {
            input: {
              documentName: filePath?.name,
              documentPath: filePath?.path,
              documentTypeId: 27,
              documentStatusId: 1,
              documentFormat: file.type,
              forCompany: true,
              companyId: company?.id || 1
            }
          }
        });

        setUploadedDocs([...uploadedDocs, data.createDocument.id]);
      }
    },
    onError: err => {
      console.error({ err });
    }
  });

  const _isAdmin = (userId: string): boolean => {
    return state.lookups?.admins?.userList?.some(a => a.id === userId) ?? false;
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setUploading(true);
    setFile(file);

    const ext = file.type.split("/").pop();
    const name = `user_upload_${Date.now()}`;
    const path = `review-documents/${name}.${ext}`;
    setFilePath({ path, name });

    getPresignedUrl({
      variables: {
        input: {
          filePath: path,
          contentType: file.type,
          forCompany: false,
          companyName: "Admin"
        }
      }
    });

    setTimeout(() => {
      setUploading(false);
      onSuccess();
    }, 3500);
  };

  const handleViewDocument = (filePath: string) => {
    setLoadingDoc(true);
    getDownloadUrl({
      variables: {
        input: {
          filePath,
          forCompany: true
        }
      }
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const variables = {
      input: {
        step,
        hasPassed: passed,
        creatorId: userId,
        companyId: company?.id,
        taskId: id,
        comment: !isEmpty(comment) ? comment : undefined,
        ...(taskType === "Audit Certificate" && {
          createFinancialTicket,
          financialTicketAmount
        })
      }
    };

    if (!isBoolean(passed) && comment) {
      await createReviewComment({
        variables: {
          input: {
            step,
            hasPassed: passed,
            creatorId: userId,
            companyId: company?.id,
            taskId: id,
            comment
          }
        }
      });
    } else if (
      ["Penalty (Complaint)", "Audit Certificate"].includes(taskType)
    ) {
      await createFinancialReview({ variables });
    } else if (taskType === "Renewal") {
      await createRenewalReview({ variables });
    } else if (taskType === "Financial Ticket") {
      await getReviewInfo({
        variables: {
          taskId:
            taskType === "Audit Certificate"
              ? id
              : props.relatedTaskId || props.relatedTasks[0]?.id
        }
      });
    } else {
      const input = {
        ...(reviewId && { id: +reviewId }),
        step,
        hasPassed: passed,
        creatorId: +localStorage.getItem("userId"),
        companyId: reviewId ? undefined : company?.id,
        employeeId: reviewId ? undefined : employee?.id,
        taskId: reviewId ? undefined : id,
        comment: isEmpty(comment) ? undefined : comment,
        linkedUserId: +linkedUserId,
        ...(props.linkReviewToTaskId && {
          linkReviewToTaskId: props.linkReviewToTaskId
        }),
        ...(uploadedDocs.length && { documents: uploadedDocs }),
        registrationTaskId
      };

      await upsertReview({
        variables: { input }
      });
    }
  };

  return (
    <div className={props.relatedTaskId === 0 ? "no-interact" : ""}>
      {isAdmin && (
        <>
          <Divider />
          <h3>Review</h3>
          <br />
          <Radio.Group onChange={toggleResult} value={passed}>
            <Radio>Pending</Radio>
            <Radio
              disabled={taskType === "Renewal" && !invoiceExists}
              value={true}
            >
              Pass
            </Radio>
            <Radio value={false}>Fail</Radio>
          </Radio.Group>

          {taskType === "Audit Certificate" && (
            <>
              <br />
              <br />
              <h4>Do you want to create a financial ticket?</h4>
              <Radio.Group
                onChange={e => setCreateFinancialTicket(e.target.value)}
                value={createFinancialTicket}
              >
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
              <br />
              <br />
              {createFinancialTicket && (
                <Row gutter={12}>
                  <Col span={3}>
                    <label>Amount:</label>
                  </Col>
                  <Col span={8}>
                    <InputNumber
                      style={{ width: "150px" }}
                      onChange={v => setFinancialTicketAmount(v)}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
      {!passed && ["Financial Ticket", "Renewal"].includes(props.type.name) && (
        <>
          <p style={{ paddingTop: "10px" }}>Reason for failing application:</p>
          <Radio.Group onChange={changeFailReason} value={failedReason}>
            <Radio value="document-error">Documentation incomplete</Radio>
            <Radio value="short-payment">Short Payment</Radio>
            <Radio value="fraud">Fraud</Radio>
          </Radio.Group>
        </>
      )}
      <Divider />
      <div>
        <h3>Comments</h3>
        <Spin spinning={loadingDoc}>
          {reviewInfo &&
            !isEmpty(reviewStepComments) &&
            reviewStepComments.map(comment => {
              return (
                <Comment
                  key={comment.id?.toString()}
                  author={`${comment?.author?.firstName} ${
                    comment?.author?.lastName
                  } ${comment?.author?.id === props.userId ? "(You)" : ""}`}
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: _isAdmin(comment?.author?.id)
                          ? "rgb(82, 196, 26)"
                          : "#408ef3"
                      }}
                      icon="user"
                    />
                  }
                  content={
                    <div>
                      <span>{comment.body}</span>
                      <br />
                      {comment?.documents?.map(({ documentPath }) => (
                        <span
                          onClick={() => handleViewDocument(documentPath)}
                          className="purple-link pointer"
                          key={documentPath}
                        >
                          {documentPath.split("/")[1]}
                        </span>
                      ))}
                    </div>
                  }
                  datetime={format(
                    parseISO(comment.createdDate),
                    "yyyy-MM-dd hh:mm:ss"
                  )}
                />
              );
            })}
        </Spin>
      </div>
      <h3> Add a comment</h3>
      <Card
        className="comment-card"
        cover={
          <Input.TextArea
            style={{ resize: "none" }}
            rows={4}
            placeholder="Start typing..."
            value={comment}
            disabled={isSubmitting}
            onChange={e => setComment(e.target.value)}
          />
        }
      >
        <Card.Meta
          title={
            <div className="comment-action">
              {isAdmin ? (
                <Menu
                  mode="vertical"
                  theme="light"
                  className="link-user-menu"
                  style={{
                    visibility: isEmpty(comment) ? "hidden" : "visible"
                  }}
                >
                  <Menu.SubMenu
                    title={
                      <span>
                        <Icon
                          type="link"
                          style={{ fontSize: "26px", color: "darkslategray" }}
                        />
                        <span>Link user ({linkedUserName})</span>
                      </span>
                    }
                  >
                    {Object.entries(applicationOwner).map(i => (
                      <Menu.Item
                        key={i[1]?.id}
                        id={i[1]?.name}
                        onClick={e => {
                          setLinkedUserId(e.key);
                          setLinkedUserName(e.item.props.id);
                        }}
                      >
                        {i[1]?.name?.toUpperCase()}({i[0]})
                      </Menu.Item>
                    ))}
                    <PermissionsGuard
                      perform={"review:transfer_stream_user"}
                      yes={() => (
                        <Menu>
                          {linkUsers.map(u => (
                            <Menu.Item
                              key={u.id}
                              id={`${u.firstName} ${u.lastName}`}
                              onClick={e => {
                                setLinkedUserId(e.key);
                                setLinkedUserName(e.item.props.id);
                              }}
                            >
                              {u.firstName} {u.lastName} (Admin)
                            </Menu.Item>
                          ))}
                        </Menu>
                      )}
                    />
                  </Menu.SubMenu>
                </Menu>
              ) : (
                <Upload disabled={uploading} customRequest={handleUpload}>
                  <Button>
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
              )}
              <div>
                <Button
                  className="purple-button"
                  loading={isSubmitting || loadingReview}
                  onClick={handleSubmit}
                  style={{ width: "150px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default withRouter(ReviewComment);
