import React, { useState, useEffect, useMemo } from "react";
import { Layout, Icon, Dropdown, Button, Menu } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import "./index.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { PRIVATE_ROUTES } from "../../navigation/routes";
import logo from "../../assets/images/layout-logo.png";
import { signOut } from "../../utils/auth/signOut";
import Navigation from "./legalFinanceAdminNavigation";
import { GET_LOOKUPS, GET_USER_REGISTRATIONS } from "../../graphql/queries";
import {
  MEMBER_STREAM,
  ADMIN_STREAM,
  FINANCE_STREAM,
  LEGAL_STREAM,
  SUPER_USER_STREAM,
  EXECUTIVE_STREAM,
  APPLICATION_TO_BE_VERIFIED,
  APPLICATION_VERIFIED,
  MANUAL_REGISTRATION_ROLES
} from "../../constants";
import MemberNavigation from "./memberNavigation";
import SuperUserNavigation from "./superAdminNavigation";
import { useGlobalState } from "../../globalStore";
import { useFilterState } from "contexts/AdminFilterContext";
import { If } from "components/If";
import { notifyError } from "utils/notification";
import { CREATE_BATCH_REGISTRATION } from "graphql/mutations";
import { useRegistrationState } from "stores/registration";
import { useUserState } from "stores/user";

const { Header, Content, Sider } = Layout;

const stage = process.env.REACT_APP_RELEASE_STAGE;
const stageLabel = stage === "prod" ? "" : `[${stage.toUpperCase()}] `;

export default function DefaultLayout() {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [roles, setRoles] = useState("");
  const [permissions, setPermissions] = useState("");
  const [roleStream, setRoleStream] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const { setGlobalState, clearGlobalState, state } = useGlobalState();
  const { clearLocal } = useFilterState();

  const {
    setRegistrationState,
    setRegistrationStateObject,
    batchRegistrationId,
    manualRegistrationType
  } = useRegistrationState();

  const {
    clearUserState,
    setUserState,
    idNumber,
    firstName,
    lastName,
    email,
    stream
  } = useUserState();

  const isAdmin = [
    ADMIN_STREAM,
    FINANCE_STREAM,
    LEGAL_STREAM,
    SUPER_USER_STREAM,
    EXECUTIVE_STREAM
  ].includes(roleStream);

  const { refetch } = useQuery(GET_LOOKUPS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getLookups }) => {
      if (getLookups) setGlobalState({ lookups: getLookups });
    },
    onError: error => console.error("get_lookups_error", error)
  });

  useQuery(GET_USER_REGISTRATIONS, {
    onCompleted: data => {
      if (data?.getUserRegistrations && data.getUserRegistrations.length > 0) {
        const latestIndex = data.getUserRegistrations.length - 1;
        const {
          id,
          employee,
          employer,
          registrationType // TODO: sort out the registration type
        } = data.getUserRegistrations[latestIndex];

        setUserState("pendingApplications", data?.getUserRegistrations);

        setRegistrationStateObject({
          manualRegistrationApplicationId: id,
          manualRegisterId: employee?.id || undefined,
          manualRegisterCompanyId: employer?.id || undefined,
          manualRegistrationType: "New"
        });
      }
    },
    onError: error => {
      notifyError(error);
    }
  });

  const [createBatchRegistration, { loading }] = useMutation(
    CREATE_BATCH_REGISTRATION
  );

  useEffect(() => {
    debugger;
  }, []);

  useEffect(() => {
    if (!state.lookups) refetch();
  }, []);

  useEffect(() => {
    setRoles(localStorage.getItem("userRoles"));
    setRoleStream(localStorage.getItem("roleStream"));
    setPermissions(localStorage.getItem("permissions"));
    setApplicationStatus(localStorage.getItem("applicationStatus"));
  }, []);

  useEffect(() => {
    let isMounted = true;
    function collapseMenuOnRegistration() {
      if (isMounted && location.pathname === "/register") {
        setCollapsed(true);
      }
    }
    collapseMenuOnRegistration();
    return () => {
      isMounted = false;
    };
  }, [location]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const NavigatorProps = {
    permissions,
    roles,
    roleStream
  };

  let backgroundColor = [
    ADMIN_STREAM,
    FINANCE_STREAM,
    SUPER_USER_STREAM,
    EXECUTIVE_STREAM,
    LEGAL_STREAM
  ].includes(roleStream)
    ? "darkgreen"
    : roleStream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      roles.includes("Sole Proprietor")
    ? "#ca8c2b"
    : roleStream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      roles.includes("Employee")
    ? "#c92127"
    : roleStream === MEMBER_STREAM &&
      [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
        applicationStatus
      ) &&
      roles.includes("Company")
    ? "#292a66"
    : "white";

  async function batchRegistration() {
    if (batchRegistrationId) {
      history.push(`/register/batch-registration/${batchRegistrationId}`);
    }

    try {
      const { data, errors } = await createBatchRegistration({
        variables: {
          input: {}
        }
      });

      if (errors) {
        throw errors[0];
      }

      const batchRegData = data.createBatchRegistration;
      setRegistrationState("batchRegistrationId", batchRegData.id);

      history.push(`/register/batch-registration/${batchRegData.id}`);
    } catch (error) {
      // @ts-ignore
      notifyError(error);
    }
  }

  const manualRegEnabled = useMemo(() => {
    return !!batchRegistrationId && !loading;
  }, [batchRegistrationId, manualRegistrationType, loading]);

  const batchRegEnabled = useMemo(() => {
    return !!manualRegistrationType && !loading && !batchRegistrationId;
  }, [batchRegistrationId, manualRegistrationType, loading]);

  const newItemMenu = (
    <Menu>
      <Menu.Item disabled={batchRegEnabled} onClick={batchRegistration}>
        {batchRegistrationId
          ? "Continue Batch Registration"
          : "Batch Registration"}
      </Menu.Item>
      {MANUAL_REGISTRATION_ROLES.includes(roleStream) && (
        <Menu.Item
          disabled={manualRegEnabled}
          onClick={() => {
            history.push("/admin/team/manual-register");
          }}
        >
          {manualRegistrationType && !batchRegistrationId
            ? "Continue Registration"
            : "Manual Registration"}
        </Menu.Item>
      )}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <div
        style={{
          padding: "10px",
          borderBottom: "0.1px solid gray",
          marginBottom: "10px"
        }}
      >
        <h3>
          {firstName} {lastName} ({stream})
        </h3>
        <h4 style={{ textAlign: "center" }}>{email}</h4>
      </div>
      <Menu.Item
        onClick={() => {
          history.push(isAdmin ? "/admin/profile" : "/profile");
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          clearUserState();
          clearLocal();
          signOut({
            email,
            idNumber
          });
          clearGlobalState();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Sider
        width="20%"
        trigger={null}
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo-container">
          <img
            src={logo}
            alt="CFDC Logo"
            className="logo"
            style={{
              marginLeft: collapsed ? 0 : "16px"
            }}
          />
        </div>
        <hr />
        <If condition={isAdmin && ![SUPER_USER_STREAM].includes(roleStream)}>
          <Navigation {...NavigatorProps} />
        </If>
        {roleStream === MEMBER_STREAM && (
          <MemberNavigation {...NavigatorProps} />
        )}
        {[SUPER_USER_STREAM].includes(roleStream) && (
          <SuperUserNavigation {...NavigatorProps} />
        )}
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            fontFamily: "system-ui",
            backgroundColor: backgroundColor
          }}
          className="header"
        >
          <div className="header-content">
            <Icon
              className="trigger"
              type={collapsed ? "menu-unfold" : "menu-fold"}
              style={{
                color: backgroundColor === "white" ? "black" : "white"
              }}
              onClick={onCollapse}
            />
            {[
              ADMIN_STREAM,
              FINANCE_STREAM,
              LEGAL_STREAM,
              SUPER_USER_STREAM,
              EXECUTIVE_STREAM
            ].includes(roleStream) ? (
              <h3>{stageLabel}CFDC Portal: Admin</h3>
            ) : roleStream === MEMBER_STREAM &&
              [APPLICATION_TO_BE_VERIFIED, APPLICATION_VERIFIED].includes(
                applicationStatus
              ) ? (
              <h3>
                {stageLabel}CFDC Portal: Member({roles}){" "}
              </h3>
            ) : (
              ""
            )}
            <div>
              <If condition={isAdmin}>
                <Dropdown overlay={newItemMenu} placement="bottomCenter">
                  <Button
                    loading={loading}
                    icon="plus"
                    style={{ marginRight: "10px" }}
                  />
                </Dropdown>
              </If>

              <Dropdown overlay={userMenu} placement="bottomCenter">
                <Button icon="user" style={{ marginRight: "10px" }} />
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="content-wrapper">
          <Switch>
            {PRIVATE_ROUTES.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
