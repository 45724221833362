import React from "react";
import { Button } from "antd";
import Completed from "../../assets/images/task_complete.svg";

export const Success = () => {
  return (
    <>
      <div className="submission-result">
        <div className="form-item-block">
          <div className="flex-column">
            <h2>Complaint successfully submitted</h2>
            <span>
              Your complaint has been submitted to our administrators
              successfully. The complaint at hand will be handled by our
              administrators with the debt collector provided. We will notify
              you on the outcome and steps going forward.
            </span>
            <br />
            <br />
            <Button
              type="default"
              href="https://www.cfdc.org.za/"
              className="purple-button"
            >
              Back to website
            </Button>
          </div>
          <img
            src={Completed}
            alt="Successful registration"
            className="result-icon"
            style={{ height: "60%", width: "50%" }}
          />
        </div>
      </div>
    </>
  );
};
