import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  Button,
  Spin,
  Divider,
  Badge,
  Dropdown,
  Menu,
  Icon
} from "antd";
import { isEmpty } from "lodash";
import { format, parseISO, max } from "date-fns";
import { useLazyQuery } from "@apollo/react-hooks";
import { useHistory, withRouter } from "react-router";
import { GET_COMPANIES_BY_MULTIFILTER } from "../graphql/queries";
import CompanyFilters from "./filters";
import { TableLoader } from "../../../components/UserManagement/TableContentPlaceholder";
import InternalErrorPage from "../../InternalErrorPage";
import ChangeCompanyModal from "./ChangeCompanyModal";
import CreateFinancialTaskModal from "./CreateFinancialTaskModal";
import "../../Projects/project.css";
import { useGlobalState } from "../../../globalStore";
import { MANUAL_REGISTRATION_ROLES } from "../../../constants";
import ChangeCertificateDateModal from "components/UserManagement/ChangeCertificateDates";
import BlacklistEntityModal from "./BlacklistEntityModal";

const isActiveMultiFilter = multiFilter => {
  let activeStatus = false;
  for (let filterKey of Object.keys(multiFilter)) {
    if (multiFilter[filterKey]) {
      activeStatus = true;
    }
  }
  return activeStatus;
};

const defaultMultiFilter = {
  generalSearch: false,
  type: false,
  status: false,
  sort: "DESC"
};

function CompanyList({ mode }: any) {
  const history = useHistory();
  const { state } = useGlobalState();
  const [companyList, setCompanyList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [skip, setSkip] = useState(0);
  const [initialising, setInitialising] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [blacklistModalVisible, setBlacklistModalVisible] = useState(false);
  const [financialTaskModalVisible, setFinancialTaskModalVisible] = useState(
    false
  );
  const [changeDateModalVisible, setChangeDateModalVisible] = useState(false);
  const [typeOfChange, setTypeOfChange] = useState<"delete" | "status">();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [multiFilter, setMultiFilter] = useState(
    Object.assign({}, defaultMultiFilter)
  );
  const [useMode] = useState(mode);
  const pageSize = 10;
  const roleStream = localStorage.getItem("userRoles");

  const handlePagination = page => {
    setSkip((page - 1) * pageSize);
    setCurrentPage(page);
  };

  const filtersRef = useRef();

  const clearFilters = () => {
    if (filtersRef && filtersRef.current) {
      // @ts-ignore: Object is possibly undefined
      filtersRef.current.resetFilters();
      let newMultiFilter = Object.assign({}, defaultMultiFilter);
      setMultiFilter(newMultiFilter);
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterCompanyQuery(newMultiFilter, 0, true);
    }
  };

  const [getFilteredCompanyList] = useLazyQuery(GET_COMPANIES_BY_MULTIFILTER, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setCompanyList(data.companiesByMultiFilter.companyList);
      if (data.companiesByMultiFilter.total) {
        setListTotal(data.companiesByMultiFilter.total);
      }
      setLoading(false);
      setInitialising(false);
      setShowSpin(false);
    },
    onError: error => {
      setError(error.message);
    }
  });

  const [getFilteredCompanyListNoCache] = useLazyQuery(
    GET_COMPANIES_BY_MULTIFILTER,
    {
      onCompleted: data => {
        setCompanyList(data.companiesByMultiFilter.companyList);
        if (data.companiesByMultiFilter.total) {
          setListTotal(data.companiesByMultiFilter.total);
        }
        setLoading(false);
        setInitialising(false);
        setShowSpin(false);
      },
      onError: error => {
        setError(error.message);
      },
      fetchPolicy: "network-only"
    }
  );

  const runMultiFilterCompanyQuery = (
    useMultiFilter,
    useSkip,
    skipCache = false
  ) => {
    setShowSpin(true);
    setLoading(true);
    let postFilters = {};
    for (let [key, value] of Object.entries(useMultiFilter)) {
      if (value) {
        postFilters[key] = value;
      }
    }
    if (!skipCache) {
      getFilteredCompanyList({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize
          }
        }
      });
    } else {
      getFilteredCompanyListNoCache({
        variables: {
          input: {
            ...postFilters,
            includeTotal: useSkip === 0 ? true : false,
            skip: useSkip,
            take: pageSize
          }
        }
      });
    }
  };

  const handleCompanyFilterChange = (value, filterType, reset) => {
    let newMultiFilter = Object.assign(multiFilter, { [filterType]: value });
    setMultiFilter(newMultiFilter);
    if (newMultiFilter && isActiveMultiFilter(newMultiFilter)) {
      setSkip(0);
      setCurrentPage(1);
      runMultiFilterCompanyQuery(newMultiFilter, 0);
    }
  };

  useEffect(() => {
    runMultiFilterCompanyQuery(multiFilter, skip);
  }, [currentPage, skip]);

  const modalProps = {
    ref: null,
    visible: modalVisible,
    setModalVisible,
    companyDetails: selectedCompany,
    onCancel: () => setModalVisible(false),
    refreshList: clearFilters,
    typeOfChange
  };

  const columns = [
    {
      title: "Company",
      key: "company",
      render: record =>
        (record.registeredName
          ? record.registeredName
          : record.tradingName
        ).toUpperCase()
    },
    {
      title: "# of Users",
      key: "userCount",
      dataIndex: "employmentCount"
    },
    {
      title: "Membership #",
      dataIndex: "membershipNumber",
      key: "membershipNumber"
    },
    {
      title: "Valid Until",
      render: ({ certificates }) => {
        if (certificates.length === 0) return "N/A";
        return format(
          max(certificates.map(({ toDate }) => parseISO(toDate))),
          "yyyy-MM-dd"
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: record => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        ) : (
          ""
        );
      }
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: record => {
        const items = [
          <Menu.Item
            key="View user profile"
            onClick={() => history.push(`/admin/company/${record.id}`)}
          >
            View company
          </Menu.Item>
        ];

        if (roleStream === "Super User") {
          items.push(
            <Menu.Item
              key="Delete Company"
              onClick={() => {
                setTypeOfChange("delete");
                setSelectedCompany(record);
                setModalVisible(true);
              }}
            >
              Delete Company
            </Menu.Item>,
            <Menu.Item
              key="Blacklist Company"
              onClick={() => {
                setSelectedCompany(record);
                setBlacklistModalVisible(true);
              }}
            >
              Blacklist Company
            </Menu.Item>,
            <Menu.Item
              key="Change Certificate Dates"
              disabled={!record?.certificates?.length}
              onClick={() => {
                setSelectedCompany(record);
                setChangeDateModalVisible(true);
              }}
            >
              Change Certificate Dates
            </Menu.Item>,
            <Menu.Item
              key="Change Status"
              onClick={() => {
                setTypeOfChange("status");
                setSelectedCompany(record);
                setModalVisible(true);
              }}
            >
              Change Status
            </Menu.Item>,
            <Menu.Item
              key="Create Financial Ticket"
              onClick={() => {
                setSelectedCompany(record);
                setFinancialTaskModalVisible(true);
              }}
            >
              Create Financial Ticket
            </Menu.Item>
          );
        }

        return (
          <Dropdown
            overlay={<Menu>{items.map(i => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <a
              className="ant-dropdown-link purple-link"
              onClick={e => e.preventDefault()}
            >
              More
              <Icon type="down" title="member actions dropdown" />
            </a>
          </Dropdown>
        );
      }
    }
  ];

  if (error) {
    return <InternalErrorPage error={error} />;
  }

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <div className="col-sm-12 col-md-12 placeholder-table-card">
        <Card>
          <div className="card-header">
            <h3>Company Management</h3>
            <div>
              {MANUAL_REGISTRATION_ROLES.includes(roleStream) && (
                <Button
                  onClick={() => {
                    history.push("/admin/team/manual-register");
                  }}
                  className="red-button"
                  style={{ marginRight: "20px" }}
                >
                  {state.manualRegistrationType
                    ? "Continue Registration"
                    : "Manual Registration"}
                </Button>
              )}
              <Button className="purple-button" onClick={clearFilters}>
                Clear filters
              </Button>
            </div>
          </div>
          {initialising && <TableLoader />}
          <div className={loading ? "no-interact" : null}>
            {!initialising && (
              <CompanyFilters
                ref={filtersRef}
                setError={setError}
                handleCompanyFilterChange={handleCompanyFilterChange}
                defaultMultiFilter={defaultMultiFilter}
                mode={useMode}
              />
            )}
            {companyList && !isEmpty(companyList) && (
              <>
                <Divider />
                <Table
                  rowKey="id"
                  columns={columns}
                  pagination={{
                    current: currentPage,
                    pageSize,
                    onChange: page => handlePagination(page),
                    total: listTotal,
                    showTotal: () => <h3>Total: {listTotal}</h3>
                  }}
                  dataSource={companyList}
                />
              </>
            )}
          </div>
          {!initialising && companyList && isEmpty(companyList) && (
            <>
              <Divider />
              <Table />
            </>
          )}
        </Card>
      </div>
      <ChangeCompanyModal {...modalProps} />
      <ChangeCertificateDateModal
        visible={changeDateModalVisible}
        onCancel={() => setChangeDateModalVisible(!changeDateModalVisible)}
        data={selectedCompany}
        isCompany={true}
        refreshList={clearFilters}
      />
      {financialTaskModalVisible && (
        <CreateFinancialTaskModal
          {...{
            ref: null,
            companyDetails: selectedCompany,
            onCancel: () => setFinancialTaskModalVisible(false),
            visible: financialTaskModalVisible,
            setModalVisible: setFinancialTaskModalVisible
          }}
        />
      )}
      {blacklistModalVisible && (
        <BlacklistEntityModal
          refreshList={clearFilters}
          type="company"
          entity={selectedCompany}
          onCancel={() => {
            setBlacklistModalVisible(false);
          }}
        />
      )}
    </>
  );
}

export default withRouter(CompanyList);
