import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_STATUSES
} from "../../../graphql/queries";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const TaskFilters = (
  { handleTransactionFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const [transactionTypeList, setTransactionTypeList] = useState([]);
  const [transactionStatusList, setTransactionStatusList] = useState([]);
  const transactionIdFilterRef = useRef();
  const transactionTypeFilterRef = useRef();
  const transactionStatusFilterRef = useRef();
  const taskStatusFilterRef = useRef();
  const userFilterRef = useRef();
  const createdFilterRef = useRef();
  const sortFilterRef = useRef();
  const invoiceNumberRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      let conditionalRefs = [];
      if (mode === "member-overview-transactions") {
        conditionalRefs = [
          {
            ref: taskStatusFilterRef,
            defaultValue: defaultMultiFilter["taskStatus"]
          }
        ];
      } else if (mode === "all-transactions") {
        conditionalRefs = [
          {
            ref: userFilterRef,
            defaultValue: defaultMultiFilter["userId"]
          }
        ];
      }
      resetFilterCollection([
        {
          ref: transactionIdFilterRef,
          defaultValue: defaultMultiFilter["transactionId"]
        },
        {
          ref: transactionTypeFilterRef,
          defaultValue: defaultMultiFilter["transactionTypeId"]
        },
        {
          ref: transactionStatusFilterRef,
          defaultValue: defaultMultiFilter["transactionStatusId"]
        },
        {
          ref: createdFilterRef,
          defaultValue: defaultMultiFilter["dateStart"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        },
        {
          ref: invoiceNumberRef,
          defaultValue: defaultMultiFilter["invoiceNumber"]
        },
        ...conditionalRefs
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  const [getTransactionTypes] = useLazyQuery(GET_TRANSACTION_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setTransactionTypeList(data.transactionTypes);
    },
    onError: error => {
      setError(error);
    }
  });

  const [getTransactionStatuses] = useLazyQuery(GET_TRANSACTION_STATUSES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setTransactionStatusList(data.transactionStatuses);
    },
    onError: error => {
      setError(error);
    }
  });

  useEffect(() => {
    getTransactionTypes({ variables: {} });
    getTransactionStatuses({ variables: {} });
  }, []);

  const handleUserFilterUpdate = data => {
    if (data * 1 >= 0) {
      handleTransactionFilterChange(data, "assignedTo");
    }
  };

  const handleTaskFilterUpdate = data => {
    if (data * 1 >= 0) {
      handleTransactionFilterChange(data * 1, "id");
    }
  };

  const handleInvoiceNumberUpdate = data => {
    data && handleTransactionFilterChange(data, "invoiceNumber");
  };

  const debouncedTransactionIdFilterUpdate = useCallback(
    debounce(handleTaskFilterUpdate, 250),
    []
  );

  const debouncedInvoiceNumberFilterUpdate = useCallback(
    debounce(handleInvoiceNumberUpdate, 250),
    []
  );

  const debouncedUserFilterUpdate = useCallback(
    debounce(handleUserFilterUpdate, 250),
    []
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Transaction ID:</label>
            <Input
              ref={transactionIdFilterRef}
              type="number"
              placeholder="Search transaction by ID"
              style={{ width: "calc(100% - 75px)" }}
              onChange={event =>
                debouncedTransactionIdFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Type:</label>
            <Select
              ref={transactionTypeFilterRef}
              placeholder="Select transaction type"
              onChange={value => handleTransactionFilterChange(value, "typeId")}
              style={{ width: "calc(100% - 75px)" }}
            >
              {transactionTypeList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Status:</label>
            <Select
              ref={transactionStatusFilterRef}
              placeholder="Select transaction status"
              onChange={value =>
                handleTransactionFilterChange(value, "transactionStatusId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {transactionStatusList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {["all-tasks"].indexOf(mode) > -1 && (
          <Col span={8}>
            <div className="flex-center-space-between">
              <label>User:</label>
              <Input
                ref={userFilterRef}
                placeholder="Search user ID"
                type="number"
                onChange={event =>
                  debouncedUserFilterUpdate(event.target.value)
                }
                style={{ width: "calc(100% - 75px)" }}
              />
            </div>
          </Col>
        )}
        {mode === "all-transactions" && (
          <Col span={8}>
            <div className="flex-center-space-between">
              <label>Invoice Number:</label>
              <Input
                ref={invoiceNumberRef}
                type="text"
                placeholder="Search transaction by invoice number"
                style={{ width: "calc(100% - 75px)" }}
                onChange={event =>
                  debouncedInvoiceNumberFilterUpdate(event.target.value)
                }
              />
            </div>
          </Col>
        )}
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort: </label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={value => handleTransactionFilterChange(value, "sort")}
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(TaskFilters);
