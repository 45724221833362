import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_CAPACITY_LIST,
  GET_BUSINESS_UNITS_LIST
} from "../../../graphql/queries";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const EmployeeFilters = (
  { handleEmployeeFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const [employeeCapacityList, setEmployeeCapacityList] = useState([]);
  const [employeeBusinessUnitList, setEmployeeBusinessUnitList] = useState([]);
  const usernameFilterRef = useRef();
  const statusFilterRef = useRef();
  const businessUnitIdFilterRef = useRef();
  const capacityFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: usernameFilterRef,
          defaultValue: defaultMultiFilter["username"]
        },
        {
          ref: capacityFilterRef,
          defaultValue: defaultMultiFilter["capacity"]
        },
        {
          ref: statusFilterRef,
          defaultValue: defaultMultiFilter["status"]
        },
        {
          ref: businessUnitIdFilterRef,
          defaultValue: defaultMultiFilter["businessUnitId"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        }
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  const [getEmployeeCapacities] = useLazyQuery(GET_CAPACITY_LIST, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setEmployeeCapacityList(data.capacityList);
    },
    onError: error => {
      setError(error);
    }
  });

  const [getEmployeeBusinessUnits] = useLazyQuery(GET_BUSINESS_UNITS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setEmployeeBusinessUnitList(data.businessUnitList);
    },
    onError: error => {
      setError(error);
    }
  });

  useEffect(() => {
    getEmployeeCapacities({ variables: {} });
    getEmployeeBusinessUnits({ variables: {} });
  }, []);

  const handleEmployeeFilterUpdate = data => {
    handleEmployeeFilterChange(data, "username");
  };

  const debouncedEmployeeUsernameFilterUpdate = useCallback(
    debounce(handleEmployeeFilterUpdate, 250),
    []
  );

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Username:</label>
            <Input
              ref={usernameFilterRef}
              placeholder="Search employee by name"
              style={{ width: "calc(100% - 75px)" }}
              onChange={event =>
                debouncedEmployeeUsernameFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Capacity:</label>
            <Select
              ref={capacityFilterRef}
              placeholder="Select employee capacity"
              onChange={value =>
                handleEmployeeFilterChange(value, "capacityId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {employeeCapacityList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Status:</label>
            <Select
              ref={statusFilterRef}
              placeholder="Select employee status"
              onChange={value => handleEmployeeFilterChange(value, "status")}
              style={{ width: "calc(100% - 75px)" }}
            >
              <Option value="active">Active</Option>
              <Option value="deregistered">Deregistered</Option>
              <Option value="deactivated">Deactivated</Option>
              <Option value="suspended">Suspended</Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Business Unit:</label>
            <Select
              ref={businessUnitIdFilterRef}
              placeholder="Select employee business unit"
              onChange={value =>
                handleEmployeeFilterChange(value, "businessUnitId")
              }
              style={{ width: "calc(100% - 75px)" }}
            >
              {employeeBusinessUnitList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort:</label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={value => handleEmployeeFilterChange(value, "sort")}
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(EmployeeFilters);
