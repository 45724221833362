import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { Modal, Form, Input, Select, Spin } from "antd";
import { GET_USER_FILTERS } from "../graphql/queries";
const { Option } = Select;

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  showSpin,
  form,
  initialData
}) => {
  const { getFieldDecorator } = form;

  const [userTypes, setUserTypes] = useState<any>();
  const [getUserTypes, { loading }] = useLazyQuery(GET_USER_FILTERS, {
    onCompleted: data => {
      let userTypesCollection = data.userFilters.userTypes.filter(
        x =>
          !["Employee", "Company", "Sole Proprietor", "Super User"].includes(
            x.name
          )
      );
      setUserTypes(userTypesCollection);
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    getUserTypes();
  }, []);

  const userTypeOptions: any = userTypes
    ? userTypes.map(type => {
        return (
          <Option key={"user-type-" + type.id} value={type.id}>
            {type.name}
          </Option>
        );
      })
    : [];

  return (
    <Modal
      visible={visible}
      title="Create Team member"
      okText="Invite"
      onCancel={onCancel}
      onOk={onCreate}
      okButtonProps={{
        className: "purple-button"
      }}
      okType="default"
    >
      {" "}
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <Form layout="vertical">
        <Form.Item label="First Name">
          {getFieldDecorator("firstName", {
            initialValue: initialData ? initialData.firstName : "",
            rules: [
              {
                required: true,
                message: "Please input user's first name!"
              }
            ]
          })(<Input placeholder="Please input user's first name!" />)}
        </Form.Item>

        <Form.Item label="Last Name">
          {getFieldDecorator("lastName", {
            initialValue: initialData ? initialData.lastName : "",
            rules: [
              {
                required: true,
                message: "Please input user's last name!"
              }
            ]
          })(<Input placeholder="Please input user's last name!" />)}
        </Form.Item>

        <Form.Item label="Email">
          {getFieldDecorator("email", {
            initialValue: initialData ? initialData.email : "",
            rules: [
              {
                required: true,
                message: "Please input user's email!"
              },
              {
                type: "email",
                message: "The input is not valid E-mail!"
              }
            ]
          })(<Input placeholder="Please input user's email!" />)}
        </Form.Item>

        <Form.Item label="ID Number">
          {getFieldDecorator("idNumber", {
            rules: [
              {
                required: true,
                message: "Please input user's ID Number!"
              }
            ]
          })(<Input placeholder="Please input user's ID Number!" />)}
        </Form.Item>

        <Form.Item label="Phone Number">
          {getFieldDecorator("phone", {
            rules: [
              { required: true, message: "Please input user's phone number!" }
            ]
          })(
            <Input
              addonBefore={
                <div>
                  <span role="img" aria-label="flag" style={{ color: "black" }}>
                    🇿🇦
                  </span>{" "}
                  +27
                </div>
              }
              placeholder="Please input user's phone number"
            />
          )}
        </Form.Item>

        <Form.Item label="User Type">
          {getFieldDecorator("userType", {
            rules: [
              {
                required: true,
                message: "Please select user type"
              }
            ]
          })(
            <Select loading={loading} placeholder="Select type">
              {userTypeOptions}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const InviteUserForm = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default InviteUserForm;
