import React from "react";
import { Card, Tabs } from "antd";
import ProfileManager from "./profile";
import PermissionsGuard from "../../../../components/Auth/can";
import AccessDenied from "../../../../components/Auth/accessDenied";

const Profile: React.FC<any> = ({ ...props }) => {
  return (
    <PermissionsGuard
      perform={"admin_team_profile:view"}
      yes={() => (
        <>
          <Card className="body-card">
            <Tabs tabPosition="left">
              <Tabs.TabPane
                tab="Basic Settings"
                key="1"
                className="tab-content"
              >
                <ProfileManager {...props} />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </>
      )}
      no={() => <AccessDenied permission="admin_team_profile:view" />}
    />
  );
};

export default Profile;
