import styled from "styled-components";
import {
  color,
  ColorProps,
  height,
  HeightProps,
  space,
  SpaceProps,
  width
} from "styled-system";

export type ImageProps = SpaceProps & ColorProps & HeightProps;

export default styled.img<ImageProps>`
  ${space};
  ${color};
  ${height};
  ${width};
  display: inline-block;
  max-width: 100%;
  height: auto;
`;
