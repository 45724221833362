import React, { useState } from "react";
import { Form, Button, Radio, Select, DatePicker, Spin, Row, Col } from "antd";
import { useQuery, useMutation } from "react-apollo";
import { GET_OFFENCES_LIST, GET_PREQUALIFICATION } from "../../graphql/queries";
import {
  CREATE_PREQUALIFICATION,
  UPDATE_PREQUALIFICATION
} from "../../graphql/mutations";
import "../Register/index.css";
import InternalErrorPage from "containers/InternalErrorPage";
import { openNotificationWithIcon } from "utils/notification";
import { isBoolean, isEmpty } from "lodash";
import { useHistory } from "react-router";
import moment from "moment";
import { If } from "components/If";
import { useRegistrationState } from "stores/registration";
import { FormComponentProps } from "antd/lib/form/Form";
import { useUserState } from "stores/user";

const { Option } = Select;

function PrequalificationForm(props: any) {
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [hasOffence, setHasOffence] = useState<boolean>();
  const [convicted, setConvicted] = useState<boolean>();
  const [curatedForLegalCapacity, setCuratedForLegalCapacity] = useState<
    boolean
  >();
  const history = useHistory();
  const [improperConduct, setImproperConduct] = useState<boolean>();
  const [appliedForExemption, setAppliedForExemption] = useState<boolean>();
  const [prequalification, setPrequalification] = useState<any>();
  const [prequalificationId, setPrequalificationId] = useState<number>();
  const { getFieldDecorator } = props.form;
  const [createPrequalification] = useMutation(CREATE_PREQUALIFICATION);
  const [updatePrequalification] = useMutation(UPDATE_PREQUALIFICATION);
  const {
    setRegistrationState,
    manualRegisterId,
    manualRegistrationType,
    applicationId,
    registeringAs
  } = useRegistrationState();
  const { id: uid } = useUserState();

  const userId = manualRegistrationType ? manualRegisterId : uid;

  const step = {
    title:
      registeringAs === "A Sole Proprietor"
        ? "Step 4: Prequalification"
        : "Prequalification",
    content: "Complete the short form that enables you for prequalification"
  };

  const {
    loading: loadingPrequalification,
    error: errorPrequalification,
    data: dataPrequalification
  } = useQuery(GET_PREQUALIFICATION, {
    skip: !userId,
    variables: { userId: userId },
    onCompleted: () => {
      if (!isEmpty(dataPrequalification.prequalification)) {
        const _prequalification = dataPrequalification.prequalification;

        setPrequalificationId(_prequalification?.id);
        setPrequalification(_prequalification);
        setHasOffence(_prequalification?.onTrial);
        setRegistrationState(
          "appliedForExemption",
          _prequalification.appliedForExemption
        );

        // curated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          history.push({
            pathname: "/register/fail/emancipation",
            state: { applicationId }
          });
        }
      }
    }
  });

  const {
    loading: loadingOffences,
    error: errorOffences,
    data: dataOffences
  } = useQuery(GET_OFFENCES_LIST);

  if (errorOffences || errorPrequalification) return <InternalErrorPage />;

  const { offenceList } = !loadingOffences && dataOffences;

  //selectable options of offences
  const offenceOptions: any = offenceList
    ? offenceList.map(type => {
        return (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        );
      })
    : [];

  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err && isBoolean(values.hasOffence) && values.hasOffence) {
        return;
      }

      // when employee is not on trial for offence
      if (!values.hasOffence) {
        const input = {
          id: prequalificationId ? prequalificationId : undefined,
          onTrial: values.hasOffence,
          convicted: false,
          appliedForExemption: false,
          curatedForLegalCapacity: false,
          dateOfTrial: null,
          typeOfOffence: null,
          guiltyOfImproperConduct: false,
          isProofOfExemptionApplicationUploaded: false,
          userId
        };

        return savePrequalification(input);
      }

      // when employee is on trial for offence - Yes to Q1
      const input = {
        id: prequalificationId ? prequalificationId : undefined,
        onTrial: values.hasOffence === "yes",
        convicted: values.convicted === "yes",
        appliedForExemption: values.appliedForExemption === "yes",
        curatedForLegalCapacity: values.curatedForLegalCapacity === "yes",
        dateOfTrial: values.dateOfTrialOffence,
        typeOfOffence: values.typeOfOffence ? +values.typeOfOffence : null,
        guiltyOfImproperConduct: values.improperConduct === "yes",
        userId
      };

      savePrequalification(input);
    });
  };

  const savePrequalification = (values: any) => {
    setIsSubmiting(true);

    const upsertPrequalification = prequalificationId
      ? updatePrequalification
      : createPrequalification;

    upsertPrequalification({
      variables: {
        input: { ...values }
      }
    })
      .then(async res => {
        const _prequalification = prequalificationId
          ? res.data.updatePrequalification
          : res.data.createPrequalification;

        setPrequalificationId(_prequalification.id);
        setPrequalification(_prequalification);

        setIsSubmiting(false);
        setRegistrationState("step3Saved", true);
        setRegistrationState("appliedForExemption", values.appliedForExemption);
        openNotificationWithIcon(
          "success",
          "Save Success",
          "Prequalification data successfuly saved"
        );

        // currated for legal capacity or has offence and convicted but has not applied for exemption
        // fail the application
        if (
          (_prequalification?.onTrial &&
            _prequalification?.convicted &&
            !_prequalification?.appliedForExemption) ||
          _prequalification?.curatedForLegalCapacity
        ) {
          window.location.replace("/register/fail/prequalification");
        }
      })
      .catch(err => {
        setIsSubmiting(false);
        openNotificationWithIcon("error", "Saving Error", err.message);
      });
  };

  return (
    <div className="flex-column">
      <div>
        <h3 className="step-title">{step.title}</h3>
        <p>{step.content}</p>
      </div>

      <Form>
        {(loadingOffences || loadingPrequalification) && <Spin />}
        <div className="flex-column input-select input-spacer">
          <Row gutter={[10, 10]} className="input-spacer">
            <Col span={16}>
              <span>
                1. Have you ever been found guilty of an offence of which
                violence, dishonesty, extortion or intimidation is an element or
                have in the preceding 10 years been convicted of such offence?{" "}
              </span>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("hasOffence", {
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.onTrial === true
                      ? "yes"
                      : "no"
                    : hasOffence,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate"
                    }
                  ]
                })(
                  <Select
                    placeholder="Please select"
                    className="full-width"
                    onChange={val => {
                      setHasOffence(String(val) === "yes");
                    }}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <If condition={hasOffence}>
            <Row gutter={[10, 10]}>
              <Col span={8} className="">
                <Form.Item label="Date of conviction">
                  {getFieldDecorator("dateOfTrialOffence", {
                    initialValue: !isEmpty(prequalification)
                      ? moment(prequalification.dateOfTrial)
                      : moment(),
                    rules: [
                      {
                        required: true,
                        message: "Please select date of trial or offence"
                      }
                    ]
                  })(<DatePicker className="full-width" />)}
                </Form.Item>
              </Col>

              <Col span={8} className="">
                <Form.Item label="Type of offence">
                  {getFieldDecorator("typeOfOffence", {
                    initialValue: !isEmpty(prequalification?.typeOfOffence)
                      ? prequalification.typeOfOffence?.id
                      : "",
                    rules: [
                      {
                        required: true,
                        message: "Please select date type of offense"
                      }
                    ]
                  })(
                    <Select placeholder="Please select" className="full-width">
                      {offenceOptions}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={8} className="">
                <Form.Item label="Convicted?">
                  {getFieldDecorator("convicted", {
                    initialValue: !isEmpty(prequalification)
                      ? prequalification.convicted === true
                        ? "yes"
                        : "no"
                      : convicted,
                    rules: [
                      {
                        required: true,
                        message: "Please indicate"
                      }
                    ]
                  })(
                    <Select
                      onChange={val => {
                        setConvicted(String(val) === "yes");
                      }}
                      value={convicted}
                      className="full-width"
                      placeholder="Please select"
                    >
                      <Option value={"yes"}>Yes</Option>
                      <Radio value={"no"}>No</Radio>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </If>

          <If
            condition={
              (isBoolean(convicted) && convicted) || prequalification?.convicted
            }
          >
            <Row gutter={[10, 10]}>
              <Col span={16}>
                <span>
                  1.1 Has an application for exemption in terms of section 10(2)
                  been lodged with the Ministry of Justice and Constitutional
                  Development?
                </span>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator("appliedForExemption", {
                    initialValue: !isEmpty(prequalification)
                      ? prequalification.appliedForExemption === true
                        ? "yes"
                        : "no"
                      : appliedForExemption,
                    rules: [
                      {
                        required: true,
                        message: "Please indicate"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Please select"
                      className="full-width"
                      onChange={val => {
                        setAppliedForExemption(String(val) === "yes");
                      }}
                    >
                      <Option value={"yes"}>Yes</Option>
                      <Option value={"no"}>No</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </If>

          <Row className="input-spacer">
            <Col span={16}>
              <span>
                2. Have you, in terms of section 15 of Act, been found guilty of
                improper conduct?
                <br />
                <small>
                  You will be required to provide proof of this at the end of
                  the registration process.
                </small>
              </span>
              <br />
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator("improperConduct", {
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.guiltyOfImproperConduct === true
                      ? "yes"
                      : "no"
                    : improperConduct,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate"
                    }
                  ]
                })(
                  <Select
                    onChange={val => {
                      setImproperConduct(String(val) === "yes");
                    }}
                    placeholder="Please select"
                    className="full-width"
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row className="input-spacer">
            <Col span={16}>
              <span>
                3. Have you ever been placed under curatorship for legal
                capacity?
              </span>
            </Col>
            <Col span={8} className="">
              <Form.Item>
                {getFieldDecorator("curatedForLegalCapacity", {
                  initialValue: !isEmpty(prequalification)
                    ? prequalification.curatedForLegalCapacity === true
                      ? "yes"
                      : "no"
                    : curatedForLegalCapacity,
                  rules: [
                    {
                      required: true,
                      message: "Please indicate"
                    }
                  ]
                })(
                  <Select
                    placeholder="Please select"
                    className="full-width"
                    onChange={val => {
                      setCuratedForLegalCapacity(String(val) === "yes");
                    }}
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="input-spacer">
            <Col span={24}>
              <span>
                4. Based on Credit Bureau results, the following indicators are
                reflected, please provide proof of the contrary where
                applicable:
              </span>
            </Col>
            <Col span={24} className="input-spacer">
              {/* Eagle Eye Results */}
            </Col>
          </Row>

          <Row>
            <Col span={16} />
            <Col span={8}>
              <Button
                className="btn-registration-capacity-selected full-width"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Save Prequalification
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

interface IProps extends FormComponentProps {
  nextStep?: () => void;
}

const Prequalification = Form.create<IProps>({ name: "normal_register" })(
  PrequalificationForm
);

export default Prequalification;
