import * as AWSCognito from "amazon-cognito-identity-js";
import { ID_TOKEN_NAME } from "../../constants";

export const authenticateUser = async (
  data: any,
  handleResult: CallableFunction
): Promise<any> => {
  try {
    const { idNumber, email, password } = data;

    const poolData: AWSCognito.ICognitoUserPoolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
    };
    const userPool = new AWSCognito.CognitoUserPool(poolData);

    const authenticationData = {
      Username: idNumber ? idNumber : email,
      Password: password
    };
    const authenticationDetails = new AWSCognito.AuthenticationDetails(
      authenticationData
    );
    const userData = {
      Username: idNumber ? idNumber : email,
      Pool: userPool
    };

    const cognitoUser = new AWSCognito.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        localStorage.setItem(ID_TOKEN_NAME, result.getIdToken().getJwtToken());
        localStorage.setItem("isAuthenticated", "true");
        handleResult(undefined, result, cognitoUser);
        Promise.resolve(result);
      },

      onFailure: function(err) {
        handleResult(err, undefined);
        Promise.resolve(err);
      },
      mfaRequired: function(codeDeliveryDetails) {
        var verificationCode = prompt("Please input verification code", "");
        cognitoUser.sendMFACode(verificationCode, this);
      }
    });
  } catch (error) {
    Promise.reject(error);
  }
};
