import React, { useState, useEffect, useCallback } from "react";
import { Form, Card, Divider, Skeleton, Descriptions } from "antd";
import { useQuery } from "react-apollo";
import { GET_USER } from "../Admin/graphql/queries";
import { isEmpty } from "lodash";
import "../Register/index.css";
import InternalErrorPage from "../InternalErrorPage";
import ContactForm from "./contactDetailsForm";
import AddressInfo from "./addressForm";
import RelatedPartyForm from "./relatedPartyForm";
import { Capacity } from "typings/types";
import { IUserData, IUserDataVars } from "interfaces/user";
import { useUserState } from "stores/user";
import { useRegistrationState } from "stores/registration";
import { If } from "components/If";
import { FormComponentProps } from "antd/lib/form/Form";

function BusinessInfoForm(props: any) {
  const [representativeSaved, setRepresentativeSaved] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [userCapacity, setUserCapacity] = useState<Capacity>();
  const [companyContactsInfo, setCompanyContactsInfo] = useState([]);
  const [isContactInfoSaved, setIsContactInfoSaved] = useState(false);
  const [
    postalPhysicalAddressesSaved,
    setPostalPhysicalAddressesSaved
  ] = useState(false);
  const [allRequiredContactsSaved, setAllRequiredContactsSaved] = useState(
    false
  );
  const [contactFormRef, setContactFormRef] = useState(null);
  const [addressFormRef, setAddressFormRef] = useState(null);

  useEffect(() => {
    if (contactFormRef) {
      contactFormRef.resetFields();
    }
    if (addressFormRef) {
      addressFormRef.resetFields();
    }
  }, [contactFormRef, addressFormRef]);

  useEffect(() => {
    checkRequiredFormsFilled();
  }, [
    postalPhysicalAddressesSaved,
    allRequiredContactsSaved,
    representativeSaved
  ]);

  const { email: username } = useUserState();
  const {
    setRegistrationStateObject: setRegistrationState,
    manualRegisterId: manualRegisterUserId,
    companyId
  } = useRegistrationState();

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser
    //refetch: refetchUser,
  } = useQuery<IUserData, IUserDataVars>(GET_USER, {
    variables: {
      input: !!manualRegisterUserId
        ? { id: manualRegisterUserId }
        : { email: username }
    },
    skip: isEmpty(username),
    onCompleted: () => {
      setUserInfo(dataUser.userData);
      const employment = dataUser.userData?.employment?.find(
        ({ company }) => company.id === companyId
      );
      setUserCapacity(employment?.capacity);
    }
  });

  const checkRequiredFormsFilled = () => {
    if (
      postalPhysicalAddressesSaved &&
      allRequiredContactsSaved &&
      representativeSaved
    ) {
      setRegistrationState({ step2Saved: true });
    }
  };

  const saveContactFormRef = useCallback(node => {
    if (node !== null) {
      setContactFormRef(node);
    }
  }, []);

  const saveAddressFormRef = useCallback(node => {
    if (node !== null) {
      setAddressFormRef(node);
    }
  }, []);

  const ContactFormProps = {
    ref: saveContactFormRef,
    onDone: (
      contactSaved: boolean,
      allRequireContactsSaved: boolean,
      contacts: any
    ) => {
      setIsContactInfoSaved(contactSaved);
      setCompanyContactsInfo(contacts);
      setAllRequiredContactsSaved(allRequireContactsSaved);
      checkRequiredFormsFilled();
    }
  };

  const AddressFormProps = {
    ref: saveAddressFormRef,
    sendAddressInfo: (postalPhysicalSaved: boolean) => {
      setPostalPhysicalAddressesSaved(postalPhysicalSaved);
      checkRequiredFormsFilled();
    }
  };

  const RelatedPartyFormProps = {
    ref: saveAddressFormRef,
    sendRelatedPartyInfo: (relatedPartySaved: boolean) => {
      setRepresentativeSaved(relatedPartySaved);
      checkRequiredFormsFilled();
    }
  };

  if (errorUser) {
    return <InternalErrorPage />;
  }

  const step = {
    title: "Step 2: Business and personal information",
    content: "Complete your basic personal information"
  };

  return (
    <>
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      <h2>Representative Information</h2>

      <If condition={loadingUser}>
        <Skeleton active paragraph={{ rows: 5 }} />
      </If>

      <If condition={!!userInfo}>
        <Card className="input-block-wrapper input-select">
          <Descriptions layout="vertical" title="Your Information">
            <Descriptions.Item label="ID/Passport Number">
              {userInfo?.idNumber}
            </Descriptions.Item>
            <Descriptions.Item label="First Name">
              {userInfo?.firstName}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {userInfo?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {userInfo?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {userInfo?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Capacity">
              {userCapacity?.name}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </If>

      <br />

      <h2>Business Information</h2>

      <br />
      <span>
        <label>Contact Information</label>
      </span>
      <br />
      <span>
        To add contact information in the table below, select type from the
        dropdown:
      </span>
      <br />
      <span>
        Mandatory Business Unit Contact to be added included Legal, Finance,
        Administration{" "}
      </span>
      <br />
      <br />

      <ContactForm {...ContactFormProps} />

      {(isContactInfoSaved || !isEmpty(companyContactsInfo)) && (
        <div className="flex-column input-select">
          <span>
            <label>Related Party information</label>
          </span>
          <br />
          <span>Please indicate all related parties in the table below</span>
          <br />
          <br />
          <RelatedPartyForm {...RelatedPartyFormProps} />
        </div>
      )}
      <AddressInfo {...AddressFormProps} />
    </>
  );
}

interface IProps extends FormComponentProps {
  nextStep?: () => void;
}

const BusinessInfo = Form.create<IProps>({ name: "normal_register" })(
  BusinessInfoForm
);
export default BusinessInfo;
