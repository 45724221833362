import React, { useState } from "react";
import { Table } from "antd";
import download from "downloadjs";

type Props = {
  reportList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
};

export default ({
  reportList = [],
  currentPage,
  pageSize,
  listTotal
}: Props) => {
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: "Company Name",
      dataIndex: "",
      key: "name",
      render: ({ name }) => name?.toUpperCase()
    },
    {
      title: "Month",
      dataIndex: "month"
    },
    {
      title: "Employees",
      dataIndex: "members"
    },
    {
      title: "Directors",
      dataIndex: "directors"
    },
    {
      title: "Entity",
      dataIndex: "entity"
    },
    {
      title: "Company Name",
      key: "html",
      render: ({ html, month, name }) => {
        return (
          <h4
            style={{ cursor: loading ? "diabled" : "pointer" }}
            className="purple-link"
            onClick={async () => {
              setLoading(true);

              const sliceSize = 1024;
              const byteCharacters = atob(html);
              const bytesLength = byteCharacters.length;
              const slicesCount = Math.ceil(bytesLength / sliceSize);
              const byteArrays = new Array(slicesCount);

              for (
                let sliceIndex = 0;
                sliceIndex < slicesCount;
                sliceIndex += 1
              ) {
                const begin = sliceIndex * sliceSize;
                const end = Math.min(begin + sliceSize, bytesLength);

                const bytes = new Array(end - begin);
                for (
                  let offset = begin, i = 0;
                  offset < end;
                  i += 1, offset += 1
                ) {
                  bytes[i] = byteCharacters[offset].charCodeAt(0);
                }

                byteArrays[sliceIndex] = new Uint8Array(bytes);
              }

              const blob = new Blob(byteArrays, { type: "application/pdf" });
              download(
                new Blob([blob]),
                `${name} - ${month}.pdf`,
                "application/pdf"
              );

              setLoading(false);
            }}
          >
            {loading ? "Downloading ...." : "Download"}
          </h4>
        );
      }
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize,
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>
      }}
      dataSource={reportList}
    />
  );
};
