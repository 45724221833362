import React, { useState, useEffect } from "react";
import MemberDocuments from "../Admin/Documents/memberDocuments";
import CompanyDocuments from "../Admin/Documents/memberDocuments";
import { useLazyQuery } from "react-apollo";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../../containers/Admin/graphql/queries";

const Documents = ({}) => {
  const userRole = localStorage.getItem("userRoles");
  const isEmployee = userRole.indexOf("Employee") > -1;
  const memberId = Number(localStorage.getItem("userId"));
  const [companyId, setCompanyId] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: memberId
      },
      onCompleted: data => {
        setIdNumber(data.userData.idNumber);
        if (typeof data?.userData?.employment[0]?.company?.id === "number") {
          setCompanyId(data.userData.employment[0].company.id);
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      {isEmployee && (
        <MemberDocuments
          mode={"my-documents"}
          companyId={companyId}
          memberId={memberId}
          memberIdNumber={idNumber}
        />
      )}
      {/* {!isNaN(companyId) && !isEmployee && userRole === 'Sole Proprietor' && (
        <CompanyDocuments
          mode={"soleprop-overview-documents"}
          companyId={companyId}
          memberIdNumber={idNumber}
        />
      )} */}
      {!isEmployee && (
        <CompanyDocuments
          mode={
            userRole === "Sole Proprietor"
              ? "soleprop-overview-documents"
              : "company-overview-documents"
          }
          memberId={memberId}
          companyId={companyId}
          memberIdNumber={idNumber}
        />
      )}
    </>
  );
};
export default Documents;
