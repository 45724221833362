import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import PageNotFound from "../containers/PageNotFound";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes";

const Navigation = () => (
  <Router>
    <Switch>
      {PUBLIC_ROUTES.map(route => {
        // @ts-ignore
        return <Route key={route.path} {...route} />;
      })}
      {PRIVATE_ROUTES.map(route => {
        return <PrivateRoute key={route.path} {...route} />;
      })}
      <Route render={PageNotFound} />
    </Switch>
  </Router>
);

export default Navigation;
