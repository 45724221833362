import React, { useState, useEffect } from "react";
import {
  Button,
  Radio,
  Form,
  Input,
  Select,
  Divider,
  Card,
  Skeleton
} from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import "../Register/index.css";
import { GET_COMPANY_FINANCIAL_INFO } from "../../graphql/queries";
import {
  CREATE_COMPANY_FINANCIAL_INFO,
  UPDATE_COMPANY_FINANCIAL_INFO,
  DELETE_BANK_ACCOUNT
} from "../../graphql/mutations";
import { useMutation, useQuery } from "react-apollo";
import { openNotificationWithIcon } from "utils/notification";
import { months, COMPANY_TYPES } from "../../constants";
import { useRegistrationState } from "stores/registration";

const FinancialInformationForm: React.FC<any> = ({
  useCompanyId,
  useRegisteringAs,
  isRegistrationMode = true,
  ...props
}) => {
  const { getFieldDecorator } = props.form;
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    companyId: cid,
    ...rest
  } = useRegistrationState();

  const [natureOfBusiness, setNatureOfBusiness] = useState("Debt Collector");
  const [addingBusinessAccount, setAddingBusinessAccount] = useState(false);
  const [addingTrustAccount, setAddingTrustAccount] = useState(false);
  const [selectedBusinessAccount, setSelectedBusinessAccount] = useState<any>();
  const [selectedTrustAccount, setSelectedTrustAccount] = useState<any>();
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [financialYearStart, setFinancialYearStart] = useState("");
  const [financialYearEnd, setFinancialYearEnd] = useState("");
  const [finacialIformationId, setFinancialInformationId] = useState();
  const [currentStep, setCurrentStep] = useState("step4Saved");

  const registeredAs = useRegisteringAs || registeringAs;
  const companyId = useCompanyId ? +useCompanyId : cid;
  const isCompany = COMPANY_TYPES.includes(registeredAs);

  const [deleteBankAccount] = useMutation(DELETE_BANK_ACCOUNT);
  const [upsertFinancialInformation] = useMutation(
    finacialIformationId
      ? CREATE_COMPANY_FINANCIAL_INFO
      : UPDATE_COMPANY_FINANCIAL_INFO,
    {
      onError: () => {
        setIsSubmiting(false);
        openNotificationWithIcon(
          "error",
          "Save Error",
          "Error when saving financial information. Please try again"
        );
      },
      onCompleted: () => {
        setIsSubmiting(false);
        if (isRegistrationMode) {
          setRegistrationState({ [currentStep]: true });
        }

        openNotificationWithIcon(
          "success",
          "Save Success",
          "Company financial information saved successfully"
        );
      }
    }
  );
  const [businessAccounts, setBusinessAccounts] = useState([]);
  const [trustAccounts, setTrustAccounts] = useState([]);

  const financialYearOptions = months.map(m => {
    return (
      <Select.Option value={m} key={m}>
        {m}
      </Select.Option>
    );
  });

  useEffect(() => {
    isCompany ? setCurrentStep("step4Saved") : setCurrentStep("step6Saved");

    if (isRegistrationMode && !!rest[currentStep]) {
      refetchFinancialInfo({ companyId }).then(resp => {
        setCompanyFinancialInfo(resp.data);
      });
    }
  }, []);

  const step = {
    title: `Step ${isCompany ? 4 : 6}: Financial information`,
    content: "Enter your financial information"
  };

  const {
    loading: loadingFinancialInfo,
    refetch: refetchFinancialInfo
  } = useQuery(GET_COMPANY_FINANCIAL_INFO, {
    variables: {
      companyId
    },
    onError: () =>
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading financial information"
      ),
    onCompleted: data => {
      setCompanyFinancialInfo(data);
    }
  });

  const handleSubmitAccount = () => {
    setIsSubmiting(true);

    if (addingBusinessAccount) {
      //submit business account
      props.form.validateFields(
        [
          "businessAccountId",
          "businessBankName",
          "businessAccountName",
          "businessAccountNumber",
          "businessAccountType",
          "businessBranchCode"
        ],
        (err, values) => {
          if (err) return;

          let accounts = businessAccounts;
          //if account has id remove then replace with new
          if (values.businessAccountId) {
            accounts = businessAccounts.filter(
              x => x.id !== values.businessAccountId
            );
          }
          accounts.push({
            id: values.businessAccountId,
            bank: values.businessBankName,
            accountName: values.businessAccountName,
            accountNumber: values.businessAccountNumber,
            accountType: values.businessAccountType,
            branchCode: values.businessBranchCode,
            account: "business",
            companyId
          });
          setBusinessAccounts(accounts);
        }
      );
      setIsSubmiting(false);
      setAddingBusinessAccount(false);
      props.form.resetFields([
        "businessAccountId",
        "businessBankName",
        "businessAccountName",
        "businessAccountNumber",
        "businessAccountType",
        "businessBranchCode"
      ]);
    }
    if (addingTrustAccount) {
      //submit business account

      props.form.validateFields(
        [
          "trustAccountId",
          "trustBankName",
          "trustAccountName",
          "trustAccountNumber",
          "trustAccountType",
          "trustBranchCode"
        ],
        (err, values) => {
          if (err) return;
          let accounts = trustAccounts;
          //if account has id remove then replace with new
          if (values.trustAccountId) {
            accounts = trustAccounts.filter(
              x => x.id !== values.businessAccountId
            );
          }
          accounts.push({
            id: values.trustAccountId,
            bank: values.trustBankName,
            accountName: values.trustAccountName,
            accountNumber: values.trustAccountNumber,
            accountType: values.trustAccountType,
            branchCode: values.trustBranchCode,
            account: "trust",
            companyId
          });
          setTrustAccounts(accounts);
        }
      );
      setIsSubmiting(false);
      setAddingTrustAccount(false);
      props.form.resetFields([
        "trustAccountId",
        "trustBankName",
        "trustAccountName",
        "trustAccountNumber",
        "trustAccountType",
        "trustBranchCode"
      ]);
    }
  };

  const handleSubmitFinancialIformation = () => {
    props.form.validateFields(
      ["financialYearStart", "financialYearEnd", "natureOfBusiness"],
      (err, values) => {
        if (err) {
          return;
        }
        if (isEmpty(businessAccounts) || isEmpty(trustAccounts)) {
          return openNotificationWithIcon(
            "error",
            "Error",
            "Please make sure that you provide both Trust and Bussiness Accounts information "
          );
        }
        setIsSubmiting(true);

        upsertFinancialInformation({
          variables: {
            input: {
              id: finacialIformationId ? finacialIformationId : undefined,
              financialYearStartDate: values.financialYearStart,
              financialYearEndDate: values.financialYearEnd,
              natureOfBusiness: values.natureOfBusiness.toUpperCase(),
              companyId: +companyId,
              trustAccounts,
              businessAccounts
            }
          }
        });
      }
    );
  };

  const setCompanyFinancialInfo = dataFinancialInfo => {
    if (!isEmpty(dataFinancialInfo.companyFinancialInformation)) {
      // set financial year details
      setFinancialYearStart(
        dataFinancialInfo.companyFinancialInformation?.company
          ?.financialYearStartDate
      );
      setFinancialYearEnd(
        dataFinancialInfo.companyFinancialInformation?.company
          ?.financialYearEndDate
      );

      setFinancialInformationId(
        dataFinancialInfo.companyFinancialInformation.id
      );
      // set business account details
      const bussAccounts = dataFinancialInfo.companyFinancialInformation?.businessAccounts.map(
        acc => {
          return {
            id: acc.id,
            bank: acc.bank,
            accountName: acc.accountName,
            accountNumber: acc.accountNumber,
            accountType: acc.accountType,
            branchCode: acc.branchCode,
            account: "business",
            companyId
          };
        }
      );
      setBusinessAccounts(bussAccounts);
      //set trust account details
      const trustAccs = dataFinancialInfo.companyFinancialInformation.trustAccounts.map(
        acc => {
          return {
            id: acc.id,
            bank: acc.bank,
            accountName: acc.accountName,
            accountNumber: acc.accountNumber,
            accountType: acc.accountType,
            branchCode: acc.branchCode,
            account: "trust",
            companyId
          };
        }
      );
      setTrustAccounts(trustAccs);
      if (isRegistrationMode) {
        setRegistrationState({ [currentStep]: true });
      }
    }
  };

  const setFinancialYearEndField = e => {
    let index = months.findIndex(x => x === e);
    index = index === 0 ? months.length - 1 : index - 1;

    props.form.setFieldsValue({
      ["financialYearEnd"]: months[index]
    });
  };

  const handleAccountDelete = async (account, type) => {
    setIsSubmiting(true);
    if (account.id) {
      await deleteBankAccount({ variables: { id: account.id } });
      await refetchFinancialInfo();
    } else {
      if (type === "business") {
        setBusinessAccounts(businessAccounts.filter(x => x !== account));
      }
      if (type === "trust") {
        setBusinessAccounts(trustAccounts.filter(x => x !== account));
      }
    }
    setIsSubmiting(false);
    openNotificationWithIcon(
      "success",
      "Deletion Success",
      "Bank account information has been deleted successfully "
    );
  };

  if (loadingFinancialInfo) {
    return <Skeleton active paragraph={{ rows: 7 }} />;
  }

  return (
    <div className="flex-column">
      {isRegistrationMode && (
        <>
          <div>
            <span className="step-title">{step.title}</span>
            <p>{step.content}</p>
            <Divider />
          </div>
        </>
      )}
      <Form>
        <div className="input-block-wrapper">
          <div className="flex-column input-block">
            <label>Financial Year start date</label>
            <Form.Item style={{ paddingTop: "5px" }}>
              {getFieldDecorator("financialYearStart", {
                initialValue: financialYearStart,
                rules: [
                  {
                    required: true,
                    message: "Please select financial year start date"
                  }
                ]
              })(
                <Select
                  className="input-height input-select"
                  onChange={setFinancialYearEndField}
                >
                  {financialYearOptions}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Financial Year end date</label>
            <Form.Item style={{ paddingTop: "5px" }}>
              {getFieldDecorator("financialYearEnd", {
                initialValue: financialYearEnd,
                rules: [
                  {
                    required: true,
                    message: "Please select financial year end date"
                  }
                ]
              })(
                <Select className="input-height input-select" disabled>
                  {financialYearOptions}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block input-select">
            <label>Please indicate if the business is trading as</label>
            <Form.Item style={{ paddingTop: "5px" }}>
              {getFieldDecorator("natureOfBusiness", {
                initialValue: "Debt Collector",
                rules: [
                  {
                    required: false,
                    message: "Please indicate"
                  }
                ]
              })(
                <Radio.Group
                  onChange={e => {
                    setNatureOfBusiness(e.target.value.toUpperCase());
                  }}
                  className="input-select input-spacer"
                >
                  <Radio value={"Debt Collector"}>Debt Collector</Radio>
                  <Radio value={"Attorney"}>Attorney</Radio>
                  <Radio value={"Property Manager"}>Property Manager</Radio>
                  <Radio value={"Other"}>Other</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {isRegistrationMode &&
              natureOfBusiness &&
              natureOfBusiness !== "Other" && (
                <>
                  <span>
                    Should you have a trust account with the Law Society/Legal
                    Practice Council or the EAAB based on capacity selected, you
                    need not open a new, separate trust account for registration
                    as a debt collector. If you intend to use the same trust
                    account, kindly furnish the details of the trust account
                    with the Law Society/Legal Practice/EAAB below. <br />
                    The trust account will then be audited in terms of the rules
                    of the applicable entity and you need to furnish the Council
                    for Debt Collectors, annually, with a copy of the audit
                    report submitted with that entity.
                    <br />
                  </span>
                  <br />
                </>
              )}
          </div>
          <Divider />
          <div className="flex-column input-block input-select">
            {isRegistrationMode && (
              <>
                <label>Business Account</label>
                <br />
                <span>
                  This is the account to be used in the instance where refund is
                  due in respect of either application fee and/or annual
                  subscription fee. Also known as the business operational
                  account
                  <br />
                </span>
                <br />
              </>
            )}
            {businessAccounts.length > 0 &&
              businessAccounts.map(account => {
                return (
                  <Card
                    key={account.accountNumber}
                    className="input-block-wrapper"
                  >
                    <div className="flex-column input-block">
                      <Button
                        shape="round"
                        className="btn-registration-capacity-selected"
                      >
                        Business Account
                      </Button>
                      <br />
                      <br />
                      <span>
                        Bank: <label>{account.bank}</label>
                        <br />
                        Account name: <label>{account.accountName}</label>
                        <br />
                        Account number: <label>{account.accountNumber}</label>
                        <br />
                        Account type: <label>{account.accountType}</label>
                        <br />
                        Branch code: <label>{account.branchCode}</label>
                        <br />
                      </span>
                    </div>
                    <div className="flex-column input-block">
                      <Button
                        onClick={() => {
                          setSelectedBusinessAccount(account);
                          setAddingBusinessAccount(true);
                        }}
                      >
                        Edit Details
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          handleAccountDelete(account, "business");
                        }}
                      >
                        <span style={{ color: "purple" }}>Delete account</span>
                      </Button>
                    </div>
                  </Card>
                );
              })}
          </div>
          <div
            className="input-block-wrapper"
            style={{ display: addingBusinessAccount ? "flex" : "none" }}
          >
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("businessAccountId", {
                initialValue: selectedBusinessAccount?.id
              })(<Input />)}
            </Form.Item>
            <div className="flex-column input-block">
              <label>Account name</label>
              <Form.Item>
                {getFieldDecorator("businessAccountName", {
                  initialValue: selectedBusinessAccount?.accountName,
                  rules: [
                    {
                      required: true,
                      message: "Please account name"
                    }
                  ]
                })(<Input placeholder="Please enter account name" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Account number</label>
              <Form.Item>
                {getFieldDecorator("businessAccountNumber", {
                  initialValue: selectedBusinessAccount?.accountNumber,
                  rules: [
                    {
                      required: true,
                      message: "Please enter account number"
                    }
                  ]
                })(<Input placeholder="Please enter account number" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Account Type</label>
              <Form.Item>
                {getFieldDecorator("businessAccountType", {
                  initialValue: selectedBusinessAccount?.accountType,
                  rules: [
                    {
                      required: true,
                      message: "Please select account type"
                    }
                  ]
                })(
                  <Select className="input-select" placeholder="Select">
                    <Select.Option value="Current">Current</Select.Option>
                    <Select.Option value="Savings">Savings</Select.Option>
                    <Select.Option value="Transmission">
                      Transmission
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Bank name</label>
              <Form.Item>
                {getFieldDecorator("businessBankName", {
                  initialValue: selectedBusinessAccount?.bank,
                  rules: [
                    {
                      required: true,
                      message: "Please select bank name"
                    }
                  ]
                })(
                  <Select placeholder="Select">
                    <Select.Option value="FNB">FNB</Select.Option>
                    <Select.Option value="ABSA">ABSA</Select.Option>
                    <Select.Option value="AfricanBank">
                      African Bank
                    </Select.Option>
                    <Select.Option value="NedBank">NedBank</Select.Option>
                    <Select.Option value="Investec">Investec</Select.Option>
                    <Select.Option value="Sasfin">Sasfin</Select.Option>
                    <Select.Option value="Capitec">Capitec</Select.Option>
                    <Select.Option value="StandardBank">
                      Standard Bank
                    </Select.Option>
                    <Select.Option value="DiscoveryBank">
                      Discovery Bank
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Branch code</label>
              <Form.Item>
                {getFieldDecorator("businessBranchCode", {
                  initialValue: selectedBusinessAccount?.branchCode,
                  rules: [
                    {
                      required: true,
                      message: "Please enter branch code"
                    }
                  ]
                })(<Input placeholder="Please enter branch code" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block input-select input-spacer">
              <Button
                className="btn-registration-capacity-selected"
                onClick={handleSubmitAccount}
                loading={isSubmitting}
              >
                Save account information
              </Button>
              <Button onClick={() => setAddingBusinessAccount(false)}>
                Cancel
              </Button>
            </div>
          </div>
          <div className="flex-column input-block input-select">
            <Button
              type="default"
              className="purple-button"
              style={{ width: "100%", height: "40px" }}
              disabled={addingBusinessAccount}
              onClick={() => {
                setAddingTrustAccount(false);
                setAddingBusinessAccount(true);
              }}
            >
              + Add business account
            </Button>
          </div>
          <Divider />
          <div className="flex-column input-block input-select">
            <label>Trust Account</label>
            {isRegistrationMode && (
              <>
                <br />
                <span>
                  The Act requires that a registered debt collector is required
                  to have a trust account. The trust account is subject to the
                  following compliance:
                  <br />
                  1. The business must at all times maintain the trust account
                  <br />
                  2. The trust account must be audited annually
                  <br />
                  3. The trust account may not be used for any other purpose
                  other than the collection of funds from debtors and subsequent
                  payment to clients
                  <br />
                </span>
              </>
            )}
            <br />
            {!isEmpty(trustAccounts) &&
              trustAccounts.map(account => {
                return (
                  <Card
                    key={account.accountNumber}
                    className="input-block-wrapper"
                  >
                    <div className="flex-column input-block">
                      <Button
                        shape="round"
                        className="btn-registration-capacity-selected"
                      >
                        Trust Account
                      </Button>
                      <br />
                      <br />
                      <span>
                        Bank: <label>{account.bank}</label>
                        <br />
                        Account name: <label>{account.accountName}</label>
                        <br />
                        Account number: <label>{account.accountNumber}</label>
                        <br />
                        Account type: <label>{account.accountType}</label>
                        <br />
                        Branch code: <label>{account.branchCode}</label>
                        <br />
                      </span>
                    </div>
                    <div className="flex-column input-block">
                      <Button
                        onClick={() => {
                          setSelectedTrustAccount(account);
                          setAddingTrustAccount(true);
                        }}
                      >
                        Edit Details
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          handleAccountDelete(account, "trust");
                        }}
                      >
                        <span style={{ color: "purple" }}>Delete account</span>
                      </Button>
                    </div>
                  </Card>
                );
              })}
          </div>
          <div
            className="input-block-wrapper"
            style={{ display: addingTrustAccount ? "flex" : "none" }}
          >
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("trustAccountId", {
                initialValue: selectedTrustAccount?.id
              })(<Input />)}
            </Form.Item>
            <div className="flex-column input-block">
              <label>Account name</label>
              <Form.Item>
                {getFieldDecorator("trustAccountName", {
                  initialValue: selectedTrustAccount?.accountName,
                  rules: [
                    {
                      required: true,
                      message: "Please account name"
                    }
                  ]
                })(<Input placeholder="Please enter account name" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Account number</label>
              <Form.Item>
                {getFieldDecorator("trustAccountNumber", {
                  initialValue: selectedTrustAccount?.accountNumber,
                  rules: [
                    {
                      required: true,
                      message: "Please enter account number"
                    }
                  ]
                })(<Input placeholder="Please enter account number" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Account Type</label>
              <Form.Item>
                {getFieldDecorator("trustAccountType", {
                  initialValue: selectedTrustAccount?.accountType,
                  rules: [
                    {
                      required: true,
                      message: "Please select account type"
                    }
                  ]
                })(
                  <Select className="input-select" placeholder="Select">
                    <Select.Option value="Current">Current</Select.Option>
                    <Select.Option value="Savings">Savings</Select.Option>
                    <Select.Option value="Transmission">
                      Transmission
                    </Select.Option>
                    <Select.Option value="Trust">Trust</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Bank name</label>
              <Form.Item>
                {getFieldDecorator("trustBankName", {
                  initialValue: selectedTrustAccount?.bank,
                  rules: [
                    {
                      required: true,
                      message: "Please select bank name"
                    }
                  ]
                })(
                  <Select placeholder="Select">
                    <Select.Option value="FNB">FNB</Select.Option>
                    <Select.Option value="ABSA">ABSA</Select.Option>
                    <Select.Option value="AfricanBank">
                      African Bank
                    </Select.Option>
                    <Select.Option value="NedBank">NedBank</Select.Option>
                    <Select.Option value="Investec">Investec</Select.Option>
                    <Select.Option value="Sasfin">Sasfin</Select.Option>
                    <Select.Option value="Capitec">Capitec</Select.Option>
                    <Select.Option value="StandardBank">
                      Standard Bank
                    </Select.Option>
                    <Select.Option value="DiscoveryBank">
                      Discovery Bank
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="flex-column input-block">
              <label>Branch code</label>
              <Form.Item>
                {getFieldDecorator("trustBranchCode", {
                  initialValue: selectedTrustAccount?.branchCode,
                  rules: [
                    {
                      required: true,
                      message: "Please enter brach code"
                    }
                  ]
                })(<Input placeholder="Please enter branch code" />)}
              </Form.Item>
            </div>
            <div className="flex-column input-block input-select input-spacer">
              <Button
                className="btn-registration-capacity-selected"
                onClick={handleSubmitAccount}
                loading={isSubmitting}
              >
                Save account information
              </Button>
              <Button
                onClick={() => setAddingTrustAccount(false)}
                style={{ width: "120px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="flex-column input-block input-select">
            <Button
              type="default"
              className="purple-button"
              style={{ width: "100%", height: "40px" }}
              disabled={addingTrustAccount}
              onClick={() => {
                setAddingBusinessAccount(false);
                setAddingTrustAccount(true);
              }}
            >
              + Add trust account
            </Button>
          </div>
          <Divider />
          <div className="flex-column input-block input-select input-spacer">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmitFinancialIformation}
              loading={isSubmitting}
            >
              Save financial information
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

interface IProps extends FormComponentProps {
  useCompanyId?: number;
  disableHeading?: boolean;
  isRegistrationMode?: boolean;
  useRegisteringAs?: string;
  nextStep?: () => void;
}

const FinancialInformation = Form.create<IProps>({ name: "normal_register" })(
  FinancialInformationForm
);

export default FinancialInformation;
