import landingBg from "../assets/images/bg-2.jpg";
import successImg from "../assets/images/undraw_High_five_u364.png";
import blockchain from "../assets/icons/Blockchain.svg";
import designPrototype from "../assets/icons/DesignPrototyping.svg";
import devTeam from "../assets/icons/DevTeam.svg";
import hostingMaintenance from "../assets/icons/HostingMaintenance.svg";
import innovation from "../assets/icons/Innovation.svg";
import mobileApp from "../assets/icons/MobileApp.svg";
import other from "../assets/icons/Other.svg";
import softwareDev from "../assets/icons/SoftwareDev.svg";
import webApplication from "../assets/icons/WebApplication.svg";
import inbox from "../assets/icons/Inbox.svg";
import files from "../assets/icons/Files.svg";
import signature from "../assets/icons/RequireSignature.svg";
import members from "../assets/icons/Members.svg";
import signedDocs from "../assets/icons/SignedDocs.svg";
import chevronUpDown from "../assets/icons/updownchevron.png";
import paperclip from "../assets/icons/paperclip.svg";
import sendButton from "../assets/icons/send-button.png";

const images = {
  landingBackground: landingBg,
  successImage: successImg,
  DealsInbox: inbox,
  DealsFiles: files,
  DealsSignature: signature,
  DealsMembers: members,
  DealsSignedDocs: signedDocs,
  SelectProjectChevron: chevronUpDown,
  AttachmentClip: paperclip,
  SendButton: sendButton,
};
const ServiceIcons = {
  Blockchain: blockchain,
  DesignPrototype: designPrototype,
  DevTeam: devTeam,
  HostingMaintenance: hostingMaintenance,
  Innovation: innovation,
  MobileApp: mobileApp,
  Other: other,
  SoftwareDev: softwareDev,
  WebApplication: webApplication,
};
export { images, ServiceIcons };
