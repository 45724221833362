import React, { useState, useEffect, FC } from "react";
import { Button, Input, Radio, Select, Form } from "antd";
import { isEmpty } from "lodash";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  UPDATE_PROSECUTION_APPEARANCE,
  CREATE_PENALTY,
  CREATE_PENALTY_INVOICE,
  UPDATE_TASK
} from "graphql/mutations";
import { COMPLAINT_WITHDRAWAL, COMPLAINT_OTHER } from "../../../../constants";
import { notifyError, openNotificationWithIcon } from "utils/notification";
import fileIcon from "../../../../assets/images/pdf_icon.png";
import { GENERATE_SAGE_DOC } from "graphql/queries";

type Props = {
  appearance: any;
  debtCollectorId?: number;
  companyId: number;
  complaintId: number;
  complaint: any;
  taskStatus: string;
  taskId: number;
  form: any;
  uploadDocument?: () => Promise<void>;
  isComplaintAdmin: boolean;
  refreshAppearance: () => void;
  setResolveComplaint: (val: boolean) => void;
  companyName: string;
};

const HearingOutcome: FC<Props> = ({
  appearance,
  debtCollectorId,
  companyId,
  companyName,
  complaintId,
  complaint,
  taskStatus,
  taskId,
  form,
  uploadDocument,
  isComplaintAdmin,
  refreshAppearance,
  setResolveComplaint
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [verdict, setVerdict] = useState("");
  const userRole = localStorage.getItem("roleStream");
  const [invoiceUrl, setInvoiceUrl] = useState<string>();
  const [createInvoice] = useMutation(CREATE_PENALTY_INVOICE);
  const { getFieldDecorator } = form;

  useEffect(() => {
    if (appearance?.hearingVerdict) {
      setVerdict(appearance?.hearingVerdict);
      setSubmitted(true);
    }
  }, []);

  const [updateTask] = useMutation(UPDATE_TASK);

  const [getInvoiceUrl] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "no-cache",
    onError: notifyError,
    onCompleted: ({ generateSageDocument }) => {
      setSubmitting(false);
      setInvoiceUrl(generateSageDocument);
    }
  });

  const [updateProsecutionAppearance] = useMutation(
    UPDATE_PROSECUTION_APPEARANCE,
    {
      onCompleted: data => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Appearance information updated successfully"
        );
      },
      onError: () => {
        openNotificationWithIcon(
          "error",
          "Error",
          "The was an error updating prosecution"
        );
      }
    }
  );

  const [createPenalty] = useMutation(CREATE_PENALTY, {
    onCompleted: data =>
      openNotificationWithIcon(
        "success",
        "Success",
        "Penalty information updated successfully"
      ),
    onError: () => {
      openNotificationWithIcon(
        "error",
        "Error",
        "The was an error updating penalty information"
      );
    }
  });

  const validatePenaltyFields = (rules, value, callback) => {
    if (form.getFieldValue("sanction") === "Penalty" && isEmpty(value)) {
      callback("This value is required");
    } else {
      callback();
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await new Promise<any>((resolve, reject) => {
        form.validateFields((err, values) => {
          if (err) return reject("Please fill in all the details above");
          return resolve(values);
        });
      });

      setSubmitting(true);
      if (values.sanction === "Penalty") {
        await createPenalty({
          variables: {
            input: {
              appearanceId: appearance.id,
              penaltyAmount: values.penaltyAmount,
              paymentTerm: +values.paymentTerm || 1,
              companyId,
              penaltyStatus: "Unpaid"
            }
          }
        });
      } else {
        await updateTask({
          variables: {
            input: {
              id: taskId,
              taskStatus:
                values.sanction === "Withdrawal"
                  ? COMPLAINT_WITHDRAWAL
                  : COMPLAINT_OTHER,
              assignedToId: localStorage.getItem("userId")
            }
          }
        });
      }

      await updateProsecutionAppearance({
        variables: {
          input: {
            id: appearance?.id,
            taskId,
            hearingVerdict: values.verdict,
            sanctionOrSentence: values.sanction
          }
        }
      });

      refreshAppearance();
    } catch (err) {
      notifyError(err);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const generateInvoice = async () => {
    try {
      setSubmitting(true);
      if (appearance.penalty?.penaltyStatus === "Invoiced") {
        return getInvoiceUrl({
          variables: {
            input: {
              docType: "invoice",
              companyId,
              sageInvoiceId:
                appearance.penalty.invoices[
                  appearance.penalty.invoices.length - 1
                ].sageInvoiceId
            }
          }
        });
      }
      const invoiceAmount =
        +appearance.penalty.penaltyAmount / +appearance.penalty.paymentTerm;

      // create db record for the invoice
      const resp = await createInvoice({
        variables: {
          input: {
            invoiceAmount: invoiceAmount.toString(),
            description: "Sanction from a complaint hearing",
            userId: debtCollectorId,
            companyId,
            complaintId,
            penaltyId: appearance?.penalty?.id,
            directDepositReferenceNumber: `
                ${companyName
                  ?.substr(0, 4)
                  .toUpperCase()}-${taskId}/${new Date().getFullYear()}`
          }
        }
      });

      setInvoiceUrl(resp.data.createPenaltyInvoice);
    } catch (err) {
      notifyError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {isComplaintAdmin ? (
        <>
          <Form.Item label={"Verdict"}>
            {getFieldDecorator("verdict", {
              initialValue: appearance?.hearingVerdict,
              rules: [
                {
                  required: true,
                  message: `Please indicate if the verdict of the hearing`
                }
              ]
            })(
              <Radio.Group
                disabled={
                  taskStatus === "Complete" ||
                  (appearance?.sanctionOrSentence !== null &&
                    appearance?.hearingVerdict !== "Not Guilty")
                    ? true
                    : false
                }
                onChange={e => {
                  setVerdict(e.target.value);
                  if (e.target.value === "Not Guilty")
                    setResolveComplaint(true);
                }}
              >
                <Radio value="Guilty">Guilty</Radio>
                <Radio value="Not Guilty">Not Guilty</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {verdict == "Guilty" && (
            <>
              {appearance?.penalty?.paymentTerm && (
                <div
                  style={{
                    background: "#d79b2d",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff"
                  }}
                >
                  Based on the hearing outcome, a penalty payment of R
                  {appearance?.penalty?.penaltyAmount} must be paid to settle
                  this complaint. Please contact CFDC Legal Department if you
                  have any questions.
                </div>
              )}
              {appearance?.sanctionOrSentence === "Withdrawal" ||
                (appearance?.sanctionOrSentence === "Other" && (
                  <div
                    style={{
                      background: "#d79b2d",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      color: "#fff"
                    }}
                  >
                    {userRole === "Legal" && (
                      <p>
                        This hearing outcome requires the attention of CFDC
                        Admin to address
                      </p>
                    )}
                    {userRole !== "Legal" && (
                      <p>
                        This hearing outcome is currently being addressed by the
                        CFDC admins. If you have any questions, please contact
                        CFDC.
                      </p>
                    )}
                  </div>
                ))}
              <Form.Item label={`Sanction/Sentence`} className="input-block">
                {getFieldDecorator("sanction", {
                  initialValue: appearance?.sanctionOrSentence,
                  rules: [
                    {
                      required: true,
                      message: `Please select sanction or sentence`
                    }
                  ]
                })(
                  <Select
                    disabled={
                      taskStatus === "Complete" ||
                      (appearance?.sanctionOrSentence !== null &&
                        appearance?.hearingVerdict !== "Not Guilty")
                        ? true
                        : false
                    }
                    placeholder="Please select sanction or sentence"
                    style={{ width: "100%" }}
                  >
                    <Select.Option value="Penalty">Penalty</Select.Option>
                    <Select.Option value="Withdrawal">Withdrawal</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                    {/* Removed for now till deregistration flow of a user has been mapped out */}
                    {/* <Select.Option value="De-registration">
                  De-registration
                </Select.Option> */}
                  </Select>
                )}
              </Form.Item>

              <div className="form-item-block">
                <Form.Item
                  label={`If penalty provided:`}
                  className="input-block"
                >
                  {getFieldDecorator("penaltyAmount", {
                    initialValue: appearance?.penalty?.penaltyAmount,
                    rules: [{ validator: validatePenaltyFields }]
                  })(
                    <Input
                      placeholder="Please enter penalty amount"
                      disabled={userRole === "Legal" ? false : true}
                    />
                  )}
                </Form.Item>
                <Form.Item label={`Payment Term`} className="input-block">
                  {getFieldDecorator("paymentTerm", {
                    initialValue: appearance?.penalty?.paymentTerm
                      ? appearance?.penalty?.paymentTerm
                      : 1
                  })(
                    <Input
                      type="number"
                      min={1}
                      readOnly
                      placeholder="Please payment term"
                    />
                  )}
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  className="purple-button"
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  {submitted ? "Verdict Saved" : "Save Verdict"}
                </Button>

                <div>
                  {invoiceUrl ? (
                    <>
                      <span>
                        <img
                          style={{ height: "17px", width: "17px" }}
                          src={fileIcon}
                        />
                        &nbsp; &nbsp;
                        {`Invoice CFDC Penalty.pdf`}
                        <Button
                          type="link"
                          loading={submitting}
                          onClick={() => window.open(invoiceUrl)}
                        >
                          View
                        </Button>
                      </span>
                    </>
                  ) : (
                    <Button
                      className="purple-button"
                      loading={submitting}
                      onClick={generateInvoice}
                      disabled={!appearance?.penalty}
                    >
                      Create Financial Ticket
                    </Button>
                  )}
                </div>

                <Button
                  className="purple-button"
                  onClick={() => setResolveComplaint(true)}
                >
                  Resolve Task
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {appearance?.hearingVerdict === "Not Guilty" && (
            <div
              style={{
                background: "green",
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
                color: "#fff"
              }}
            >
              This complaint was deemed not guilty by CFDC Legal Department.
            </div>
          )}
          {appearance?.penalty?.paymentTerm && (
            <div
              style={{
                background: "#d79b2d",
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
                color: "#fff"
              }}
            >
              Based on the hearing outcome, a penalty payment of R
              {appearance?.penalty?.penaltyAmount} must be paid to settle this
              complaint. Please contact CFDC Legal Department if you have any
              questions.
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HearingOutcome;
