import React from "react";
import { COMPANY_TYPES } from "../../constants";
import { Divider } from "antd";
import { useRegistrationState } from "stores/registration";

function Authentication() {
  const { registeringAs } = useRegistrationState();

  const isCompany = COMPANY_TYPES.includes(registeringAs);
  const isSP = registeringAs === "A Sole Proprietor";

  const step = {
    title: `Step ${isCompany ? 6 : isSP ? 8 : 7}: Authentication`,
    content: "Confirm your user profile "
  };

  return (
    <div className="flex-column">
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      <div className="input-block-wrapper">
        <div className="flex-column input-block input-select">
          <label>Authentication</label>
          <br />
          <span>
            An OTP (One-Time-Pin) has been sent via SMS to the cellphone number
            provided during signing up for registration
          </span>
        </div>
        <div className="flex-column input-block input-spacer input-select">
          <label>
            Please note the following, by submitting the OTP you agree to, and
            declare that:
          </label>
          <br />
          <span>
            1. All information provided by yourself as part of the registration
            is true, complete and accurate to the best of your knowledge
            <br />
            2. In terms of Regulations promulgated in terms of the Debt
            Collectors Act 114 of 1998 it is an offense to furnish false or
            misleading particulars or information or make a false or misleading
            statement
            <br />
            3. The registration application is subject to review by the Council
            for Debt Collectors for verification and upon request additional
            information or supporting documents may be required
          </span>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
