import { Button, Result } from "antd";
import { useHistory } from "react-router";
import React from "react";
import Waiting from "../../assets/images/waiting-season.svg";
import { APPLICATION_IN_PROGRESS } from "../../constants";
import "./index.css";

const AwaitingConfirmation: React.FC<any> = () => {
  const history = useHistory();
  const inProgress =
    localStorage.getItem("applicationStatus") === APPLICATION_IN_PROGRESS;
  return (
    <div className="vertical-center">
      <Result
        icon={
          <img src={Waiting} alt="Company-Employee Awaiting Confirmation" />
        }
        title={
          <>
            {!inProgress && (
              <div>
                <h4 className="title" style={{ textAlign: "center" }}>
                  Awaiting Verification
                </h4>
                <p className="message" style={{ textAlign: "center" }}>
                  Your registration has been submitted successfully to the
                  Council of Debt Collectors. Please note that we will notify
                  you via email if we require any further documentation from
                  you. Your registration needs to be reviewed and approved by
                  the Council before you will receive your Membership number and
                  certificate. You will receive an email once your registration
                  has been approved or rejected and you will be able to access
                  your portal.
                </p>
              </div>
            )}
            {inProgress && (
              <div>
                <h4 style={{ textAlign: "center" }}>Application In Progress</h4>
                <p className="message" style={{ textAlign: "center" }}>
                  You have an active application in progress
                  <br />
                  Please click the button bellow to finilize your application
                </p>
                <br />
                <Button
                  className="purple-button"
                  onClick={() => history.push("/register")}
                >
                  Continue
                </Button>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
export default AwaitingConfirmation;
