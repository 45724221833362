import React, { useState } from "react";
import { Form, Button, Input, Select, Card, Table, Skeleton } from "antd";
import { useMutation, useQuery } from "react-apollo";
import { FormComponentProps } from "antd/lib/form/Form";
import {
  CREATE_COMPANY_REPRESENTATIVE,
  UPDATE_COMPANY_REPRESENTATIVE
} from "../../graphql/mutations";
import {
  GET_CAPACITY_LIST,
  GET_COMPANY_REPRENSENTATIVES
} from "graphql/queries";
import "../Register/index.css";
import InternalErrorPage from "containers/InternalErrorPage";
import { openNotificationWithIcon } from "utils/notification";
import { isEmpty } from "lodash";
import { useRegistrationState } from "stores/registration";
import { If } from "components/If";

const { Option } = Select;

const RelatedPartyInfoForm: React.FC<any> = ({
  form,
  sendRelatedPartyInfo,
  useCompanyId = false,
  useRegisteringAs = false
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getFieldDecorator } = form;
  const [selectedParty, setSelectedParty] = useState<any>();
  const [addingMember, setAddingMember] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [roles, setRoles] = useState<any>(false);
  const [createCompanyRepresentative] = useMutation(
    CREATE_COMPANY_REPRESENTATIVE
  );
  const [updateCompanyRepresentative] = useMutation(
    UPDATE_COMPANY_REPRESENTATIVE
  );

  const { companyId: cid, registeringAs: ras } = useRegistrationState();

  const companyId = useCompanyId ? +useCompanyId : cid;
  const registeringAs = useRegisteringAs || ras;

  const {
    loading: loadingCapacity,
    error: errorCapacity,
    data: dataCapacity
  } = useQuery(GET_CAPACITY_LIST, {
    onCompleted: () => {
      setRoles(dataCapacity);
    }
  });

  const {
    loading: loadingRepresentatives,
    error: errorRepresentatives,
    data: dataRepresentatives,
    refetch: refetchRepresentatives
  } = useQuery(GET_COMPANY_REPRENSENTATIVES, {
    variables: {
      companyId
    },
    onCompleted: () => {
      // set representatives info from query result
      setRepresentatives(dataRepresentatives.companyRepresentatives);
      if (
        !isEmpty(dataRepresentatives.companyRepresentatives) &&
        sendRelatedPartyInfo
      ) {
        sendRelatedPartyInfo(true);
      }
    },
    fetchPolicy: "network-only"
  });

  if (errorCapacity || errorRepresentatives) {
    return <InternalErrorPage />;
  }

  const { capacityList } =
    !loadingCapacity && roles && roles["capacityList"] && roles;

  //selectable options of capacity
  const capacityOptions: any = capacityList
    ? capacityList
        .filter(item => {
          let allowedCapacity;
          if (registeringAs === "A Sole Proprietor") {
            allowedCapacity = "Sole Proprietor";
          } else if (registeringAs === "Company") {
            allowedCapacity = "Director";
          } else if (registeringAs === "Closed Corporation") {
            allowedCapacity = "Member";
          } else if (registeringAs === "Partnership") {
            allowedCapacity = "Partner";
          } else if (registeringAs === "Trust") {
            allowedCapacity = "Trustee";
          } else if (registeringAs === "Co-operative") {
            allowedCapacity = "Director";
          }
          if ([allowedCapacity].indexOf(item.name) > -1) {
            return true;
          }
          return false;
        })
        .map((type, i) => {
          return (
            <Option key={i} value={type.id}>
              {type.name}
            </Option>
          );
        })
    : [];

  const handleSubmitMemberInfo = e => {
    e.preventDefault();
    form.validateFields(
      ["idNumber", "firstName", "lastName", "email", "capacity", "phone"],
      async (err, values) => {
        if (err) {
          return;
        }
        setIsSubmitting(true);

        const upsertCompanyRepresentative = !isEmpty(selectedParty)
          ? updateCompanyRepresentative
          : createCompanyRepresentative;
        // submit data to backend
        const resp = await upsertCompanyRepresentative({
          variables: {
            input: {
              id: selectedParty?.id,
              firstName: values.firstName,
              lastName: values.lastName,
              idNumber: values.idNumber,
              capacityId: values.capacity,
              phone: values.phone,
              companyId: !isEmpty(selectedParty) ? undefined : companyId
            }
          }
        });

        if (resp.errors) {
          console.error("upsert_company_representative_error", resp.errors);
          return openNotificationWithIcon(
            "error",
            "Error",
            "An error occurred!!"
          );
        }
        openNotificationWithIcon(
          "success",
          "Create Success",
          `Company representative created successfully`
        );
        //setMemberSaved(true);
        form.resetFields();
        if (sendRelatedPartyInfo) {
          sendRelatedPartyInfo(true);
        }
        // refetch reps and update
        const repsResponse = await refetchRepresentatives({
          companyId
        });

        if (repsResponse.errors) {
          return setIsSubmitting(false);
        }

        setRepresentatives(repsResponse.data.companyRepresentatives);

        setSelectedParty({});
        setIsSubmitting(false);
        setAddingMember(false);
      }
    );
  };

  if (loadingRepresentatives || loadingCapacity) {
    return <Skeleton active paragraph={{ rows: 6 }} />;
  }

  return (
    <>
      <If condition={!isEmpty(representatives)}>
        <Table
          dataSource={representatives}
          pagination={false}
          rowKey={record => String(record.id)}
          columns={[
            {
              key: "idNumber",
              title: "Id/Passport Number",
              dataIndex: "idNumber"
            },
            {
              key: "firstName",
              title: "First Name",
              dataIndex: "firstName"
            },
            {
              key: "lastName",
              title: "Last Name",
              dataIndex: "lastName"
            },
            {
              key: "Capacity",
              title: "Capacity",
              dataIndex: "capacity.name"
            },
            {
              key: "actions",
              title: "Actions",
              render: (record: any) => (
                <Button
                  icon="edit"
                  type="link"
                  className="purple-text"
                  onClick={() => {
                    setSelectedParty(record);
                    setAddingMember(true);
                  }}
                >
                  Edit
                </Button>
              )
            }
          ]}
        />
      </If>

      <br />
      {addingMember && (
        <Card className="input-select contact-table-card">
          <Form>
            <table
              className="table table-bordered"
              style={{ width: "100%" }}
              key="contacts"
            >
              <thead style={{ border: "1px solid ligthtgrey" }}>
                <tr>
                  <th>Related Party Information</th>
                  <th>Related Party Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("capacity", {
                        initialValue: selectedParty?.capacity?.id,
                        rules: [
                          {
                            required: true,
                            message: "Please select capacity"
                          }
                        ]
                      })(
                        <Select placeholder="Select">{capacityOptions}</Select>
                      )}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("firstName", {
                        initialValue: selectedParty?.firstName,
                        rules: [
                          {
                            required: true,
                            message: "Please enter first name"
                          }
                        ]
                      })(<Input placeholder="Enter first name" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("lastName", {
                        initialValue: selectedParty?.lastName,
                        rules: [
                          {
                            required: true,
                            message: "Please enter last name"
                          }
                        ]
                      })(<Input placeholder="Enter last name" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>ID Number</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("idNumber", {
                        initialValue: selectedParty?.idNumber,
                        rules: [
                          {
                            required: true,
                            message: "Please enter id number"
                          }
                        ]
                      })(<Input placeholder="Enter id number" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {getFieldDecorator("phone", {
                        initialValue: selectedParty?.phone || "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter phone number"
                          }
                        ]
                      })(<Input placeholder="Enter phone number" />)}
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>

          <div className="input-spacer input-block input-select ">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmitMemberInfo}
              loading={isSubmitting}
            >
              Save party information
            </Button>
          </div>
        </Card>
      )}
      <div className="input-block input-select ">
        <Button
          type="dashed"
          style={{
            width: "100%",
            height: "40px"
          }}
          onClick={() => setAddingMember(true)}
          disabled={
            addingMember ||
            (registeringAs === "A Sole Proprietor" && !isEmpty(representatives))
          }
          loading={isSubmitting}
        >
          + Add (another) member
        </Button>
      </div>
    </>
  );
};

interface IProps extends FormComponentProps {
  useCompanyId?: number;
  useRegisteringAs?: string;
}

const RelatedPartyInfo = Form.create<IProps>({ name: "normal_register" })(
  RelatedPartyInfoForm
);
export default RelatedPartyInfo;
