import React, { useState, useEffect } from "react";
import { Tabs, Breadcrumb } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";
import Profile from "./profile";
import BusinessProfile from "../../Companies/CompanyOverview/companyProfile";
import MemberTasks from "../../Tasks/memberTasks";
import MemberTransactions from "../../Transactions/memberTransactions";
import MemberEmployees from "../../Employees/companyEmployees";
import MemberDocuments from "../../Documents/memberDocuments";
import { GET_MEMBER_OVERVIEW_INFORMATION } from "../../graphql/queries";
import "./index.css";
import { filterUnemployed } from "../../../../utils/filterUnemployed";

const MemberOverview = props => {
  const [useMemberOverviewData, setMemberOverviewData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [useCompanyId, setCompanyId] = useState(null);
  const [useCompanyName, setCompanyName] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [
    showBusinessAndEmployeeTabs,
    setShowBusinessAndEmployeesTabs
  ] = useState(false);
  const [useCompanyInformation, setCompanyInformation] = useState(null);
  const [employments, setEmployments] = useState(null);
  const [fetchMemberOverviewInformation] = useLazyQuery(
    GET_MEMBER_OVERVIEW_INFORMATION,
    {
      variables: {
        userId: props.match.params.memberId * 1
      },
      onCompleted: data => {
        setMemberOverviewData(data);
        setIdNumber(data.userData.idNumber);
        setIsLoading(false);

        const _employments = filterUnemployed(data.userData.employment);

        if (
          _employments.length > 0 &&
          typeof _employments[0]?.company?.id === "number"
        ) {
          setCompanyId(_employments[0].company.id);
          setCompanyName(
            _employments[0]?.company?.registeredName ||
              _employments[0]?.company?.tradingName
          );
          setCompanyInformation(_employments[0]);
          setEmployments(_employments);
          if (
            ["Director", "Sole Proprietor"].includes(
              _employments[0].capacity?.name
            )
          ) {
            setShowBusinessAndEmployeesTabs(true);
          }
        }
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    fetchMemberOverviewInformation();
  }, []);

  return (
    <>
      <div className="member-profile-header-div">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/admin/members">Members</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href={`/admin/member/${props.match.params.memberId}`}>
              {useMemberOverviewData && useMemberOverviewData.userData
                ? `${useMemberOverviewData.userData.firstName} ${useMemberOverviewData.userData.lastName}`
                : props.match.params.memberId}
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
        {useMemberOverviewData && useMemberOverviewData.userData && (
          <h2>{`${useMemberOverviewData.userData.firstName}
            ${useMemberOverviewData.userData.lastName}
            : ${employments
              ?.map(e => e?.capacity?.name)
              .join(" ")
              .trim() || "Employee"}`}</h2>
        )}
      </div>
      <Tabs tabPosition="top">
        <Tabs.TabPane tab="Profile" key="1" className="tab-content">
          <Profile
            profileData={useMemberOverviewData}
            companyId={useCompanyId}
            companyInformation={useCompanyInformation}
            refetchProfile={fetchMemberOverviewInformation}
            employments={employments}
          />
        </Tabs.TabPane>
        {showBusinessAndEmployeeTabs && (
          <Tabs.TabPane tab="Business" key="2" className="tab-content">
            <BusinessProfile
              profileData={useMemberOverviewData}
              companyId={useCompanyId}
              companyInformation={useCompanyInformation.company}
            />
          </Tabs.TabPane>
        )}
        {showBusinessAndEmployeeTabs && (
          <Tabs.TabPane tab="Employees" key="3" className="tab-content">
            <MemberEmployees companyId={Number.parseInt(useCompanyId)} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab="Tasks"
          disabled={isLoading}
          key={showBusinessAndEmployeeTabs ? "4" : "2"}
          className="tab-content"
        >
          <MemberTasks
            memberId={Number.parseInt(props.match.params.memberId)}
            mode="member-overview-tasks"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Transactions"
          disabled={isLoading}
          key={showBusinessAndEmployeeTabs ? "5" : "3"}
          className="tab-content"
        >
          <MemberTransactions
            memberId={Number.parseInt(props.match.params.memberId)}
            mode="member-overview-transactions"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Documents"
          disabled={isLoading}
          key={showBusinessAndEmployeeTabs ? "6" : "4"}
          className="tab-content"
        >
          <MemberDocuments
            companyId={useCompanyId}
            companyName={useCompanyName}
            memberId={Number.parseInt(props.match.params.memberId)}
            memberIdNumber={idNumber}
            mode="member-overview-documents"
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default MemberOverview;
