import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { ADMIN_STREAMS } from "../constants";
import { Application } from "typings/types";

interface UserState {
  setUserState: (key: keyof UserState, value: any) => void;
  setUserStateObject: (obj: Partial<UserState>) => void;
  clearUserState: (incBatchId?: boolean) => void;
  isAdmin: () => boolean;
  isSuperAdmin: () => boolean;
  email?: string;
  id?: number;
  firstName?: string;
  lastName?: string;
  idNumber?: string;
  phone?: string;
  type?: string;
  permissions?: string[];
  roles?: string[];
  stream?: string;
  companyId?: number;
  applicationId?: number;
  pendingApplications?: Application[];
}

export const useUserState = create<UserState>()(
  devtools(
    persist(
      (set, get) => ({
        setUserState: (key, value) => set({ [key]: value }),
        setUserStateObject: (obj: Partial<UserState>) => {
          set(state => ({ ...state, ...obj }));
        },
        clearUserState: (incBatchId = false) => {
          const empty: Partial<UserState> = {
            email: undefined,
            id: undefined,
            firstName: undefined,
            lastName: undefined,
            idNumber: undefined,
            phone: undefined,
            type: undefined,
            permissions: undefined,
            roles: undefined,
            stream: undefined,
            companyId: undefined,
            applicationId: undefined
          };

          set(empty);
        },
        isAdmin: () => {
          return ADMIN_STREAMS.includes(get().stream);
        },
        isSuperAdmin: () => {
          return get().stream === "Super User";
        }
      }),
      {
        name: "user-storage",
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
