import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Steps, Breadcrumb, Badge, Result, Divider } from "antd";
import { useHistory, useLocation } from "react-router";
import { parseISO, format } from "date-fns";
import { useMutation, useQuery } from "react-apollo";
import { isEmpty } from "lodash";
import BusinessInformation from "../../Admin/Review/businessInformation";
import DocumentationInformation from "../../Admin/Review/documentationInformation";
import PersonalInformation from "../../Admin/Review/personalInformation";
import Prequalification from "../../Admin/Review/prequalification";
import EmploymentInformation from "../../Admin/Review/employmentInformation";
import FinancialInformation from "../../Admin/Review/financialInformation";
import AuditorInformation from "../../Admin/Review/auditorInformation";
import AuthenticationInformation from "../../Admin/Review/authenticationInformation";
import PaymentInformation from "../../Admin/Review/paymentInformation";
import EducationExperienceInformation from "../../Admin/Review/educationExperience";
import InformationLoader from "../../Admin/Review/informationLoader";
import {
  GET_APPLICATION_REVIEW_INFO,
  GET_TASK
} from "../../../graphql/queries";
import "../../Admin/Review/index.css";
import { openNotificationWithIcon } from "utils/notification";
import { UPDATE_TASK } from "graphql/mutations";

function Review() {
  const location = useLocation();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  const [reviewInfo, setReviewInfo] = useState<any>();
  const [stickySteps, setStickySteps] = useState(false);
  // const [adminCommentAvailable, setAdminCommentAvailable] = useState(false);
  const stepsRef = useRef(null);

  const consolidatedSteps = [
    {
      title: "Business information",
      status: "wait"
    },
    {
      title: "Personal information",
      status: "wait"
    },
    {
      title: "Prequalification",
      status: "wait"
    },
    {
      title: "Employment information",
      status: "wait"
    },
    {
      title: "Education & Experience",
      status: "wait"
    },
    {
      title: "Financial information",
      status: "wait"
    },
    {
      title: "Auditor/Accountant information",
      status: "wait"
    },
    {
      title: "Authentication",
      status: "wait"
    },
    {
      title: "Documentation",
      status: "wait"
    },
    {
      title: "Registration fee and payment",
      status: "wait"
    }
  ];

  const taskId = (location.state as any).relatedTask.id;

  useEffect(() => {
    document.querySelector("main").scrollTop = 0;
  }, [currentStep]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        stepsRef.current &&
        stepsRef.current.getBoundingClientRect().top > 93 &&
        stickySteps
      ) {
        setStickySteps(false);
      } else if (
        stepsRef.current &&
        stepsRef.current.getBoundingClientRect().top < 93 &&
        !stickySteps
      ) {
        setStickySteps(true);
      }
    };

    document
      .querySelector("main")
      .addEventListener("scroll", handleScroll, { passive: true });

    return () =>
      document
        .querySelector("main")
        .removeEventListener("scroll", handleScroll);
  }, [stickySteps]);

  const { data: response, loading: loadingTask } = useQuery(GET_TASK, {
    variables: { taskId },
    skip: !taskId,
    onError: error => console.error("get_task_error", error)
  });

  const task = response?.task;

  const [updateTask, { loading: isUpdating }] = useMutation(UPDATE_TASK, {
    variables: {
      input: { id: (location.state as any).id, taskStatus: "Complete" }
    },
    onCompleted: () => {
      history.push("/tasks");
    },
    onError: err => {
      console.error("update_task_error", err);
    }
  });

  const { loading: loadingReview } = useQuery(GET_APPLICATION_REVIEW_INFO, {
    variables: { taskId },
    fetchPolicy: "no-cache",
    skip: isEmpty(task),
    onCompleted: data => {
      if (data.reviewByTask) {
        setReviewInfo(data.reviewByTask);

        // set step error status
        if (!data.reviewByTask?.steps[currentStep]?.hasPassed) {
          steps[currentStep].status = "error";
        }

        // allow next navigation for already saved reviews
        data.reviewByTask?.steps.map(x =>
          localStorage.setItem(`${x.step}Saved`, "true")
        );

        const current = data.reviewByTask?.steps?.length - 1;

        setCurrentStep(current);
      }
    }
  });

  if (loadingReview || loadingTask) return <InformationLoader />;

  if (isEmpty(task)) return <InvalidTask />;

  const {
    id,
    employee,
    company,
    application,
    type,
    createdDate,
    taskStatus,
    relatedTask,
    relatedTasks
  } = task;

  const previous = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };
  const next = () => {
    const isLastStep = currentStep === steps.length - 1;

    const stepTitle_array = steps[currentStep].title
      .replace(" & ", " ")
      .split(" ");
    const stepTitle =
      stepTitle_array.length > 1
        ? `${stepTitle_array[0]}${stepTitle_array[1]?.replace(
            stepTitle_array[1][0],
            stepTitle_array[1][0]?.toUpperCase()
          )}`
        : stepTitle_array[0];

    if (isLastStep) {
      // close the ticket
      updateTask();
    } else if (
      localStorage.getItem(`${stepTitle}Saved`) === "true" &&
      currentStep < steps.length
    ) {
      setCurrentStep(currentStep + 1);
    } else {
      openNotificationWithIcon("error", "Save Error", "Please save review");
    }
  };

  const isCompanyReg = type.name === "Company";
  const isEmployeeReg = type.name === "Employee";
  const isSoleProprietorReg = type.name === "Sole Proprietor";

  let employeeApplicationId = employee?.applications.find(x => x.taskId === id)
    ?.id;
  if (!employeeApplicationId && employee?.applications.length === 1) {
    employeeApplicationId = employee?.applications[0].id;
  }

  let applicantType = "N/A";

  if (application?.applicantType) {
    applicantType = application?.applicantType;
  } else if (company?.application?.applicantType) {
    applicantType = company?.application?.applicantType;
  }

  const employeeSteps = consolidatedSteps.filter(
    x =>
      ![
        "Business information",
        "Auditor/Accountant information",
        "Financial information"
      ].includes(x.title)
  );

  const companySteps = consolidatedSteps.filter(
    x =>
      ![
        "Education & Experience",
        "Employment information",
        "Personal information"
      ].includes(x.title)
  );

  const solePropSteps = consolidatedSteps.filter(
    x => !["Employment information"].includes(x.title)
  );

  const steps = isEmployeeReg
    ? employeeSteps
    : isCompanyReg
    ? companySteps
    : solePropSteps;

  const taskType = task.type.name;

  const isFinancialTicket =
    taskType === "Financial Ticket" || taskType === "Renewal";

  const stepComponentProps = {
    transaction: task,
    registrationTaskId: (location.state as any).id,
    reviewInfo
  };

  return (
    <>
      <Card className="detail-card" key="detail-card">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/admin/tasks">Tasks: New Applicants</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isEmployeeReg
              ? `${employee?.firstName} ${employee?.lastName}`
              : isCompanyReg
              ? `${company?.registeredName}`
              : `${company?.tradingName}`}
          </Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <div className="flex-row">
          <h2>
            {isEmployeeReg
              ? `${employee?.firstName} ${employee?.lastName}`
              : isCompanyReg
              ? `${company?.registeredName}`
              : `${company?.tradingName}`}
          </h2>
          <h3>
            Status:{" "}
            <span>
              <Badge color={"orange"} status={taskStatus} />
              {taskStatus}
            </span>
            )
          </h3>
        </div>
        <div className="flex-row">
          <label>Task ID: {id}</label>
          <label>Type: {type.name}</label>
          <label>Applicant Type: {applicantType}</label>
          <label>
            Date Created: {format(parseISO(createdDate), "dd-MM-yyyy")}
          </label>
        </div>
      </Card>
      <div className={"review-sider"}>
        <div
          style={stickySteps ? { opacity: 0 } : { opacity: 1 }}
          key={1}
          ref={stepsRef}
          className="padding-left-2"
        >
          <Steps direction="vertical" current={currentStep}>
            {steps.map(item => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div
          className="review-steps-sticky"
          style={stickySteps ? { opacity: 1 } : { opacity: 0 }}
        >
          <Steps direction="vertical" current={currentStep}>
            {steps.map(item => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className={!isFinancialTicket ? "review-content" : ""}>
        <Card
          key="review-card"
          className="card"
          title={
            <>
              <h3>Review Process</h3>
              {isFinancialTicket && (
                <span>
                  Please review the task related to an online application using{" "}
                  <b>Direct Deposit </b> as a payment option.
                </span>
              )}
              {!isFinancialTicket && (
                <span>
                  This is documented in a {steps.length} step process whereby
                  each section will be reviewed and marked
                </span>
              )}
            </>
          }
        >
          <div className="review-content-section">
            {currentStep < steps.length && (
              <h3 className="review-step-content">
                Review: {steps[currentStep]?.title}
              </h3>
            )}

            {/* This may need to be refactored for readability */}
            <div className="review-step-content">
              {!isFinancialTicket &&
                (!isEmployeeReg && currentStep === 0 ? (
                  <BusinessInformation {...stepComponentProps} />
                ) : (isEmployeeReg && currentStep === 0) ||
                  (isSoleProprietorReg && currentStep === 1) ? (
                  <PersonalInformation {...stepComponentProps} />
                ) : (!isSoleProprietorReg && currentStep === 1) ||
                  (isSoleProprietorReg && currentStep === 2) ? (
                  <Prequalification {...stepComponentProps} />
                ) : (isCompanyReg && currentStep === 2) ||
                  (isSoleProprietorReg && currentStep === 4) ? (
                  <FinancialInformation {...stepComponentProps} />
                ) : isEmployeeReg && currentStep === 2 ? (
                  <EmploymentInformation {...stepComponentProps} />
                ) : (isEmployeeReg && currentStep === 3) ||
                  (isSoleProprietorReg && currentStep === 3) ? (
                  <EducationExperienceInformation {...stepComponentProps} />
                ) : (isSoleProprietorReg && currentStep === 5) ||
                  (isCompanyReg && currentStep === 3) ? (
                  <AuditorInformation {...stepComponentProps} />
                ) : (isSoleProprietorReg && currentStep === 6) ||
                  (!isSoleProprietorReg && currentStep === 4) ? (
                  <AuthenticationInformation {...stepComponentProps} />
                ) : (isSoleProprietorReg && currentStep === 7) ||
                  (!isSoleProprietorReg && currentStep === 5) ? (
                  <DocumentationInformation {...stepComponentProps} />
                ) : (
                  ((isSoleProprietorReg && currentStep === 8) ||
                    (!isSoleProprietorReg && currentStep === 6)) && (
                    <PaymentInformation {...stepComponentProps} />
                  )
                ))}
            </div>
          </div>
          <Divider />
          {currentStep < steps.length && (
            <div className="stepper-btn-container">
              {!isFinancialTicket && (
                <>
                  <Button
                    className="btn-previous-reject"
                    onClick={previous}
                    style={{
                      display: currentStep === 0 ? "none" : "inline"
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    type="default"
                    onClick={next}
                    loading={isUpdating}
                    className="btn-add-comment"
                    disabled={!!!(relatedTask || relatedTasks.length > 0)}
                  >
                    {currentStep < steps.length - 1 ? "Next Step" : "Complete"}
                  </Button>
                </>
              )}
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

const InvalidTask = () => {
  return (
    <Result
      status="warning"
      title="Invalid task review"
      subTitle="Please go back to tasks page and select a task to review"
      extra={
        <Button type="default" className="btn-add-comment">
          <a href="/tasks">Go To Tasks</a>
        </Button>
      }
    />
  );
};
export default Review;
