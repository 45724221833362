import * as React from "react";
import styled from "styled-components";
import {
  alignItems,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  maxHeight,
  space,
  width
} from "styled-system";
import { StyledSystemProps } from "../../typings/StyledSystemProps";

type Props = StyledSystemProps & {
  color?: string;
  backgroundImage?: string;
};

const StyledContainer = styled.div`
  ${flex};
  ${space};
  ${color};
  ${width};
  ${height};
  ${flexWrap};
  ${maxHeight};
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  display: flex;
  box-sizing: border-box;
  background-image: url(${(props: Props) => props.backgroundImage});
  background-size: cover;
`;

const PageWrap: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
};

PageWrap.defaultProps = {
  p: 3,
  flex: 1,
  width: "100%",
  height: "100%",
  flexDirection: "row",
  bg: "background.tint1",
  alignItems: "flex-start",
  justifyContent: "flex-start"
};

export default PageWrap;
