import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { Button, Spin } from "antd";
import { GET_DOCUMENTS } from "../../../../graphql/queries";
import { openNotificationWithIcon } from "utils/notification";
import fileIcon from "../../../../assets/images/pdf_icon.png";
import { isEmpty } from "lodash";
import "../index.css";

const SupportingDocuments: React.FC<any> = ({
  taskId,
  documentTypeId,
  complaintId,
  mode = "debt-collector",
  debtCollectorId,
  companyName,
  getDownloadUrl
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [supportingDocuments, setSupportingDocuments] = useState([]);
  const [getSupportingDocuments, { loading }] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const documents = data?.documents?.documentList;
      if (!isEmpty(documents)) {
        setSupportingDocuments(documents);
      }
    },
    onError: () => {
      openNotificationWithIcon("error", "Error", "Error loading documents");
    }
  });

  useEffect(() => {
    if (complaintId && taskId && documentTypeId) {
      getSupportingDocuments({
        variables: {
          input: {
            taskId: mode === "debt-collector" ? taskId : undefined,
            complaintId: mode === "debt-collector" ? undefined : complaintId,
            documentTypeId
          }
        }
      });
    }
  }, [complaintId, taskId, documentTypeId]);

  const handleViewDocument = async filePath => {
    try {
      setSubmitting(true);
      // request download url
      await getDownloadUrl({
        filePath,
        companyName,
        forCompany: true,
        userId: debtCollectorId
      });
      setSubmitting(false);
    } catch {
      openNotificationWithIcon(
        "error",
        "Upload Error",
        "Error occured during upload"
      );
    }
  };

  return (
    <>
      {loading && <Spin />}
      {mode === "consumer" && !isEmpty(supportingDocuments) && (
        <h3>Consumer Supporting Documents:</h3>
      )}
      <div style={{ paddingLeft: "15px" }}>
        {supportingDocuments.map(doc => {
          return (
            <div key={doc.name}>
              <span>
                <img style={{ height: "17px", width: "17px" }} src={fileIcon} />
                &nbsp; &nbsp;
                {doc.name}
                <Button
                  type="link"
                  loading={submitting}
                  onClick={() => handleViewDocument(doc.documentPath)}
                >
                  View
                </Button>
              </span>
              <br />
            </div>
          );
        })}
      </div>
    </>
  );
};
export default SupportingDocuments;
