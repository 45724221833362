import React, { useState, useEffect } from "react";
import {
  Card,
  Spin,
  Row,
  Col,
  Divider,
  Button,
  Checkbox,
  Table,
  Alert
} from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { format, compareDesc, parseISO } from "date-fns";
import InternalErrorPage from "../../../InternalErrorPage";
import ProfileInformationLoader from "./profileInformationLoader";
import CompanyProfileEditable from "./companyProfileEditable";
import { GET_COMPANY_SUMMARY_MEMBER_PROFILE } from "../../../../graphql/queries";
import "../../Members/MemberOverview/index.css";
import { ADMIN_STREAMS } from "../../../../constants";
import { GET_BLACKLIST_STATUS } from "containers/Admin/graphql/queries";
import { notifyError } from "utils/notification";
import BlacklistStatusAlert from "components/BlacklistStatusAlert";

const contactTableColumns = [
  {
    title: "Contact Type",
    dataIndex: "contactType",
    key: "contactType"
  },
  {
    title: "Contact Details",
    dataIndex: "contactDetail",
    key: "contactDetail"
  },
  {
    title: "Business Unit",
    dataIndex: "businessUnit",
    key: "businessUnit"
  },
  {
    title: "Business Unit Contact Name",
    dataIndex: "businessUnitContactName",
    key: "businessUnitContactName"
  }
];

const companyContactsToTableDataRow = (contacts = []) => {
  return contacts.map((item, index) => {
    let rows = [];
    let commonProps = {
      businessUnit: item.businessUnit.name,
      businessUnitContactName: item.businessUnitContactName
    };
    if (item.cellphone) {
      rows.push({
        key: `Contact record Cell Phone ${index}`,
        contactType: "Cell Phone",
        contactDetail: item.cellphone,
        ...commonProps
      });
    }
    if (item.secondaryCellphone) {
      rows.push({
        key: `Contact record Cell Phone 2 ${index}`,
        contactType: "Cell Phone 2",
        contactDetail: item.secondaryCellphone,
        ...commonProps
      });
    }
    if (item.workPhone) {
      rows.push({
        key: `Contact record Work Number ${index}`,
        contactType: "Work Number",
        contactDetail: item.workPhone,
        ...commonProps
      });
    }
    if (item.homePhone) {
      rows.push({
        key: `Contact record Home Number ${index}`,
        contactType: "Home Number",
        contactDetail: item.homePhone,
        ...commonProps
      });
    }
    if (item.email) {
      rows.push({
        key: `Contact record Email ${index}`,
        contactType: "Email",
        contactDetail: item.email,
        ...commonProps
      });
    }
    if (item.secondaryEmail) {
      rows.push({
        key: `Contact record Email 2 ${index}`,
        contactType: "Email 2",
        contactDetail: item.secondaryEmail,
        ...commonProps
      });
    }
    return rows;
  });
};

interface IProps {
  profileData?: any;
  companyId: number;
  companyInformation?: any;
}

const BusinessProfile: React.FC<IProps> = ({
  profileData,
  companyId,
  companyInformation
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [useCompanyInformation, setCompanyInformation] = useState(
    companyInformation
  );
  const [useCompanyId, setCompanyId] = useState(companyId);
  const [useCompanyMembershipNumber, setCompanyMembershipNumber] = useState(
    null
  );
  const [
    useCompanyFinancialInformation,
    setCompanyFinancialInformation
  ] = useState(null);
  const [useCompanyRepresentatives, setCompanyRepresentatives] = useState(null);
  const [useCompanyContacts, setCompanyContacts] = useState(null);
  const [useCompanyAccountant, setCompanyAccountant] = useState(null);
  const [useCompanyAddresses, setCompanyAddresses] = useState(null);
  const [useEditMode, setEditMode] = useState(false);
  const userRole = localStorage.getItem("userRoles");

  const { data: blacklistData, refetch } = useQuery(GET_BLACKLIST_STATUS, {
    skip: !companyId,
    variables: {
      id: companyId,
      type: "company"
    },
    onError: error => {
      notifyError(error);
    }
  });

  const [getCompanyOverviewInfo] = useLazyQuery(
    GET_COMPANY_SUMMARY_MEMBER_PROFILE,
    {
      onCompleted: data => {
        if (data) {
          if (data.companyFinancialInformation) {
            setCompanyFinancialInformation(data.companyFinancialInformation);
          }
          if (data.company) {
            setCompanyInformation(data.company);
          }
          if (data.companyRepresentatives) {
            setCompanyRepresentatives(data.companyRepresentatives);
          }
          if (data.companyContacts) {
            let companyContactsTableData = companyContactsToTableDataRow(
              data.companyContacts
            );
            setCompanyContacts(companyContactsTableData);
          }
          if (data.companyAccountant) {
            setCompanyAccountant(data.companyAccountant);
          }
          if (data.addresses) {
            setCompanyAddresses(data.addresses);
          }
          if (data.company && data.company.membershipNumber) {
            setCompanyMembershipNumber(data.company.membershipNumber);
          }
        }
        setLoading(false);
      },
      onError: error => {
        setError(error);
      },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (typeof companyId === "number") {
      setCompanyId(companyId);
      refetch();
      getCompanyOverviewInfo({
        variables: {
          companyId: companyId
        }
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (profileData) {
      if (profileData.userData) {
        setUserData(profileData.userData);
      }
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [profileData]);
  useEffect(() => {
    setCompanyInformation(companyInformation);
  }, [companyInformation]);
  const disableEditAndRefreshCompany = () => {
    setEditMode(false);
    getCompanyOverviewInfo({
      variables: {
        companyId: companyId
      }
    });
  };
  if (error) {
    return <InternalErrorPage error={error} />;
  } else {
    return (
      <Spin tip="Loading..." spinning={loading}>
        <div className="col-sm-12 col-md-12 card-page-border">
          <Card>
            {!useCompanyMembershipNumber && <ProfileInformationLoader />}
            {useCompanyMembershipNumber && useEditMode && (
              <>
                <div className="card-header">
                  <h3>Edit Business Info</h3>
                  <h3>Membership number: {useCompanyMembershipNumber}</h3>
                </div>
                <CompanyProfileEditable
                  companyId={useCompanyId}
                  companyDetails={useCompanyInformation}
                />
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Button
                      onClick={() => disableEditAndRefreshCompany()}
                      className="purple-button"
                      style={{ marginRight: "20px" }}
                    >
                      View Profile
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {useCompanyInformation && !useEditMode && (
              <>
                {!!blacklistData?.getEntityBlacklistStatus && (
                  <BlacklistStatusAlert
                    type="company"
                    blacklistData={blacklistData.getEntityBlacklistStatus}
                  />
                )}
                <div className="card-header">
                  <h3>Business basic info</h3>
                  <div className="">
                    <h3>
                      Membership number:{" "}
                      <span className="bold">
                        {useCompanyInformation?.membershipNumber || "N/A"}
                      </span>
                    </h3>
                    <h3>
                      Valid Until:{" "}
                      <span className="bold">
                        {useCompanyInformation?.certificates?.length
                          ? format(
                              useCompanyInformation?.certificates
                                .map(c => parseISO(c.toDate))
                                .sort(compareDesc)[0],
                              "dd-MM-yyyy"
                            )
                          : "N/A"}
                      </span>
                    </h3>
                    <h3>
                      Status:{" "}
                      <span className="bold">
                        {useCompanyInformation?.status || "N/A"}
                      </span>
                    </h3>
                  </div>
                </div>
                {useCompanyInformation && useCompanyInformation && (
                  <Row gutter={[24, 24]}>
                    <Col span={6}>
                      <span className="bold">Registered name: </span>
                      {useCompanyInformation?.registeredName
                        ? useCompanyInformation?.registeredName
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">Registered number: </span>
                      {useCompanyInformation?.registeredNumber
                        ? useCompanyInformation?.registeredNumber
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">Trading name: </span>
                      {useCompanyInformation?.tradingName
                        ? useCompanyInformation?.tradingName
                        : "N/A"}
                    </Col>
                    <Col span={6}>
                      <span className="bold">VAT number: </span>
                      {useCompanyInformation?.vatNumber || "N/A"}
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                )}
                {userData && userData.idNumber && (
                  <>
                    <Divider />
                    <h3>Representative information</h3>
                    <Row gutter={[24, 24]}>
                      <Col span={6}>
                        <span className="bold">ID number: </span>
                        {userData.idNumber ? userData.idNumber : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">First name: </span>
                        {userData.firstName ? userData.firstName : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Last name: </span>
                        {userData.lastName ? userData.lastName : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Phone number: </span>
                        {userData.phone ? userData.phone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email: </span>
                        {userData.email ? userData.email : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Capacity: </span>
                        {userData.capacity ? userData.capacity?.name : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Business unit: </span>
                        {userData.businessUnit
                          ? userData.businessUnit?.name
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Phone number 2: </span>
                        {userData.secondaryPhone
                          ? userData.secondaryPhone
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Work number: </span>
                        {userData.workPhone ? userData.workPhone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Home number: </span>
                        {userData.homePhone ? userData.homePhone : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Work fax: </span>
                        {userData.fax ? userData.fax : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email private: </span>
                        {userData.email ? userData.email : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Marital status: </span>
                        {userData.maritalStatus
                          ? userData.maritalStatus
                          : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Ethnicity: </span>
                        {userData.ethnicity ? userData.ethnicity : "N/A"}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Age: </span>
                        {userData.age ? userData.age : "N/A"}
                      </Col>
                    </Row>
                  </>
                )}
                <Divider />
                <div className={loading ? "no-interact" : null}>
                  <h3>Business location info</h3>
                  <Row gutter={[24, 24]}>
                    {useCompanyAddresses &&
                      useCompanyAddresses.map((item, index) => (
                        <Col key={`Qualification ${index + 1}`} span={16}>
                          <Card>
                            <div className="flex-column">
                              <Button
                                shape="round"
                                className="btn-purple-non-interactive"
                              >
                                {item.addressType?.name.includes("Postal")
                                  ? `Postal Address`
                                  : `Address ${index + 1}`}
                              </Button>
                              <br />
                              <span>
                                Address type:{" "}
                                <label>{item.addressType?.name}</label>
                                <br />
                                {item.location && (
                                  <>
                                    Location: <label>{item.location}</label>
                                    <br />
                                  </>
                                )}
                                Complex/Building/Apartment name:{" "}
                                <label>{item.addressName}</label>
                                <br />
                                Street Address:{" "}
                                <label>{item.streetAddress}</label>
                                <br />
                                Town/Suburb: <label>{item.suburb}</label>
                                <br />
                                City: <label>{item.city}</label>
                                <br />
                                Postal Code: <label>{item.postalCode}</label>
                                <br />
                                {!item.addressType?.name.includes("Postal") && (
                                  <Checkbox
                                    checked={item.domicilliumAddress}
                                    value={item.domicilliumAddress}
                                    className="no-interact-normal-opacity"
                                  >
                                    Domicilium Address
                                  </Checkbox>
                                )}
                              </span>
                            </div>
                          </Card>
                          <br />
                        </Col>
                      ))}
                  </Row>
                  <Divider />
                  <h3>Financial info</h3>
                  {useCompanyFinancialInformation &&
                    useCompanyFinancialInformation.company && (
                      <>
                        <Row gutter={[24, 24]}>
                          <Col span={4}>
                            <span className="bold">Financial start date: </span>
                            {
                              useCompanyFinancialInformation.company
                                ?.financialYearStartDate
                            }
                          </Col>
                          <Col span={4}>
                            <span className="bold">Financial end date: </span>
                            {
                              useCompanyFinancialInformation.company
                                ?.financialYearEndDate
                            }
                          </Col>
                          <Col span={4}>
                            <span className="bold">Business trades as: </span>
                            {useCompanyFinancialInformation.company?.tradingAs}
                          </Col>
                          <Col span={12}></Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                          <Col span={4}>
                            <span className="bold">Account Details:</span>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                          {useCompanyFinancialInformation &&
                            useCompanyFinancialInformation.businessAccounts.map(
                              (item, index) => (
                                <Col
                                  key={`Business Account ${index + 1}`}
                                  span={16}
                                >
                                  <Card>
                                    <div className="flex-column">
                                      <Button
                                        shape="round"
                                        className="btn-purple-non-interactive"
                                      >
                                        {`Business Account ${index + 1}`}
                                      </Button>
                                      <br />
                                      <span>
                                        Bank: <label>{item?.bank}</label>
                                        <br />
                                        Account name:{" "}
                                        <label>{item?.accountName}</label>
                                        <br />
                                        Account number:{" "}
                                        <label>{item?.accountNumber}</label>
                                        <br />
                                        Account type:{" "}
                                        <label>{item?.accountType}</label>
                                        <br />
                                        Branch code:{" "}
                                        <label>{item?.branchCode}</label>
                                        <br />
                                      </span>
                                    </div>
                                  </Card>
                                  <br />
                                </Col>
                              )
                            )}
                        </Row>
                        <Row gutter={[24, 24]}>
                          {useCompanyFinancialInformation &&
                            useCompanyFinancialInformation.trustAccounts.map(
                              (item, index) => (
                                <Col
                                  key={`Trust Account ${index + 1}`}
                                  span={16}
                                >
                                  <Card>
                                    <div className="flex-column">
                                      <Button
                                        shape="round"
                                        className="btn-purple-non-interactive"
                                      >
                                        {`Trust Account ${index + 1}`}
                                      </Button>
                                      <br />
                                      <span>
                                        Bank: <label>{item?.bank}</label>
                                        <br />
                                        Account name:{" "}
                                        <label>{item?.accountName}</label>
                                        <br />
                                        Account number:{" "}
                                        <label>{item?.accountNumber}</label>
                                        <br />
                                        Account type:{" "}
                                        <label>{item?.accountType}</label>
                                        <br />
                                        Branch code:{" "}
                                        <label>{item?.branchCode}</label>
                                        <br />
                                      </span>
                                    </div>
                                  </Card>
                                  <br />
                                </Col>
                              )
                            )}
                        </Row>
                      </>
                    )}
                  <Divider />
                  <h3>Related party/team members</h3>
                  {useCompanyRepresentatives && (
                    <Row gutter={[24, 24]}>
                      {useCompanyFinancialInformation &&
                        useCompanyRepresentatives.map((item, index) => (
                          <Col key={`Related Member ${index + 1}`} span={16}>
                            <Card>
                              <div className="flex-column">
                                <Button
                                  shape="round"
                                  className="btn-purple-non-interactive"
                                >
                                  {`Related Member ${index + 1}`}
                                </Button>
                                <br />
                                <span>
                                  Role type:{" "}
                                  <label>{item?.capacity?.name}</label>
                                  <br />
                                  First name: <label>{item?.firstName}</label>
                                  <br />
                                  Surname: <label>{item?.lastName}</label>
                                  <br />
                                  ID Number: <label>{item?.idNumber}</label>
                                  <br />
                                </span>
                              </div>
                            </Card>
                            <br />
                          </Col>
                        ))}
                    </Row>
                  )}
                  <Divider />
                  <h3>Contact Information</h3>
                  {useCompanyContacts && (
                    <Row gutter={[24, 24]}>
                      {useCompanyContacts &&
                        useCompanyContacts.map((item, index) => (
                          <Col key={`Contact Point ${index + 1}`} span={16}>
                            <Card>
                              <div className="flex-column">
                                <Button
                                  shape="round"
                                  className="btn-purple-non-interactive"
                                >
                                  {`Contact Point ${index + 1}`}
                                </Button>
                                <br />
                                <Table
                                  pagination={{ hideOnSinglePage: true }}
                                  columns={contactTableColumns}
                                  dataSource={item}
                                />
                              </div>
                            </Card>
                            <br />
                          </Col>
                        ))}
                    </Row>
                  )}
                  <Divider />
                  <h3>Auditor/Accountant info</h3>
                  {useCompanyAccountant && (
                    <Row gutter={[24, 24]}>
                      <Col span={6}>
                        <span className="bold">Name of business: </span>
                        {useCompanyAccountant?.businessName}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact person: </span>
                        {useCompanyAccountant?.contactPersonName}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact number: </span>
                        {useCompanyAccountant?.contactCellNumber}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Contact number (office): </span>
                        {useCompanyAccountant?.contactOfficeNumber}
                      </Col>
                      <Col span={6}>
                        <span className="bold">Email address: </span>
                        {useCompanyAccountant?.email}
                      </Col>
                    </Row>
                  )}
                  <Divider />
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Button
                        onClick={() => setEditMode(true)}
                        className="purple-button"
                        style={{ marginRight: "20px" }}
                        disabled={!ADMIN_STREAMS.includes(userRole)}
                      >
                        Edit Profile
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Card>
        </div>
      </Spin>
    );
  }
};

export default BusinessProfile;
