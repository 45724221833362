import React, { useState, useEffect } from "react";
import { Card, Button, Spin } from "antd";
import moment from "moment";
import "./index.css";
import { useLazyQuery } from "react-apollo";
import { GENERATE_SAGE_DOC } from "graphql/queries";
import { openNotificationWithIcon } from "utils/notification";
import { useHistory } from "react-router";

const TransactionOverview = props => {
  const history = useHistory();
  const [presignedDownloadUrl, setPresignedDownloadUrl] = useState(null);
  const [showSpin, setShowSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const { transaction } = props.location.state;
  const invoiceId =
    transaction?.task?.invoice?.sageInvoiceId || transaction?.sageTaxInvoiceId;

  const [getSageDocument] = useLazyQuery(GENERATE_SAGE_DOC, {
    fetchPolicy: "network-only",
    onError: err => {
      if (
        err.message === "Unauthorized" ||
        err.message?.includes("token has expired")
      )
        history.push("/login");

      openNotificationWithIcon(
        "error",
        "Load Error",
        "There was an error retrieving the document"
      );
    },
    onCompleted: data => {
      setPresignedDownloadUrl(data.generateSageDocument);
      setShowSpin(false);
      setLoading(false);
    }
  });

  const handleViewTemplate = (type, companyId, id) => {
    setShowSpin(true);
    setLoading(true);
    getSageDocument({
      variables: {
        input: {
          docType: type,
          companyId,
          ...(type === "receipt"
            ? { sageReceiptId: id }
            : { sageInvoiceId: id })
        }
      }
    });
  };

  useEffect(() => {
    if (presignedDownloadUrl) window.open(presignedDownloadUrl);
  }, [presignedDownloadUrl]);

  return (
    <>
      <Spin
        tip="Loading..."
        className="loader"
        style={{ display: showSpin ? "block" : "none" }}
      />
      <div style={{ margin: "1vw" }} className={loading ? "no-interact" : null}>
        <Card className="information-card" key={`Address ${1}`}>
          <div className="flex-column information-block">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <h2>Transaction {transaction?.id}</h2>
              <h3>
                Status: <a>{transaction?.status.name}</a>
              </h3>
            </div>

            <br />
            <span>
              Transaction Type: <label>{transaction?.type?.name}</label>
              <br />
              Invoice Number:{" "}
              <label>{transaction?.directDepositInvoiceNumber || ""}</label>
              <br />
              Company:{" "}
              <label>
                {transaction?.company.registeredName ||
                  transaction?.company.tradingName}
              </label>
              <br />
              Date Created:{" "}
              <label>
                {moment(transaction?.createdDate).format("DD-MM-YYYY")}
              </label>
              <br />
              Last Modified:{" "}
              <label>
                {moment(transaction?.createdDate).format("DD-MM-YYYY")}
              </label>
              <br />
              <div style={{ float: "right" }}>
                {false && (
                  <Button
                    className="purple-button"
                    style={{ marginRight: "8px" }}
                    disabled={transaction.sageReceiptId ? false : true}
                    onClick={() =>
                      handleViewTemplate(
                        "receipt",
                        transaction.company.id,
                        transaction.sageReceiptId
                      )
                    }
                  >
                    View Receipt
                  </Button>
                )}
                <Button
                  className="purple-button"
                  style={{ marginRight: "8px" }}
                  disabled={!invoiceId}
                  onClick={() => {
                    handleViewTemplate(
                      "invoice",
                      transaction.company.id,
                      invoiceId
                    );
                  }}
                >
                  View Invoice
                </Button>
              </div>
            </span>
          </div>
        </Card>
      </div>
    </>
  );
};

export default TransactionOverview;
