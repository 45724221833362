import React, {
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { Input, Row, Col, Select } from "antd";
import debounce from "lodash/debounce";
const { Option } = Select;

export const CompanyFilters = (
  { handleCompanyFilterChange, defaultMultiFilter, setError, mode },
  ref
) => {
  const generalSearchFilterRef = useRef();
  const statusFilterRef = useRef();
  const typeFilterRef = useRef();
  const sortFilterRef = useRef();

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      resetFilterCollection([
        {
          ref: generalSearchFilterRef,
          defaultValue: defaultMultiFilter["generalSearch"]
        },
        {
          ref: typeFilterRef,
          defaultValue: defaultMultiFilter["type"]
        },
        {
          ref: statusFilterRef,
          defaultValue: defaultMultiFilter["status"]
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"]
        }
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  const handleCompanyGeneralSearchFilterUpdate = data => {
    handleCompanyFilterChange(data, "generalSearch");
  };

  const debouncedCompanyGeneralSearchFilterUpdate = useCallback(
    debounce(handleCompanyGeneralSearchFilterUpdate, 250),
    []
  );

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Company:</label>
            <Input
              ref={generalSearchFilterRef}
              placeholder="By name or membership number"
              style={{ width: "calc(100% - 75px)" }}
              onChange={event =>
                debouncedCompanyGeneralSearchFilterUpdate(event.target.value)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Type:</label>
            <Select
              ref={typeFilterRef}
              placeholder="Select company type"
              onChange={value => handleCompanyFilterChange(value, "type")}
              style={{ width: "calc(100% - 75px)" }}
            >
              <Option value="Company">Company</Option>
              <Option value="Closed Corporation">Closed Corporation</Option>
              <Option value="Co-operative">Co-operative</Option>
              <Option value="Partnership">Partnership</Option>
              <Option value="A Sole Proprietor">Sole Proprietor</Option>
              <Option value="Trust">Trust</Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Status:</label>
            <Select
              ref={statusFilterRef}
              placeholder="Select company status"
              onChange={value => handleCompanyFilterChange(value, "status")}
              style={{ width: "calc(100% - 75px)" }}
            >
              <Option value="active">Active</Option>
              <Option value="deactivated">Deactivated</Option>
              <Option value="deregistered">Deregistered</Option>
              <Option value="pending">Pending</Option>
              <Option value="suspended">Suspended</Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Sort:</label>
            <Select
              ref={sortFilterRef}
              placeholder="sort by..."
              onChange={value => handleCompanyFilterChange(value, "sort")}
              style={{ width: "calc(100% - 75px)" }}
              defaultValue={defaultMultiFilter["sort"]}
            >
              <Option value="DESC">Newest</Option>
              <Option value="ASC">Oldest</Option>
            </Select>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default forwardRef(CompanyFilters);
