import React, { useState, useEffect } from "react";
import { Form, Radio, Icon } from "antd";
import { useQuery } from "react-apollo";
import { isEmpty } from "lodash";
import { SEARCH_REPRESENTATIVE_BY_ID } from "../../graphql/queries";
import "../Register/index.css";
import { useRegistrationState } from "stores/registration";
import { useUserState } from "stores/user";

function RegistrationForm(props: any) {
  const [linkedCompany, setLinkedCompany] = useState("");
  const [capacityInLinkedCompany, setCapacityInLinkedCompany] = useState("");
  const setRegistrationState = useRegistrationState(
    s => s.setRegistrationStateObject
  );
  const {
    registeringAs = "Employee of a Business",
    registrationCapacity,
    manualRegistrationType
  } = useRegistrationState();
  const { idNumber } = useUserState();

  useEffect(() => {
    if (registrationCapacity === "Individual") {
      setRegistrationState({ registeringAs, step1Saved: true });
    }
  }, [registrationCapacity]);

  const { loading } = useQuery(SEARCH_REPRESENTATIVE_BY_ID, {
    variables: { idNumber },
    onCompleted: data => {
      const result = data.companyRepresentativeByIdNumber;
      if (!isEmpty(result)) {
        setLinkedCompany(result.represents?.tradingName);
        setCapacityInLinkedCompany(result.capacity?.name);
      }
    }
  });

  return (
    <div className="flex-column">
      <div className="business-basic-details">
        {!loading && !isEmpty(linkedCompany) && !manualRegistrationType && (
          <div>
            <p>
              <Icon
                style={{
                  fontSize: "22px",
                  color: "rgb(82, 196, 26)"
                }}
                type="check-circle"
              />{" "}
              The ID Number provided matched our records with a current Capacity
              of <strong>{capacityInLinkedCompany}</strong> linked to Company:{" "}
              <strong>{linkedCompany}</strong>
            </p>
            <br />
          </div>
        )}
        <Radio.Group
          onChange={e => {
            setRegistrationState({
              registeringAs: e.target.value,
              step1Saved: true
            });
          }}
          value={registeringAs}
        >
          <Radio value={"Employee of a Business"}>Employee of a Business</Radio>
        </Radio.Group>

        <div className="input-select input-spacer">
          <span>
            Based on the information above, the registration is subject to Form
            [1A] per Regulations relating to Debt Collectors Act, 2003
          </span>
        </div>
      </div>
    </div>
  );
}
export default Form.create({ name: "normal_register" })(RegistrationForm);
