import React, {
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import { TASK_STATUS_TYPES } from "../../../constants";
import { useGlobalState } from "../../../globalStore";
import { Input, Row, Col, Select, DatePicker } from "antd";
import debounce from "lodash/debounce";
import moment from "moment";
import { useFilterState } from "contexts/AdminFilterContext";

const { Option } = Select;

export function AdminTaskFilters({ defaultMultiFilter, mode }, ref) {
  const { state } = useGlobalState();
  const taskIdFilterRef = useRef();
  const taskTypeFilterRef = useRef();
  const applicantTypeFilterRef = useRef();
  const taskStatusFilterRef = useRef();
  const userFilterRef = useRef();

  const {
    filters: { filter },
    saveFilter,
    saveFilterObject,
    filterReset
  } = useFilterState();

  const taskTypeList = state?.lookups?.taskTypes || [];

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      filterReset();
      let conditionalRefs = [];
      if (mode === "my-tasks") {
        conditionalRefs = [
          {
            ref: taskStatusFilterRef,
            defaultValue: defaultMultiFilter["taskStatus"]
          },
          {
            ref: userFilterRef,
            defaultValue: defaultMultiFilter["userId"]
          }
        ];
      } else if (mode === "member-overview-tasks") {
        conditionalRefs = [
          {
            ref: taskStatusFilterRef,
            defaultValue: defaultMultiFilter["taskStatus"]
          }
        ];
      } else if (mode === "new-tasks") {
        conditionalRefs = [
          {
            ref: applicantTypeFilterRef,
            defaultValue: defaultMultiFilter["applicantType"]
          },
          {
            ref: userFilterRef,
            defaultValue: defaultMultiFilter["userId"]
          }
        ];
      }
      resetFilterCollection([
        {
          ref: taskIdFilterRef,
          defaultValue: defaultMultiFilter["taskId"]
        },
        {
          ref: taskTypeFilterRef,
          defaultValue: defaultMultiFilter["taskTypeId"]
        },
        ...conditionalRefs
      ]);
    }
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({ value: [useDefaultValue] });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue
          });
        } else {
          currentRef.setState({
            value: useDefaultValue !== null ? useDefaultValue : ""
          });
        }
      }
    }
  };

  const handleSearchTermFilterUpdate = (data: string) => {
    if (data) {
      saveFilter("searchTerm", data);
    }
  };

  const handleTaskFilterUpdate = data => {
    if (data * 1 >= 0) {
      const taskId = data * 1;
      saveFilter("taskId", taskId);
    }
  };

  const debouncedTaskIdFilterUpdate = useCallback(
    debounce(handleTaskFilterUpdate, 250),
    []
  );
  const debouncedSearchTermUpdate = useCallback(
    debounce(handleSearchTermFilterUpdate, 250),
    []
  );

  function handleTaskStatusFilterUpdate(value: any) {
    saveFilter("taskStatus", value);
  }

  function handleTaskTypeFilterUpdate(value: any) {
    saveFilter("taskTypeId", value);
  }

  const taskStatusTypeOptions = TASK_STATUS_TYPES.map(x => {
    return (
      <Option key={x.name} value={x.name}>
        {x.name}
      </Option>
    );
  });

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Task ID:</label>
            <Input
              ref={taskIdFilterRef}
              type="number"
              defaultValue={
                filter?.taskId ? filter?.taskId.toString() : undefined
              }
              placeholder="Search task by ID"
              style={{ width: "calc(100% - 75px)" }}
              onChange={event => {
                debouncedTaskIdFilterUpdate(event.target.value);
              }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="flex-center-space-between">
            <label>Type:</label>
            <Select
              ref={taskTypeFilterRef}
              placeholder="Select task type"
              defaultValue={filter?.taskTypeId || undefined}
              onChange={handleTaskTypeFilterUpdate}
              style={{ width: "calc(100% - 75px)" }}
              {...(defaultMultiFilter.taskTypeId
                ? { defaultValue: defaultMultiFilter.taskTypeId }
                : {})}
            >
              {taskTypeList.map(x => {
                return (
                  <Option key={x.name} value={x.id}>
                    {x.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </Col>
        {[
          "new-tasks",
          "my-tasks",
          "member-overview-tasks",
          "company-overview-tasks"
        ].includes(mode) && (
          <Col span={8}>
            <div className="flex-center-space-between">
              <label>Status:</label>
              <Select
                ref={taskStatusFilterRef}
                placeholder="Please select task status"
                onChange={handleTaskStatusFilterUpdate}
                style={{ width: "calc(100% - 75px)" }}
              >
                {taskStatusTypeOptions}
              </Select>
            </div>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 24]}>
        {["my-tasks", "new-tasks"].indexOf(mode) > -1 && (
          <Col span={8}>
            <div className="flex-center-space-between">
              <label>Search:</label>
              <Input
                ref={userFilterRef}
                placeholder="Search by applicant / company name"
                type="text"
                defaultValue={
                  typeof filter?.searchTerm === "string"
                    ? filter?.searchTerm
                    : undefined
                }
                onChange={event => {
                  debouncedSearchTermUpdate(event.target.value);
                }}
                style={{ width: "calc(100% - 75px)" }}
              />
            </div>
          </Col>
        )}
        <Col span={16}>
          <div className="flex-center-space-between">
            <label>Dates: </label>
            <DatePicker.RangePicker
              style={{ width: "calc(100% - 75px)" }}
              separator="=>"
              value={[
                filter?.dateStart ? moment(filter?.dateStart) : undefined,
                filter?.dateEnd ? moment(filter?.dateEnd) : undefined
              ]}
              onChange={dates => {
                if (dates) {
                  const dateStart = dates[0]
                    .startOf("day")
                    .locale("en_za")
                    .toDate();
                  const dateEnd = dates[1].startOf("day").toDate();

                  saveFilterObject({ dateStart, dateEnd });
                } else {
                  saveFilterObject({ dateStart: false, dateEnd: false });
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default forwardRef(AdminTaskFilters);
