import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
import { DEREGISTERED_COMPANY } from "../../constants";
import { useGlobalState } from "../../globalStore";

const MemberNavigation = ({ roles, permissions, roleStream }) => {
  const { state } = useGlobalState();
  const userRole = localStorage.getItem("userRoles");
  const companyStatus = localStorage.getItem("companyStatus");

  const isEmployee = userRole === "Employee";
  const disabled = companyStatus === DEREGISTERED_COMPANY;

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["/profile"]}
      selectedKeys={[window.location.pathname]}
      mode="inline"
    >
      <Menu.Item key="/profile" disabled={disabled}>
        <Link to="/profile">
          <Icon type="user" />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      {!isEmployee && (
        <Menu.Item key="/business-profile" disabled={disabled}>
          <Link to="/business-profile">
            <Icon type="user" />
            <span>Business Profile</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="/tasks" disabled={disabled}>
        <Link to="/tasks">
          <Icon type="file-text" />
          <span>Tasks</span>
          {(state?.lookups?.registrationCount ?? 0) > 0 && (
            <Icon
              type="exclamation-circle"
              theme="filled"
              style={{
                color: "red",
                fontSize: "15px",
                marginLeft: "10px"
              }}
            />
          )}
        </Link>
      </Menu.Item>
      <Menu.Item key="/transactions" disabled={disabled}>
        <Link to="/transactions">
          <Icon type="credit-card" />
          <span>Transactions</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/documents">
        <Link to="/documents">
          <Icon type="folder-open" />
          <span>Documents</span>
        </Link>
      </Menu.Item>
      {!isEmployee && (
        <Menu.Item key="/employees">
          <Link to="/employees">
            <Icon type="team" />
            <span>Team</span>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MemberNavigation;
