import React from "react";
import { Modal, Form, Input, Select } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { GET_PERMISSIONS } from "../../containers/Admin/graphql/queries";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  initialData,
}) => {
  const { getFieldDecorator } = form;

  const { Option } = Select;
  const { loading, error, data } = useQuery(GET_PERMISSIONS);

  if (loading) return <>loading...</>;
  if (error) return <>{error.message}</>;

  const { permissionsList } = data;

  //selectable options of permissions
  const children: any = permissionsList
    ? permissionsList.map((type) => {
        return (
          <Option key={type.id} value={type.id}>
            {type.name}
          </Option>
        );
      })
    : [];

  // array of permissions that the role is assigned
  const permissionId: any = [];
  const permissionName: any = [];
  if (initialData && initialData.permissions)
    initialData.permissions.map((perm) => {
      permissionId.push(perm.id);
      permissionName.push(perm.name);
      return false;
    });
  return (
    <Modal
      visible={visible}
      title={initialData && initialData.id ? "Edit Role" : "Add Role"}
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Form.Item label="ID" style={{ display: "none" }}>
          {getFieldDecorator("id", {
            initialValue: initialData ? initialData.id : undefined,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Role Name">
          {getFieldDecorator("name", {
            initialValue: initialData ? initialData.name : "",
            rules: [
              {
                required: true,
                message: "Please input name of the role!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: initialData ? initialData.description : "",
            rules: [
              {
                required: true,
                message: "Please input the role's description!",
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Permissions">
          {getFieldDecorator("permissions", {
            initialValue: permissionId,
            rules: [
              {
                required: true,
                message: "Please select role permission(s)",
              },
            ],
          })(
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              defaultValue={permissionName}
            >
              {children}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditRoleForm = Form.create({
  name: "modal_form",
})(ModalFormComponent);

export default EditRoleForm;
