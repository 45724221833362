import React from "react";
import { Modal, Form, Input } from "antd";

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  initialData
}) => {
  const { getFieldDecorator } = form;

  return (
    <Modal
      visible={visible}
      title={
        initialData && initialData.id
          ? "Edit Business Unit"
          : "Add Business Unit"
      }
      okText="Save"
      onCancel={onCancel}
      onOk={onCreate}
    >
      <Form layout="vertical">
        <Form.Item label="ID" style={{ display: "none" }}>
          {getFieldDecorator("id", {
            initialValue: initialData ? initialData.id : undefined
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Business Uint">
          {getFieldDecorator("name", {
            initialValue: initialData ? initialData.name : "",
            rules: [
              {
                required: true,
                message: "Please input name of the business unit!"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: initialData ? initialData.description : "",
            rules: [
              {
                required: true,
                message: "Please input the business unit's description!"
              }
            ]
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditBusinessUnitForm = Form.create({
  name: "modal_form"
})(ModalFormComponent);

export default EditBusinessUnitForm;
