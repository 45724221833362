import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Button,
  Radio,
  Input,
  Select,
  Card,
  Divider,
  Skeleton
} from "antd";
import { useMutation, useQuery } from "react-apollo";
import { UPDATE_USER } from "../../graphql/mutations";
import { GET_ETHNICITY_LIST, GET_MARITAL_STATUS_LIST } from "graphql/queries";
import { GET_USER } from "../Admin/graphql/queries";
import { isEmpty, isInteger, isDate, isBoolean } from "lodash";
import "../Register/index.css";
import InternalErrorPage from "containers/InternalErrorPage";
import { openNotificationWithIcon } from "utils/notification";
import { validateMobilePhoneNumber } from "utils/validatePhoneNumber";
import AddressInfo from "../RegisterEmployee/addressForm";
import { APPLICATION_FAILED } from "../../constants";
import { FormComponentProps } from "antd/lib/form/Form";
import { useRegistrationState } from "stores/registration";
import { useUserState } from "stores/user";
import UserInformation from "components/UserInformation";

const { Option } = Select;

function BusinessInfoForm(props: any) {
  const { getFieldDecorator } = props.form;
  const {
    registeringAs,
    registrationCapacity,
    registrationType,
    ...rest
  } = useRegistrationState();
  const { email } = useUserState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmancipated, setIsEmancipated] = useState(true);

  const [updatingUser, setUpdatingUser] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  const [userDetailsUpdated, setUserDetailsUpdated] = useState(false);
  const [
    postalPhysicalAddressesSaved,
    setPostalPhysicalAddressesSaved
  ] = useState(false);
  const [addressFormRef, setAddressFormRef] = useState(null);
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (!!rest["step3Saved"]) {
      refetchUser({ input: { email } }).then(resp => {
        setUserInfo(resp.data.userData);

        // under 18 years old and not emancipated
        // fail the application
        if (resp.data.userData.applicationStatus === APPLICATION_FAILED) {
          window.location.replace("/register/fail/emancipation");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (addressFormRef) {
      addressFormRef.resetFields();
    }
  }, [addressFormRef]);

  useEffect(() => {
    checkRequiredFormsFilled();
  }, [postalPhysicalAddressesSaved, userDetailsUpdated]);

  const checkRequiredFormsFilled = () => {
    if (postalPhysicalAddressesSaved && userDetailsUpdated) {
      rest.setRegistrationState("step3Saved", true);
    }
  };

  const saveAddressFormRef = useCallback(node => {
    if (node !== null) {
      setAddressFormRef(node);
    }
  }, []);

  const AddressFormProps = {
    ref: saveAddressFormRef,
    sendAddressInfo: (postalPhysicalSaved: boolean) => {
      setPostalPhysicalAddressesSaved(postalPhysicalSaved);
      checkRequiredFormsFilled();
    }
  };

  const {
    loading: loadingUser,
    error: errorUser,
    data: dataUser,
    refetch: refetchUser
  } = useQuery(GET_USER, {
    variables: { input: { email } },
    onCompleted: () => {
      setUserInfo(dataUser.userData);
      if (dataUser.userData?.ethnicity) {
        setUserDetailsUpdated(true);
      }
    }
  });

  const {
    loading: loadingEthnicity,
    error: errorEthnicity,
    data: dataEthnicity
  } = useQuery(GET_ETHNICITY_LIST);

  const {
    loading: loadingMaritalStatus,
    error: errorMaritalStatus,
    data: dataMaritalStatus
  } = useQuery(GET_MARITAL_STATUS_LIST);

  if (errorEthnicity || errorMaritalStatus || errorUser) {
    return <InternalErrorPage />;
  }

  const { ethnicityList } = !loadingEthnicity && dataEthnicity;
  const { maritalStatusList } = !loadingMaritalStatus && dataMaritalStatus;

  //selectable ethnicity options
  const ethnicityOptions: any = ethnicityList
    ? ethnicityList.map(type => {
        return (
          <Option key={type.id} value={type.name}>
            {type.name}
          </Option>
        );
      })
    : [];

  //selectable ethnicity options
  const maritalStatusOptions: any = maritalStatusList
    ? maritalStatusList.map(type => {
        return (
          <Option key={type.id} value={type.name}>
            {type.name}
          </Option>
        );
      })
    : [];

  const step = {
    title: "Step 3: Personal information",
    content: "Complete your basic personal information"
  };

  const calculateAgeFromID = () => {
    const idNumber = userInfo.idNumber;
    const isIDNo = isInteger(Number.parseInt(idNumber));

    if (isIDNo) {
      const birthYear = idNumber.substr(0, 2);
      const birthMonth = idNumber.substr(2, 2);
      const birthDay = idNumber.substr(4, 2);
      const cutOff = new Date().getFullYear() - 2000;

      const dob = `${
        birthYear > cutOff ? 19 : 20
      }${birthYear}/${birthMonth}/${birthDay}`;

      const today = new Date();
      const birthDate = new Date(dob);

      if (!isDate(birthDate)) {
        return;
      }

      let age = today.getFullYear() - birthDate.getFullYear();

      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      props.form.setFieldsValue({
        age
      });
    }
  };

  const validateAge = (rules, value, callback) => {
    if (value < 16) {
      callback("Please enter a valid age");
    }
    callback();
  };

  const handleSubmitUserAdditionalInfo = e => {
    e.preventDefault();
    props.form.validateFields(
      [
        "businessUnit",
        "phoneNumber2",
        "email2",
        "workNumber",
        "faxNumber",
        "age",
        "maritalStatus",
        "ethnicity",
        "homeNumber",
        "isEmancipated"
      ],
      async (err, values) => {
        if (err) {
          return;
        }
        setIsSubmitting(true);
        //update user details
        const resp = await updateUser({
          variables: {
            input: {
              id: userInfo.id,
              secondaryPhone: values.phoneNumber2,
              workPhone: values.workNumber,
              homePhone: values.homeNumber,
              secondaryEmail: values.email2,
              fax: values.faxNumber,
              businessUnitId: values.businessUnit,
              age: Number.parseInt(values.age),
              maritalStatus: values.maritalStatus,
              ethnicity: values.ethnicity,
              registrationType: registrationType,
              registrationCapacity: registrationCapacity,
              registeredAs: registeringAs,
              isEmancipated: values.isEmancipated
            }
          }
        });

        if (resp.errors) {
          setIsSubmitting(false);
          return openNotificationWithIcon(
            "error",
            "Error",
            resp.errors[0].message
          );
        }

        //setIsUserAdditionalInfoSaved(true);
        setUserInfo(resp.data.updateUser);
        setUserDetailsUpdated(true);
        setUpdatingUser(false);
        setIsSubmitting(false);

        openNotificationWithIcon(
          "success",
          "Save Success",
          "User information updated successfully"
        );

        // under 18 years old and not emancipated
        // fail the application
        if (resp.data.updateUser.applicationStatus === APPLICATION_FAILED) {
          window.location.replace("/register/fail/emancipation");
        }
      }
    );
  };

  if (loadingEthnicity || loadingMaritalStatus || loadingUser) {
    return <Skeleton active paragraph={{ rows: 7 }} />;
  }

  return (
    <>
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>
      <span>Identification information:</span>
      {!isEmpty(userInfo) && !userInfo.ethnicity && (
        <div className="input-block-wrapper">
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>ID/Passport number</label>
            <br />
            <span>{userInfo.idNumber}</span>
          </div>
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>FirstName</label>
            <br />
            <span>{userInfo.firstName}</span>
          </div>
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>Last Name</label>
            <br />
            <span>{userInfo.lastName}</span>
          </div>
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>Phone number</label>
            <br />
            <span>{userInfo.phone}</span>
          </div>
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>Email</label>
            <br />
            <span>{userInfo.email}</span>
          </div>
          <div className="flex-column input-block" style={{ width: "30%" }}>
            <label>Capacity</label>
            <br />
            <span>{registrationCapacity}</span>
          </div>
        </div>
      )}
      {!isEmpty(userInfo) && userInfo.ethnicity && (
        <UserInformation user={userInfo} onEdit={() => setUpdatingUser(true)} />
      )}

      <Form>
        <div
          className="input-block-wrapper"
          style={{
            display:
              (!isEmpty(userInfo) && !userInfo.ethnicity) || updatingUser
                ? "flex"
                : "none"
          }}
        >
          <div className="flex-column input-block">
            <label>Phone number 2</label>
            <Form.Item>
              {getFieldDecorator("phoneNumber2", {
                initialValue:
                  userInfo && !isEmpty(userInfo.secondaryPhone)
                    ? userInfo.secondaryPhone
                    : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your alternate phone number"
                  },
                  {
                    validator: validateMobilePhoneNumber
                  }
                ]
              })(<Input placeholder="Enter alternative phone number" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Home number</label>
            <Form.Item>
              {getFieldDecorator("homeNumber", {
                initialValue:
                  userInfo && !isEmpty(userInfo.homePhone)
                    ? userInfo.homePhone
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Enter your home number"
                  }
                ]
              })(
                <Input
                  placeholder="Enter your home number"
                  onBlur={calculateAgeFromID}
                />
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Work number</label>
            <Form.Item>
              {getFieldDecorator("workNumber", {
                initialValue:
                  userInfo && !isEmpty(userInfo.workPhone)
                    ? userInfo.workPhone
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Enter your work number"
                  }
                ]
              })(<Input placeholder="Enter your work number" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Email private</label>
            <Form.Item>
              {getFieldDecorator("email2", {
                initialValue:
                  userInfo && !isEmpty(userInfo.secondaryEmail)
                    ? userInfo.secondaryEmail
                    : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your 2nd email"
                  },
                  {
                    type: "email",
                    message: "Enter a valid email"
                  }
                ]
              })(<Input placeholder="Enter your 2nd email address" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Work Fax</label>
            <Form.Item style={{ paddingTop: "5px" }}>
              {getFieldDecorator("faxNumber", {
                initialValue:
                  userInfo && !isEmpty(userInfo.fax) ? userInfo.fax : "",
                rules: [
                  {
                    required: false,
                    message: "Enter your fax number"
                  }
                ]
              })(<Input placeholder="Enter your fax number" />)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Marital status</label>
            <Form.Item>
              {getFieldDecorator("maritalStatus", {
                initialValue:
                  userInfo && !isEmpty(userInfo.maritalStatus)
                    ? userInfo.maritalStatus
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Enter your last name"
                  }
                ]
              })(
                <Select className="input-select">{maritalStatusOptions}</Select>
              )}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Ethnicity</label>
            <Form.Item>
              {getFieldDecorator("ethnicity", {
                initialValue:
                  userInfo && !isEmpty(userInfo.ethnicity)
                    ? userInfo.ethnicity
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please select your ethnicity"
                  }
                ]
              })(<Select className="input-select">{ethnicityOptions}</Select>)}
            </Form.Item>
          </div>
          <div className="flex-column input-block">
            <label>Age</label>
            <Form.Item style={{ paddingTop: "5px" }}>
              {getFieldDecorator("age", {
                initialValue:
                  userInfo && !isNaN(userInfo.age) ? userInfo.age : 0,
                rules: [
                  {
                    required: true,
                    message: "Enter your age"
                  },
                  { validator: validateAge }
                ]
              })(<Input type="number" />)}
            </Form.Item>
          </div>

          {props.form.getFieldValue("age") < 18 && (
            <div className="flex-column input-block input-select">
              <label>Have you been emancipated?</label>
              <Form.Item>
                {getFieldDecorator("isEmancipated", {
                  initialValue: isBoolean(userInfo?.isEmancipated)
                    ? userInfo?.isEmancipated
                    : "",
                  rules: [
                    {
                      required: true,
                      message: "Enter your age"
                    }
                  ]
                })(
                  <Radio.Group
                    onChange={e => {
                      setIsEmancipated(e.target.value);
                    }}
                    value={isEmancipated}
                    className="input-select input-spacer"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>
          )}
          <div className="flex-column input-block input-select">
            <Button
              className="btn-registration-capacity-selected"
              onClick={handleSubmitUserAdditionalInfo}
              loading={isSubmitting}
            >
              Save information
            </Button>
          </div>
        </div>
      </Form>

      <AddressInfo {...AddressFormProps} />
    </>
  );
}

interface IProps extends FormComponentProps {
  nextStep: () => void;
}

const BusinessInfo = Form.create<IProps>({ name: "normal_register" })(
  BusinessInfoForm
);

export default BusinessInfo;
