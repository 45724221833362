import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_CREDIT_BUREAU_RESULTS,
  GET_DOCUMENTS,
  GET_DOWNLOAD_URL
} from "../../../graphql/queries";
import { GET_PREQUALIFICATION } from "../../../graphql/queries";
import { isEmpty, isInteger } from "lodash";
import ResultComment from "./resultComment";
import InformationLoader from "./informationLoader";
import "./index.css";
import { Button, Icon, Divider } from "antd";

interface Document {
  id: number;
  name: string;
  fileDownloadUrl: string;
  documentPath: string;
}

const ReviewDocumentationInformation: React.FC<any> = ({
  transaction,
  reviewInfo,
  registrationTaskId
}) => {
  const { company, type, employee } = transaction;
  const userId = type.name === "Employee" ? employee.id : undefined;
  const companyId = company ? company.id : undefined;
  const registrationCapacity = company
    ? company.registrationCapacity
    : type.name;

  const [proof, setProof] = useState<Document>();
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const { loading: loadingDocuments } = useQuery(GET_DOCUMENTS, {
    variables: {
      input: {
        userId,
        companyId
      }
    },
    onCompleted: data => {
      setDocuments([...data.documents.documentList]);
    }
  });

  const [creditBureauResults, setCreditBureauResults] = useState([]);
  const { loading } = useQuery(GET_CREDIT_BUREAU_RESULTS, {
    skip: !isInteger(userId),
    variables: {
      userId
    },
    onCompleted: data => {
      if (data.eagleEyeValidation) {
        setCreditBureauResults(Object.entries(data.eagleEyeValidation));
      }
    }
  });

  const [prequalificationInfo, setPrequalificationInfo] = useState<any>();
  const { loading: loadingPrequalification } = useQuery(GET_PREQUALIFICATION, {
    skip: !isInteger(userId),
    variables: {
      userId
    },
    onCompleted: data => {
      setPrequalificationInfo(data.prequalification);
    }
  });

  const [getDownloadUrl, { loading: loadingDoc }] = useLazyQuery(
    GET_DOWNLOAD_URL,
    {
      fetchPolicy: "network-only",
      variables: {
        input: {
          companyName: "",
          forCompany: false,
          userId,
          filePath: ""
        }
      },
      onCompleted: data => {
        window.open(data.preSignedDownloadUrl);
      }
    }
  );

  useEffect(() => {
    const proof = documents.find(doc => doc.name === "Proof_of_Exemption");
    if (proof) {
      setProof(proof);
    }
    return;
  }, [documents]);

  const ResultComponentsProps = {
    ...transaction,
    step: "Documentation",
    reviewInfo,
    registrationTaskId
  };

  if (loading || loadingPrequalification || loadingDocuments)
    return <InformationLoader />;

  return (
    <>
      <div className="flex-column">
        <hr />
        {prequalificationInfo?.appliedForExemption && (
          <>
            <div className="flex-column information-block input-select">
              <span>
                <label>Based on the following questions: </label>
              </span>
              <br />

              <span>
                1.1 Has application for exemption in terms of section 10(2) been
                lodged with Ministry of Justice and Constitutional Development
              </span>
              <br />
              <label>Proof of Exemption</label>
              {proof ? (
                <Button
                  style={{ marginTop: "10px" }}
                  type="default"
                  onClick={() =>
                    getDownloadUrl({
                      variables: {
                        input: {
                          userId,
                          forCompany: userId ? false : true,
                          companyName: userId ? "" : company.registeredName,
                          filePath: proof.documentPath
                        }
                      }
                    })
                  }
                >
                  <Icon type="file" />
                  View Proof of Exemption
                </Button>
              ) : (
                <span>
                  <label>No documents uploaded</label>
                </span>
              )}
            </div>
            <Divider />
          </>
        )}
        {documents.length === 0 && (
          <>
            <div className="flex-column information-block input-select">
              <span>
                <label>No documents were uploaded.</label>
              </span>
            </div>
            <Divider />
          </>
        )}
        {registrationCapacity !== "Business" && !isEmpty(creditBureauResults) && (
          <div className="flex-column information-block input-select">
            <span>
              <label>
                Based on Credit Bureau results, the following indicators are
                reflected.
              </label>
              {"  "}
              <Button
                onClick={() => {
                  getDownloadUrl({
                    variables: {
                      input: {
                        userId,
                        forCompany: false,
                        companyName: undefined,
                        filePath: prequalificationInfo.reportUrl
                      }
                    }
                  });
                }}
                loading={loadingDoc}
                className="primary-button"
              >
                View Eagle Eye Report
              </Button>
              <br />
            </span>
            {!isEmpty(creditBureauResults) &&
              creditBureauResults.map(([indicator, value], index) => {
                let documentName;
                let currentIndicatorDocs = documents.filter(
                  doc => doc.name === `${indicator}_Proof`
                );
                if (
                  (value &&
                    ![
                      "__typename",
                      "isDeceased",
                      "idPassesCDV",
                      "nameMatches"
                    ].includes(indicator)) ||
                  (!value && ["idPassesCDV", "nameMatches"].includes(indicator))
                )
                  return (
                    <div
                      className="flex-column information-block input-select"
                      key={`cbr-${index}`}
                    >
                      <label style={{ marginTop: "10px" }}>
                        {indicator === "underDebtReview"
                          ? (documentName = "Debt Review")
                          : indicator === "hasDefault"
                          ? (documentName = "Arrears registered")
                          : indicator === "hasJudgment"
                          ? (documentName = "Judgement by Court Order")
                          : indicator === "isIndigent"
                          ? (documentName = "isIndigent")
                          : indicator === "hasTraceLocator"
                          ? (documentName = "hasTraceLocator")
                          : indicator === "hasSAFPS"
                          ? (documentName = "Fraud Prevention")
                          : indicator === "hasSAFPSPR"
                          ? (documentName = "Fraud Prevention Purpetrator")
                          : indicator}
                      </label>

                      <div className="row">
                        {currentIndicatorDocs.length > 0 &&
                          currentIndicatorDocs.map((value, key) => {
                            return (
                              <Button
                                key={key}
                                style={{ margin: "10px" }}
                                type="default"
                                onClick={() =>
                                  getDownloadUrl({
                                    variables: {
                                      input: {
                                        userId,
                                        forCompany: userId ? false : true,
                                        companyName: userId
                                          ? ""
                                          : company.registeredName,
                                        filePath: value.documentPath
                                      }
                                    }
                                  })
                                }
                              >
                                <Icon type="file" />
                                View {documentName}
                              </Button>
                            );
                          })}
                        {currentIndicatorDocs.length === 0 && (
                          <div
                            className="information-block"
                            style={{ marginLeft: "15px", marginTop: "10px" }}
                          >
                            <span>
                              <label>No documents uploaded</label>
                            </span>
                          </div>
                        )}
                      </div>
                      <Divider />
                    </div>
                  );
                return <></>;
              })}
          </div>
        )}
      </div>
      <br />
      <ResultComment {...ResultComponentsProps} />
    </>
  );
};
export default ReviewDocumentationInformation;
