import React, { useState, useCallback } from "react";
import { Card, Table, Input, Icon, Spin, Button } from "antd";
import { isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_PERMISSIONS } from "../../containers/Admin/graphql/queries";
import {
  UPDATE_PERMISSIONS,
  CREATE_PERMISSIONS,
  DELETE_PERMISSIONS
} from "../../containers/Admin/graphql/mutations";
import { openNotificationWithIcon } from "../../utils/notification";
import EditPermissionsForm from "./EditPermissions";
import { TableLoader } from "./TableContentPlaceholder";
import "../../containers/Projects/project.css";
import InternalErrorPage from "../../containers/InternalErrorPage";
import PermissionsGuard from "../../components/Auth/can";
import AccessDeined from "../../components/Auth/accessDenied";
import { CSVLink } from "react-csv";

function PermissionsList(props) {
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [permissionsList, setPermissionsList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [updatePermissions] = useMutation(UPDATE_PERMISSIONS);
  const [deletePermission] = useMutation(DELETE_PERMISSIONS);
  const [createPermissions] = useMutation(CREATE_PERMISSIONS);
  const [showSpin, setShowSpin] = useState(false);
  const [constantPermissionsList, setConstantPermissionsList] = useState([]);
  const permissions = localStorage.getItem("userPermissions");

  React.useEffect(() => {
    if (permissionsList) {
      if (!isEmpty(searchText)) {
        const reg = new RegExp(searchText, "gi");
        const filteredPermissionsList = permissionsList.filter((record: any) =>
          record.name.match(reg)
        );

        setPermissionsList([...filteredPermissionsList]);
      } else setPermissionsList([...constantPermissionsList]);
    }
  }, [searchText]);

  const onInputChange = e => {
    setSearchText(e.target.value);
  };

  const _deletePermission = id => {
    setShowSpin(true);
    deletePermission({ variables: { id } })
      .then(() => {
        refetch().then(res => setPermissionsList(res.data.permissionsList)); // refetch updated permissions listß
        setShowSpin(false);
        openNotificationWithIcon(
          "success",
          "Delete operation success ",
          "Permission deleted successfully"
        );
      })
      .catch(error => {
        setShowSpin(false);
        openNotificationWithIcon(
          "error",
          "Delete operation error ",
          error.message
        );
      });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: (
        <div className="custom-filter-dropdown">
          <Input
            placeholder="Search name"
            value={searchText}
            onChange={onInputChange}
          />
        </div>
      ),
      filterDropdownVisible: filterDropdownVisible,
      onFilterDropdownVisibleChange: visible =>
        setFilterDropdownVisible(visible)
    },
    { title: "Description", dataIndex: "description", key: "description" },

    {
      title: <span>Action</span>,
      dataIndex: "",
      key: "id",
      render: record => (
        <div>
          <PermissionsGuard
            permissions={permissions}
            perform={"permissions:edit"}
            yes={() => (
              <Icon
                type="edit"
                title="Edit"
                onClick={() => showModal(record)}
              />
            )}
          />
          {"  "}
          <PermissionsGuard
            permissions={permissions}
            perform={"permissions:delete"}
            yes={() => (
              <Icon
                type="delete"
                title="Delete"
                onClick={() => _deletePermission(record.id)}
              />
            )}
          />
        </div>
      )
    }
  ];

  const showModal = e => {
    setSelectedPermissions(e);
    setModalVisible(true);
  };

  const handleCreate = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        return;
      }

      setShowSpin(true);
      const createUpdateMutation = values.id
        ? updatePermissions
        : createPermissions;
      //submit data
      createUpdateMutation({
        variables: {
          id: values.id,
          name: values.name,
          description: values.description,
          permissions: values.permissions,
          userRole: values.userRole
        }
      })
        .then(() => {
          refetch().then(res => setPermissionsList(res.data.permissionsList)); // refetch updated permissions list
          setShowSpin(false);
          openNotificationWithIcon(
            "success",
            "Update success ",
            "Permission updated successfully"
          );
        })
        .catch(error => {
          setShowSpin(false);
          openNotificationWithIcon("error", "Update Error ", error.message);
        });

      formRef.resetFields();
      setModalVisible(false);
    });
  };

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const ModalProps = {
    ref: saveFormRef,
    visible: modalVisible,
    onCancel: () => setModalVisible(false),
    onCreate: () => handleCreate(),
    initialData: selectedPermissions
  };

  const { loading, error, refetch } = useQuery(GET_PERMISSIONS, {
    onCompleted: data => {
      setPermissionsList(data.permissionsList);
      setConstantPermissionsList(data.permissionsList);
    }
  });

  if (loading) return <TableLoader />;
  if (error)
    return (
      <>
        <InternalErrorPage />
      </>
    );

  return (
    <PermissionsGuard
      permissions={permissions}
      perform={"permissions:list"}
      yes={() => (
        <>
          <Spin
            tip="Loading..."
            className="loader"
            style={{ display: showSpin ? "block" : "none" }}
          />
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card style={{ height: "14vh" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px"
                }}
              >
                <Card.Meta
                  title={"Permissions Management"}
                  description={
                    <p style={{ height: "fit-content" }}>
                      Manage New Harmony user roles' permissions
                    </p>
                  }
                />
                <PermissionsGuard
                  permissions={permissions}
                  perform={"permissions:create"}
                  yes={() => (
                    <Button
                      type="primary"
                      style={{ alignSelf: "auto" }}
                      onClick={() => showModal(undefined)}
                    >
                      Add a Permission
                    </Button>
                  )}
                />
              </div>
            </Card>
          </div>
          <div className="col-sm-12 col-md-12 placeholder-table-card">
            <Card>
              <Table
                columns={columns}
                pagination={{ pageSize: 10 }}
                dataSource={permissionsList}
                rowKey={record => record.id}
              />
              <Button
                type="primary"
                style={{ position: "relative", float: "right" }}
              >
                <CSVLink data={permissionsList}>Download</CSVLink>
              </Button>
            </Card>
          </div>
          <EditPermissionsForm {...ModalProps} />
        </>
      )}
      no={() => <AccessDeined permission={"permissions:create"} />}
    />
  );
}

export default PermissionsList;
