import React from "react";
import { Alert } from "antd";
import { format } from "date-fns";

type Props = {
  blacklistData: any;
  type: "company" | "user";
};

function BlacklistStatusAlert({ blacklistData, type }: Props) {
  const entity = type === "company" ? "Company" : "User";
  return (
    <Alert
      showIcon
      message={`${entity} is Blacklisted`}
      description={
        <div>
          <p>{`${format(new Date(blacklistData.createdAt), "dd/MM/yyyy")}: ${
            blacklistData.reason
          }`}</p>
          <p>
            Reach out to {blacklistData.listedBy.firstName}{" "}
            {blacklistData.listedBy.lastName} for more information.
          </p>
        </div>
      }
      type="error"
      style={{ marginBottom: "20px" }}
    />
  );
}

export default BlacklistStatusAlert;
