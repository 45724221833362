import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import * as yup from "yup";
import { Modal, Form, Input, Checkbox, Button } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_USER } from "../../graphql/mutations";
import { openNotificationWithIcon } from "../../utils/notification";
import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from "../../constants";

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
  }
});

const ModalFormComponent = ({
  visible,
  onCancel,
  onCreate,
  form,
  mode,
  emailPrefill = "",
  okText = "Save",
  modalHeading = "Account Confirmation",
  cancelText = "Cancel",
  hideOkButton = false
}) => {
  const { getFieldDecorator } = form;
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [updateUser] = useMutation(UPDATE_USER);

  const validatePasswordResetForm = () => {
    return (
      code.length > 0 && password.length > 0 && password === confirmPassword
    );
  };

  useEffect(() => {
    setConfirmed(false);
    setPassword("");
    setConfirmPassword("");
    setIsConfirming(false);
    setCode("");
  }, [visible]);

  const handlePasswordUpdate = async event => {
    event.preventDefault();

    const schema = yup.object().shape({
      code: yup.string(),
      password: yup
        .string()
        .min(8, "Password has to be longer than 8 characters")
        .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
        .required("A password is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password has to be longer than 8 characters")
        .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
        .required("A password is required")
    });

    const isValid = await schema.isValid({ code, password, confirmPassword });

    if (!isValid) {
      return openNotificationWithIcon(
        "error",
        "Validation Error",
        "Please make sure that the passwords match and the code is correct."
      );
    }
    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        localStorage.getItem("idNumber"),
        code,
        password
      );
      await updateUser({
        variables: {
          input: {
            id: Number(localStorage.getItem("userId")),
            requirePasswordReset: false
          }
        }
      });
      setConfirmed(true);
    } catch (e) {
      setConfirmed(false);
      setIsConfirming(false);
      openNotificationWithIcon("error", "Validation Error", e.message);
    }
  };

  return (
    <Modal
      visible={visible}
      title={modalHeading}
      okText={okText}
      onCancel={onCancel}
      onOk={onCreate}
      cancelText={cancelText}
      okButtonProps={hideOkButton ? { style: { display: "none" } } : {}}
    >
      <Form layout="vertical">
        {mode === "aws-email-confirmation" && (
          <>
            <span>
              It appears your registration has not been confirmed yet. Please
              capture your email address and the confirmation code that has been
              sent to your email.
            </span>
            <br />
            <Form.Item label="ID/Passport Number">
              {getFieldDecorator("idNumber", {
                rules: [
                  {
                    required: true,
                    message: "Please input your ID/Passport number!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Confirmation code">
              {getFieldDecorator("code", {
                rules: [
                  {
                    required: true,
                    message: "Please input the confirmation code!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </>
        )}
        {mode === "update-email-confirmation" && (
          <>
            <span>
              It appears your registration has not been confirmed yet. Please
              confirm your email address below and click on the verification
              link which is emailed to you.
            </span>
            <br />
            <br />
            <Form.Item label="Email address">
              {getFieldDecorator("email", {
                initialValue: emailPrefill,
                rules: [
                  {
                    required: true,
                    message: "Please input your email address!"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("confirm", {
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message: "Please confirm that you understand this."
                  }
                ]
              })(
                <Checkbox>
                  I understand that any updates made to this email address will
                  adjust the email address I use to log in
                </Checkbox>
              )}
            </Form.Item>
          </>
        )}
        {mode === "update-password-confirmation" && (
          <>
            {!confirmed && (
              <>
                <span>
                  We require you to set a new password, this is to ensure that
                  your account is secure and can not be accessed by anyone other
                  than you.
                </span>
                <br />
                <Form.Item
                  label="Confirmation Code"
                  htmlFor="code"
                  style={{ paddingTop: "2em" }}
                >
                  <Input
                    autoFocus
                    type="tel"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                  />
                  <span>
                    Please check your email for the confirmation code.
                  </span>
                </Form.Item>
                <hr />
                <br />
                <Form.Item label="New Password" htmlFor="password">
                  <Input.Password
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Confirm Password" id="confirmPassword">
                  <Input.Password
                    type="password"
                    onChange={e => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={handlePasswordUpdate}
                    loading={isConfirming}
                    disabled={!validatePasswordResetForm()}
                    className="btn-account-confirmation"
                  >
                    Save new password
                  </Button>
                </Form.Item>
              </>
            )}
            {confirmed && (
              <>
                <span>
                  You have successfully updated your password. You may now log
                  in.
                </span>
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

interface IProps extends FormComponentProps {
  emailPrefill?: string;
  mode: string;
}

const ConfirmAccountForm = Form.create<IProps>({
  name: "modal_form"
})(ModalFormComponent);

export default ConfirmAccountForm;
