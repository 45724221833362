import React, { useState, useEffect, useCallback } from "react";
import { Divider, Form, Typography } from "antd";
import { isEmpty } from "lodash";
import "../Register/index.css";
import ContactForm from "../RegisterCompany/contactDetailsForm";
import AddressInfo from "../RegisterCompany/addressForm";
import RelatedPartyForm from "../RegisterCompany/relatedPartyForm";
import { FormComponentProps } from "antd/lib/form/Form";
import { useRegistrationState } from "stores/registration";

function BusinessInfoForm(props: any) {
  const { setRegistrationState } = useRegistrationState();
  const [representativeSaved, setRepresentativeSaved] = useState(false);
  const [companyContactsInfo, setCompanyContactsInfo] = useState([]);
  const [isContactInfoSaved, setIsContactInfoSaved] = useState(false);
  const [
    postalPhysicalAddressesSaved,
    setPostalPhysicalAddressesSaved
  ] = useState(false);
  const [allRequiredContactsSaved, setAllRequiredContactsSaved] = useState(
    false
  );
  const [contactFormRef, setContactFormRef] = useState(null);
  const [addressFormRef, setAddressFormRef] = useState(null);

  useEffect(() => {
    if (contactFormRef) {
      contactFormRef.resetFields();
    }
    if (addressFormRef) {
      addressFormRef.resetFields();
    }
  }, [contactFormRef, addressFormRef]);

  useEffect(() => {
    checkRequiredFormsFilled();
  }, [
    postalPhysicalAddressesSaved,
    allRequiredContactsSaved,
    representativeSaved
  ]);

  const checkRequiredFormsFilled = () => {
    if (
      postalPhysicalAddressesSaved &&
      allRequiredContactsSaved &&
      representativeSaved
    ) {
      setRegistrationState("step2Saved", true);
    }
  };

  const saveContactFormRef = useCallback(node => {
    if (node !== null) {
      setContactFormRef(node);
    }
  }, []);

  const saveAddressFormRef = useCallback(node => {
    if (node !== null) {
      setAddressFormRef(node);
    }
  }, []);

  const ContactFormProps = {
    ref: saveContactFormRef,
    onDone: (
      contactSaved: boolean,
      allRequireContactsSaved: boolean,
      contacts: any
    ) => {
      setIsContactInfoSaved(contactSaved);
      setCompanyContactsInfo(contacts);
      setAllRequiredContactsSaved(allRequireContactsSaved);
      checkRequiredFormsFilled();
    }
  };

  const AddressFormProps = {
    ref: saveAddressFormRef,
    sendAddressInfo: (postalPhysicalSaved: boolean) => {
      setPostalPhysicalAddressesSaved(postalPhysicalSaved);
      checkRequiredFormsFilled();
    }
  };

  const RelatedPartyFormProps = {
    ref: saveAddressFormRef,
    sendRelatedPartyInfo: (relatedPartySaved: boolean) => {
      setRepresentativeSaved(relatedPartySaved);
      checkRequiredFormsFilled();
    }
  };

  const step = {
    title: "Step 2: Business information",
    content: "Complete your business information"
  };

  return (
    <>
      <div>
        <span className="step-title">{step.title}</span>
        <p>{step.content}</p>
        <Divider />
      </div>

      <Typography.Title level={4}>Contact Information</Typography.Title>
      <br />
      <Typography.Paragraph>
        <Typography.Text>
          To add contact information in the table below, select type from the
          dropdown:
        </Typography.Text>
        <br />
        <Typography.Text>
          Mandatory Business Unit Contact to be added included Legal, Finance
          and Administration.
        </Typography.Text>
      </Typography.Paragraph>
      <br />

      <ContactForm {...ContactFormProps} />

      <AddressInfo {...AddressFormProps} />

      {(isContactInfoSaved || !isEmpty(companyContactsInfo)) && (
        <div className="flex-column input-select">
          <Typography.Title level={4}>
            Related Party information
          </Typography.Title>
          <br />
          <Typography.Paragraph>
            Please indicate all related parties in the table below
          </Typography.Paragraph>
          <br />
          <br />
          <RelatedPartyForm {...RelatedPartyFormProps} />
        </div>
      )}
    </>
  );
}

interface IProps extends FormComponentProps {
  nextStep?: () => void;
}

const BusinessInfo = Form.create<IProps>({ name: "normal_register" })(
  BusinessInfoForm
);
export default BusinessInfo;
